import { Component, HostListener, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Menu } from 'src/app/models/menu';
import { Permiso, PermisoAccionList } from 'src/app/models/permiso';
import { Proyecto } from 'src/app/models/proyecto';
import { Usuario } from 'src/app/models/usuario';
import { MenuService } from 'src/app/services/interfaz-inicial/menu.service';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { PermisosService } from 'src/app/services/permisos.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { environment } from 'src/environments/environment';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import { FechaService } from 'src/app/services/servicios-funcionalidad/fecha.service';


@Component({
  selector: 'app-interfaz-inicial',
  templateUrl: './interfaz-inicial.component.html',
  styleUrls: ['./interfaz-inicial.component.scss'],
})
export class InterfazInicialComponent {

  currentUser: Usuario = new Usuario
  paginator: MatPaginatorIntl = new MatPaginatorIntl
  environment = environment;

  listadoProyectos: any[] = []
  proyaux: Proyecto = new Proyecto
  isExpanded: boolean = true
  pantallaChiquita: boolean = true
  getScreenWidth: any;
  menuaux: any;
  isOpened: boolean = true;
  menuListado: Menu[] = []
  img = window.sessionStorage.getItem(environment.IMG_PERFIL)

  tipoUsu: any = {}
  depatamentoUsu: any = {}
  public isSuperAdmin: boolean = false;
  public isLicencia: boolean = false;
  public permisos: PermisoAccionList[] = []
  numOfNotificaciones: number = 0;

  constructor(private router: Router,
    private tokenService: TokenStorageService,
    private menuService: MenuService,
    private permisosService: PermisosProyectoService,
    private proyectoService: ProyectoService,
    private tableService: TableService,
    private notificacionesService: NotificacionesService,
    private location: Location,
    private notificationService: NotificationService,
    private storageService: StorageServiceService,
    private fechaService: FechaService,
    private localStroage: StorageServiceService
  ) {

  }

  async ngOnInit(): Promise<void> {
    const width = window.innerWidth;

    console.log(this.storageService.getLocal("isExpanded"));



    window.addEventListener('resize', () => {
      const width = window.innerWidth;


      if (width < 1750) {
        this.isExpanded = false
        this.pantallaChiquita = false
      } else {
        if (this.storageService.getLocal("isExpanded")) {
          this.isExpanded = this.storageService.getLocal("isExpanded") == "true" ? true : false
        } else {
          this.isExpanded = true
        }
        this.pantallaChiquita = true

      }
    });
    if (width < 1750) {
      this.isExpanded = false
      this.pantallaChiquita = false

    } else {
      if (this.storageService.getLocal("isExpanded")) {

        this.isExpanded = this.storageService.getLocal("isExpanded") == "true" ? true : false
      } else {
        this.isExpanded = true
      }
      this.pantallaChiquita = true

    }

    console.log(this.isExpanded);



    if (window.sessionStorage.getItem("urlPreLogin") != null) {
      let pathAux: any = window.sessionStorage.getItem("urlPreLogin")?.toString()
      window.sessionStorage.removeItem("urlPreLogin")
      this.router.navigate([pathAux])
    }
    this.paginator.itemsPerPageLabel = "Registros Por Página:"
    this.paginator.firstPageLabel = "Primera Página"
    this.paginator.lastPageLabel = "Última Página"
    this.paginator.nextPageLabel = "Siguiente Página"
    this.paginator.previousPageLabel = "Página Anterior"
    this.paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
    this.currentUser = this.tokenService.getUser()
    if (this.currentUser.rol.id == 1) {

      this.isSuperAdmin = true
    } else if (this.currentUser.rol.id == 3) {
      this.isLicencia = true
    }
    if (this.currentUser.active == false) {
      this.notificationService.warning("Alerta", "Usted no esta activo en la aplicación", 5000)
      this.logout()
    }
    /*   if(!sessionStorage.getItem("isExpanded")){
        sessionStorage.setItem("isExpanded",this.isExpanded+"")
      }else{
        //console.log(sessionStorage.getItem("isExpanded"));
        //console.log((sessionStorage.getItem("isExpanded")=="true"));
        
        this.isExpanded = (sessionStorage.getItem("isExpanded")=="true")
      }   */
    if (this.currentUser.proyectos && this.currentUser.proyectos.length != 0
      && this.currentUser.rol.id != 1 && !this.isSuperAdmin && !this.isLicencia) {
      if (!sessionStorage.getItem("listaProyectos")) {
        this.listadoProyectos = this.currentUser.proyectos
        sessionStorage.setItem("listaProyectos", JSON.stringify(this.listadoProyectos))
      } else if (sessionStorage.getItem('listaProyectos') != null) {
        var a: any = sessionStorage.getItem("listaProyectos")
        this.listadoProyectos = JSON.parse(a);
      }
      if (!sessionStorage.getItem("proyAux")) {

        this.proyaux = this.listadoProyectos[0]
        sessionStorage.setItem("proyAux", JSON.stringify(this.proyaux))

      } else if (sessionStorage.getItem('proyAux') && sessionStorage.getItem('proyAux') != "undefined") {
        var a: any = sessionStorage.getItem("proyAux")
        this.proyaux = JSON.parse(a);
      }
      if (sessionStorage.getItem('tipoUsu') && sessionStorage.getItem('tipoUsu') != "undefined") {
        var a: any = sessionStorage.getItem("tipoUsu")
        this.tipoUsu = JSON.parse(a);
      }
      if (sessionStorage.getItem('departamento') && sessionStorage.getItem('departamento') != "undefined") {
        var a: any = sessionStorage.getItem("departamento")
        this.depatamentoUsu = JSON.parse(a);
      }
      if (sessionStorage.getItem('permisosUsu') && sessionStorage.getItem('permisosUsu') != "undefined") {
        var a: any = sessionStorage.getItem("permisosUsu")
        this.permisos = JSON.parse(a);
        //console.log("permisosUsu",this.permisos);

      }

      await this.updatePermisos()

    } else if (this.currentUser.rol.id == 1 && this.isSuperAdmin || this.isLicencia && !this.currentUser.proyectos) {

      this.menuService.getAllMenus().subscribe({
        next: (menus) => {
          this.menuListado = menus
        }
      })
    } else if (this.isLicencia && this.currentUser.proyectos) {

      if (!sessionStorage.getItem("listaProyectos")) {
        this.listadoProyectos = this.currentUser.proyectos
        sessionStorage.setItem("listaProyectos", JSON.stringify(this.listadoProyectos))
      } else if (sessionStorage.getItem('listaProyectos') != null) {
        var a: any = sessionStorage.getItem("listaProyectos")
        this.listadoProyectos = JSON.parse(a);
      }
      if (!sessionStorage.getItem("proyAux")) {

        this.proyaux = this.listadoProyectos[0]
        sessionStorage.setItem("proyAux", JSON.stringify(this.proyaux))

      } else if (sessionStorage.getItem('proyAux') && sessionStorage.getItem('proyAux') != "undefined") {
        var a: any = sessionStorage.getItem("proyAux")

        this.proyaux = JSON.parse(a);
        //console.log(this.proyaux);

      }


      this.menuService.getAllMenus().subscribe({
        next: (menus) => {
          this.menuListado = menus
        }
      })
    } else {
      this.notificationService.warning("Alerta", "Usted no tiene ningun proyecto asignado", 5000)
      this.logout()
    }
    if (this.proyaux != undefined) {
      //console.log(this.proyaux);

      this.tableService.proy = this.proyaux
    }
    this.comprobarNotificacionesPendientes()
  }

  /**
   * Escucha el evento de cambio de tamaño de la ventana y actualiza el ancho de la pantalla.
   * 
   * @param event El evento de cambio de tamaño de la ventana.
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }


  /**
   * Navega a la URL especificada, actualizando el estado de las notificaciones si la URL es "/notificaciones".
   * 
   * @param url La URL a la que se va a navegar.
   * @param permiso El permiso necesario para acceder a la URL (opcional).
   */
  navegar(url: any, permiso?: any) {
    console.log(url);

    if(url.toLowerCase().includes("mailto")) {
      window.open(url);
      return;
    }

    if (url != "/enDesarrollo") {

      if (this.currentUser.rol.id == 1 && this.isSuperAdmin || this.currentUser.rol.id == 3 && this.isLicencia) {
        this.router.navigate(["admin-contacine" + url])

      } else {
        if (!permiso) {
          this.router.navigate(["admin-contacine" + url])

        } else if (this.permisos.some(e =>
          e.permiso.id === permiso
        )) {
          this.router.navigate(["admin-contacine" + url])
        }

      }
    } else {
      this.notificationService.warning("Pagina no disponible", "Esta pagina no se encuentra actualmente activa", 5000)
    }
  }

  /**
   * Alterna el estado del menu (drawer) entre abierto y cerrado.
   * 
   * @param drawer El menu (drawer) que se va a alterar.
   */
  cambiarEstado(drawer: any) {
    drawer.toggle()
    this.isExpanded = !this.isExpanded
  }

  /**
   * Abre o cierra el menú desplegable del menu.
   * 
   * @param e El evento que activa la función.
   */
  openMenu(e: any) {
    let arrowParent = e.target.parentElement.parentElement;//selecting main parent of arrow
    arrowParent.classList.toggle("showMenu")
  }

  /**
   * Obtiene la primera letra de una cadena y la convierte en mayúscula.
   * 
   * @param nombre El nombre del que se obtendrá la primera letra.
   * @returns La primera letra en mayúscula.
   */
  getFirstLetter(nombre: string) {
    if (nombre) {
      return nombre.charAt(0).toUpperCase();
    }
    return
  }

  /**
  * Cierra la sesión del usuario, eliminando los permisos y redirigiéndolo a la página de inicio.
  */
  logout() {
    this.permisos = []
    this.tokenService.signOut();
    this.router.navigate([""]);
  }

  /**
   * Busca los permisos del usuario para el proyecto seleccionado y actualiza la lista de menús disponibles.
   * 
   * @param event El evento de selección.
   * @param proyecto El proyecto seleccionado.
   */
  searchPermisosProy(event: any, proyecto: Proyecto) {


    if (event.isUserInput) {
      this.storageService.vaciar()


      this.proyaux = proyecto
      sessionStorage.setItem("proyAux", JSON.stringify(this.proyaux))

      this.tableService.setProyecto(this.proyaux);
      if (this.location.path().toString().includes("ver") || this.location.path().toString().includes("editar") || this.location.path().toString().includes("nueva") || this.location.path().toString().includes("nuevo")) {
        let pathAuxiliar = this.location.path()
        if (pathAuxiliar.includes("proyecto")) {
          this.router.navigate(["/admin-contacine/proyectos"])
        } else if (pathAuxiliar.includes("gasto")) {
          this.router.navigate(["/admin-contacine/registro/gastos"])
        } else if (pathAuxiliar.includes("orden-compra")) {
          this.router.navigate(["/admin-contacine/orden-compra"])
        } else if (pathAuxiliar.includes("proveedor")) {
          this.router.navigate(["/admin-contacine/proveedores"])
        } else if (pathAuxiliar.includes("usuario")) {
          this.router.navigate(["/admin-contacine/mantenimiento/usuarios"])
        } else if (pathAuxiliar.includes("cuenta")) {
          this.router.navigate(["/admin-contacine/mantenimiento/cuentas"])
        } else if (pathAuxiliar.includes("productora")) {
          this.router.navigate(["/admin-contacine/mantenimiento/productoras"])
        }
      }
      if (!this.isLicencia) {
        this.updatePermisos()
      }
    }
    if (!this.isSuperAdmin) {

      this.getFechas()
    }
  }
  semanaActual = ""
  fechaActual = ""
  getFechas() {
    this.fechaActual = this.fechaService.type(new Date)

    this.proyectoService.getSem(this.proyaux.id).subscribe({
      next: (data) => {
        console.log(data);

        if (data && data.semanas && data.text) {
          this.semanaActual = data.text + ' ' + data.semanas
        }else if(data && data.text){
          this.semanaActual = data.text
        } else {
          this.semanaActual = " Sin fechas"
        }

      }
    })
  }
  /**
   * Actualiza los permisos del usuario y comprueba si hay notificaciones pendientes.
   */
  updatePermisos() {

    if (!this.isSuperAdmin) {
      if (this.numOfNotificaciones != 0) {
        this.comprobarNotificacionesPendientes()
      }
    }
    this.getFechas()

    this.menuListado = []
    this.permisosService.getPermisosFormPreyectoAndUsu(this.proyaux.id, this.currentUser.id).subscribe({
      next: (data) => {
        if (data.permisos) {
          this.permisos = [...data.permisos]

        } else {
          this.notificationService.warning("ALERTA", "No tienes permisos en este proyecto, contacte con un administrador.", 5000)
          this.logout()
        }
        sessionStorage.setItem("permisosUsu", JSON.stringify(this.permisos))
        //console.log(data);
        this.tipoUsu = data.tipoUsu
        //console.log("tipo USu", this.tipoUsu);
        sessionStorage.setItem("tipoUsu", JSON.stringify(this.tipoUsu))
        this.depatamentoUsu = data.departamento
        sessionStorage.setItem("departamento", JSON.stringify(this.depatamentoUsu))
        this.menuService.getAllMenus().subscribe({
          next: (menus) => {
            menus.forEach((menu: Menu) => {

              if (menu.perfil) {
                this.menuListado.push(menu)
              } else {
                this.permisos.forEach((permiso) => {
                  if (menu.idPermiso == permiso.permiso.id) {
                    if (!this.menuListado.some(e =>
                      e.id === menu.id
                    )) {
                      this.menuListado.push(menu);
                    }
                  }

                })
              }
            });
            menus.forEach((menu: Menu) => {
              this.menuListado.forEach(menuH => {
                if (menu.listadoHijos.some(e =>
                  e.id === menuH.id
                )) {
                  if (!this.menuListado.some(e =>
                    e.id === menu.id
                  )) {
                    this.menuListado.push(menu);
                  }
                }
              })

            })



            this.menuListado.sort((a, b) => a.id - b.id);
          }
        })
      }

    })

  }

  /**
   * Verifica si un elemento hijo existe en la lista de menús.
   * 
   * @param child El elemento hijo a verificar.
   * @returns true si el elemento hijo existe, false de lo contrario.
   */
  checkExists(child: Menu): boolean {
    return this.menuListado.some(e =>
      e.id === child.id
    )
  }

  /**
   * Comprueba si hay notificaciones pendientes y actualiza el número de notificaciones.
   */
  comprobarNotificacionesPendientes() {
    this.numOfNotificaciones = 0
    if (this.proyaux && this.proyaux.id) {

      this.notificacionesService.findByUsuario(this.tokenService.getUser().id
        , this.proyaux.id).subscribe({
          next: (data: any) => {
            if (data == null) {
              this.numOfNotificaciones = 0
            } else {
              data.forEach((element: any) => {
                if (!element.estado) {
                  this.numOfNotificaciones += 1;
                }
              });
            }


          },
          error: (err) => {
          }
        })
    }
    console.log("notificaciones -> ", this.numOfNotificaciones)
  }
  /**
  * Compara dos objetos para verificar si son iguales basándose en su propiedad `id`.
  *
  * @param obj1 - El primer objeto a comparar.
  * @param obj2 - El segundo objeto a comparar.
  * @returns Devuelve `true` si los objetos son iguales basándose en su propiedad `id`, de lo contrario devuelve `false`.
  */
  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id;
  }

  checkTip(element: any): any {
    //console.log(element);

    //var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    /*  if (etiqueta && etiqueta.descripcion) {
       return " - " +etiqueta.descripcion
     }else{
       return ""
     } */
  }

  chageExpanded() {
    this.isExpanded = !this.isExpanded
    this.storageService.guardarLocal("isExpanded", this.isExpanded);
    console.log(this.storageService.getLocal("isExpanded"));

  }

}
