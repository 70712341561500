import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  url: string = environment.API_URL + "/menu";
  /* 
  Servicio para la peticion al back para el listado de los menus
  */

  constructor(private http: HttpClient
  ) { }

  getAllMenus(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }

}


