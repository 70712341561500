import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaboralService {
  API_URL = environment.API_URL;
  /* 
  Servicio para las peticiones al back para la modificacion,guardado y listado de gastos
  */
  constructor(private http: HttpClient) { }
  getLaboral(request: any) {
    return this.http.post(`${this.API_URL}/laboral/filter`, request);
  }

  importLaboral(file: File, proyectoid: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('proyectoId', proyectoid);
    return this.http.post(`${this.API_URL}/laboral/import`, formData);
  }


  delete(id: any) {
    return this.http.get(`${this.API_URL}/laboral/delete/${id}`);
  }

  pasarACoste(id: any) {
    console.log("idproy",id);
    
    return this.http.get(`${this.API_URL}/laboral/pasarCoste/${id}`);
  }
}
