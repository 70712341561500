
import {
    animate,
    keyframes,
    query,
    stagger,
    state,
    style,
    transition,
    trigger,
  } from '@angular/animations';
export const dropdownAnimation = trigger('slideInOut', [
    state('in', style({
      overflow: 'hidden',
      height: '*',
      width: '100%',
      display: 'block'
    })),
    state('out', style({
      opacity: '0',
      overflow: 'hidden',
      height: '0px',
      'padding-top': '0', 
      'padding-bottom': '0',
      width: '100%',
      display: 'none'
    })),
    transition('in => out', [style({display: 'block'}),animate('400ms ease-in-out')]),
    transition('out => in', [style({display: 'none'}),animate('400ms ease-in-out')])
  ])