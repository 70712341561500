import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Productora } from 'src/app/models/productora';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductoraService } from 'src/app/services/productora.service ';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';

@Component({
  selector: 'app-nueva-productora',
  templateUrl: './nueva-productora.component.html',
  styleUrls: ['./nueva-productora.component.scss']
})
export class NuevaProductoraComponent {

  loading: boolean = false
  formArray: any = []

  isUpdate: boolean = false
  isReading: boolean = false
  id!: number
  productora: Productora = new Productora
  productoraAux: Productora = new Productora
  expandedTelefono = 1
  proyectosList: any = []
  proyAux: any = []

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Productora"
  nombrePagina = "Productora"

  private formBuilder: FormBuilder = new FormBuilder

  form!: FormGroup;

  get bancosIban(): FormArray {
    return this.form.get("bancosIban") as FormArray;
  }
  get telefonos(): FormArray {
    return this.form.get("telefonos") as FormArray;
  }

  isAdmin = false


  constructor(
    private notificationService: NotificationService,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
    private route: ActivatedRoute,
    private productoraService: ProductoraService,
    private proyectoService: ProyectoService,
    private storageService: StorageServiceService,
    private interfazInicial: InterfazInicialComponent,
    private tableService: TableService

  ) { }

  ngOnInit(): void {

    this.findAllProyectos()
    this.dateAdapter.setLocale('es-ES');

    if (this.interfazInicial.isLicencia || this.interfazInicial.isSuperAdmin) {
      this.isAdmin = true
    }

    if (this.tableService.checkAnyAccionByPermiso(24, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    } else {

      this.route.params.subscribe(params => {
        if (params['id']) {
          this.id = params['id'];


          console.log(this.route.url.subscribe(data => {
            if (data[0].path.includes("ver")) {
              this.isReading = true
              if (this.tableService.checkPermiso(24, 1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            } else if (data[0].path.includes("editar")) {
              this.isUpdate = true
              if (this.tableService.checkPermiso(24, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            }


          }));


        } else {
          if (this.tableService.checkPermiso(24, 2, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
            this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
            this.router.navigate(["admin-contacine/proyectos"])
          }
        }
      })
    }
    var storedFormData: any = {}
    if (!this.isUpdate && !this.isReading) {
      try {
        storedFormData = JSON.parse(this.storageService.get('productoraForm') ? this.storageService.get('productoraForm') : "");
      } catch {

      }
    }

    this.form = new FormGroup({
      id: new FormControl(''),
      cif: new FormControl({ value: storedFormData.cif || "", disabled: this.isReading }, Validators.required),
      nombre: new FormControl({ value: storedFormData.nombre || "", disabled: this.isReading }, Validators.required),
      direccion: new FormControl({ value: storedFormData.direccion || "", disabled: this.isReading }, Validators.required),
      codigoPostal: new FormControl({ value: storedFormData.codigoPostal || "", disabled: this.isReading }, [Validators.required]),
      ciudad: new FormControl({ value: storedFormData.ciudad || "", disabled: this.isReading }, Validators.required),
      telefonos: this.camposTelefono(storedFormData),
      seguridadSocial: new FormControl({ value: storedFormData.seguridadSocial || "", disabled: this.isReading }, [Validators.pattern('^[0-9]{15}$')]),
      pais: new FormControl({ value: storedFormData.pais || "", disabled: this.isReading }, Validators.required),
      proyectos: new FormControl({ value: [{ id: this.interfazInicial.proyaux.id }], disabled: this.isReading }),
      bancosIban: new FormArray([
        new FormGroup({
          id: new FormControl({ value: "", disabled: this.isReading }),
          nombreBanco: new FormControl({ value: "", disabled: this.isReading }, Validators.required),
          iban: new FormControl({ value: "", disabled: this.isReading }, Validators.required),
        }),
      ]),
    });

    if (storedFormData.bancosIban) {
      this.bancosIban.clear()
      storedFormData.bancosIban.forEach((ban: any) => {
        this.addBancosIbanUpdate(
          ban.nombreBanco,
          ban.iban
        );
      })
    }
    if (this.isUpdate || this.isReading && this.id) {
      this.productoraService.findById(this.id).subscribe((data) => {
        this.bancosIban.clear();

        this.productora = new Productora(data)
        this.productora.proyectos?.forEach((data) => {
          this.proyAux.push({ id: data.id })
        })
        this.form.get('cif')?.setValue(this.productora.cif)
        this.form.get('nombre')?.setValue(this.productora.nombre)
        this.form.get('direccion')?.setValue(this.productora.direccion)
        this.form.get('codigoPostal')?.setValue(this.productora.codigoPostal)
        this.form.get('ciudad')?.setValue(this.productora.ciudad)
        this.form.controls['telefonos'] = this.camposTelefono(storedFormData)
        this.form.get('seguridadSocial')?.setValue(this.productora.seguridadSocial)
        this.form.get('pais')?.setValue(this.productora.pais);
        this.form.get("proyectos")?.setValue(this.proyAux)

        this.productora.bancosIban.forEach((ban) => {
          this.addBancosIbanUpdate(
            ban.nombreBanco,
            ban.iban
          );
        })

      })

    }

  }

  /**
   * Obtiene todos los proyectos y los almacena en la lista de proyectos.
   */
  findAllProyectos() {
    this.proyectoService.getAllProyectos().subscribe({
      next: (data) => {
        console.log(data)
        this.proyectosList = data;
      },
      error: (error) => {
        console.log(error)
      },
    });
  }

  /**
   * Agrega un nuevo banco y su correspondiente IBAN al FomrArray .
   * @param nombre Nombre del banco.
   * @param iban IBAN del banco.
   */
  addBancosIbanUpdate(nombre: any, iban: any) {

    const metadato = this.formBuilder.group({
      nombreBanco: new FormControl({ value: nombre, disabled: this.isReading }),
      iban: new FormControl({ value: iban, disabled: this.isReading }),
    });

    this.bancosIban.push(metadato);


  }

  /**
   * Crea un FormArray para los teléfonos de la productora.
   * @returns FormArray para los teléfonos.
   */
  camposTelefono(storage: any) {
    var telefonos = this.productora?.telefonos;

    if (storage.telefonos) {
      telefonos = storage.telefonos
    }
    if (telefonos == null || telefonos.length == 0) {
      var Telefono = new FormArray([new FormControl({ value: "", disabled: this.isReading }, [Validators.required, Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)])])

    }
    else {
      var Telefonoup = new FormArray([new FormControl({ value: telefonos[0].toString(), disabled: this.isReading })])
      for (let i = 1; i < telefonos.length; i++) {
        Telefonoup.push(new FormControl({ value: telefonos[i].toString(), disabled: this.isReading }));
        this.expandedTelefono++;
      }
      return Telefonoup
    }
    return Telefono;
  }
  /**
   * Agrega un nuevo campo de teléfono al FormArray.
   */
  addTelefono() {
    let lug = this.form.controls['telefonos'] as FormArray;
    lug.push(
      new FormControl('', [Validators.required, Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)]),
    )
    this.expandedTelefono++;
  }

  /**
   * Elimina un campo de teléfono del FormArray.
   * @param i Índice del campo de teléfono a eliminar.
   */
  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedTelefono--;
  }

  /**
   * Maneja el envío del formulario guardado o actualizacion de la nueva productora.
   */
  onSubmit() {
    this.isSave = false

    if (!this.isReading) {

      if (this.form.valid) {
        if (!this.isUpdate) {
          console.log(new Productora(this.form.value));
          this.loading = true;
          this.productoraService.save(new Productora(this.form.value)).subscribe({
            next: (data) => {
              this.loading = false;
              this.storageService.eliminar("productoraForm")
              this.notificationService.success("Guardado", "La productora se ha guardado correctamente.", 5000);
              this.router.navigate(['admin-contacine/mantenimiento/productoras']);
              //TODO
            },
            error: (err) => {
              this.loading = false;
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })
        } else {

          this.form.get('id')?.setValue(this.productora.id)
          this.loading = true;
          console.log(new Productora(this.form.value));

          this.productoraService.update(this.id, new Productora(this.form.value)).subscribe({
            next: (data) => {
              //TODO
              this.loading = false;
              this.notificationService.success("Guardado", "La productora se ha actualizado correctamente.", 5000);

              this.router.navigate(['admin-contacine/mantenimiento/productoras']);

            },
            error: (err) => {
              this.loading = false;
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })
        }
      } else {
        this.notificationService.warning("ALERTA", "Rellene todos los campos.", 5000)
      }
    } else {
      this.router.navigate(['admin-contacine/mantenimiento/productoras'])
    }


  }

  /**
   * Agrega un nuevo formulario para banco e IBAN al FormArray principal.
   */
  addbancosIban(): void {
    const metadato = this.formBuilder.group({
      nombreBanco: new FormControl({ value: "", disabled: this.isReading }),
      iban: new FormControl({ value: "", disabled: this.isReading }),
    });

    this.bancosIban.push(metadato);
  }

  /**
   * Elimina un formulario de banco e IBAN del FormArray principal.
   * @param index Índice del formulario a eliminar.
   */
  removebancosIban(index: number): void {
    this.bancosIban.removeAt(index);
  }

  /**
   * Compara dos objetos y devuelve true si son iguales o sus identificadores son iguales.
   * @param obj1 Primer objeto a comparar.
   * @param obj2 Segundo objeto a comparar.
   * @returns True si los objetos son iguales o sus identificadores son iguales, false en caso contrario.
   */
  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id

  }


  volver() {
    this.router.navigate(['admin-contacine/mantenimiento/productoras'])
  }

  onFormChange() {

    if (!this.isUpdate && !this.isReading) {
      this.storageService.guardar('productoraForm', JSON.stringify(this.form.value));
    }
  }

  limparFormulario() {
    this.storageService.eliminar('productoraForm');
    this.form.reset()
  }


  existProdu: any
  modalExistProdu = false

  checkNifCifExists() {
    if (this.form.get("cif")?.value && !this.isAdmin && !this.isUpdate && !this.isReading) {
      this.productoraService.findByNifCif(this.form.get("cif")?.value).subscribe({
        next: (data) => {
          this.existProdu = data
          this.modalExistProdu = true
        },
        error: (error) => {
          console.log(error);
        }
      })
    }
  }

  asociarProyExit() {
    this.proyectoService.asociarProyProdExit(this.existProdu.id, this.interfazInicial.proyaux.id).subscribe({
      next: (data: any) => {

        this.loading = false;
        this.storageService.eliminar("productoraForm")

        this.modalExistProdu = false
        this.router.navigateByUrl("/admin-contacine/editar-productora/" + this.existProdu.id)
        this.notificationService.success('ÉXITO', 'Datos recuperados con éxito.', 5000);
      },
      error: (err) => {
        this.modalExistProdu = false
        this.notificationService.error('ERROR', 'Productora no asociada.', 5000);

      }
    }

    )

  }

}
