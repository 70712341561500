<app-header-listados [nombre]="nombrePagina" [subTipos]="subTipos" [(exportPDF)]="export"
    [(eventoTipo)]="eventoTipo" [(eventoSubTipo)]="eventoSubTipo"></app-header-listados>
<div>
    <div>
        <div class="fondoblanco">
            <!--  <div class="contenedor-principal">
                <div class="contenedor-principal-izq">
                </div>
            </div> -->
            <span *ngIf="eventoTipo?checkInfoSub(eventoTipo):''"></span>
            <span *ngIf="eventoSubTipo?checkInfoData(eventoSubTipo):''"></span>
            <span *ngIf="export?generarPDF(contentPdf):''"></span>
            <span #contentPdf>
            
                <div id="informe" *ngIf="array && array.length != 0">

                    <div id="cabecera-informe" class="container-fluid header">
                        <div class="row align-items-center">
                          <div class="col-md-6 logo">
                            <img src="assets/contacineLogos/Cc_logo.png">
                            <img src="assets/contacineLogos/CC_logo_texto.png" style="height: 30px; margin-left: 10px;">
                          </div>
                          <div class="col-md-6 header-text">
                            <div id="titulo-informe" style="margin-bottom: 5px;">Informe | {{ informeTipo }} | {{ informeSubtipo }}</div>
                            <div id="fecha-informe">{{ informeFecha }}</div>
                          </div>
                        </div>
                      </div>

                      <table id="datos-informe" class="col-12">

                        <ng-container *ngIf="subData.id == 1 || subData.id == 2 || subData.id == 3">
                            <thead>
                                <tr class="header-tabla-inf">
                                    <th class="col-4 ml-2">{{subData.nombre}}</th>
                                    <th class="col-1 right">Total</th>
                                    <th class="col-2 right">Presupuesto</th>
                                    <th class="col-1 right">Coste</th>
                                    <th class="col-1 right">Orden Compra</th>
                                    <th class="col-1 right">Previsto</th>
                                    <th class="col-1 right">Proyec. Cierre</th>
                                    <th class="col-1 right">Desviación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <span *ngFor="let a of array; let i = index">
                                    
                                    <tr class="datos-tabla info-Cap" *ngIf="checkCap(a,i)">
    
                                        <td class=" desc col-4">{{informe[a][0].codigo.capitulo.nombre}}</td>
                                        <td class=" col-1 right">
                                            {{calcularTotalCostesCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularTotalCostesCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            | numberEsp)+'€':''}}</td>
                                        <td class=" col-2  right">
                                            {{calcularPresuCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuCosteCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">
                                            {{calcularCosteCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularCosteCosteCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">
                                            {{calcularOCCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularOCCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">
                                            {{calcularPrevistoCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPrevistoCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">
                                            {{calcularProyeccionCierreCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularProyeccionCierreCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">
                                            {{calcularDesviacionCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularDesviacionCosteCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€':''}}</td>
    
                                    </tr>

                                    <tr class="datos-tabla info-subCap" *ngIf="checkSubCap(a,i)">
    
                                        <td class=" desc col-4">{{informe[a][0].codigo.subcapitulo.codigo|codigosCuentas}} -
                                            {{informe[a][0].codigo.subcapitulo.nombre}}</td>
                                        <td class=" col-1 right">{{calcularTotalCostesCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularTotalCostesCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1)
                                            | numberEsp)+'€':''}}</td>
                                        <td class=" col-2  right">{{calcularPresuCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularCosteCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularCosteCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularOCCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ? (calcularOCCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularPrevistoCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPrevistoCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) | numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularProyeccionCierreCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularProyeccionCierreCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) | numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularDesviacionCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularDesviacionCosteCuentas(
                                            (informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre,1) | numberEsp)+'€':''}}</td>
    
                                    </tr>

                                    <tr class="pointer datos-tabla">
                                        <td class=" tit col-4">{{a}}</td>
                                        <td class=" col-1 right">{{calcularTotalCostesCuentas(a,0) ?
                                            (calcularTotalCostesCuentas(a,0)
                                            | numberEsp)+'€':''}}</td>
                                        <td class=" col-2  right">{{calcularPresuCosteCuentas(a,0) ?
                                            (calcularPresuCosteCuentas(a,0)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularCosteCosteCuentas(a,0) ?
                                            (calcularCosteCosteCuentas(a,0)
                                            |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularOCCosteCuentas(a,0) ?
                                            (calcularOCCosteCuentas(a,0) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularPrevistoCosteCuentas(a,0) ?
                                            (calcularPrevistoCosteCuentas(a,0) | numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularProyeccionCierreCosteCuentas(a,0) ?
                                            (calcularProyeccionCierreCosteCuentas(a,0) | numberEsp)+'€':''}}</td>
                                        <td class="col-1  right">{{calcularDesviacionCosteCuentas(a,0) ?
                                            (calcularDesviacionCosteCuentas(a,0) | numberEsp)+'€':''}}</td>
                                    </tr>
                                </span>
                            </tbody>
                            <tfoot>
                                <tr class="totales-tabla">
                                    <td class="col-4">Totales</td>
                                    <td class="col-1 right">{{totalCuentas ? (totalCuentas | numberEsp)+'€':''}}</td>
                                    <td class="col-2 right">{{presuCuentas ? (presuCuentas | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{costeCuentas ? (costeCuentas | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{oCCuentas ? (oCCuentas | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{previstoCuentas ? (previstoCuentas | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{proyeccionCierreCuentas ? (proyeccionCierreCuentas |
                                        numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{desviacionCuentas ? (desviacionCuentas | numberEsp)+'€':''}}
                                    </td>
                                </tr>
                            </tfoot>
                        </ng-container>
                        <ng-container *ngIf="subData.id == 4 || subData.id == 5 || subData.id == 6">
                            <thead>
                                <tr class="header-tabla-inf">
                                    <th [ngClass]="subData.id==6?'col-4':'col-5'">{{subData.nombre}}</th>
                                    <th class="col-1 right" *ngIf="subData.id==6">Tipo</th>
                                    <th class="col-1 text-center">P</th>
                                    <th class="col-1 text-center">R</th>
                                    <th class="col-1 text-center">C</th>
                                    <th class="col-1 text-center">T. Unid.</th>
                                    <th class="col-1 right">Cantidad</th>
                                    <th class="col-2 right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <span *ngFor="let a of array; let i = index">
                                    <tr class="datos-tabla info-Cap" *ngIf="checkCap(a,i)">
    
                                        <td class="desc" [ngClass]="subData.id==6?'col-4':'col-5'">
                                            {{informe[a][0].codigo.capitulo.nombre}}</td>
                                        <td *ngIf="subData.id==6" class="col-1"></td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuPCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuPCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuRCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuRCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuCCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuCCuentas(informe[a][0].codigo.capitulo.nombre,2)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuTUnidCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuTUnidCuentas(informe[a][0].codigo.capitulo.nombre,2) | numberEsp)
                                            : ''}}</td>
                                        <td class="col-1 right">
                                            {{calcularPresuCantidadCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuCantidadCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€' : ''}}</td>
                                        <td class="col-2 right">
                                            {{calcularPresuTotalCuentas(informe[a][0].codigo.capitulo.nombre,2) ?
                                            (calcularPresuTotalCuentas(informe[a][0].codigo.capitulo.nombre,2) |
                                            numberEsp)+'€' : ''}}</td>
    
                                    </tr>
                                    <tr class="datos-tabla info-subCap" *ngIf="checkSubCap(a,i)">
    
                                        <td class="desc" [ngClass]="subData.id==6?'col-4':'col-5'">
                                            {{(informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+" - "+
                                            informe[a][0].codigo.subcapitulo.nombre}}</td>
                                        <td *ngIf="subData.id==6" class="col-1"></td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuPCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuPCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuRCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuRCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuCCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuCCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">
                                            {{calcularPresuTUnidCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuTUnidCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) | numberEsp) : ''}}</td>
                                        <td class="col-1 right">
                                            {{calcularPresuCantidadCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuCantidadCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) | numberEsp)+'€' : ''}}</td>
                                        <td class="col-2 right">
                                            {{calcularPresuTotalCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) ?
                                            (calcularPresuTotalCuentas((informe[a][0].codigo.subcapitulo.codigo|codigosCuentas)+"
                                            - "+ informe[a][0].codigo.subcapitulo.nombre,1) |
                                            numberEsp)+'€' : ''}}</td>
                                    </tr>
                                    <tr class="pointer datos-tabla">
                                        <td class="tit" [ngClass]="subData.id==6?'col-4':'col-5'">{{a}}</td>
                                        <td *ngIf="subData.id==6" class="col-1">{{informe[a][0].tipoUnidad?informe[a][0].tipoUnidad.nombre:""}}</td>
                                        <td class="col-1 text-center">{{calcularPresuPCuentas(a,0) ?
                                            (calcularPresuPCuentas(a,0)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">{{calcularPresuRCuentas(a,0) ?
                                            (calcularPresuRCuentas(a,0)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">{{calcularPresuCCuentas(a,0) ?
                                            (calcularPresuCCuentas(a,0)
                                            |
                                            numberEsp) : ''}}</td>
                                        <td class="col-1 text-center">{{calcularPresuTUnidCuentas(a,0) ?
                                            (calcularPresuTUnidCuentas(a,0) | numberEsp) : ''}}</td>
                                        <td class="col-1 right">{{calcularPresuCantidadCuentas(a,0) ?
                                            (calcularPresuCantidadCuentas(a,0) | numberEsp)+'€' : ''}}</td>
                                        <td class="col-2 right">{{calcularPresuTotalCuentas(a,0) ?
                                            (calcularPresuTotalCuentas(a,0) |
                                            numberEsp)+'€' : ''}}</td>
                                    </tr>
                                </span>
                            </tbody>
                            <tfoot>
                                <tr class=" totales-tabla">
                                    <td class="col-5">Totales</td>
                                    <td class="col-1 text-center">{{semanasP ? (semanasP | numberEsp) : ''}}</td>
                                    <td class="col-1 text-center">{{semanasR ? (semanasR | numberEsp) : ''}}</td>
                                    <td class="col-1 text-center">{{semanasC ? (semanasC | numberEsp) : ''}}</td>
                                    <td class="col-1 text-center">{{totalUnid ? (totalUnid | numberEsp) : ''}}</td>
                                    <td class="col-1 right">{{cantidadPresu ? (cantidadPresu | numberEsp)+'€' : ''}}</td>
                                    <td class="col-2 right">{{totalPresu ? (totalPresu | numberEsp)+'€' : ''}}</td>
                                </tr>
                            </tfoot>
                        </ng-container>
                        <ng-container *ngIf="subData.id == 7 || subData.id == 8">
                            <thead>
                                <tr class=" header-tabla-inf">
                                    <th class="tit" [ngClass]="subData.id==7?'col-2':'col-1'">{{subData.id==7?
                                        'Nombre proveedor':'Código'}}</th>
                                    <th [ngClass]="subData.id==7?'col-1':'col-2'">{{subData.id==7?
                                        'Código':'Nombre proveedor'}}</th>
                                    <th class="col-1 text-center">Concepto</th>
                                    <th class="col-1 text-center">Nº Reg</th>
                                    <th class="col-1 text-center">Fecha</th>
                                    <th class="col-1 text-center">Nº Factura</th>
                                    <th class="col-1 text-center">Descripción</th>
                                    <th class="col-1 text-center">Base</th>
                                    <th class="col-1 text-center">IVA</th>
                                    <th class="col-1 text-center">IRPF</th>
                                    <th class="col-1 text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let a of array">
    
                                    <tr *ngFor="let fact of informe[a]" class="pointer datos-tabla">
                                        <td class="left" [ngClass]="subData.id==7?'col-2':'col-1'">{{subData.id==7?a:(a|
                                            codigosCuentas)}}</td>
                                        <td class="left" [ngClass]="subData.id==7?'col-1':'col-2'">{{subData.id==7?
                                            (fact.conceptos[0].codigo.cuenta |
                                            codigosCuentas):fact.proveedor?fact.proveedor.nombreFiscal:""}}</td>
                                        <td class="col-1 text-center">{{fact.conceptos[0].descripcion}}</td>
                                        <td class="col-1 text-center">{{fact.id}}</td>
                                        <td class="col-1 text-center">{{fact.fecha_factura}}</td>
                                        <td class="col-1 text-center">{{fact.numero_factura}}</td>
                                        <td class="col-1 text-center">???</td>
                                        <td class="col-1 right">{{fact.base?(fact.base | numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{fact.iva?(fact.iva | numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{fact.irpf?(fact.irpf | numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{fact.total+fact.total_pagado?
                                            (fact.total+fact.total_pagado | numberEsp)+'€':''}}</td>
                                    </tr>
                                    <tr class=" totales-minis">
    
                                        <td class="col-8 desc">{{a}}</td>
                                        <td class="col-1 right">{{calcularFactBase(a)?(calcularFactBase(a) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{calcularFactIva(a)?(calcularFactIva(a) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{calcularFactIrpf(a)?(calcularFactIrpf(a) |
                                            numberEsp)+'€':''}}</td>
                                        <td class="col-1 right">{{calcularFactTotal(a)?(calcularFactTotal(a) |
                                            numberEsp)+'€':''}}</td>
    
                                    </tr>
                                </ng-container>
                                <tr class=" totales-tabla">
    
                                    <td class="col-8">Totales</td>
                                    <td class="col-1 right">{{factBaseTot ? (factBaseTot | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{factIvaTot ? (factIvaTot | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{factIrpfTot ? (factIrpfTot | numberEsp)+'€':''}}</td>
                                    <td class="col-1 right">{{factTotalTot ? (factTotalTot | numberEsp)+'€':''}}</td>
    
                                </tr>
                            </tbody>
                        </ng-container>
    
                    </table>

                </div>
            </span>

            <div *ngIf="loading" class="sppiner_file">
                <p class="wi-100">Cargando informe...</p>
                <div class="wi-100">
                    <div class="spinner-border" role="status"></div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="sppiner-container" *ngIf="chargeDoc">
    <div class="spinner-border" role="status"></div>

    <!-- <span *ngIf="array && array.length!=0">
    <span *ngIf="subData.id == 1||subData.id == 2||subData.id == 3">

        <div class="col-12 header-tabla-inf">
            <label class="col-4">{{subData.nombre}}</label>
            <label class="col-1 text-center">Total</label>
            <label class="col-2 text-center">Presupuesto</label>
            <label class="col-1 text-center">Coste</label>
            <label class="col-1 text-center">Orden Compra</label>
            <label class="col-1 text-center">Previsto</label>
            <label class="col-1 text-center">Proyec. Cierre</label>
            <label class="col-1 text-center">Desviacion</label>
        </div>

        <div *ngFor="let a of array" class="w-100 pointer">
            <div>
                <div class="col-12 datos-tabla">
                    <label class="col-4">{{a}}</label>
                    <label
                        class="col-1 right">{{calcularTotalCostesCuentas(a)?(calcularTotalCostesCuentas(a)
                        |
                        numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularPresuCosteCuentas(a)
                        ?(calcularPresuCosteCuentas(a) |
                        numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularCosteCosteCuentas(a)
                        ?(calcularCosteCosteCuentas(a) |
                        numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularOCCosteCuentas(a) ?(calcularOCCosteCuentas(a) |
                        numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularPrevistoCosteCuentas(a)
                        ?(calcularPrevistoCosteCuentas(a) |
                        numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularProyeccionCierreCosteCuentas(a)
                        ?(calcularProyeccionCierreCosteCuentas(a) | numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{calcularDesviacionCosteCuentas(a)
                        ?(calcularDesviacionCosteCuentas(a) |
                        numberEsp)+'€':''}}</label>
                </div>

            </div>
        </div>

ni
        <div class="col-12 totales-tabla">
            <label class="col-5">Totales</label>
            <label class="col-1 right">{{totalCuentas?(totalCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{presuCuentas ?(presuCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{costeCuentas ?(costeCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{oCCuentas ?(oCCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{previstoCuentas ?(previstoCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{proyeccionCierreCuentas ?(proyeccionCierreCuentas |
                numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{desviacionCuentas ?(desviacionCuentas |
                numberEsp)+'€':''}}</label>
        </div> 
        no
        <div class="col-12 totales-tabla">
            <label class="col-5">Totales</label>
            <label class="col-1 right">{{totalCuentas?(totalCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{presuCuentas ?(presuCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{costeCuentas ?(costeCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{oCCuentas ?(oCCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{previstoCuentas ?(previstoCuentas | numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{proyeccionCierreCuentas ?(proyeccionCierreCuentas |
                numberEsp)+'€':''}}</label>
            <label class="col-1 right">{{desviacionCuentas ?(desviacionCuentas |
                numberEsp)+'€':''}}</label>
        </div>


    </span>
    <span *ngIf="subData.id == 4||subData.id == 5||subData.id == 6">
        <div class="col-12 header-tabla-inf">
            <label [ngClass]="subData.id==6?'col-4':'col-5'">{{subData.nombre}}</label>
            <label class="col-1" *ngIf="subData.id==6">Tipo</label>
            <label class="col-1 text-center">P</label>
            <label class="col-1 text-center">R</label>
            <label class="col-1 text-center">C</label>
            <label class="col-1 text-center">T. Unid.</label>
            <label class="col-1 text-center">Cantidad</label>
            <label class="col-2 text-center">Total</label>
        </div>
        <div *ngFor="let a of array" class="w-100 pointer">
            <div>
                <div class="col-12 datos-tabla">
                    <label [ngClass]="subData.id==6?'col-4':'col-5'">{{a}}</label>
                    <label *ngIf="subData.id==6"
                        class="col-1">{{informe[a][0].tipoUnidad.nombre}}</label>
                    <label class="col-1 text-center">{{calcularPresuPCuentas(a)
                        ?(calcularPresuPCuentas(a) |
                        numberEsp):''}}</label>
                    <label class="col-1 text-center">{{calcularPresuRCuentas(a)
                        ?(calcularPresuRCuentas(a) |
                        numberEsp):''}}</label>
                    <label class="col-1 text-center">{{calcularPresuCCuentas(a)
                        ?(calcularPresuCCuentas(a) |
                        numberEsp):''}}</label>
                    <label class="col-1 text-center">{{calcularPresuTUnidCuentas(a)
                        ?(calcularPresuTUnidCuentas(a) |
                        numberEsp):''}}</label>
                    <label class="col-1 right">{{calcularPresuCantidadCuentas(a)
                        ?(calcularPresuCantidadCuentas(a) | numberEsp)+'€':''}}</label>
                    <label class="col-2 right">{{calcularPresuTotalCuentas(a)
                        ?(calcularPresuTotalCuentas(a) |
                        numberEsp)+'€':''}}</label>
                </div>

            </div>
        </div>
        <div>

            <div class="col-12 totales-tabla">
                <label class="col-5">Totales</label>
                <label class="col-1 text-center">{{semanasP ?(semanasP | numberEsp):''}}</label>
                <label class="col-1 text-center">{{semanasR ?(semanasR | numberEsp):''}}</label>
                <label class="col-1 text-center">{{semanasC ?(semanasC | numberEsp):''}}</label>
                <label class="col-1 text-center">{{totalUnid ?(totalUnid | numberEsp):''}}</label>
                <label class="col-1 right">{{cantidadPresu ?(cantidadPresu |
                    numberEsp)+'€':''}}</label>
                <label class="col-2 right">{{totalPresu ?(totalPresu |
                    numberEsp)+'€':''}}</label>
            </div>

        </div>
    </span>
    <span *ngIf="subData.id == 7||subData.id == 8">
        <div class="col-12 header-tabla-inf">
            <label [ngClass]="subData.id==7?'col-2':'col-1'">{{subData.id==7?
                'Nombre proveedor':'Codigo'}}</label>
            <label [ngClass]="subData.id==7?'col-1':'col-2'">{{subData.id==7?
                'Codigo':'Nombre proveedor'}}</label>
            <label class="col-1 text-center">Concepto</label>
            <label class="col-1 text-center">Nº Reg</label>
            <label class="col-1 text-center">Fecha</label>
            <label class="col-1 text-center">Nº Factura</label>
            <label class="col-1 text-center">Descripcion</label>
            <label class="col-1 text-center">Base</label>
            <label class="col-1 text-center">IVA</label>
            <label class="col-1 text-center">IRPF</label>
            <label class="col-1 text-center">Total</label>
        </div>
        <div *ngFor="let a of array" class="w-100 pointer">
            <div>
                <div class="col-12 datos-tabla" *ngFor="let fact of informe[a]">
                    <label [ngClass]="subData.id==7?'col-2':'col-1'">{{a}}</label>
                    <label
                        [ngClass]="subData.id==7?'col-1':'col-2'">{{subData.id==7?fact.conceptos[0].codigo.cuenta:fact.proveedor.nombreComercial}}</label>

                    <label class="col-1">{{fact.conceptos[0].descripcion}}</label>
                    <label class="col-1">{{fact.id}}</label>
                    <label class="col-1">{{fact.fecha_factura}}</label>
                    <label class="col-1">{{fact.numero_factura}}</label>
                    <label class="col-1">???</label>
                    <label class="col-1 right">{{fact.base?(fact.base | numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{fact.iva?(fact.iva | numberEsp)+'€':''}}</label>
                    <label class="col-1 right">{{fact.irpf?(fact.irpf | numberEsp)+'€':''}}</label>
                    <label
                        class="col-1 right">{{fact.total+fact.total_pagado?(fact.total+fact.total_pagado
                        |
                        numberEsp)+'€':''}}</label>

                </div>

            </div>
            <div class="col-12 totales-minis">
                <label class="col-8 desc">{{a}}</label>
                <label class="col-1 right">{{calcularFactBase(a)?(calcularFactBase(a)|
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{calcularFactIva(a)?(calcularFactIva(a)|
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{calcularFactIrpf(a)?(calcularFactIrpf(a)|
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{calcularFactTotal(a)?(calcularFactTotal(a)|
                    numberEsp)+'€':''}}</label>
            </div>
        </div>
        <div>

            <div class="col-12 totales-tabla">
                <label class="col-8">Totales</label>

                <label class="col-1 right">{{factBaseTot ?(factBaseTot |
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{factIvaTot ?(factIvaTot |
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{factIrpfTot ?(factIrpfTot |
                    numberEsp)+'€':''}}</label>
                <label class="col-1 right">{{factTotalTot ?(factTotalTot |
                    numberEsp)+'€':''}}</label>
            </div>

        </div>
    </span>
</span> -->