
export class Permiso {

    id!: number;
    nombre!: String;
    listadoHijos!: Permiso[];
    padre!: boolean;

    public constructor(data?: any, init?: Partial<Permiso>) {
        if (data) {
            this.id = data.id;
            this.nombre = data.nombre;
            this.listadoHijos = data.listadoHijos;
            this.padre = data.padre;

        } else {
            Object.assign(this, init);
        }


    }

}
export class AccionPermiso {

    id!: number;
    accion!: String;

    public constructor(data?: any, init?: Partial<Permiso>) {
        if (data) {
            this.id = data.id;
            this.accion = data.accion;
        } else {
            Object.assign(this, init);
        }


    }

}

export class PermisoAccionList {

    permiso!: Permiso;
    accion!: AccionPermiso;
    

    public constructor(data?: any, init?: Partial<Permiso>) {
        if (data) {
            this.permiso = data.permiso;
            this.accion = data.accion;
            

        } else {
            Object.assign(this, init);
        }


    }

}