<form *ngIf="!isSuccessful" [formGroup]="form" class="rowForm">
    <label class="formMaterial titLogin">¿Olvidaste la contraseña?</label>

    <div class="form-group formMaterial col-12">
        <mat-form-field appearance="outline" class="col-12  formField">
            <input matInput type="email" class="form-control" name="email" formControlName="email" required email
                placeholder="Email" />
        </mat-form-field>
        <p>{{form.errors}}</p>
        <!-- <div class="alert-danger" *ngIf="form.controls.email.errors">
            
            <div *ngIf="form.controls.email.errors['required']">El email es requerido</div>
            <div *ngIf="form.errors['email']">Escriba un email válido</div>
        </div> -->
    </div>

    <div class="form-group formMaterial col-12">
        <button class=" buttonListado col-12" type="submit" (click)="onSubmit()">Enviar</button>
    </div>
</form>