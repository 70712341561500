<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [isViewPage]="isReading"></app-header-listados>
<div>
    <div class="topInfo">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVO'}} USUARIO</label>
    </div>
    <div class="form-surrouded">
        <form class="rowForm" [formGroup]="form" (change)="onFormChange()">
            <div class="col-12">
                <label class="col-6 col-md-2 formLabel">Nombre de usuario<span class="red">*</span></label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                    <input formControlName="nombreUsuario" matInput placeholder="Nombre de usuario...">
                    <mat-error>Nombre de usuario requerido</mat-error>
                </mat-form-field>
                <label class="col-6 col-md-2 formLabel">DNI<span class="red">*</span></label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                    <input formControlName="dni" matInput placeholder="DNI...">
                    <mat-error>DNI requerido</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="!isUpdate && !isReading">
                <label class="col-6 col-md-2 formLabel">Contraseña<span class="red">*</span></label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField password-div">
                    <input formControlName="password" type="{{showPass ? 'text' : 'password' }}" matInput
                        placeholder="Contraseña...">
                    <mat-icon class="material-icons eye-b pointer" (click)="showPass = !showPass">
                        visibility
                    </mat-icon>

                    <mat-error *ngIf="this.form.get('password')?.hasError('required')">La contraseña es
                        requerida</mat-error>
                    <mat-error *ngIf="this.form.get('password')?.hasError('minlength')">La contraseña debe tener más de
                        6 caracteres</mat-error>

                </mat-form-field>
                <label class="col-6 col-md-2 formLabel">Repita la contraseña</label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField">
                    <input formControlName="reppassword" type="{{showRepPass ? 'text' : 'password' }}" matInput
                        placeholder="Repita la contraseña...">
                    <mat-icon class="material-icons eye-b pointer" (click)="showRepPass = !showRepPass">
                        visibility
                    </mat-icon>
                    <mat-error *ngIf="this.form.get('reppassword')?.hasError('forbiddenName')">Las contraseñas deben
                        coincidir</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12">

           
            <label class="col-6 col-md-2 formLabel">Pais</label>
            <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                <input formControlName="pais" matInput placeholder="Pais...">
                <mat-error>Pais requerido</mat-error>
            </mat-form-field>
            <label class="col-6 col-md-2 formLabel text-align-center">Ciudad</label>
            <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                <input formControlName="ciudad" matInput placeholder="Ciudad...">
                <mat-error>Ciudad requerido</mat-error>
            </mat-form-field>
            </div>
         <!--    <label class="col-6 col-md-2 formLabel">Dirección</label>
            <mat-form-field appearance="outline" class="col-10 formField" [ngClass]="isReading?'desactivado':''">
                <input formControlName="direccion" matInput placeholder="Dirección...">
                <mat-error>Dirección requerido</mat-error>
            </mat-form-field> -->

            <div class="col-12">
               <!--  <label class="col-6 col-md-2 formLabel">Código Postal</label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                    <input type="number" formControlName="codigoPostal" matInput
                        placeholder="Código Postal...">
                    <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['pattern']!= undefined">
                        La longitud del código postal tiene que ser 5</mat-error>
                    <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['required']!= undefined">Código Postal
                        requerido</mat-error>
                </mat-form-field> -->
                <label class="col-6 col-md-2 formLabel">Email<span class="red">*</span></label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                    <input formControlName="email" matInput placeholder="Email..."
                        (blur)="checkEmailExists()">
                    <mat-error *ngIf="this.form.get('email')?.hasError('email')">El email no cumple el formato
                        deseado</mat-error>
                    <mat-error *ngIf="this.form.get('email')?.hasError('required')">Email requerido</mat-error>
                </mat-form-field>

                <label class="col-6 col-md-2 formLabel">Teléfono</label>
                <mat-form-field appearance="outline" class="col-6 col-md-4 formField" [ngClass]="isReading?'desactivado':''">
                    <input formControlName="telefono" matInput placeholder="Teléfono...">
                    <mat-error *ngIf="this.form.controls['telefono'].errors?.['pattern']!= undefined">
                        El teléfono no cumple el formato </mat-error>
                    <mat-error *ngIf="this.form.controls['telefono'].errors?.['required']!= undefined">Teléfono
                        requerido</mat-error>
                </mat-form-field>
            </div>


            <div class="col-12 ">
                <div formArrayName="infoProyecto" class="col-12">
                    <form class="concepto">
                        <div *ngFor="let item of infoProyecto.controls; let i = index" [formGroupName]="i"
                            class="col-12">
                            <div class="col-12 ">


                                <label class="col-6 col-md-2 formLabel" *ngIf="isAdmin">Proyecto<span
                                        class="red">*</span></label>
                                <mat-form-field appearance="outline" *ngIf="isAdmin" class="col-6 col-md-4 formField"
                                    [ngClass]="isReading?'desactivado':''">
                                    <mat-select matInput placeholder="Proyecto..." formControlName="proyecto"
                                        [compareWith]="compararObjetos">
                                        <mat-option (onSelectionChange)="checkEmpresaList(proyecto,i)" *ngFor="let proyecto of proyectosList[i]" [value]="{id:proyecto.id}">
                                            <span class="columnas"> {{proyecto.titulo}}</span></mat-option>
                                    </mat-select>
                                    <mat-error>Proyecto requerido</mat-error>
                                </mat-form-field>


                                <label class="col-6 col-md-2 formLabel">Empresa</label>
                                <mat-form-field appearance="outline" class="formField"
                                       [ngClass]="isReading?isAdmin?'col-6 col-md-4 desactivado':'col-6 col-md-2 desactivado':isAdmin?'col-6 col-md-4':'col-6 col-md-2'">
                                    <mat-select matInput placeholder="Empresa..." formControlName="empresa"
                                        [compareWith]="compararObjetos">
                                        <mat-option *ngFor="let empresa of empresasFiltradas[i]" [value]="empresa">
                                            <span class="columnas"> {{empresa.nombre}}</span></mat-option>
                                    </mat-select>
                                    <mat-error>Empresa requerida</mat-error>
                                </mat-form-field>

                                <!-- TODO: ver como desactivar esto -->
                                <label class="col-6 col-md-2 formLabel">Departamento<span class="red">*</span></label>


                                <mat-form-field appearance="outline" class="formField"
                                    [ngClass]="isReading?isAdmin?'col-6 col-md-4 desactivado':'col-6 col-md-2 desactivado':isAdmin?'col-6 col-md-4':'col-6 col-md-2'">
                                    <mat-select matInput placeholder="Departamento..."
                                        formControlName="departamento" [compareWith]="compararObjetos">
                                        <mat-option *ngFor="let departamento of departamentos"
                                            [value]="departamento">{{departamento.nombre}}</mat-option>
                                    </mat-select>
                                    <mat-error>Departamento requerido</mat-error>

                                </mat-form-field>


                                <label class="col-6 col-md-2 formLabel">Cargo<span class="red">*</span></label>

                                <mat-form-field appearance="outline" class="formField"
                                    [ngClass]="isReading?isAdmin?'col-6 col-md-4 desactivado':'col-6 col-md-2 desactivado':isAdmin?'col-6 col-md-4':'col-6 col-md-2'">
                                    <mat-select matInput placeholder="Cargo..."
                                        formControlName="tipoUsuario" [compareWith]="compararObjetos">
                                        <mat-option *ngFor="let tipo of tiposUsuas"
                                            [value]="tipo">{{tipo.nombre}}</mat-option>
                                    </mat-select>
                                    <mat-icon *ngIf="!isReading && infoProyecto.length!=1"
                                        class="material-icons pointer eye-b mr-1" (click)="removeInfoProyecto(i)">
                                        highlight_off
                                    </mat-icon>
                                    <mat-error>Cargo requerido</mat-error>
                                </mat-form-field>
                                <!--    <mat-form-field appearance="outline" class="formField"
                                    [ngClass]="isReading?isAdmin?'col-6 col-md-2 desactivado':'col-6 col-md-4 desactivado':isAdmin?'col-6 col-md-2':'col-6 col-md-4'">
                                    <input formControlName="cargo" matInput placeholder="Introduzca el cargo...">
                                    <mat-icon *ngIf="!isReading && infoProyecto.length!=1"
                                        class="material-icons pointer eye-b" (click)="removeInfoProyecto(i)">
                                        highlight_off
                                    </mat-icon>
                                    <mat-error>Cargo requerido</mat-error>
                                </mat-form-field> -->
                            </div>
                            <div class="col-12 formMaterial" *ngIf="isAdmin">
                                <div class="col-12 cuadroBotones"
                                    *ngIf="i == infoProyecto.length-1 &&proyectosList.length!=infoProyecto.length &&!isReading">
                                    <button type="button" class="buttonSecondary aniadir"
                                        (click)="addInfoProyecto()"><mat-icon class="material-symbols-outlined">
                                            add
                                        </mat-icon> Añadir fila
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>








            <div class="col-12 cuadroBotones">
              <!--   <span *ngIf="!isReading" [ngClass]="(!isReading && !isUpdate)?'':'formMaterial'">

                    <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                    </button>
                </span> -->
                    <button *ngIf="isClearForm?limparFormulario():''">
                    </button>

                <button *ngIf="isSave?onSubmit():''">
                </button>
            </div>







        </form>
    </div>

    <form class="rowForm tabla" [formGroup]="form2" *ngIf="isUpdate ">
        <div *ngIf="isAdmin" class="col-3 formMaterial">
            <mat-form-field appearance="outline" class="col-12 formField">
                <mat-select matInput placeholder="Proyecto..." formControlName="proyecto">
                    <mat-option *ngFor="let proyecto of proyectosSeleccionadosList"
                        (onSelectionChange)="searchPermisos($event,proyecto)"
                        [value]="proyecto">{{proyecto.titulo}}</mat-option>
                </mat-select>
                <mat-error>Proyecto requerido</mat-error>
            </mat-form-field>
        </div>
        <!--  <div *ngIf="isAdmin" class="col-3 formMaterial">
            <mat-form-field appearance="outline" class="col-12 formField">
                <mat-select matInput placeholder="Tipo usuario..." formControlName="tipoUsuario"
                    [compareWith]="compararObjetos">
                    <mat-option *ngFor="let tipo of tiposUsuas" (onSelectionChange)="getPermisosTipo($event,tipo)"
                        [value]="tipo">{{tipo.nombre}}</mat-option>
                </mat-select>
                <mat-error>Tipo usuario requerido</mat-error>
            </mat-form-field>
        </div> -->
        <b class="col-12 titTabl">Permisos</b>

        <div class="filaTitulos d-flex col-12">
            <b class="col-6 col-md-4 txtPadre">Secciones</b>
            <div class="col-1" *ngFor="let accion of accionPermisos">
                <b>{{primLetMay(accion.accion)}}</b>
            </div>
        </div>
        <div class="table-container data_table" style="width: 100%;">
            <div class="col-12" *ngFor="let permiso of permisosAux">
                <div *ngIf="permiso.padre" class="col-12">
                    <div class="color-head fila d-flex">
                        <b class="col-4 txtPadre">{{primLetMay(permiso.nombre)}}</b>
                        <div class="col-1 d-flex justify-content-center" *ngFor="let accion of accionPermisos">
                            <mat-checkbox [checked]="checkPermiso(accion,permiso)"
                                (change)="getPermiso($event.checked,accion,permiso)"></mat-checkbox>
                        </div>
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <label class="pointer blue" (click)="getAllPermiso(false,permiso)"><b>Todos</b></label>
                        </div>
    
                        <div class="col-1 d-flex justify-content-center align-items-center">
                            <label class="pointer red" (click)="getAllPermiso(true,permiso)"><b>Limpiar</b></label>
                        </div>
                    </div>
                    <span *ngIf="permiso.listadoHijos.length>0">
                        <div *ngFor="let hijo of permiso.listadoHijos" class="fila d-flex">
                            <label class="col-6 col-md-4 txtHijo">{{primLetMay(hijo.nombre)}}</label>
                            <div class="col-1 d-flex justify-content-center" *ngFor="let accion of accionPermisos">
                                <mat-checkbox [checked]="checkPermiso(accion,hijo)"
                                    (change)="getPermiso($event.checked,accion,hijo)"></mat-checkbox>
                            </div>
    
                            <div class="col-1 d-flex justify-content-center align-items-center">
                                <label class="pointer blue" (click)="getAllPermiso(false,hijo)">Todos</label>
                            </div>
    
                            <div class="col-1 d-flex justify-content-center align-items-center">
                                <label class="pointer red" (click)="getAllPermiso(true,hijo)">Limpiar</label>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group col-12 cuadroBotones " *ngIf="isUpdate">
            <button class="buttonSquared buttonPrimary " (click)="guardarPemisos()">{{'Guardar Permisos'}}
            </button>

        </div>
    </form>


    <div class="dialog-container" *ngIf="modalExistUser">
        <div class="dialog-content">
            <div class="contenedortextos">
                <div class="circle_document">
                    <a class="document-div"></a>
                </div>
            </div>
            <div class="contenedortextos">
                <h4 class="colorh4">Este usuario ya existe</h4>
            </div>
            <div class="contenedortextos2">
                <p>¿Desea asignarle este proyecto?</p>
            </div>

            <div class="d-flex justify-content-end">
                <button (click)="(modalExistUser = false)" id="btcancel" class="btn btn-primary mx-1 ">Cancelar</button>
                <button id="btacept" class="btn btn-primary ml-1">
                    <div></div>
                    <span (click)="asociarProyExit()" class="align-middle"> Confirmar</span>
                </button>
            </div>
        </div>
    </div>
</div>


<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>