import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Cuenta } from 'src/app/models/cuenta';
import { FilterService } from 'src/app/services/filter.service';
import { OrdenCompraService } from 'src/app/services/orden-compra.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import {
  OrdenCompraExport,
  OrdenCompraListar,
} from 'src/app/models/orden-compra';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { finalize } from 'rxjs';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';

@Component({
  selector: 'app-listado-ordenes-compra',
  templateUrl: './listado-ordenes-compra.component.html',
  styleUrls: ['./listado-ordenes-compra.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class ListadoOrdenesCompraComponent {
  nombrePagina = 'ORDENES DE COMPRA';
  rutaNuevo = 'admin-contacine/nueva-orden-compra';
  nombreBoton = 'Nueva Orden Compra';
  evento: any = '';
  contadorFiltros: number = 0;
  departamento: any;
  totalBase: number = 0;
  totalIva: number = 0;
  totalIRPF: number = 0;
  totalFacturado: number = 0;
  totalConceptos: number = 0;
  pendienteFacturar: number = 0;
  detalle: string = 'in';
  oCRecibidas: any;

  @Input() set player(value: any) {}

  filters: any[] = [];
  pageSize = '';
  page = '0';
  sorts: any[] = /* [{ key: 'id', direction: 'DESC' }]; */ []

  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };

  isFirst: boolean = true;

  mostrarPopup = 'out';
  mostrarDetalle = 'in';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0;

  dialogCerrar = false;
  idCerrar = '';

  listaCuent: any = [];

  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  dataExport: any;
  dialogVisible: boolean = false;
  idEliminar: any = 0;
  codigo: any;
  codigoHijo: any;
  constructor(
    private router: Router,
    private ordenCompraService: OrdenCompraService,
    public interfazInicial: InterfazInicialComponent,
    private filterService: FilterService,
    public tableService: TableService,
    public notificationService: NotificationService,
    public permisosProyectoService: PermisosProyectoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (
      this.tableService.checkAnyAccionByPermiso(
        12,
        this.interfazInicial.isSuperAdmin,
        this.interfazInicial.permisos
      )
    ) {
      this.notificationService.error(
        'ERROR',
        'No tienes permisos suficientes.',
        5000
      );
      this.router.navigate(['admin-contacine/proyectos']);
    }

    if (this.route.snapshot.queryParamMap.get('codigo')) {
      console.log('dentro');

      this.codigo = this.route.snapshot.queryParamMap.get('codigo');
      console.log('codigo', this.codigo);
    }
    if (this.route.snapshot.queryParamMap.get('codigoHijo')) {
      console.log('dentro');

      this.codigoHijo = this.route.snapshot.queryParamMap.get('codigoHijo');
      console.log('codigo', this.codigoHijo);
    }
    if (!this.interfazInicial.isSuperAdmin) {
      this.tableService.subscribeToProyectoChanges((proyectoActual: any) => {
        this.filter.filters = [];
        this.getDepartamento();
      });

      this.getDepartamento();
    } else {
      this.getOrdenesDeCompra();
    }
  }

  getDepartamento() {
    this.permisosProyectoService
      .getPermisosFormPreyectoAndUsu(
        this.interfazInicial.proyaux.id,
        this.interfazInicial.currentUser.id
      )
      .pipe(
        finalize(() => {
          this.getOrdenesDeCompra();
        })
      )
      .subscribe({
        next: (data: any) => {
          this.departamento = data.departamento;
          if (this.departamento == undefined) {
            this.notificationService.warning(
              'ERROR',
              'No dispones de un departamento en este proyecto.',
              5000
            );
          }
        },
      });
  }

  evaluateFilter() {
    console.log(this.interfazInicial.tipoUsu);
    console.log( this.departamento);

    if (!this.interfazInicial.isSuperAdmin) {
      if (
        this.interfazInicial.tipoUsu.id == 3 ||
        this.interfazInicial.tipoUsu.id == 2 ||
        this.interfazInicial.tipoUsu.id == 4 ||
        this.interfazInicial.isLicencia
      ) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.contadorFiltros = 1;
      } else if ((this.interfazInicial.tipoUsu.id == 5 || this.interfazInicial.tipoUsu.id == 6) 
        && this.departamento) {
        this.contadorFiltros = 2;
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'departamento.id',
          operator: 'LIKE',
          value: this.departamento.id,
        });
      } else {
        this.contadorFiltros = 3;
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'departamento.id',
          operator: 'LIKE',
          value: this.departamento.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'solicitante.id',
          operator: 'LIKE',
          value: this.interfazInicial.currentUser.id,
        });
      }
    }
  }

  /**
   * Agrega un filtro para el campo proyecto.id a la lista de filtros.
   */

  /**
   * Inicializa las listas y actualiza la tabla.
   */
  iniciarListas() {
    this.displayedColumnscompleta = [];
    if (
      sessionStorage.getItem(this.nombrePagina) &&
      sessionStorage.getItem(this.nombrePagina) != 'undefined'
    ) {
      var a: any = sessionStorage.getItem(this.nombrePagina);
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {
      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }

    this.actualizarTabla();
  }

  /**
   * Actualiza la lista de columnas a ser mostradas en la tabla.
   */
  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }
  /**
   * Realiza el filtrado individual de datos en la tabla.
   *
   * @param event Evento que desencadenó el filtro.
   * @param type Tipo de filtro.
   * @param operator Operador de comparación.
   * @param field_type Tipo de campo.
   */
  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filter.filters = this.filter.filters.splice(
      this.filter.filters.length - this.contadorFiltros,
      this.contadorFiltros
    );
    this.filter.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filters,
      null
    );

    this.getOrdenesDeCompra();
  }

  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }

  /**
   * Habilita la visualización de todos los campos en la tabla.
   */
  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }

  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }

  /**
   * Realiza un filtro general en la tabla.
   */
  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.filter = {
        filters: [],
        page: this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      this.getOrdenesDeCompra();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filter.filters = [
          {
            operator: operator,
            value: this.evento,
            values: [
              'Departamento.nombre',
              'Proyecto.titulo',
              'Proveedor.nombreComercial',
              'PagoOc.observaciones',
              'gastos.id',
              'conceptos.descripcion',
            ],
          },
        ];
        this.getOrdenesDeCompra();
      } else {
        this.filter.filters = [];
        this.getOrdenesDeCompra();
      }
    }
  }

  /**
   * Ordena los elementos de la tabla según la propiedad especificada.
   *
   * @param property Propiedad por la cual ordenar los elementos.
   * @param sorts Lista de objetos de ordenación.
   */
  ordenar(property: string, sorts: any[] = []) {
    this.filter.filters = this.filter.filters.splice(
      this.filter.filters.length - this.contadorFiltros,
      this.contadorFiltros
    );
    
    let splitProp = property.split(".")[0];

    var orden = this.ordenarCampos[splitProp] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == splitProp) {
        this.ordenarCampos[splitProp] = !this.ordenarCampos[splitProp];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.filter.sorts = [{ key: property, direction: orden }];

    this.getOrdenesDeCompra();
  }

  /**
   * Evalúa y actualiza el tamaño de página en la tabla.
   *
   * @param event Evento de cambio de tamaño de página.
   */
  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    this.getOrdenesDeCompra();
  }

  /**
   * Obtiene las órdenes de compra con los filtros y opciones actuales.
   */
  getOrdenesDeCompra() {
    this.evaluateFilter();
    /* Importante resetear todas las listas pero en teoria se podrian copiar y 
    pegar los metodos cambiando el servicio que compruaba el back y los objetos que construyen las listas y objetos*/
    if (this.codigo) {
      this.filter.filters.push({
        field_type: 'STRING',
        key: 'conceptos.codigo.cuenta',
        operator: 'LIKE',
        value: this.codigo + '',
      });
      if (this.codigoHijo) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'LIKE',
          value: this.codigoHijo + '',
        });
      } else {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'IS_NULL',
        });
      }
    }

    // Get only active oc (fechaBaja is NULL)
    this.filter.filters.push({
      field_type: 'DATE',
      key: 'fechaBaja',
      operator: 'IS_NULL'
    });

    console.log('filter ->  ', this.filter);
    this.ordenCompraService
      .filter(this.filter)
      .pipe(
        finalize(() => {
          this.calculatTotales();
        })
      )
      .subscribe({
        next: (data: any) => {
          this.oCRecibidas = data.content;
          console.log(data);
          console.log(data.content);
          console.log(data.pageList)
          if (this.ordenarCampos['id'] == undefined) {
            this.ordenarCampos = this.filterService.crearCampos(data);
          }
          this.dataExport = [];
          if (data) {
            data.content?.forEach((element: any) => {
              this.dataExport.push(new OrdenCompraExport(element));
            });
          }
          this.listaCuent = [];
          if (
            data &&
            data.content &&
            data.content != null &&
            data.content.length > 0
          ) {
            /* Este es el objeto de la lista cambiando los campos del 
          contructor de orden se cambia los campos de orden en la tabla */
            var aux = new OrdenCompraListar(data.content[0]);
            if (this.isFirst) {
              this.paginator._intl = this.interfazInicial.paginator;
              this.displayedColumns = Object.keys(aux);
              if (this.interfazInicial.tipoUsu != undefined) {
                if (this.interfazInicial.tipoUsu.id != 3) {
                  this.displayedColumns.splice(
                    this.displayedColumns.indexOf('cerrada'),
                    1
                  );
                }
              }
              this.displayedColumns.push('acciones');
              this.paginator._intl = this.interfazInicial.paginator;
              this.isFirst = false;
              this.iniciarListas();
            }

            this.dataSource = data.content;
            this.totalElements = data.content.length;

            this.dataSource.paginator = this.paginator;
          } else {
            this.totalElements = 0;
            this.dataSource = data.content;
            this.iniciarListas();
          }
        },
        error: (error) => {},
      });
  }

  /**
   * Convierte la primera letra de cada palabra en mayúscula.
   *
   * @param text Texto a convertir.
   * @returns Texto con la primera letra de cada palabra en mayúscula.
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim();

    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * Navega a la página especificada para la orden de compra.
   *
   * @param id ID de la orden de compra.
   * @param accion Acción a realizar (ver, editar).
   */
  navegar(id: any, accion: string) {
    this.router.navigate([`admin-contacine/${accion}-orden-compra/${id}`]);
  }

  /**
   * Navega a la página de detalle del gasto especificado.
   *
   * @param id ID del gasto.
   */
  navegarAGasto(id: any) {
    this.router.navigate([`admin-contacine/ver-gasto/${id}`]);
  }

  /**
   * Maneja el evento de cambio de página en la paginación.
   *
   * @param event Evento de cambio de página.
   */
  nextPage(event: PageEvent) {
    this.filter.page = event.pageIndex + '';
    this.filter.pageSize = event.pageSize + '';

    this.getOrdenesDeCompra();
  }

  /**
   * Descarga los documentos asociados a la orden de compra.
   *
   * @param element Elemento de orden de compra.
   */
  download(element: any) {
    var idsNext: any = [];
    if (element.documentos && element.documentos.length > 0) {
      element.documentos.forEach((element: any) => {
        idsNext.push(element.id_nextcloud);
      });

      this.ordenCompraService.download(idsNext).subscribe({
        next: (data: ArrayBuffer) => {
          var file = new Blob([data], { type: 'application/zip' });
          var fileUrl = URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = fileUrl;
          a.download = 'Orden_Compra_' + element.id + '.zip';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        error: (err) => {},
      });
    } else {
      this.notificationService.warning(
        'ALERTA',
        'Esta Orden de Compra no tiene documentos.',
        5000
      );
    }
  }

  /**
   * Elimina la orden de compra especificada por su ID.
   *
   * @param id ID de la orden de compra a eliminar.
   */
  delete(id: any) {
    this.ordenCompraService.deleteById(id).subscribe({
      next: (data) => {},
      error: (err) => {
        if (err.error && err.error.text) {
          this.notificationService.success('ÉXITO', err.error.text, 5000);
        } else {
          this.notificationService.warning(
            'ALERTA',
            'Ha ocurrido un error.',
            5000
          );
        }
        this.idEliminar = 0;
        this.dialogVisible = false;

        this.getOrdenesDeCompra();
      },
    });
  }

  cerrarOc(id: any) {
    this.ordenCompraService.cerrarOc(id).subscribe({
      next: (data) => {
        this.getOrdenesDeCompra();
      },
    });
    this.dialogCerrar = false;
  }


  comprobarEditarBorrar(element: any): Boolean {
    if (element.gastos.length != 0) {
      return true;
    } else {
      if (element.estado.id == 1 || element.estado.id == 3) {
        return true;
      }
      else if(
        this.interfazInicial.tipoUsu.id == 3 ||
        this.interfazInicial.isSuperAdmin ||
        this.interfazInicial.isLicencia
      ) {
        return false;
      } 
      else {
        return false;
      }
      
    }
  }

  aniadirOCNumber(numero: any): String {
    let num: string = numero + '';
    for (let i = num.length; i < 4; i++) {
      num = 0 + num;
    }
    return 'OC' + num;
  }

  calculatTotales() {
    //this.getTotalOrdenDeCompraProyecto();
    this.totalBase = 0;
    this.totalIva = 0;
    this.totalIRPF = 0;
    this.pendienteFacturar = 0;
    this.totalConceptos = 0;
    (this.totalFacturado = 0),
      this.oCRecibidas.forEach((element: any) => {
        console.log(element);
        this.totalFacturado = this.totalFacturado + element.facturado;
        this.pendienteFacturar = this.pendienteFacturar + element.pendientePago;
        this.totalBase = this.totalBase + element.base;
        this.totalIva = this.totalIva + element.totalIva;
        this.totalIRPF = this.totalIRPF + element.totalIrpf;
        this.totalConceptos = this.totalConceptos + element.totalConceptos;
      });
  }

  /*getTotalOrdenDeCompraProyecto() {
  let proyectoAux;
  if (this.interfazInicial.proyaux.id == undefined) {
    proyectoAux = 0;
  } else {
    proyectoAux = this.interfazInicial.proyaux.id;
  }
  this.ordenCompraService
    .getTotalOrdenDeCompraProyecto(proyectoAux)
    .subscribe({
      next: (data) => {
        console.log("pendienteOc",data)
        this.pendienteOc = data;
      },
      error: (error) => {},
    });

  }*/

    setDefaultTableHead(){
      this.displayedColumns = ['numOc', 'base', 'conceptos', 'codigoConcepto', 'descripcionConcepto', 'departamento', 'documentos', 'estado', 'fechaAlta', 'fechaModificacion', 'pendientePago', 'facturado', 'gastos', 'pagoOc', 'proveedor', 'proyecto', 'solicitante', 'totalConceptos', 'totalIrpf', 'totalIva', 'fianza', 'JC', 'DP', 'JD', 'modificado', 'cerrada']
    }

    conceptosTooltip(index: number): string {
      return `${this.oCRecibidas[index].conceptos
        .map((concepto: any) => `${concepto.codigo.cuenta} - ${this.getConcepto(concepto.codigo)} ${concepto.descripcion != undefined && concepto.descripcion != '' ? ' (' + concepto.descripcion  + ')' : ''}`)
        .join('\n')}`;
    }

    getConcepto(element: any): string {
      var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
      if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
      else return element.concepto;
    }
}
