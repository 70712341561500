import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Presupuesto } from '../models/presupuesto';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {
  /* 
  Servicio para las peticiones con back para el listado,modificaciones de control de coste y presupuesto
  */
  url: string = environment.API_URL + "/presupuesto";
  urlCoste: string = environment.API_URL + "/control-costes";

  constructor(private http: HttpClient) { }

  deletePresupuesto(id: string): Observable<string> {
    return this.http.delete<string>(`${this.url}/${id}`);
  }

  getPresupuestosByProyectoId(id: string): Observable<Presupuesto[]> {
    return this.http.get<Presupuesto[]>(`${this.url}/byProyecto/${id}`);
  }

  saveAllPresupuestos(presupuestos: Presupuesto[]): Observable<Presupuesto[]> {
    return this.http.post<Presupuesto[]>(`${this.url}/saveAll`, presupuestos);
  }

  closePresupuesto(id: string): Observable<string> {
    return this.http.get(`${this.url}/close/${id}`, { responseType: 'text' });
  }

  isPresupuestoClosed(id: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/isPresupuestoClosed/${id}`);
  }

  deletePresupuestoByProyecto(id: string): Observable<string> {
    return this.http.get(`${this.url}/deleteByProyecto/${id}`, { responseType: 'text' });
  }

  getControlByProyectoId(id: string): Observable<any> {
    return this.http.get(`${this.urlCoste}/list/${id}`);
  }

  importPresupuesto(file: File, id: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.url}/import/${id}`, formData);
  }

  downloadExcelTemplate(ruta: string, nombreArchivo: string): void {
    this.http.get(ruta, { responseType: 'blob' }).subscribe(blob => {
      var file = new Blob([blob], { type: 'application/xlsx' })
      console.log("Blob: ", blob);
      
      var fileUrl = URL.createObjectURL(blob)
      console.log("FileUrl: ", fileUrl);
      
      var a = document.createElement('a')
      a.href = fileUrl
      var a = document.createElement('a')
      a.download = `${nombreArchivo}.xlsx`;
    })
  }
}
