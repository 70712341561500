import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FilterService } from 'src/app/services/filter.service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { InterfazInicialComponent } from 'src/app/components/interfaz-inicial/interfaz-inicial.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { LaboralService } from 'src/app/services/laboral.service';
import { LaboralExport } from 'src/app/models/laboral';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-listado-laboral',
  templateUrl: './listado-laboral.component.html',
  styleUrl: './listado-laboral.component.scss',
})
export class ListadoLaboralComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('inputFile')
  input!: ElementRef;
  isForImport = false;
  isForCoste = false;
  canvas: any;
  loading: boolean = false;
  dataExport: any;
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  filters: any[] = [];
  pageSize: any = 99999;
  page: any = 0;
  totalElements = 0;
  nombrePagina = 'Laboral';
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  nombreBoton = 'Pasar a Coste';
  rutaNuevo = '';
  exportar = 'true';
  idEliminar: any = 0;
  canExport: boolean=true
  displayedColumns = [
    'nº',
    'tipo',
    'dni',
    'nombre',
    'periodo',
    'codigo',
    'concepto',
    'descripcion',
    'bruto',
    'complemento material',
    'vehiculo',
    'kms',
    'dietasActores',
    'dietasTecnicos',
    'hExtras',
    'hFestivas',
    'brutoConExtras',
    'prIrpf',
    'irpf',
    'ss',
    'total',
    'fechaPago',
    'formaPago',
    'ssEmpresa',
    'Acciones',
  ];
  evento: any = '';
  codigo: any;
  codigoHijo: any;

  dataSource = new MatTableDataSource();
  displayedColumnscompleta: string[] = [];
  listadoJustifica: any;
  dialogVisible: boolean = false;
  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('codigo')) {
      console.log('dentro');

      this.codigo = this.route.snapshot.queryParamMap.get('codigo');
      console.log('codigo', this.codigo);
      if (this.route.snapshot.queryParamMap.get('codigoHijo')) {
        console.log('dentro');
  
        this.codigoHijo = this.route.snapshot.queryParamMap.get('codigoHijo');
        console.log('codigo', this.codigoHijo);
      }
      this.initFilters();
    }
    this.getLaboral();
  }

  constructor(
    private laboralService: LaboralService,
    public tableService: TableService,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    private notificationService: NotificationService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}
  initFilters(){
    if (this.codigo) {
      this.filter.filters.push({
        field_type: 'STRING',
        key: 'codigo.cuenta',
        operator: 'LIKE',
        value: this.codigo + '',
      });
      if (this.codigoHijo) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'codigo.codigoHijo',
          operator: 'LIKE',
          value: this.codigoHijo + '',
        });
      } else {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'codigo.codigoHijo',
          operator: 'IS_NULL',
          value: ''
        });
      }
    }
  }
  getLaboral() {
    this.filter.filters.forEach((element: any) => {
      if (element.key == 'proyecto.id') {
        this.filter.filters.shift();
      }
    });

    this.filter.filters.unshift({
      field_type: 'STRING',
      key: 'proyecto.id',
      operator: 'LIKE',
      value: this.interfazInicial.proyaux.id,
    });

  
    this.laboralService
      .getLaboral(this.filter)

      .subscribe({
        next: (data: any) => {
          this.dataExport = [];
          this.listadoJustifica = data.soruce;
          this.dataSource = data.content;
          
          this.totalElements = data.content.length;
          data.content.forEach((element: any) => {
            this.dataExport.push(new LaboralExport(element));
          });
          if (this.ordenarCampos['id'] == undefined) {
            this.paginator._intl = this.interfazInicial.paginator;
            let nombre: string = '';
            if (data['content'].length != 0) {
              var mapa = Object.entries(data['content'][0]);
              mapa.forEach((element) => {
                nombre = element[0];
                this.ordenarCampos[nombre] = false;
              });
            }
          }
          this.displayedColumnscompleta = [];
          if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
            var a: any = sessionStorage.getItem(this.nombrePagina)
            this.mostrarCampos = JSON.parse(a);
            this.displayedColumns.forEach((element) => {
              this.displayedColumnscompleta.push(element);
            });
          } else {
      
            this.displayedColumns.forEach((element) => {
              this.mostrarCampos[element] = true;
              this.displayedColumnscompleta.push(element);
            });
          }

          this.actualizarTabla();
         /*  this.displayedColumns.forEach((element) => {
            this.mostrarCampos[element] = true;
            this.displayedColumnscompleta.push(element);
          }); */
          this.totalElements = data.content.length;
          this.dataSource.paginator = this.paginator;
        },
        error: (error) => {},
      });
  }

  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.filter = {
        filters: [],
        page: this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      this.getLaboral();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filter.filters = [
          {
            operator: operator,
            value: this.evento,
            values: ['PeriodoIva.nombre'],
          },
        ];
        this.getLaboral();
      } else {
        this.filter.filters = [];
        this.getLaboral();
      }
    }
  }
  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (property.includes(clave)) {
        this.ordenarCampos[clave] = !this.ordenarCampos[clave];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.filter.sorts = [{ key: property, direction: orden }];

    this.getLaboral();
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filter.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filter.filters,
      null
    );
  
 

    this.getLaboral();
  }

  nextPage(event: PageEvent) {
    this.filter.page = event.pageIndex + '';
    this.filter.pageSize = event.pageSize + ''
    this.getLaboral();
  }

  borrarElemento(id: any) {
    this.laboralService.delete(id).subscribe({
      next: (data) => {
        this.getLaboral();
        this.notificationService.success(
          'ÉXITO',
          'Registro borrado correctamente.',
          5000
        );
      },
      error: (error) => {},
    });
    this.dialogVisible = false;
  }
  /**
   * Obtiene el documento y realiza la importación laboral
   *
   * @param event
   */
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.loading = true;
      const file = event.target.files[0];
        this.laboralService
        .importLaboral(file, this.interfazInicial.proyaux.id)
        .subscribe({
          next: (data: any) => {
            this.getLaboral();

            //falta actualizar la lista al crear las justificaciones
            this.loading = false;
            this.notificationService.success(
              'ÉXITO',
              'Laboral importado con éxito.',
              5000
            );
            this.input.nativeElement.value = '';
            this.isForImport = false;
          },
          error: (err) => {
            this.loading = false;
            this.notificationService.error('ERROR', err.error.message, 5000);
            this.input.nativeElement.value = '';
            this.isForImport = false;
          },
        });
    }
  }

  /**
   * Descarga la plantilla Excel para poder importar laboral
   */
  downloadTemplate() {
    const fileName = 'Plantilla_Laboral.xlsx';
    const filePath = './assets/templates/Plantilla_laboral.xlsx';

    this.http.get(filePath, { responseType: 'blob' }).subscribe((blob) => {
      saveAs(blob, fileName);
    });
  }
  notifExport() {
    this.notificationService.warning(
      'ALERTA',
      'No tienes permisos para exportar.',
      5000
    );
  }

  pasarACostes() {
    this.loading = true
    this.laboralService.pasarACoste(this.interfazInicial.proyaux.id).subscribe({
      next: (data) => {
        this.getLaboral();
        this.loading = false;
        this.notificationService.success(
          'ÉXITO',
          'Registro pasado a coste correctamente',
          5000
        );
        this.isForCoste = false;
      },
      error: (error) => {
        this.loading = false;
        this.isForCoste = false;
      },
    });
  }
}
