<div class="page_content">
    <div class="content_inner">
        <div class="content_login">
            <div class="card_login">

                <div class="d-flex flex-row align-items-center justify-content-center mb-12">
                    <div class="logo-base logo-login mt-40"></div>
                </div>
                <form [formGroup]="form" class="rowForm" *ngIf="!isRecoverPass">
                    <div *ngIf="!isLoggedIn">

                        <label class="titLogin">La aventura empieza aquí</label>
                        <label class="marbot subTitLogin">Gestiona tus proyectos de manera ágil y sencilla</label>

                        <div class="formLogin">

                          <!--   <label class="subTitLogin ">Usuario</label>
                            <mat-form-field appearance="outline" class="col-12 formField auxFormField ">
                                <input type="text" class="form-control" matInput placeholder="Usuario">
                            </mat-form-field> -->

                            <div class="input-label"><label class="input-label">Email</label></div>
                            <mat-form-field appearance="outline" class="col-12 formField auxFormField ">
                                <input type="email" class="form-control" name="email" formControlName="email" required
                                    email matInput placeholder="Correo electrónico"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}">
                            </mat-form-field>

                            <div class="input-label"><label>Contraseña</label></div>
                            <mat-form-field appearance="outline" class="col-12 formField auxFormField ">
                                <input type="password" class="form-control" matInput name="password"
                                    formControlName="password" placeholder="Contraseña"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['password'].errors}">
                            </mat-form-field>

                            <div class="checkBoxLog mb-2">
                                <mat-checkbox (change)="acceptTemCon = $event.checked "> </mat-checkbox>
                                <p class="forgotSpan formMaterial subTitLogin">
                                    Acepto los
                                    <a class="pointer forgot-password" [routerLink]="['terminos-condiciones']"
                                        target="_blank">términos y condiciones</a>
                                </p>
                            </div>

                            <button class=" buttonListado col-12" (click)="onSubmit()">{{'Iniciar sesión'}}
                            </button>

                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        </div>
                    </div>
                </form>

                <app-forgot-pass *ngIf="isRecoverPass"></app-forgot-pass>
                <p class="mt-5 subTitLogin ">{{ !isRecoverPass? '¿Has olvidado tu contraseña?':''}}</p>
                <p class="no-mt"><a (click)="isRecoverPass = !isRecoverPass" class="pointer">{{ !isRecoverPass?
                        'Recupérala':'Cancelar'}}</a>
                </p>
            </div>
        </div>
    </div>
</div>