import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { InterfazInicialComponent } from './components/interfaz-inicial/interfaz-inicial.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RegistroGastosComponent } from './components/Registro/registro-gastos/registro-gastos.component';
import { MatTableModule } from '@angular/material/table';
import { PlantillasComponent } from './components/plantillas/plantillas.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ListadoPresupuestosComponent } from './components/presupuestos/listado-presupuesto/listado-presupuestos/listado-presupuestos.component';




import { ReactiveFormsModule } from '@angular/forms';
import { InicioComponent } from './components/_inicio/inicio.component';
import { HttpClientModule } from '@angular/common/http';
import { RaizComponent } from './components/_raiz/raiz.component';
import { ForgotPassComponent } from './components/forgot-pass/forgot-pass.component';
import { TwoFactorComponent } from './components/two-factor/two-factor.component';
import { MatDialogModule } from '@angular/material/dialog';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RestorePassComponent } from './components/restore-pass/restore-pass.component';

import { NuevoGastoComponent } from './components/Registro/nuevo-gasto/nuevo-gasto.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NuevoUsuarioComponent } from './components/usuarios/nuevo-usuario/nuevo-usuario.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ListadoUsuariosComponent } from './components/usuarios/listado-usuarios/listado-usuarios.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MiPerfilComponent } from './components/usuarios/mi-perfil/mi-perfil.component';
import { HeaderListadosComponent } from './components/header-listados/header-listados.component';
import { ListaProveedoresComponent } from './components/proveedores/lista-proveedores/lista-proveedores.component';
import { FormularioProveeedorComponent } from './components/proveedores/formulario-proveeedor/formulario-proveeedor.component';
import { NuevoProyectoComponent } from './components/Proyecto/nuevo-proyecto/nuevo-proyecto.component';
import { ListadoProyectosComponent } from './components/Proyecto/listado-proyectos/listado-proyectos.component';
import { ListadoProductorasComponent } from './components/productoras/listado-productoras/listado-productoras.component';
import { NuevaProductoraComponent } from './components/productoras/nueva-productora/nueva-productora.component';
import { NuevaCuentaComponent } from './components/cuentas/nueva-cuenta/nueva-cuenta.component';
import { ListadoCuentasComponent } from './components/cuentas/listado-cuentas/listado-cuentas.component';
import { ListadoOrdenesCompraComponent } from './components/orden-compra/listado-ordenes-compra/listado-ordenes-compra.component';
import { NuevaOrdenCompraComponent } from './components/orden-compra/nueva-orden-compra/nueva-orden-compra.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NuevoPresupuestoComponent } from './components/presupuestos/nuevo-presupuesto/nuevo-presupuesto.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones/notificaciones.component';
import { RegistroPagosComponent } from './components/Registro/registro-pagos/registro-pagos.component';
import { NuevoInformeComponent } from './components/informes/nuevo-informe/nuevo-informe.component';
import { CodigosCuentasPipe } from './services/servicios-funcionalidad/codigos-cuentas.pipe';
import { NuevoControlCosteComponent } from './components/control-costes/nuevo-control-coste/nuevo-control-coste.component';
import { NumberEspPipe } from './services/servicios-funcionalidad/number-esp.pipe';
import { ManualComponent } from './components/manual/manual.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ListadoJustificacionesComponent } from './components/justificaciones/listado-justificaciones/listado-justificaciones.component';
import { ListadoLaboralComponent } from './components/Registro/registro-laboral/listado-laboral/listado-laboral.component';
import { MatTooltipModule } from '@angular/material/tooltip';


const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InterfazInicialComponent,
    RegistroGastosComponent,
    PlantillasComponent,
    RaizComponent,
    InicioComponent,
    ForgotPassComponent,
    TwoFactorComponent,
    RestorePassComponent,
    NotificationComponent,
    NuevoGastoComponent,
    NotificationComponent,
    NuevoUsuarioComponent,
    ListadoUsuariosComponent,
    MiPerfilComponent,
    HeaderListadosComponent,
    ListaProveedoresComponent,
    FormularioProveeedorComponent,
    NuevoProyectoComponent,
    ListadoProyectosComponent,
    ListadoProductorasComponent,
    NuevaProductoraComponent,
    NuevaCuentaComponent,
    ListadoCuentasComponent,
    ListadoOrdenesCompraComponent,
    NuevaOrdenCompraComponent,
    NuevoPresupuestoComponent,
    ListadoPresupuestosComponent,
    NotificacionesComponent,
    RegistroPagosComponent,
    NuevoInformeComponent,
    CodigosCuentasPipe,
    NuevoControlCosteComponent,
    NumberEspPipe,
    ManualComponent,
    ListadoJustificacionesComponent,
    ListadoLaboralComponent


  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatPaginatorModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatAutocompleteModule,
    FullCalendarModule,
    NgxMaskDirective,
    MatTooltipModule
    
  ],
  providers: [authInterceptorProviders,provideNgxMask(), {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE],
    
  }, { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }],
  bootstrap: [AppComponent]
})
export class AppModule { }


