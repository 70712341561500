import { Component, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { AuthService } from 'src/app/services/usuario/auth.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent {

  form2: FormGroup = new FormGroup({
    totpkey: new FormControl("", [Validators.required, Validators.minLength(6)])
  });

  isLoginFailed = false;
  submitted = false;
  loading = false;
  tfaFlag: boolean = false;
  resetQR: boolean = false;
  resetedQR: boolean = false;
  qr: any;
  errorQR: boolean = false;
  errorMessage: String = "";
  tfActivated: boolean = false;





  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TwoFactorComponent>,
    private usuarioService: UsuarioService


  ) {
  }
  ngOnInit(): void {
    this.tfaFlag = this.data.action as boolean
    this.qr = this.data.qr.body
  }

  loginTfaEnabled(): any {

    this.submitted = true;

    if (this.form2.get('totpkey')?.hasError('minlength')) {
      this.notificationService.error('ERROR', 'El código debe contener 6 caracteres.', 5000);
      return false;
    }

    if (!this.form2.valid) {
      this.notificationService.warning('Información', 'Rellene todos los campos por favor.', 5000);
      return false;
    }
    this.authService.login(this.data.email, this.data.pass, this.form2.controls['totpkey'].value).subscribe({
      next: (dataTotpkey) => {

        if (dataTotpkey.codigoError !== "INVALID_2FA") {
          this.isLoginFailed = false;
          this.tokenStorage.saveToken(dataTotpkey.token);

          this.usuarioService.getCurrentUser().subscribe({
            next: (data) => {
              this.authService.updateAuthStatus(true);
              this.tokenStorage.setUser(data.user);
              this.cerrar()

              this.router.navigate(['admin-contacine/registro/gastos']);
            },
            error: (error) => {
              this.tokenStorage.signOut();
            }
          })

        } else {
          this.notificationService.error('ERROR', 'El código no es correcto.', 5000);
          this.form2.controls['totpkey'].setErrors({ 'incorrect': true });
        }
      },
      error: (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      },
    })
  }

  guardarQR(): any {
    this.submitted = true;

    if (this.form2.get('totpkey')?.hasError('minlength')) {
      this.notificationService.error('ERROR', 'El código debe contener 6 caracteres.', 5000);
      return false;
    }

    if (!this.form2.valid) {
      this.notificationService.warning('ERROR', 'Faltan campos por rellenar.', 5000);
    } else {

      this.authService.guardarQR(this.qr.secret, this.form2.get('totpkey')?.value, this.data.email).subscribe({
        next: (data) => {


          this.qr = data.body;
          if (this.qr.result === "success") {
            this.loginTfaEnabled()

          } else {
            this.form2 = this.formBuilder.group({
              totpkey: [""]
            });
            this.errorQR = true;
          }
        },
        error: (error) => {

          if (error.error.codigo == 637) {
            this.notificationService.error('ERROR', 'Código incorrecto.', 5000);
          }
        }
      });
    }
  }
  resetearQR(): any {
    this.resetQR = true;
    this.guardarQR();
  }


  cerrar() {
    this.dialogRef.close();

  }
}
