import { Injectable } from '@angular/core';
import { InterfazInicialComponent } from 'src/app/components/interfaz-inicial/interfaz-inicial.component';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(

  ) {

  }
  /* 
  Servicio que se encarga de generar un excel paginado con 
  los hijos de cualquier objeto que se le pase los cabeceros son las keys
  */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()
    return text.charAt(0).toUpperCase() + text.slice(1)

  }
  export(nombreBoton: any, dataExport: any, nombre: any, titulo: string) {
    // Crear hoja de cálculo para objetos padres

    const parentSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExport);

    Object.keys(dataExport[0]).forEach((columnName, columnIndex) => {

      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: columnIndex });
      parentSheet[cellAddress].v = this.primLetMay(columnName);
    });

    // Crear libro de trabajo con hoja de cálculo para objetos padres solamente
    const workbook: XLSX.WorkBook = {

      Sheets: { [`${nombreBoton}`]: parentSheet },
      SheetNames: [`${nombreBoton}`]
    };

    // Extraer y crear hojas de cálculo para objetos hijos
    dataExport.forEach((parent: any, parentIndex: any) => {
      // Verificar si la propiedad es un objeto
      for (const prop in parent) {



        if (Array.isArray(parent[prop])) {

          const children = parent[prop];
          children.forEach((child: any) => {

            Object.keys(child).forEach(oldName => {

              const newName: any = this.primLetMay(oldName);
              if (child.hasOwnProperty(oldName)) {

                child[newName] = child[oldName];
                delete child[oldName];
              }
            });
          });

          //_de_${nombreBoton.replaceAll(" ", "_")} 

          // Si la propiedad es un objeto, crear una hoja de cálculo para los objetos hijos
          const childrenSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(parent[prop]);
          XLSX.utils.book_append_sheet(workbook, childrenSheet, `${this.primLetMay(prop)}_${parentIndex + 1}`);

          let childCellAddress: string | undefined;

          // Buscar el índice de la columna que contiene el nombre del campo del objeto hijo
          if (parentSheet['!ref']) {
            for (let col = 0; col < parentSheet['!ref']?.split(':')[1].charCodeAt(0) - parentSheet['!ref']?.split(':')[0].charCodeAt(0); col++) {
              if (parentSheet[String.fromCharCode('A'.charCodeAt(0) + col) + '1']?.v === this.primLetMay(prop)) {
                // Se encontró la columna que contiene el nombre del campo del objeto hijo
                childCellAddress = String.fromCharCode('A'.charCodeAt(0) + col) + (parentIndex + 2);

              }
            }
          }

          // Vincular objetos padres con objetos hijos
          if (childCellAddress) {
            const firstChildCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
            parentSheet[childCellAddress].l = {
              //_de_${nombreBoton.replaceAll(" ", "_")}
              Target: `#${this.primLetMay(prop)}_${parentIndex + 1}!${firstChildCell}`,
              Tooltip: "Ir a hijos"
            };
            //_de_${nombreBoton.replaceAll(" ", "_")}
            parentSheet[childCellAddress].f = `HYPERLINK("#${this.primLetMay(prop)}_${parentIndex + 1}!${firstChildCell}", "Ir a hoja")`;
          }
        }
      }
    });

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, titulo + "_" + nombre + ".xlsx");


  }
  
  otherexport(nombreBoton: any, dataExport: any, nombre: any, titulo: string) {
    // Crear hoja de cálculo para objetos padres

    const parentSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataExport);

    Object.keys(dataExport[0]).forEach((columnName, columnIndex) => {

      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: columnIndex });
      parentSheet[cellAddress].v = this.primLetMay(columnName);
    });

    // Crear libro de trabajo con hoja de cálculo para objetos padres solamente
    const workbook: XLSX.WorkBook = {

      Sheets: { [`${nombreBoton}`]: parentSheet },
      SheetNames: [`${nombreBoton}`]
    };

    let childrenSheet = new Map();
    
    // Extraer y crear hojas de cálculo para objetos hijos
    dataExport.forEach((parent: any, parentIndex: any) => {
      // Verificar si la propiedad es un objeto
      for (const prop in parent) {



        if (Array.isArray(parent[prop])) {

          const children = parent[prop];
          children.forEach((child: any) => {

            Object.keys(child).forEach(oldName => {

              const newName: any = this.primLetMay(oldName);
              if (child.hasOwnProperty(oldName)) {

                child[newName] = child[oldName];
                delete child[oldName];
              }
            });
          });

          //_de_${nombreBoton.replaceAll(" ", "_")} 

          // Si la propiedad es un objeto, crear una hoja de cálculo para los objetos hijos
          if(parent[prop].length > 0){
            if(!childrenSheet.has(prop)){
              
                let childSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(parent[prop]);
                childrenSheet.set(prop, {length: parent[prop].length + 2, childSheet: childSheet})
                XLSX.utils.book_append_sheet(workbook, childSheet, `${this.primLetMay(prop)}`);
            }
            else{
              let childSheet: XLSX.WorkSheet = childrenSheet.get(prop).childSheet;
              let children: any = [];
              parent[prop].forEach((child: any) => {
                children.push(Object.values(child));
              })
              XLSX.utils.sheet_add_aoa(childSheet, children, {origin:`A${childrenSheet.get(prop).length}`});
              childrenSheet.set(prop, {length: childrenSheet.get(prop).length + parent[prop].length, childSheet: childSheet})
              
            }
            
            let childCellAddress: string | undefined;

            // Buscar el índice de la columna que contiene el nombre del campo del objeto hijo
            if (parentSheet['!ref']) {
              for (let col = 0; col < parentSheet['!ref']?.split(':')[1].charCodeAt(0) - parentSheet['!ref']?.split(':')[0].charCodeAt(0); col++) {
                if (parentSheet[String.fromCharCode('A'.charCodeAt(0) + col) + parent[prop].length]?.v === this.primLetMay(prop)) {
                  // Se encontró la columna que contiene el nombre del campo del objeto hijo
                  childCellAddress = String.fromCharCode('A'.charCodeAt(0) + col) + (parentIndex + 2);

                }
              }
            }

            // Vincular objetos padres con objetos hijos
            if (childCellAddress) {
              const firstChildCell = XLSX.utils.encode_cell({ r: childrenSheet.get(prop).length - parent[prop].length -1, c: 0 });
              parentSheet[childCellAddress].l = {
                //_de_${nombreBoton.replaceAll(" ", "_")}
                Target: `#${this.primLetMay(prop)}!${firstChildCell}`,
                Tooltip: "Ver " + prop
              };
              //_de_${nombreBoton.replaceAll(" ", "_")}
              parentSheet[childCellAddress].f = `HYPERLINK("#${this.primLetMay(prop)}!${firstChildCell}", "Ver ${prop}")`;
            }
          }
        }
      }
    });

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, titulo + "_" + nombre + ".xlsx");


  }
  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
