import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Proyecto, ProyectoExport, ProyectoListar } from 'src/app/models/proyecto';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { FilterService } from 'src/app/services/filter.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-listado-proyectos',
  templateUrl: './listado-proyectos.component.html',
  styleUrls: ['./listado-proyectos.component.scss']
})
export class ListadoProyectosComponent {

  nombrePagina = "Proyectos"
  rutaNuevo = 'admin-contacine/nuevo-proyecto'
  nombreBoton = "Nuevo Proyecto"
  evento: any = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0

  dialogVisible: boolean = false
  idEliminar: any = 0

  filters: any[] = [];
  pageSize = "10";
  page = "0";
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };

  isFirst: boolean = true


  mostrarPopup = 'out';
  mostrarDetalle = 'in';

  dataExport: any

  listaProy: any = []

  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource<ProyectoListar>();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};

  constructor(
    private router: Router,
    private proyectoService: ProyectoService,
    public interfazInicial: InterfazInicialComponent,
    private filterService: FilterService,
    public tableService: TableService,
    public notificationService: NotificationService,
  ) { }

  isAdmin = false
  ngOnInit(): void {
    if (this.interfazInicial.isLicencia || this.interfazInicial.isSuperAdmin) {
      this.isAdmin = true
    }

    let operator = 'ALL_FIELDS_CLASS';
    if (!this.interfazInicial.isSuperAdmin) {
      this.tableService.subscribeToProyectoChanges(() => {

        this.getProyectosList();
      });
    }
    this.getProyectosList();
  }
  getFilterProyecto() {

    if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
      if (this.filters[0] != undefined) {
        if (this.filters[0].key == 'id') {
          this.filters.shift()
        }
      }
      this.filters.unshift(
        {
          field_type: "STRING",
          key: "id",
          operator: "EQUAL",
          value: this.interfazInicial.proyaux.id
        });
    }

    // Get only active projects (fechaBaja is NULL)
    this.filters.push({
      field_type: 'DATE',
      key: 'fechaBaja',
      operator: 'IS_NULL'
    });
  }

  iniciarListas() {

    this.displayedColumnscompleta = []
    if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
      var a: any = sessionStorage.getItem(this.nombrePagina)
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {

      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }


    this.actualizarTabla();
  }

  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    let valueBool = null
    if (type == 'igic' || type == 'intracomunitario' || type == 'iva') {
      let typeAux: string = type;
      console.log(typeAux.substring(1, typeAux.length))
      type = 'hasI' + typeAux.substring(1, typeAux.length)
      if (event.target != null && event.target.value != "") {
        let value: string = event.target.value
        if (("si").includes((value.toLowerCase()))) {
          valueBool = true
        }
        if (("no").includes((value.toLowerCase()))) {
          valueBool = "false"
        }
      }
    } else {
      valueBool = null
    }

    this.filters = this.filterService.doFilter(event, type, operator, field_type, this.filters, valueBool)
    console.log(this.filters)
    this.page = "0"
    this.paginator.pageIndex = 0

    this.getProyectosList()
  }

  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }


  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }

  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }



  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.page = '0';
      this.getProyectosList()
    } else {
      let operator = 'ALL_FIELDS_CLASS';
      if (this.evento != '') {
        this.filters = [{
          operator: operator,
          value: this.evento,
          values: ["productoras.nombre", "lugares.nombre", "decorados.nombre", "Proveedor.nombreComercial"],
        }]
        if (!this.interfazInicial.isSuperAdmin &&!this.interfazInicial.isLicencia) {

          this.filters = [{
            field_type: "STRING",
            key: "id",
            operator: "EQUAL",
            value: this.interfazInicial.proyaux.id
          }]
        }



        this.filter = {
          filters: this.filters,
          page: this.page,
          size: this.pageSize,
          sorts: this.sorts,
        };
        this.getProyectosList()
      } else {

        this.filters = []

        this.getProyectosList()
      }
    }
  }


  ordenar(property: string, sorts: any[] = []) {
    console.log(this.ordenarCampos)
    let propertyAux = null
    if (property == 'igic' || property == 'intracomunitario' || property == 'iva') {
      propertyAux = property

      if (this.ordenarCampos[property] == undefined) {
        this.ordenarCampos[property] = false;
      } else {
        this.ordenarCampos[property] = !this.ordenarCampos[property];
      }
      let typeAux: string = property;
      property = 'hasI' + typeAux.substring(1, typeAux.length)
    }
    console.log(this.ordenarCampos[property])
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == property) {
        this.ordenarCampos[property] = true;
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    if (propertyAux != null) {
      this.ordenarCampos[propertyAux] = true;
    }
    this.sorts = [{ key: property, direction: orden }];
    this.getProyectosList();
  }

  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    this.getProyectosList();
  }

  getProyectosList() {
    this.getFilterProyecto()

    this.filter = {
      filters: this.filters,
      page: this.page,
      size: this.pageSize,
      sorts: this.sorts,
    };
    
    console.log("filter ", this.filter)

    /* Importante resetear todas las listas pero en teoria se podrian copiar y 
    pegar los metodos cambiando el servicio que compruaba el back y los objetos que construyen las listas y objetos*/
    this.proyectoService.filter(this.filter).subscribe({
      next: (data: any) => {

        console.log("data", data);

        if (this.ordenarCampos['id'] == undefined) {
          this.ordenarCampos = this.filterService.crearCampos(data)
        }
        console.log(this.ordenarCampos)
        this.dataExport = []
        data.content.forEach((element: any) => {
          this.dataExport.push(new ProyectoExport(element))
        });
        this.listaProy = []
        if (data && data.content && data.content != null && data.content.length > 0) {

          /* Este es el objeto de la lista cambiando los campos del 
          contructor de orden se cambia los campos de orden en la tabla */
          var aux = new ProyectoListar(data.content[0])
          if (this.isFirst) {
            this.displayedColumns = Object.keys(aux);

            this.displayedColumns.push("acciones")
            this.paginator._intl = this.interfazInicial.paginator

            this.isFirst = false
            this.iniciarListas();
          }

          data.content.forEach((proy: any) => {
            this.listaProy.push(new ProyectoListar(proy))
          });
          this.totalElements = data.totalElements;

          this.dataSource = this.listaProy
          this.paginator.pageIndex = data.pageable.pageNumber
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = new MatTableDataSource();
        }

      },
      error: (error) => {
        //  console.log(error);
      },
    });
  }



  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()
    return text.charAt(0).toUpperCase() + text.slice(1)

  }

  navegar(id: any, accion: string) {
    this.router.navigate([`admin-contacine/${accion}-proyecto/${id}`]);
  }



  nextPage(event: PageEvent) {
    this.page = event.pageIndex + "";
    this.pageSize = event.pageSize + "";

    this.getProyectosList();
  }

  delete(id: any) {
    this.proyectoService.delete(id).subscribe({
      next: (data: any) => {
      },
      error: (err) => {
        if (err.error && err.error.text) {
          this.notificationService.success("ÉXITO", err.error.text, 5000);
          this.getProyectosList();
        } else {
          this.notificationService.info("INFO", "No es posible borrar este proyecto.", 5000);
        }
      }
    })
    this.dialogVisible = false;
  }

}
