import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterfazInicialComponent } from './components/interfaz-inicial/interfaz-inicial.component';
import { LoginComponent } from './components/login/login.component';
import { RaizComponent } from './components/_raiz/raiz.component';
import { PlantillasComponent } from './components/plantillas/plantillas.component';
import { RegistroGastosComponent } from './components/Registro/registro-gastos/registro-gastos.component';
import { authGuard } from './helpers/guards/auth.guard';
import { NuevoGastoComponent } from './components/Registro/nuevo-gasto/nuevo-gasto.component';
import { RestorePassComponent } from './components/restore-pass/restore-pass.component';
import { NuevoUsuarioComponent } from './components/usuarios/nuevo-usuario/nuevo-usuario.component';
import { ListadoUsuariosComponent } from './components/usuarios/listado-usuarios/listado-usuarios.component';
import { MiPerfilComponent } from './components/usuarios/mi-perfil/mi-perfil.component';
import { FormularioProveeedorComponent } from './components/proveedores/formulario-proveeedor/formulario-proveeedor.component';
import { ListaProveedoresComponent } from './components/proveedores/lista-proveedores/lista-proveedores.component';
import { NuevoProyectoComponent } from './components/Proyecto/nuevo-proyecto/nuevo-proyecto.component';
import { ListadoProyectosComponent } from './components/Proyecto/listado-proyectos/listado-proyectos.component';
import { ListadoProductorasComponent } from './components/productoras/listado-productoras/listado-productoras.component';
import { NuevaProductoraComponent } from './components/productoras/nueva-productora/nueva-productora.component';
import { ListadoCuentasComponent } from './components/cuentas/listado-cuentas/listado-cuentas.component';
import { NuevaCuentaComponent } from './components/cuentas/nueva-cuenta/nueva-cuenta.component';
import { ListadoOrdenesCompraComponent } from './components/orden-compra/listado-ordenes-compra/listado-ordenes-compra.component';
import { NuevaOrdenCompraComponent } from './components/orden-compra/nueva-orden-compra/nueva-orden-compra.component';
import { NuevoPresupuestoComponent } from './components/presupuestos/nuevo-presupuesto/nuevo-presupuesto.component';
import { ListadoPresupuestosComponent } from './components/presupuestos/listado-presupuesto/listado-presupuestos/listado-presupuestos.component';
import { NotificacionesComponent } from './components/notificaciones/notificaciones/notificaciones.component';
import { RegistroPagosComponent } from './components/Registro/registro-pagos/registro-pagos.component';
import { NuevoInformeComponent } from './components/informes/nuevo-informe/nuevo-informe.component';
import { NuevoControlCosteComponent } from './components/control-costes/nuevo-control-coste/nuevo-control-coste.component';
import { TerminosCondicionesComponent } from './components/terminos-condiciones/terminos-condiciones.component';
import { ManualComponent } from './components/manual/manual.component';
import { ListadoJustificacionesComponent } from './components/justificaciones/listado-justificaciones/listado-justificaciones.component';
import { ListadoLaboralComponent } from './components/Registro/registro-laboral/listado-laboral/listado-laboral.component';
import { FormUnsavedChangesGuard } from './helpers/guards/form-unsaved-changes.guard';

const routes: Routes = [

  {
    path: '', component: RaizComponent,
    children: [
      { path: 'inicio', redirectTo: '' },
      { path: 'login', component: LoginComponent, redirectTo: '' },

      { path: 'plantilla', component: PlantillasComponent },
      { path: 'restore-password/:identificador', component: RestorePassComponent },
      { path: 'terminos-condiciones', component: TerminosCondicionesComponent },



      {
        path: 'admin-contacine', component: InterfazInicialComponent, canActivate: [authGuard], children: [
          { path: 'justificaciones', component: ListadoJustificacionesComponent, canActivate: [authGuard] },

          { path: 'manual', component: ManualComponent, canActivate: [authGuard] },
          { path: 'registro/laboral', component: ListadoLaboralComponent, canActivate: [authGuard] },
          { path: 'registro/gastos', component: RegistroGastosComponent, canActivate: [authGuard] },
          { path: 'registro/pagos', component: RegistroPagosComponent, canActivate: [authGuard] },
          { path: 'nuevo-gasto', component: NuevoGastoComponent, canActivate: [authGuard] },
          { path: 'nuevo-usuario', component: NuevoUsuarioComponent, canActivate: [authGuard] },
          { path: 'editar-usuario/:id', component: NuevoUsuarioComponent, canActivate: [authGuard] },
          { path: 'ver-usuario/:id', component: NuevoUsuarioComponent, canActivate: [authGuard] },
          { path: 'mantenimiento/usuarios', component: ListadoUsuariosComponent, canActivate: [authGuard] },
          { path: 'miPerfil', component: MiPerfilComponent, canActivate: [authGuard] },
          { path: 'proveedores', component: ListaProveedoresComponent, canActivate: [authGuard] },
          { path: 'nuevo-proveedor', component: FormularioProveeedorComponent, canActivate: [authGuard] },
          { path: 'editar-proveedor/:id', component: FormularioProveeedorComponent, canActivate: [authGuard] },
          { path: 'ver-proveedor/:id', component: FormularioProveeedorComponent, canActivate: [authGuard] },
          { path: 'editar-gasto/:id', component: NuevoGastoComponent, canActivate: [authGuard] },
          { path: 'ver-gasto/:id', component: NuevoGastoComponent, canActivate: [authGuard] },
          { path: 'proyectos', component: ListadoProyectosComponent, canActivate: [authGuard] },
          { path: 'nuevo-proyecto', component: NuevoProyectoComponent, canActivate: [authGuard] },
          { path: 'editar-proyecto/:id', component: NuevoProyectoComponent, canActivate: [authGuard] },
          { path: 'ver-proyecto/:id', component: NuevoProyectoComponent, canActivate: [authGuard] },
          { path: 'mantenimiento/productoras', component: ListadoProductorasComponent, canActivate: [authGuard] },
          { path: 'nueva-productora', component: NuevaProductoraComponent, canActivate: [authGuard] },
          { path: 'editar-productora/:id', component: NuevaProductoraComponent, canActivate: [authGuard] },
          { path: 'ver-productora/:id', component: NuevaProductoraComponent, canActivate: [authGuard] },
          { path: 'mantenimiento/cuentas', component: ListadoCuentasComponent, canActivate: [authGuard] },

          { path: 'nueva-cuenta', component: NuevaCuentaComponent, canActivate: [authGuard] },
          { path: 'editar-cuenta/:id', component: NuevaCuentaComponent, canActivate: [authGuard] },
          { path: 'ver-cuenta/:id', component: NuevaCuentaComponent, canActivate: [authGuard] },

          { path: 'orden-compra', component: ListadoOrdenesCompraComponent, canActivate: [authGuard] },
          { path: 'nueva-orden-compra', component: NuevaOrdenCompraComponent, canActivate: [authGuard] },
          { path: 'editar-orden-compra/:id', component: NuevaOrdenCompraComponent, canActivate: [authGuard] },
          { path: 'ver-orden-compra/:id', component: NuevaOrdenCompraComponent, canActivate: [authGuard] },

          // { path: 'presupuesto', component: ListadoPresupuestosComponent, canActivate: [authGuard] },
          { path: 'presupuesto', component: NuevoPresupuestoComponent, canActivate: [authGuard], canDeactivate: [FormUnsavedChangesGuard] },
          { path: 'editar-presupuesto/:id', component: NuevoPresupuestoComponent, canActivate: [authGuard], canDeactivate: [FormUnsavedChangesGuard] },
          { path: 'ver-presupuesto/:id', component: NuevoPresupuestoComponent, canActivate: [authGuard], canDeactivate: [FormUnsavedChangesGuard] },


          { path: 'informes', component: NuevoInformeComponent, canActivate: [authGuard] },
          /*   { path: 'editar-presupuesto/:id', component: NuevoPresupuestoComponent, canActivate: [authGuard] },
            { path: 'ver-presupuesto/:id', component: NuevoPresupuestoComponent, canActivate: [authGuard] }, */

          { path: 'presupuesto/control', component: NuevoControlCosteComponent, canActivate: [authGuard] },


          { path: 'notificaciones', component: NotificacionesComponent, canActivate: [authGuard] },

        ]
      },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
