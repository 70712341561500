// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  version: "1.0.3",
  production: false,
  environment: 'contacine',
  API_URL: "https://contacine.aeioros.com/contacine",
  optionalModules: [],

  IMG_PERFIL: 'imgPerfil',
  TITLE_HEAD: 'Contacine',
  TITLE_REGISTER: 'Bienvenido a Contacine',
  TIENE_CALENDARIO: true,
  TIENE_INFO: true,
  TIENE_BUSQUEDA: true,
  FOOTER_INFO_TITLE: null,
  FOOTER_INFO: null,
  COPYRIGHT: null,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
