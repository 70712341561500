import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {
  /* 
  Servicio para las peticiones del back para el la modificacion,guardar y listar un proveedor   
  */
  url: string = environment.API_URL + "/proveedor";
  urlTipo: string = environment.API_URL + "/tipoDocumentoProveedor";
  constructor(private http: HttpClient) { }

  filter(filter: any) {
    return this.http.post(`${this.url}/filter`, filter);
  }
  
  save(proveedor: any) {
    console.log("PROVEEDOR,", proveedor);

    return this.http.post(`${this.url}/save`, proveedor);
  }

  getAllProveedores() {
    return this.http.get(`${this.url}/getAllProveedores`);

  }
  
  findById(id: number): Observable<any> {

    return this.http.get(`${this.url}/${id}`);
  }
  
  update(id: number, value: any) {
    return this.http.post(`${this.url}/${id + ""}`, value);

  }
  
  getAllTTiposDocs() {
    return this.http.get(`${this.urlTipo}/list`);

  }

  download(idsNext: any) {
    return this.http.post(`${this.url}/download`, idsNext, { responseType: 'arraybuffer' });
  }

  deleteById(id: number) {
    return this.http.post(`${this.url}/delete`, { id: id });
  }

  findByNifCif(value: any) {
    let params = new HttpParams().set("nifCif", value)
    return this.http.get(`${this.url}/findByNifCif`, { params: params });
  }

  asociarProyUsuExit(proveedor: number, proyectoId: number) {
    let params = new HttpParams().set("proveedorId", proveedor).set("proyectoId",proyectoId)
    console.log(params);
    
    return this.http.get(`${this.url}/asociarExitente`, { params: params });
  }
}
