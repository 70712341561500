import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Cuenta } from 'src/app/models/cuenta';
import { Proveedor } from 'src/app/models/proveedor';
import { CuentaService } from 'src/app/services/cuenta.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';

@Component({
  selector: 'app-nueva-cuenta',
  templateUrl: './nueva-cuenta.component.html',
  styleUrls: ['./nueva-cuenta.component.scss']
})
export class NuevaCuentaComponent {



  codigo = ""

  isUpdate: boolean = false
  isReading: boolean = false
  id!: number
  cuenta: Cuenta = new Cuenta
  cuentaAux: Cuenta = new Cuenta
  expandedTelefono = 1
  proyectos: any[] = []

  isAll = false

  form!: FormGroup

  private formBuilder: FormBuilder = new FormBuilder

  get hijos(): FormArray {
    return this.form.get("hijos") as FormArray;
  }
  constructor(
    private notificationService: NotificationService,
    private dateAdapter: DateAdapter<any>,
    private proyectoService: ProyectoService,
    private router: Router,
    private route: ActivatedRoute,
    private cuentaService: CuentaService,
    private interfazInicial: InterfazInicialComponent,
    private tableService: TableService

  ) { }

  cuentaUpdate: any = {}

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Cuenta"
  nombrePagina = "Cuenta"

  isAdmin = false

  ngOnInit(): void {

    if (this.interfazInicial.isSuperAdmin || this.interfazInicial.isLicencia) {
      this.isAdmin = true;
    }

    if (!this.interfazInicial.proyaux || !this.interfazInicial.proyaux.id) {
      this.notificationService.warning("ALERTA", "No puedes editar una cuenta sin proyecto asociado.", 5000)
      this.router.navigate(["admin-contacine/mantenimiento/cuentas"])
    }
    this.dateAdapter.setLocale('es-ES');
    if (this.tableService.checkAnyAccionByPermiso(23, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    } else {

      this.route.params.subscribe(params => {
        if (params['id']) {
          this.id = params['id'];


          this.route.url.subscribe(data => {
            if (data[0].path.includes("ver")) {
              this.isReading = true
              if (this.tableService.checkPermiso(23, 1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }

            } else if (data[0].path.includes("editar")) {
              this.isUpdate = true
              if (this.tableService.checkPermiso(23, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }

            }

          });
        } else {
          if (this.tableService.checkPermiso(23, 2, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
            this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
            this.router.navigate(["admin-contacine/proyectos"])
          }
        }
      })
    }

    this.form = new FormGroup({
      cuenta: new FormControl({ value: "", disabled: this.isUpdate }, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
      hijos: new FormArray([])
    })
    if (this.isUpdate) {
      this.cuentaService.getById(this.id).subscribe({
        next: (data) => {
          this.cuentaUpdate = data
          console.log(this.cuentaUpdate);

          this.form.get("cuenta")?.setValue(data.cuenta)

          this.addHijosUpdate(
            this.interfazInicial.proyaux, this.cuentaUpdate
          );
        }
      })
    } else {

      if (this.isAdmin && !this.isUpdate) {

        this.proyectoService.getAllProyectos().subscribe({
          next: (data) => {
            this.proyectos = data

            this.proyectos.forEach((proyecto) => {
              this.addHijosUpdate(
                proyecto, this.cuentaUpdate
              );
            })
          }
        })
      } else {
        this.addHijosUpdate(
          this.interfazInicial.proyaux, this.cuentaUpdate
        );
      }
    }

  }
  inputCodigo() {
    this.codigo = this.form.get("cuenta")?.value

  }

  // Método para agregar un nuevo FormGroup al array 'hijos'
  addHijosUpdate(proyecto: any, data: any) {
    console.log("cuenta", data.cuenta);
    var etiqueta: any = ""
    if (data.etiquetas) {

      etiqueta = data.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
      if (etiqueta && etiqueta.descripcion) {
        etiqueta = etiqueta.descripcion
      } else {
        etiqueta = ""
      }
    }
    const metadato = this.formBuilder.group({
      codigo: new FormControl({ value: data.cuenta, disabled: true }),
      codigoHijo: new FormControl({ value: data.codigoHijo || "", disabled: true }),
      concepto: new FormControl({ value: data.codigoHijo ? data.concepto : etiqueta || "", disabled: this.isReading }, [!this.isAdmin ? Validators.required : Validators.nullValidator]),
      proyecto: new FormControl({ value: proyecto, disabled: true }),
      titulo: new FormControl({ value: proyecto.titulo, disabled: true }),
      aux: new FormControl({ value: data.concepto, disabled: true })
    });
    console.log("aa", metadato.value);

    this.hijos.push(metadato);


  }


  onSubmit() {
    this.isSave = false
    if (!this.isReading) {
      console.log(this.form.value);
      var codigos: Cuenta[] = []
      this.hijos.controls.forEach((element: any) => {
        if (element.controls["concepto"].value) {

          const obj = {
            cuenta: this.form.get("cuenta")?.value,
            concepto: element.controls["concepto"].value,
            proyectos: [{ id: element.controls["proyecto"].value.id }],

          }
          codigos.push(obj)


        }
      });
      if (this.form.valid) {

        if (!this.isUpdate) {
          console.log(codigos);

          this.cuentaService.save(codigos).subscribe({
            next: (data) => {
              this.notificationService.success("GUARDADO", "La cuenta se ha guardado correctamente.", 5000);
              this.router.navigate(['admin-contacine/mantenimiento/cuentas']);
              //TODO
            },
            error: (err) => {
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }

            }
          })
        } else {
          console.log("aaadd", codigos);
          if (!this.cuentaUpdate.proyectos && this.form.valid && this.form.get("hijos")?.value[0] && this.form.get("hijos")?.value[0].concepto) {


            var etiqueta = {
              proyecto:  this.interfazInicial.proyaux.id ,
              descripcion: this.form.get("hijos")?.value[0].concepto + "",
              id: this.id,
            }
            this.cuentaService.asociarTipo(etiqueta).subscribe({
              next: (data) => {
                console.log(data);
                this.notificationService.success("GUARDADO", "El tipo se ha guardado correctamente.", 5000);
                this.router.navigate(['admin-contacine/mantenimiento/cuentas']);
              }
            })
          } else if (this.cuentaUpdate.proyectos && this.form.valid && this.form.get("hijos")?.value[0] && this.form.get("hijos")?.value[0].concepto) {
            const obj = {
              cuenta: this.form.get("cuenta")?.value,
              concepto: this.form.get("hijos")?.value[0].concepto,
              proyectos: [{ id: this.interfazInicial.proyaux.id }],

            }
            this.cuentaService.update(this.id, obj).subscribe({
              next: (next) => {
                console.log(next);
                this.notificationService.success("ÉXITO", "La cuenta se ha actualizado correctamente.", 5000);
                this.router.navigate(['admin-contacine/mantenimiento/cuentas']);
              },
              error: (error) => {
                this.notificationService.error("ERROR", "Ha ocurrido un error.", 5000);

              }
            })
          }
        }
      } else {
        this.notificationService.warning("ALERTA", "Hay campos vacíos.", 5000)
      }

    } else {
      this.router.navigate(['admin-contacine/mantenimiento/cuentas'])
    }



  }
  findByProyAndCodigo(item: any): any {
    if (item.controls["codigo"].value.length != 6) {
      item.controls["codigoHijo"].value = ""
    }
    return item.controls["codigoHijo"].value
  }
  setConcepto(item: any): any {

    return item.controls["concepto"].value
  }

  setCodigo(item: any): any {
    console.log(this.cuentaUpdate);

    if (item.controls["codigo"].value && item.controls["codigo"].value.length == 6 && this.codigo.length == 6 && !(item.controls["codigoHijo"].value as String) && item.controls["codigoHijo"].value == 0) {

      this.cuentaService.getHByProyAnfCod(item.controls["proyecto"].value.id, item.controls["codigo"].value).subscribe({
        next: (data) => {

          item.controls["codigoHijo"].value = data


        },
        error: (err) => {

          item.controls["codigoHijo"].value = err.error.text

        }
      })

    }


    return item.controls["codigo"].value = this.codigo

  }

  unselect(completed: boolean, item: any) {

    if (completed == true) {

    } else {
      item.controls["concepto"].value = ""
    }



  }
  checkSelected(item: any): boolean {
    if (item.controls["concepto"].value) {
      return true
    }
    return false
  }
  selectAll(active: boolean): boolean {
    if (active) {
      var aux = ""
      var index = 0
      this.hijos.controls.forEach((data: any) => {
        if (data.controls["concepto"].value) {
          aux = data.controls["concepto"].value
          index++
        }
      })

      if (index == 1) {
        this.hijos.controls.forEach((data: any) => {
          data.controls["concepto"].value = aux
        })
        return this.isAll = true
      } else {
        return this.isAll = false
      }
    }
    return this.isAll = false
  }

  volver() {
    this.router.navigate(['admin-contacine/mantenimiento/cuentas'])
  }
}
