<div class="notifications">
	<div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
		<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
	</div>
</div>

<ng-template #notificationTpl let-notification="notification">
	<div class="title d-flex justify-content-between">
		<div><b>{{notification.title}}</b></div>

		<mat-icon class="material-symbols-outlined pointer" (click)="close(notification)">
			close
		</mat-icon>
	</div>
	<div class="message">{{notification.message}}</div>
</ng-template>