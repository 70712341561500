import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch();

// window.console.log = () => { }
// window.console.debug = () => { }
// window.console.error = () => { }
// window.console.warn = () => { }
