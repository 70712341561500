import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { Proyecto, TipoMoneda } from 'src/app/models/proyecto';
import { ProductoraService } from 'src/app/services/productora.service ';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { FechaService } from 'src/app/services/servicios-funcionalidad/fecha.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import * as moment from 'moment';
import { TipoMonedaService } from 'src/app/services/tipo-moneda.service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';

@Component({
  selector: 'app-nuevo-proyecto',
  templateUrl: './nuevo-proyecto.component.html',
  styleUrls: ['./nuevo-proyecto.component.scss'],
  animations: [dropdownAnimation],

})
export class NuevoProyectoComponent {

  changeDec(event: any, control: any): any {
    if (event.key == ".") {
      control.setValue(control?.value + ",")
    }
  }

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Proyecto"
  nombrePagina = "Proyecto"

  loading: boolean = false;
  formArray: any = []

  isFormProyExpanded: String = 'in';
  mostrarProd: String = 'in';

  expandedLugar = 1
  expandedDecorado = 1

  isUpdate: boolean = false
  isReading: boolean = false
  id!: number
  proyecto: Proyecto = new Proyecto
  proyectoAux: Proyecto = new Proyecto

  productorasList: any = []

  private formBuilder: FormBuilder = new FormBuilder

  form!: FormGroup;
  formProd!: FormGroup;

  tiposMoneda: TipoMoneda[] = [];


  get monedaCambio(): FormArray {
    return this.form.get("monedaCambio") as FormArray;
  }

  // get lugares(): FormArray {
  //   return this.form.get("lugares") as FormArray;
  // }

  // get decorados(): FormArray {
  //   return this.form.get("decorados") as FormArray;
  // }

  get lugarDecorado(): FormArray {
    return this.form.get("lugarDecorado") as FormArray;
  }

  constructor(
    private notificationService: NotificationService,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
    private route: ActivatedRoute,
    private proyectoService: ProyectoService,
    private productoraService: ProductoraService,
    private fechaService: FechaService,
    private interfazInicial: InterfazInicialComponent,
    private usuarioService: UsuarioService,
    private storageService: StorageServiceService,
    private tipoMonedaService: TipoMonedaService,
    private tableService: TableService
  ) { }

  ngOnInit(): void {
    this.getTipoMoneda();
    this.dateAdapter.setLocale('es-ES');
    if (this.tableService.checkAnyAccionByPermiso(1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    } else {

      this.route.params.subscribe(params => {
        if (params['id']) {
          this.id = params['id'];
          console.log(this.route.url.subscribe(data => {
            if (data[0].path.includes("ver")) {
              this.isReading = true
              if (this.tableService.checkPermiso(1, 1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            } else if (data[0].path.includes("editar")) {
              this.isUpdate = true
              if (this.tableService.checkPermiso(1, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            }
          }));
        } else {
          if (this.tableService.checkPermiso(1, 2, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
            this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
            this.router.navigate(["admin-contacine/proyectos"])
          }
        }
      })
    }

    var storedFormData: any = {}

    if (!this.isUpdate && !this.isReading) {
      try {
        storedFormData = JSON.parse(this.storageService.get('proyForm') ? this.storageService.get('proyForm') : "");
      } catch {

      }
    }

    this.form = new FormGroup({
      id: new FormControl(''),
      titulo: new FormControl({ value: storedFormData.titulo || "", disabled: this.isReading }, Validators.required),
      fechaInicioRodaje: new FormControl({ value: storedFormData.fechaInicioRodaje ? moment(storedFormData.fechaInicioRodaje) : "", disabled: this.isReading }),
      fechaFinRodaje: new FormControl({ value: storedFormData.fechaFinRodaje ? moment(storedFormData.fechaFinRodaje) : "", disabled: this.isReading }),
      fechaInicio: new FormControl({ value: storedFormData.fechaInicio ? moment(storedFormData.fechaInicio) : "", disabled: true }),
      fechaFin: new FormControl({ value: storedFormData.fechaFin ? moment(storedFormData.fechaFin) : "", disabled: true }),
      semanasPreproducción: new FormControl({ value: storedFormData.semanasPreproducción || "", disabled: this.isReading }, [Validators.pattern("^[0-9]+([,.][0-9]{1,2})?$")]),
      semanasRodaje: new FormControl({ value: storedFormData.semanasRodaje || "", disabled: true }),
      semanasCierre: new FormControl({ value: storedFormData.semanasCierre || "", disabled: this.isReading }, [Validators.pattern("^[0-9]+([,.][0-9]{1,2})?$")]),
      hasIva: new FormControl({ value: storedFormData.hasIva || "", disabled: this.isReading }),
      hasIgic: new FormControl({ value: storedFormData.hasIgic || "", disabled: this.isReading }),
      hasIntracomunitario: new FormControl({ value: storedFormData.hasIntracomunitario || "", disabled: this.isReading }),
      monedaCambio: new FormArray([
        new FormGroup({
          id: new FormControl({ value: "", disabled: this.isReading }),
          moneda: new FormControl({ value: "", disabled: this.isReading }),
          cambio: new FormControl({ value: "", disabled: this.isReading }),
          tipoMoneda: new FormControl({ value: { id: 1 }, disabled: this.isReading })
        }),
      ]),
      lugarDecorado: new FormArray([
        new FormGroup({
          id: new FormControl({ value: "", disabled: this.isReading }),
          lugar: new FormControl({ value: "", disabled: this.isReading }),
          decorado: new FormControl({ value: "", disabled: this.isReading }),
        }),
      ]),
      // lugares: this.camposLugar(),
      // decorados: this.camposDecorados(),
      productoras: new FormControl({ value: storedFormData.productoras || "", disabled: this.isReading }),
      vehiculos: new FormControl({ value: storedFormData.vehiculos || "", disabled: this.isReading }),
      horasExtras: new FormControl({ value: storedFormData.horasExtras || "", disabled: this.isReading }),
      dietasActores: new FormControl({ value: storedFormData.dietasActores || "", disabled: this.isReading }),
      complementosMaterial: new FormControl({ value: storedFormData.complementosMaterial || "", disabled: this.isReading }),
      horasExtrasFestivas: new FormControl({ value: storedFormData.horasExtrasFestivas || "", disabled: this.isReading }),
      dietasTecnicos: new FormControl({ value: storedFormData.dietasTecnicos || "", disabled: this.isReading }),
    });


    this.productoraService.getAllProductoras().subscribe({
      next: (data) => {
        this.productorasList = data
      },
    })

    var productoras: any = []

    if (storedFormData.monedaCambio || storedFormData.lugarDecorado) {

      this.monedaCambio.clear();
      this.lugarDecorado.clear();

      storedFormData.monedaCambio.forEach((mon: any) => {
        this.addMonedasForUpdate(
          mon.moneda,
          mon.cambio,
          { id: mon.tipoMoneda.id }
        );
      });

      storedFormData.lugarDecorado.forEach((lug: any) => {
        this.addLugaresForUpdate(
          lug.lugar,
          lug.decorado
        );
      });

      this.checkSemP()
      this.checFechFin()
      this.checFechIn()
    }

    if (this.isUpdate || this.isReading && this.id) {
      this.proyectoService.findById(this.id).subscribe((data) => {

        // this.lugares.clear();
        // this.decorados.clear();
        this.proyecto = new Proyecto(data)
        console.log("proy", this.proyecto);

        this.proyecto.productoras.forEach((element: any) => {
          productoras.push({ id: element.id })
        })

        // this.form.controls['lugares'] = this.camposLugar()
        // this.form.controls['decorados'] = this.camposDecorados()
        this.form.get('titulo')?.setValue(this.proyecto.titulo)
        this.form.get('fechaInicioRodaje')?.setValue(this.proyecto.fechaInicioRodaje ? this.fechaService.date(this.proyecto.fechaInicioRodaje) : "")
        this.form.get('fechaFinRodaje')?.setValue(this.proyecto.fechaFinRodaje ? this.fechaService.date(this.proyecto.fechaFinRodaje) : "")
        this.form.get('fechaInicio')?.setValue(this.proyecto.fechaInicio ? this.fechaService.date(this.proyecto.fechaInicio) : "")
        this.form.get('fechaFin')?.setValue(this.proyecto.fechaFin ? this.fechaService.date(this.proyecto.fechaFin) : "")
        this.form.get('semanasPreproducción')?.setValue(this.proyecto.semanasPreproducción)
        this.form.get('semanasRodaje')?.setValue(this.proyecto.semanasRodaje)
        this.form.get('semanasCierre')?.setValue(this.proyecto.semanasCierre)
        this.form.get('hasIva')?.setValue(this.proyecto.hasIva ? "true" : "false")
        this.form.get('hasIgic')?.setValue(this.proyecto.hasIgic? "true" : "false")
        this.form.get('hasIntracomunitario')?.setValue(this.proyecto.hasIntracomunitario? "true" : "false")

        if (this.proyecto.monedaCambio.length != 0) {
          this.monedaCambio.clear();
        }

        this.proyecto.monedaCambio.forEach((mon) => {
          this.addMonedasForUpdate(
            mon.moneda,
            mon.cambio,
            { id: mon.tipoMoneda?.id }
          );
        });

        if (this.proyecto.lugarDecorado.length != 0) {
          this.lugarDecorado.clear();
        }

        this.proyecto.lugarDecorado.forEach((lug: any) => {
          this.addLugaresForUpdate(
            lug.lugar,
            lug.decorado
          );
        });

        console.log('form', this.form.value);
        this.form.get('productoras')?.setValue(productoras)

        if (this.proyecto.vehiculos && this.proyecto.vehiculos.cuenta) {
          this.form.get('vehiculos')?.setValue(this.proyecto.vehiculos.cuenta)
          this.form.get('vehiculos')?.disable()
        }
        if (this.proyecto.horasExtras && this.proyecto.horasExtras.cuenta) {
          this.form.get('horasExtras')?.setValue(this.proyecto.horasExtras.cuenta)
          this.form.get('horasExtras')?.disable()
        }
        if (this.proyecto.dietasActores && this.proyecto.dietasActores.cuenta) {
          this.form.get('dietasActores')?.setValue(this.proyecto.dietasActores.cuenta)
          this.form.get('dietasActores')?.disable()
        }
        if (this.proyecto.complementosMaterial && this.proyecto.complementosMaterial.cuenta) {
          this.form.get('complementosMaterial')?.setValue(this.proyecto.complementosMaterial.cuenta)
          this.form.get('complementosMaterial')?.disable()
        }
        if (this.proyecto.horasExtrasFestivas && this.proyecto.horasExtrasFestivas.cuenta) {
          this.form.get('horasExtrasFestivas')?.setValue(this.proyecto.horasExtrasFestivas.cuenta)
          this.form.get('horasExtrasFestivas')?.disable()
        }
        if (this.proyecto.dietasTecnicos && this.proyecto.dietasTecnicos.cuenta) {
          this.form.get('dietasTecnicos')?.setValue(this.proyecto.dietasTecnicos.cuenta)
          this.form.get('dietasTecnicos')?.disable()
        }


        console.log('form', this.form);

      })
    }
  }

  getTipoMoneda() {
    this.tipoMonedaService.getTiposMoneda().subscribe({
      next: (data) => {
        this.tiposMoneda = data;
      }
    })
  }

  // camposLugar() {
  //   let lugares = this.proyecto?.lugares;

  //   if (lugares == null || lugares.length == 0) {
  //     var lugar = new FormArray([new FormControl({ value: "", disabled: this.isReading }, Validators.required)])

  //   }
  //   else {
  //     var lugarup = new FormArray([new FormControl({ value: lugares[0].toString(), disabled: this.isReading }, Validators.required)])
  //     for (let i = 1; i < lugares.length; i++) {
  //       lugarup.push(new FormControl({ value: lugares[i].toString(), disabled: this.isReading }, Validators.required));
  //       this.expandedLugar++;
  //     }
  //     return lugarup
  //   }
  //   return lugar;
  // }

  // addLugar() {
  //   let lug = this.form.controls['lugares'] as FormArray;
  //   lug.push(
  //     new FormControl(null, Validators.required),
  //   )
  //   this.expandedLugar++;
  // }

  // removeLugar(i: number) {
  //   this.lugares.removeAt(i);
  //   this.expandedLugar--;
  // }

  // camposDecorados() {
  //   let decorados = this.proyecto?.decorados;

  //   if (decorados == null || decorados.length == 0) {
  //     var decorado = new FormArray([new FormControl("", Validators.required)])

  //   }
  //   else {
  //     var decoradoup = new FormArray([new FormControl({ value: decorados[0].toString(), disabled: this.isReading }, Validators.required)])
  //     for (let i = 1; i < decorados.length; i++) {
  //       decoradoup.push(new FormControl({ value: decorados[i].toString(), disabled: this.isReading }, Validators.required));
  //       this.expandedDecorado++;
  //     }
  //     return decoradoup
  //   }
  //   return decorado;
  // }

  // addDecorado() {
  //   let dec = this.form.controls['decorados'] as FormArray;
  //   dec.push(
  //     new FormControl(null, Validators.required),
  //   )
  //   this.expandedDecorado++;
  // }

  // removeDecorado(i: number) {
  //   this.decorados.removeAt(i);
  //   this.expandedDecorado--;
  // }

  addMonedasForUpdate(moneda: any, cambio: any, tipoMoneda: any) {
    const metadato = this.formBuilder.group({
      moneda: new FormControl({ value: moneda, disabled: this.isReading }),
      cambio: new FormControl({ value: cambio, disabled: this.isReading }),
      tipoMoneda: new FormControl({ value: tipoMoneda.id ? tipoMoneda : undefined, disabled: this.isReading }),
    });
    this.monedaCambio.push(metadato);
  }

  addLugaresForUpdate(lugar: any, decorado: any) {
    const metadato = this.formBuilder.group({
      lugar: [lugar],
      decorado: [decorado]
    });

    this.lugarDecorado.push(metadato);
  }

  addLugar(): void {
    const metadato = this.formBuilder.group({
      lugar: [''],
      decorado: ['']
    });
    this.lugarDecorado.push(metadato);
  }

  removeLugar(index: number): void {
    this.lugarDecorado.removeAt(index);
  }

  onSubmit() {
    this.isSave = false
    if (!this.isReading) {

      if (this.form.valid) {

        this.form.value.monedaCambio.forEach((moneda: any) => {
          if (typeof moneda.tipoMoneda == 'string') {
            moneda.tipoMoneda = {};
          }
        });

        this.proyectoAux = new Proyecto(this.form.value);

        this.proyectoAux.fechaFinRodaje = this.fechaService.type(this.proyectoAux.fechaFinRodaje)
        this.proyectoAux.fechaInicioRodaje = this.fechaService.type(this.proyectoAux.fechaInicioRodaje)

        if (typeof this.proyectoAux.productoras == 'string') {
          this.proyectoAux.productoras = [];
        }

        if (!this.isUpdate) {

          this.proyectoAux.vehiculos ? this.proyectoAux.vehiculos = { 'cuenta': this.proyectoAux.vehiculos } : this.proyectoAux.vehiculos = null;
          this.proyectoAux.horasExtras ? this.proyectoAux.horasExtras = { 'cuenta': this.proyectoAux.horasExtras } : this.proyectoAux.horasExtras = null;
          this.proyectoAux.dietasActores ? this.proyectoAux.dietasActores = { 'cuenta': this.proyectoAux.dietasActores } : this.proyectoAux.dietasActores = null;
          this.proyectoAux.complementosMaterial ? this.proyectoAux.complementosMaterial = { 'cuenta': this.proyectoAux.complementosMaterial } : this.proyectoAux.complementosMaterial = null;
          this.proyectoAux.horasExtrasFestivas ? this.proyectoAux.horasExtrasFestivas = { 'cuenta': this.proyectoAux.horasExtrasFestivas } : this.proyectoAux.horasExtrasFestivas = null;
          this.proyectoAux.dietasTecnicos ? this.proyectoAux.dietasTecnicos = { 'cuenta': this.proyectoAux.dietasTecnicos } : this.proyectoAux.dietasTecnicos = null;
          this.loading = true;
          this.proyectoService.save(this.proyectoAux).subscribe({
            next: (data) => {
              if (this.interfazInicial.currentUser.rol.id == 3) {
                this.usuarioService.getCurrentUser().subscribe({
                  next: (succ) => {
                    console.log(succ);
                    this.interfazInicial.listadoProyectos = succ.user.proyectos
                    console.log(this.interfazInicial.listadoProyectos);
                    console.log("this.interfazInicial.listadoProyectos", this.interfazInicial.listadoProyectos);
                    sessionStorage.setItem("listaProyectos", JSON.stringify(succ.user.proyectos))
                  }
                })
              }

              this.loading = false;
              this.storageService.eliminar("proyForm")
              this.notificationService.success("Guardado", "El proyecto se ha guardado correctamente.", 5000);
              this.router.navigate(['admin-contacine/proyectos']);
            }, error: (err) => {
              this.loading = false;
              console.log(err);

              if (err.error && Object.keys(err.error)[0] && !Object.keys(err.error)[1]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);


              } if (err.error && Object.keys(err.error)[0] && Object.keys(err.error)[1]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[1]], 5000);
                console.log(Object.keys(err.error));
                console.log(Object.keys(err.error)[0]);

                this.router.navigate([`admin-contacine/editar-proyecto/${ err.error[Object.keys(err.error)[0]]}`]);

              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })

        } else {
          this.form.get('id')?.setValue(this.proyecto.id)
          this.proyectoAux = new Proyecto(this.form.value)

          this.proyectoAux.fechaFinRodaje = this.fechaService.type(this.proyectoAux.fechaFinRodaje)
          this.proyectoAux.fechaInicioRodaje = this.fechaService.type(this.proyectoAux.fechaInicioRodaje)




          this.proyecto.vehiculos ? this.proyectoAux.vehiculos = this.proyecto.vehiculos :
            (this.proyectoAux.vehiculos ? this.proyectoAux.vehiculos = { 'cuenta': this.proyectoAux.vehiculos } : this.proyectoAux.vehiculos = null);
          this.proyecto.horasExtras ? this.proyectoAux.horasExtras = this.proyecto.horasExtras :
            (this.proyectoAux.horasExtras ? this.proyectoAux.horasExtras = { 'cuenta': this.proyectoAux.horasExtras } : this.proyectoAux.horasExtras = null);
          this.proyecto.dietasActores ? this.proyectoAux.dietasActores = this.proyecto.dietasActores :
            (this.proyectoAux.dietasActores ? this.proyectoAux.dietasActores = { 'cuenta': this.proyectoAux.dietasActores } : this.proyectoAux.dietasActores = null);
          this.proyecto.complementosMaterial ? this.proyectoAux.complementosMaterial = this.proyecto.complementosMaterial :
            (this.proyectoAux.complementosMaterial ? this.proyectoAux.complementosMaterial = { 'cuenta': this.proyectoAux.complementosMaterial } : this.proyectoAux.complementosMaterial = null);
          this.proyecto.horasExtrasFestivas ? this.proyectoAux.horasExtrasFestivas = this.proyecto.horasExtrasFestivas :
            (this.proyectoAux.horasExtrasFestivas ? this.proyectoAux.horasExtrasFestivas = { 'cuenta': this.proyectoAux.horasExtrasFestivas } : this.proyectoAux.horasExtrasFestivas = null);
          this.proyecto.dietasTecnicos ? this.proyectoAux.dietasTecnicos = this.proyecto.dietasTecnicos :
            (this.proyectoAux.dietasTecnicos ? this.proyectoAux.dietasTecnicos = { 'cuenta': this.proyectoAux.dietasTecnicos } : this.proyectoAux.dietasTecnicos = null);


          console.log("PROYECTOAUX", this.proyectoAux);

          this.loading = true;
          this.proyectoService.update(this.proyectoAux, this.id + "").subscribe({
            next: (data) => {
              this.loading = false;
              this.notificationService.success("Guardado", "El proyecto se ha actualizado correctamente.", 5000);
              this.router.navigate(['admin-contacine/proyectos']);
              this.usuarioService.getCurrentUser().subscribe({
                next: (data) => {
                  console.log(data);

                  this.interfazInicial.listadoProyectos = data.user.proyectos
                  this.interfazInicial.proyaux = this.interfazInicial.listadoProyectos.filter((data) => data.id === this.interfazInicial.proyaux.id)[0]
                  if (!this.interfazInicial.isSuperAdmin) {

                    this.interfazInicial.getFechas()
                  }
                }
              })
            },
            error: (err) => {
              this.loading = false;
              console.log(err);
              (err)
              if (err.error && Object.keys(err.error)[0] && !Object.keys(err.error)[1]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);


              } if (err.error && Object.keys(err.error)[0] && Object.keys(err.error)[1]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[1]], 5000);
                this.ngOnInit();

              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })
        }
      } else {
        this.notificationService.warning("ALERTA", "Rellene todos los campos.", 5000)
      }
    } else {
      this.router.navigate(["admin-contacine/proyectos"])
    }
  }

  addMoneda(): void {
    const metadato = this.formBuilder.group({
      moneda: [''],
      cambio: [''],
      tipoMoneda: ['']
    });

    this.monedaCambio.push(metadato);
  }

  removeMoneda(index: number): void {
    this.monedaCambio.removeAt(index);
  }

  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id
  }

  checkSemP() {
    if (!this.isUpdate && !this.isReading) {
      console.log("de");
      this.storageService.guardar('proyForm', JSON.stringify(this.form.value));
    }

    if (this.form.get('fechaInicioRodaje')?.value && this.form.get('fechaInicioRodaje') != null &&
      this.form.get('fechaFinRodaje')?.value && this.form.get('fechaFinRodaje')?.value != null) {
      this.proyectoService.checkSR(this.fechaService.type(this.form.get('fechaInicioRodaje')?.value), this.fechaService.type(this.form.get('fechaFinRodaje')?.value), 0).subscribe({
        next: (data) => {
          console.log(data);
          this.form.get('semanasRodaje')?.setValue(data)
        }
      })
    }
  }

  checFechIn() {
    if (!this.isUpdate && !this.isReading) {
      console.log("de");
      this.storageService.guardar('proyForm', JSON.stringify(this.form.value));
    }

    console.log(this.form.get('fechaInicioRodaje')?.value);

    if (this.form.get('semanasPreproducción')?.value && this.form.get('fechaInicioRodaje')?.value) {
      this.proyectoService.checkSR(this.fechaService.type(this.form.get('fechaInicioRodaje')?.value), null, this.form.get('semanasPreproducción')?.value).subscribe({
        next: (data) => {
          this.form.get('fechaInicio')?.setValue(this.fechaService.date(data[2] + "-" + data[1] + "-" + data[0]))
        }
      })
    }
  }

  checFechFin() {
    if (!this.isUpdate && !this.isReading) {
      this.storageService.guardar('proyForm', JSON.stringify(this.form.value));
    }

    if (this.form.get('semanasCierre')?.value && this.fechaService.type(this.form.get('fechaFinRodaje')?.value)) {
      this.proyectoService.checkSR(null, this.fechaService.type(this.form.get('fechaFinRodaje')?.value), this.form.get('semanasCierre')?.value).subscribe({
        next: (data) => {
          this.form.get('fechaFin')?.setValue(this.fechaService.date(data[2] + "-" + data[1] + "-" + data[0]))
        }
      })
    }
  }

  volver() {
    this.router.navigate(['admin-contacine/proyectos'])
  }

  onFormChange() {
    if (!this.isUpdate && !this.isReading) {
      this.storageService.guardar('proyForm', JSON.stringify(this.form.value));
    }
  }

  limparFormulario() {

    this.isClearForm = false
    this.storageService.eliminar('proyForm');
    this.form.reset()
  }

}