import { Cuenta } from "./cuenta";
import { NomId } from "./orden-compra";
import { Proyecto } from "./proyecto";

export class Presupuesto {
  id!: number;
  fechaAlta!: string
  subcapitulo!: any;
  codigo!: Cuenta;
  nombre!: string;
  tipoUnidad!: NomId;
  semanasPreparacion!: number;
  semanasRodaje!: number;
  semanasCierre!: number;
  totalSemanas!: number;
  cantidadUnidad!: number;
  finObra!: number;
  totalPresupuesto!: number;
  presupuestoEstimado!: number;
  desviacion!: number;
  proyecto!: Proyecto;

  public constructor(data?: any, init?: Partial<Presupuesto>) {
    console.log(data);
    
    if (data) {
      this.id = data.id.value;
      this.fechaAlta = data.fechaAlta;
      this.subcapitulo = data.subcapitulo;
      this.codigo = data.codigo;
      this.nombre = data.nombre.value;
      this.tipoUnidad = data.tipoUnidad.value;
      this.semanasPreparacion = data.semanasPreparacion.value;
      this.semanasRodaje = data.semanasRodaje.value;
      this.semanasCierre = data.semanasCierre.value;
      this.totalSemanas = data.totalSemanas;
      this.cantidadUnidad = data.cantidadUnidad.value;
      this.finObra = data.finObra;
      this.totalPresupuesto = data.totalPresupuesto.value;
      this.presupuestoEstimado = data.presupuestoEstimado.value;
      this.desviacion = data.desviacion.value;
      this.proyecto = data.proyecto.value;
    }

  }

}
export class PresupuestoExport {
  capitulo!: string
  subCapitulo!: string
  concepto!: string
  cuenta!: string
  nombre!: string;
  tipoUnidd!: string;
  semanasPreparacion!: string;
  semanasRodaje!: string;
  semanasCierre!: string;
  totalSemanas!: string;
  cantidadUnidad!: string;
 /*  finObra!: string; */
  totalPresupuesto!: string;
  presupuestoEstimado!: string;
  desviacion!: string;
  public constructor(data?: any, init?: Partial<PresupuestoExport>) {

    if (data) {
      this.capitulo = data.codigo.capitulo.nombre
      this.subCapitulo = data.codigo.subcapitulo.nombre
      this.concepto = data.codigo.concepto
      this.cuenta = data.codigo.cuenta+""+(data.codigo.codigoHijo?data.codigo.codigoHijo:"")
      this.nombre = data.nombre;
      this.tipoUnidd = data.tipoUnidad?.nombre;
      this.semanasPreparacion = data.semanasPreparacion;
      this.semanasRodaje = data.semanasRodaje;
      this.semanasCierre = data.semanasCierre;
      this.totalSemanas = (parseInt(this.semanasCierre) + parseInt(this.semanasPreparacion) + parseInt(this.semanasRodaje)) ?
        (parseInt(this.semanasCierre) + parseInt(this.semanasPreparacion) + parseInt(this.semanasRodaje)) + " " : "";
      this.cantidadUnidad = data.cantidadUnidad;
/*       this.finObra = data.finObra;
 */      this.totalPresupuesto = ((parseInt(this.totalSemanas) * parseFloat(this.cantidadUnidad))) ?
        ((parseInt(this.totalSemanas) * parseFloat(this.cantidadUnidad))) + "€" : "";
      this.presupuestoEstimado = data.presupuestoEstimado + "€";
      this.desviacion = (parseFloat(data.presupuestoEstimado) - parseFloat(this.totalPresupuesto)) ?
        (parseFloat(data.presupuestoEstimado) - parseFloat(this.totalPresupuesto)) + "€" : "";

    }

  }

}

