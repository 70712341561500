import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token/token-storage.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": '*',
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
    "Access-Control-Max-Age": "300",
    "Cache-Control": "no-cache"
  })
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /* 
  Servicio de peticiones al back para la autenticacion del usuario
  */
  url: string = environment.API_URL + "/user";
  isLoggedIn: boolean = false;
  authSub = new Subject<any>();
  
  constructor(private http: HttpClient, private tokenservice: TokenStorageService) { }
  /* 
  Peticion al back para realizar el login de un usuario
  */
  login(email: string, password: string, totpkey: string): Observable<any> {
    return this.http.post(`${this.url}` + '/login', {
      email,
      password,
      totpkey
    }, httpOptions);
  }
  updateAuthStatus(value: boolean) {
    this.isLoggedIn = value
    this.authSub.next(this.isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
  }
  
  guardarQR(secret: string, totpKey: string, email: string): Observable<any> {


    return this.http.post(`${this.url}/set2fa`, { 'secret': secret, 'totpKey': totpKey, 'email': email }, {
      headers: {
        "accessToken": this.tokenservice.getToken() + ""
      }
    });
  }
  pedirQr(email: String): Observable<any> {
    return this.http.post(`${this.url}/set2fa`, { email });
  }
  /* 
  Solicitud al back para el identificador para la recuperacion de contraseña
  */
  getIdentificador(emailUser: String): Observable<any> {
    return this.http.post(`${this.url}/getIdentificador`, { "email": emailUser });
  }
}
