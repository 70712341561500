<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
    [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
    [nombreBoton]="nombreBoton" [(isCards)]="isCards" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
    [canCreate]="tableService.checkPermiso(22,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [canExport]="tableService.checkPermiso(22,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [dataExport]="dataExport"></app-header-listados>


<div class="fondoblanco fondoblanco_tabla">
    <div *ngIf="!isCards" class="table_container mat-elevation-z8 data_table">
        <table mat-table [dataSource]="dataSource">
            <!-- numero Column -->
            <span *ngFor="let column of displayedColumns ">
                <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                            el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                             nombres pero puedes quitar campos que no se quieren listar -->
                <ng-container *ngIf="column!='acciones'&&column!='id' && column !='cargo'&&column!='changePassAdmin'" matColumnDef="{{column}}">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="thdisp ">


                            <input
                                *ngIf="column !='imgPerfil' && ((tableService.checkPro(column,interfazInicial.isLicencia)) || (tableService.checkPro(column,interfazInicial.isSuperAdmin)))"
                                matInput type="text" class="searchinput uppercase"
                                placeholder="{{column=='activo'? 'Estado':primLetMay(column)}}"
                                (keyup)="doFilter($event, column == 'departamento'?column+'.nombre' :column == 'proyectos'?'proyecto.titulo':'usuario.'+column, 'LIST_CONTAINS', 'STRING')"
                                [ngStyle]="{'min-width.px': tableService.calcTextWidth(primLetMay(column), '600 14px Poppins, sans-serif')}"
                                [ngClass]="column=='permisos'?'permisosCol':''">
                            <a [ngClass]="ordenarCampos[column] ? 'fechaup':'fechadawn'" (click)="ordenar(column == 'departamento'?column+'.nombre' :'usuario.'+column)"></a>




                        </div>
                    </th>
                    <tr>

                        <td *matCellDef="let element" class="celds">
                            <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                    no tienen objetos añadidos seria solo esa linea-->

                            <span class="inline_text"
                                *ngIf="column != 'activo' && column != 'nombreUsuario'&& column != 'proyectos' &&  column !='imgPerfil' &&  column !='permisos' && column !='cargo'">
                                {{element[column]?(element[column].nombre?element[column].nombre:element[column]):''}}
                            </span>
                            <!--  <span
                            *ngIf="column != 'activo' && column != 'nombreUsuario'&& column != 'proyectos' &&  column !='imgPerfil'  &&  column !='permisos'&&  element[column].nombre && column !='cargo'">
                            <label class="inline_text">{{element[column].nombre}}</label>
                        </span> -->
                            <span
                                *ngIf="(tableService.checkPro(column,interfazInicial.isSuperAdmin)||tableService.checkPro(column,interfazInicial.isLicencia)) && column == 'proyectos'">
                                <label
                                    class="inline_text">{{tableService.checkLength(element[column],"titulo")}}</label>
                            </span>
                            <span *ngIf="column == 'nombreUsuario'">
                                <label class=" mb-0"><b>{{element[column]}}</b><br><span
                                        class="email-usuario">{{element['cargo']}}</span></label>
                            </span>
                            <span *ngIf="column == 'activo'">
                                <button (click)="updateActivo(element.id,element.activo, false)"
                                    [ngClass]="element[column]?'activo': 'inactivo'">
                                    <b>{{element[column]?"Activo":"Inactivo"}}</b>
                                </button>
                            </span>
                            <span *ngIf="column == 'imgPerfil'">
                                <div class="imgUsu iconName rounded-circle">
                                    <label *ngIf="element.imgPerfil==undefined">
                                        {{getFirstLetter(element.nombreUsuario)}}
                                    </label>
                                    <img *ngIf="element.imgPerfil!=undefined" src="data:image;base64,{{img}}"
                                        width="100%" height="100%" class="rounded-circle">
                                </div>
                            </span>
                            <span
                                *ngIf="(tableService.checkPro(column,interfazInicial.isSuperAdmin)||tableService.checkPro(column,interfazInicial.isLicencia)) && column == 'permisos'">

                                <span class="permisosUsus" *ngFor="let perm of element[column] let i = index">
                                    <span [ngClass]="perm.isAll?'backblue': 'backgrey'"
                                        *ngIf="element[column].length == permisosAux.length && i==0">
                                        {{"Todos Los Permisos"}}
                                    </span>
                                    <span [ngClass]="perm.isAll?'backblue': 'backgrey'"
                                        *ngIf="element[column].length != permisosAux.length">
                                        {{ primLetMay(perm.nombre.toLowerCase()) }}
                                    </span>

                                </span>

                            </span>
                        </td>
                    </tr>
                </ng-container>
            </span>
            <ng-container matColumnDef="id" stickyEnd class="id_oculta">
                <th mat-header-cell *matHeaderCellDef class="id_oculta">
                </th>
                <td *matCellDef="let element" class="id_oculta">
                </td>
            </ng-container>
            <ng-container matColumnDef="changePassAdmin" stickyEnd class="id_oculta">
                <th mat-header-cell *matHeaderCellDef class="id_oculta">
                </th>
                <td *matCellDef="let element" class="id_oculta">
                </td>
            </ng-container>
            <ng-container matColumnDef="cargo" stickyEnd class="id_oculta">
                <th mat-header-cell *matHeaderCellDef class="id_oculta">
                </th>
                <td *matCellDef="let element" class="id_oculta">
                </td>
            </ng-container>
            <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
            <ng-container matColumnDef="acciones" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                        class="correccionmargenAcciones uppercase">
                        Acciones</span>
                </th>
                <td class=" " *matCellDef="let element" class="accionesth">
                    <div class="acciones" stickyEnd>
                        <a title="Ver"
                            [ngClass]="tableService.checkPermiso(22,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                            (click)=" tableService.checkPermiso(22,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'ver')"></a>
                        <a title="Editar"
                            [ngClass]="tableService.checkPermiso(22,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-edit-button':'edit-button pointer'"
                            (click)=" tableService.checkPermiso(22,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'editar')"></a>
                        <a title="Reenviar email"
                            [ngClass]="(tableService.checkPermiso(22,2,interfazInicial.isSuperAdmin,interfazInicial.permisos) || !element.changePassAdmin)? 'display-email-button':'email-button pointer'"
                            (click)=" (tableService.checkPermiso(22,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)|| !element.changePassAdmin)?'':solicitarNuevoCorreo(element.id)"></a>
                        <a title="Borrar"
                            [ngClass]="tableService.checkPermiso(22,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-delete-button':'delete-button pointer'"
                            (click)=" tableService.checkPermiso(22,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':darDeBaja(element.id, true)"></a>

                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                (mouseenter)="tableService.marcarColumna(row,true)" (mouseleave)="tableService.marcarColumna(row,false)"
                (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <div *ngIf="isCards" class="contenedorCards col-12 d-flex">
        <div class="col-3 cardUsu" *ngFor="let usu of listaUsus">
            <span class="back">
                <div class="col-12 infoUsu">
                    <div class="imgUsu iconName rounded-circle">
                        <p *ngIf="usu.imgPerfil==undefined">
                            {{getFirstLetter(usu.nombreUsuario)}}
                        </p>
                        <img *ngIf="usu.imgPerfil!=undefined" src="data:image;base64,{{usu.imgPerfil}}" width="100%"
                            height="100%" class="rounded-circle">
                    </div>

                    <p class="mb-0 mt-2"><b>{{usu.nombreUsuario}}</b></p>
                    <p class="mt-0">{{usu.cargo}}</p>

                    <span *ngIf="tableService.checkPro('permisos',interfazInicial.isSuperAdmin)" class="permisosData">
                        <span class="permisosUsus" *ngFor="let perm of usu.permisos let i = index">
                            <span *ngIf="usu.permisos.length == permisosAux.length && i==6"
                                [ngClass]="perm.isAll?'backblue': 'backgrey'">
                                {{"Todos Los Permisos"}}
                            </span>
                            <span [ngClass]="perm.isAll?'backblue': 'backgrey'"
                                *ngIf="usu.permisos.length != permisosAux.length">
                                {{ primLetMay(perm.nombre.toLowerCase()) }}
                            </span>

                        </span>
                    </span>

                </div>


                <div class="col-12">
                    <mat-divider class="divider col-12"></mat-divider>

                    <div class="col-12 iconsCard">
                        <!--  <mat-icon matBadge="15" matBadgeSize="small">
                    notifications
                </mat-icon> -->
                        <div class="divactivo">
                            <button (click)="updateActivo(usu.id,usu.activo, false)"
                                [ngClass]="usu.activo?'activo': 'inactivo'">
                                <b>{{usu.activo?"Activo":"Inactivo"}}</b>
                            </button>
                        </div>
                        <mat-icon title="Proyectos" [matBadge]="calcularProyectos(usu.proyectos)" matBadgeColor="warn"
                            matBadgeSize="small" class="colorFont">
                            description
                        </mat-icon>

                        <span class="pointer" matBadgeColor="warn" (click)="navegar(usu.id,'editar')">
                            <mat-icon title="Perfil">
                                account_circle
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </span>
        </div>
    </div>
    <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
        (page)="nextPage($event)">
    </mat-paginator>
</div>