<div class="confipantalla">
    <app-notification class="notifier"></app-notification>

    <div class="d-flex flex-column min-vh-100 justify-content-between" *ngIf="this.location.path() != ''">
        <div class="vh-100">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="" *ngIf="this.location.path() == ''">
        <div class="grid">
            <div class="contenedor-login-image d-flex flex-column align-items-center">
                <div class="logo-base logo-raiz"></div>
                <p class="subtitulo-imagen">La manera más sencilla de gestionar tus proyectos</p>
                <p class="texto-imagen pb-5">Gestiona tus producciones desde cualquier lugar y a un solo clic</p>
                <p class="version">v{{version}}</p>
              </div>
            <router-outlet></router-outlet>
            <div class="contenedor-login-form align-self-center justify-self-center">
                <app-inicio class=""></app-inicio>
            </div>
        </div>
        
    </div>

</div>