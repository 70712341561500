import { Component } from '@angular/core';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { ManualService } from 'src/app/services/manual.service';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
  animations: [dropdownAnimation],
})
export class ManualComponent {
  mostrarCampos: { [clave: string]: boolean } = {
    inicio: true,
    productora: false,
    proyecto: false,
    presupuesto: false,
    coste: false,
    usuario: false,
    proveedor: false,
    cuentas: false,
    ordenDeCompra: false,
    gasto: false,
  };
  manual: any;
  manualInicio: Map<any, any> = new Map();
  manualProductora: Map<any, any> = new Map();
  manualProyecto: Map<any, any> = new Map();
  manualUsuario: Map<any, any> = new Map();
  manualProveedor: Map<any, any> = new Map();
  manualCuenta: Map<any, any> = new Map();
  manualOrdenDeCompra: Map<any, any> = new Map();
  manualPresupuesto: Map<any, any> = new Map();
  manualCoste: Map<any, any> = new Map();
  manualGasto: Map<any, any> = new Map();
  constructor(private manualService: ManualService) {}

  ngOnInit(): void {
    this.getManual();
  }

  getManual() {
    this.manualService.getAllManual().subscribe({
      next: (data) => {
        console.log(data);
        this.manual = data;
        console.log(data);
        this.manual.forEach((element: any) => {
          let codigo: String = element.codigo;
          if (codigo.includes('Inicio')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualInicio.set(key, element.texto);
          }
          if (codigo.includes('Productora')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualProductora.set(key, element.texto);
          }
          if (codigo.includes('Proyecto')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualProyecto.set(key, element.texto);
          }
          if (codigo.includes('Usuario')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualUsuario.set(key, element.texto);
          }
          if (codigo.includes('Proveedor')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualProveedor.set(key, element.texto);
          }
          if (codigo.includes('Cuenta')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualCuenta.set(key, element.texto);
          }
          if (codigo.includes('Ordendecompra')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualOrdenDeCompra.set(key, element.texto);
          }
          if (codigo.includes('Presupuesto')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualPresupuesto.set(key, element.texto);
          }
          if (codigo.includes('Coste')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualCoste.set(key, element.texto);
          }
          if (codigo.includes('Gasto')) {
            let key = codigo.substring(codigo.indexOf('_') + 1, codigo.length);
            this.manualGasto.set(key, element.texto);
          }
        });
        console.log(this.manualOrdenDeCompra);
      },
      error: (error) => {},
    });
  }

  mostrar(key: any) {
    this.mostrarCampos[key];
    for (let clave in this.mostrarCampos) {
      if (clave == key) {
        console.log(clave);
        console.log(key);
        this.mostrarCampos[key] = true;
        console.log(this.mostrarCampos[key]);
      } else {
        if (this.mostrarCampos[clave] == true) {
          this.mostrarCampos[clave] = false;
        }
      }
    }
    this.mostrarCampos[key];
  }
}
