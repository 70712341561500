import { Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NomId } from 'src/app/models/orden-compra';
import { Presupuesto, PresupuestoExport } from 'src/app/models/presupuesto';
import { CuentaService } from 'src/app/services/cuenta.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrdenCompraService } from 'src/app/services/orden-compra.service';
import { PresupuestoService } from 'src/app/services/presupuesto.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { Proyecto } from 'src/app/models/proyecto';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { RaizComponent } from '../../_raiz/raiz.component';
import { ExportExcelService } from 'src/app/services/servicios-funcionalidad/export-excel.service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';

@Component({
  selector: 'app-nuevo-presupuesto',
  templateUrl: './nuevo-presupuesto.component.html',
  styleUrls: ['./nuevo-presupuesto.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class NuevoPresupuestoComponent {
  loading: boolean = false;
  dataExport: any
  isReading = false
  isOpened = true;
  form!: FormGroup
  tipoUnidades: NomId[] = []
  currentUser = this.tokenService.getUser()
  proyecto: Proyecto = this.interfazInicial.proyaux;
  tipoUsuario: any = this.interfazInicial.tipoUsu;
  presupuestos: Presupuesto[] = [];
  subcapitulosDesplegados: string[] = [];

  opcionSeleccionada: string = "Seleccione una opcion";

  private formBuilder: FormBuilder = new FormBuilder
  presupuestosPorSubcapitulo: { [subcapitulo: string]: any[] } = {};
  presupuestosPorCapitulo: any = {}
  array: any[] = []
  arrayCaps: any[] = []

  evento: any = '';
  cuentasGuar: any = []

  @ViewChild('inputFile')
  input!: ElementRef;

  panelsOpen = true;
  isForImport = false;
  isControllerOrDirProd = false;
  isProductor = false;
  canExport = false;
  isPresupuestoClosed = false;
  isEtimadoDisabled = false;
  disabeImport = true



  modalPresupuestoClosed = false;
  modalPresupuestoDelete = false;

  // -- Variables totales
  totalUnidades: number = 0
  totalCantUnit: number = 0
  totalPresupuesto: number = 0
  totalEstimado: number = 0
  totalDesviacion: number = 0

  isPresupuestoTotalExpanded = 'in';


  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Presupuesto"
  nombrePagina = "Presupuesto"

  isFormSaved = false;

  constructor(
    private notificationService: NotificationService,
    private oredenCompraService: OrdenCompraService,
    private cuentaService: CuentaService,
    private presupuestoService: PresupuestoService,
    private router: Router,
    private interfazInicial: InterfazInicialComponent,
    private tokenService: TokenStorageService,
    public raiz: RaizComponent,
    private exportService: ExportExcelService,
    private tableService: TableService,
    private storageService: StorageServiceService,
    private http: HttpClient,
  ) { }

  get codigos(): FormArray {
    return this.form.get("codigos") as FormArray;
  }


  action = ""
  ngOnInit() {
    if (this.tipoUsuario.id == 3 || this.tipoUsuario.id == 2) this.isControllerOrDirProd = true;
    if (this.tipoUsuario.id == 1) this.isProductor = true;
    if (!this.isPresupuestoClosed && !this.isControllerOrDirProd) this.isEtimadoDisabled = true;

    if (this.tableService.checkAnyAccionByPermiso(3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }

    this.canExport = this.tableService.checkPermisoProductor(2, 6, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos);

    this.form = new FormGroup({
      codigos: new FormArray([])
    })

    if (!this.interfazInicial.proyaux || !this.interfazInicial.proyaux.id) {
      this.notificationService.warning("ALERTA", "No hay proyecto seleccionado.", 5000)
      this.router.navigate(['/proyectos'])
    }

    var storedFormData: any = {}

    try {
      storedFormData = JSON.parse(this.storageService.get('presuForm') ? this.storageService.get('presuForm') : "");
    } catch {
    }
    this.cuentasGuar = storedFormData.codigos || []

    if (this.proyecto.id) {
      this.chargePresu()
    } else {
      this.getCuentas()
    }

    this.tableService.subscribeToProyectoChanges(() => {
      try {
        storedFormData = JSON.parse(this.storageService.get('presuForm') ? this.storageService.get('presuForm') : "");
      } catch {
      }
      this.disabeImport = true

      this.cuentasGuar = storedFormData.codigos || []
      this.proyecto = this.interfazInicial.proyaux

      if (this.proyecto.id) {
        this.chargePresu()
      } else {
        this.getCuentas()
      }
    });
    this.getTipoUnidades();
  }



  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): any {
    console.log(this.form.dirty);
    console.log(this.isFormSaved);

    if (this.form.dirty && !this.isFormSaved) { // Verifica si hay cambios no guardados
      const confirmationMessage = 'Tienes cambios no guardados, ¿estás seguro que quieres salir?';
      $event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }

  canDeactivate(): boolean {
    if (this.form.dirty && !this.isFormSaved) {
      return confirm('Tienes cambios no guardados, ¿estás seguro que quieres salir?');
    }
    return true;
  }

  /**
   * Cambia el estado de todos los paneles.
   */
  togglePanels() {
    this.panelsOpen = !this.panelsOpen;
  }

  chargePresu() {
    this.loading = true
    this.presupuestosPorSubcapitulo = {};
    this.presupuestosPorCapitulo = {};
    this.codigos.clear()
    this.array = []
    this.arrayCaps = []
    this.presupuestos = [];
    this.subcapitulosDesplegados = [];
    this.form.reset()
    // this.checkIfPresupuestoClosed();
    this.checkIfPresupuestoClosed();
  }

  /**
   * Obtiene los tipos de unidades.
   */
  getTipoUnidades() {
    this.oredenCompraService.getAllTiposUnidades().subscribe({
      next: (data) => {
        this.tipoUnidades = data as NomId[]
      }
    })
  }

  /**
   * Obtiene las cuentas.
   */
  getCuentas() {

    if (this.cuentasGuar && this.cuentasGuar.length != 0) {
      var aux: any = this.cuentasGuar

      aux.forEach((cod: any) => {
        this.addForm(
          cod.codigo,
          cod.tipoUnidad || "", cod.semanasPreparacion || "", cod.semanasRodaje || "", cod.semanasCierre || "",
          cod.cantidadUnidad || "", cod.finObra || "", cod.desviacion || "", cod.nombre || "", this.proyecto
        );

      })

      this.organizarPresupuestosPorSubcapitulo(this.codigos.value);
    } else {
      this.totalUnidades = 0
      this.totalCantUnit = 0
      this.totalPresupuesto = 0
      this.totalEstimado = 0
      this.totalDesviacion = 0

      this.cuentaService.getAllCuentas(this.proyecto.versionCodigos.toString()).subscribe({
        next: (data) => {
          var aux: any = data
          this.cuentaService.getAllCuentasByProyecto(this.interfazInicial.proyaux.id).subscribe({
            next: (codProy: any) => {
              if (codProy) {
                codProy.forEach((element: any) => {
                  aux.push(element)
                });
              }

              // Ordenar por cuenta
              aux = aux.sort((a: any, b: any) => a.cuenta.localeCompare(b.cuenta));

              aux.forEach((cod: any) => {
                this.addForm(
                  cod,
                  "", "", "", "",
                  "", "", "", "", this.proyecto
                );
              })

              this.organizarPresupuestosPorSubcapitulo(this.codigos.value);
              // this.organizarPresupuestosPorSubcapitulo(this.codigos.getRawValue());

            },

            error: (error) => {
              aux.forEach((cod: any) => {
                this.addForm(
                  cod,
                  "", "", "", "",
                  "", "", "", "", this.proyecto
                );
              })
              this.organizarPresupuestosPorSubcapitulo(this.codigos.value);
              // this.organizarPresupuestosPorSubcapitulo(this.codigos.getRawValue());

            },
          });
        }
      })
    }
  }

  /**
   * Verifica si un usuario tiene permiso para una acción.
   * 
   * @param idPermiso - El ID del permiso.
   * @param idAccion - El ID de la acción.
   * @returns `true` si el usuario tiene permiso para la acción, de lo contrario devuelve `false`.
   */
  checkPermiso(idPermiso: number, idAccion: number): boolean {
    if (this.interfazInicial.isSuperAdmin && this.currentUser.rol.id == 1) {
      return false;
    } else {
      return !this.interfazInicial.permisos.some(e =>
        idPermiso === e.permiso.id && e.accion.id === idAccion
      )
    }
  }

  /**
   * Verifica si el presupuesto está cerrado.
   */
  checkIfPresupuestoClosed() {
    this.presupuestoService.isPresupuestoClosed(this.proyecto.id.toString()).subscribe({
      next: (data) => {
        this.isPresupuestoClosed = data;
        if (this.isPresupuestoClosed) {
          this.cuentasGuar = []
        }
        this.isReading = data;
        this.getPresupuestos();
      },
      error: (err) => {
        if (err.error && err.error.message)
          this.notificationService.warning("ERROR", err.error.message, 5000);
      }
    })
  }

  /**
   * Obtiene los presupuestos.
   */
  getPresupuestos() {
    this.presupuestoService.getPresupuestosByProyectoId(this.proyecto.id.toString()).subscribe({
      next: (data) => {
        this.disabeImport = false
        this.presupuestos = data;
        this.getCuentas();
      },
      error: (err) => {
        this.getCuentas();
      },
    })
  }

  /**
   * Agrega al form.
   * 
   * @param cuenta - La cuenta.
   * @param tipoUnidad - El tipo de unidad.
   * @param semanasPreparacion - Las semanas de preparación.
   * @param semanasRodaje - Las semanas de rodaje.
   * @param semanasCierre - Las semanas de cierre.
   * @param cantidadUnidad - La cantidad de unidad.
   * @param finObra - El fin de obra.
   * @param desviacion - La desviación.
   * @param nombre - El nombre.
   * @param proyecto - El proyecto.
   */
  addForm(cuenta: any, tipoUnidad: any, semanasPreparacion: any, semanasRodaje: any, semanasCierre: any, cantidadUnidad: any, finObra: any, desviacion: any, nombre: string, proyecto: Proyecto) {
    //Comprobción para ver si está cerrado is es controller

    if (this.isPresupuestoClosed && !this.isControllerOrDirProd) {
      this.isEtimadoDisabled = true;
    }




    const presupuestoExistente = this.presupuestos.find(presupuesto => presupuesto.codigo.id === cuenta.id);
    // console.log("PResupuesto existente: ", presupuestoExistente);


    if (presupuestoExistente) {
      // this.totalUnidades = this.totalUnidades + presupuestoExistente.totalSemanas;
      // this.totalCantUnit = this.totalCantUnit + presupuestoExistente.cantidadUnidad;
      this.totalPresupuesto = this.totalPresupuesto + presupuestoExistente.totalPresupuesto ?? 0;
      this.totalEstimado = this.totalEstimado + presupuestoExistente.presupuestoEstimado ?? 0;
      this.totalDesviacion = this.totalDesviacion + presupuestoExistente.desviacion ?? 0;
    }

    const metadato = this.formBuilder.group({
      cuenta: new FormControl({ value: cuenta.cuenta, disabled: (this.isReading || this.isProductor) }),
      concepto: new FormControl({ value: cuenta.concepto, disabled: (this.isReading || this.isProductor) }),
      nombre: new FormControl({ value: presupuestoExistente ? presupuestoExistente.nombre : nombre, disabled: (this.isReading || this.isProductor) }),
      tipoUnidad: new FormControl({ value: presupuestoExistente ? presupuestoExistente.tipoUnidad ? presupuestoExistente.tipoUnidad.id : tipoUnidad : tipoUnidad, disabled: (this.isReading || this.isProductor) }, [Validators.required]),
      semanasPreparacion: new FormControl({ value: presupuestoExistente ? presupuestoExistente.semanasPreparacion : semanasPreparacion, disabled: (this.isReading || this.isProductor) }),
      semanasRodaje: new FormControl({ value: presupuestoExistente ? presupuestoExistente.semanasRodaje : semanasRodaje, disabled: (this.isReading || this.isProductor) }),
      semanasCierre: new FormControl({ value: presupuestoExistente ? presupuestoExistente.semanasCierre : semanasCierre, disabled: (this.isReading || this.isProductor) }),
      cantidadUnidad: new FormControl({ value: presupuestoExistente ? presupuestoExistente.cantidadUnidad : cantidadUnidad, disabled: (this.isReading || this.isProductor) }),
      // finObra: new FormControl({ value: presupuestoExistente ? presupuestoExistente.finObra ?? 0 : finObra ?? 0, disabled: this.isReading }),
      codigo: new FormControl({ value: presupuestoExistente ? presupuestoExistente.codigo : cuenta, disabled: (this.isReading || this.isProductor) }),
      // presupuestoEstimado: new FormControl({ value: presupuestoExistente ? (presupuestoExistente.presupuestoEstimado==0?'': presupuestoExistente.presupuestoEstimado) : presupuestoEstimado, disabled: this.isReading }),
      desviacion: new FormControl({ value: presupuestoExistente ? (presupuestoExistente.desviacion == 0 ? '' : presupuestoExistente.desviacion) : desviacion, disabled: (this.isReading || this.isProductor) }),
      // desviacion: new FormControl({ value: presupuestoExistente ? presupuestoExistente.desviacion : 0, disabled: this.isReading }),
      presupuestoEstimado: new FormControl({ value: presupuestoExistente ? presupuestoExistente.presupuestoEstimado : 0, disabled: (this.isReading || this.isProductor) }),
      totalPresupuesto: new FormControl({ value: presupuestoExistente ? presupuestoExistente.totalPresupuesto : 0, disabled: (this.isReading || this.isProductor) }),
      proyecto: new FormControl({ value: presupuestoExistente ? presupuestoExistente.proyecto : proyecto, disabled: (this.isReading || this.isProductor) }),
      id: new FormControl({ value: presupuestoExistente ? presupuestoExistente.id : '', disabled: (this.isReading || this.isProductor) }),
    });

    this.codigos.push(metadato);
  }

  /**
   * Convierte la primera letra de una cadena en mayúscula.
   * 
   * @param text - La cadena de texto.
   * @returns La cadena con la primera letra en mayúscula.
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  /**
   * Compara dos objetos para verificar si son iguales basándose en su propiedad `id`.
   * 
   * @param obj1 - El primer objeto a comparar.
   * @param obj2 - El segundo objeto a comparar.
   * @returns Devuelve `true` si los objetos son iguales basándose en su propiedad `id`, de lo contrario devuelve `false`.
   */
  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id
  }

  /**
  * Obtiene la suma total del presupuesto.
  * 
  * @param i - El presupuesto.
  * @returns El total del presupuesto.
  */
  getTotalPresupuesto(i: any): any {

    if (!i.controls.semanasCierre.value && !i.controls.semanasPreparacion.value && !i.controls.semanasRodaje.value && i.controls.cantidadUnidad.value) {
      return parseFloat(i.controls.cantidadUnidad) /* + parseFloat(i.controls.finObra.value) */
    }

    if (i.controls.cantidadUnidad.value /* && i.controls.finObra.value */) {
      return (this.getTotalSem(i) * parseFloat(i.controls.cantidadUnidad.value)) /* + (parseFloat(i.controls.finObra.value) ?? 0) */;
    } else {
      return 0;
    }
  }

  /**
   * Obtiene la suma total de las semanas.
   * 
   * @param i - El presupuesto.
   * @returns El total de las semanas.
   */
  getTotalSem(i: any): any {

    if (i.controls.semanasCierre.value || i.controls.semanasPreparacion.value || i.controls.semanasRodaje.value) {

      if (!i.controls.semanasCierre.value) {
        i.controls.semanasCierre.value = 0
      }

      if (!i.controls.semanasPreparacion.value) {
        i.controls.semanasPreparacion.value = 0
      }

      if (!i.controls.semanasRodaje.value) {
        i.controls.semanasRodaje.value = 0
      }

      const total = parseFloat(i.controls.semanasCierre.value) + parseFloat(i.controls.semanasPreparacion.value) + parseFloat(i.controls.semanasRodaje.value);
      return parseFloat(total.toFixed(2));
    }

  }

  /**
   * Obtiene el presupuesto estimado.
   * 
   * @param i - El presu.
   * @returns El presupuesto estimado.
   */
  getPresupuestoEstimado(i: any): any {
    if (!i.controls.desviacion || !i.controls.desviacion.value || i.controls.desviacion.value == 0) {
      return this.getTotalPresupuesto(i);
    }

    if (this.getTotalPresupuesto(i) || i.controls.desviacion.value) {
      if (!i.controls.desviacion.value) {
        i.controls.desviacion.value = 0
      }

      return this.getTotalPresupuesto(i) + parseFloat(i.controls.desviacion.value)
    }

  }

  /**
   * Envía el formulario del presupuesto.
   */
  onSubmit() {
    this.isSave = false
    const presupuestos: Presupuesto[] = [];

    this.codigos.controls.forEach((codigo: any) => {
      console.log(codigo.controls);

      const tipoUnidad: any = this.tipoUnidades.find(tipoUnidad => tipoUnidad.id == codigo.controls.tipoUnidad.value);
      codigo.controls.tipoUnidad.value = tipoUnidad;
      codigo.controls.proyecto.value = {
        id: this.proyecto.id
      }
      codigo.controls.codigo = {
        id: codigo.controls.codigo.value ? codigo.controls.codigo.value.id : codigo.controls.codigo.id
      }

      const presupuestoData: Presupuesto = new Presupuesto(codigo.controls);
      presupuestos.push(presupuestoData);
    });

    const presupuestosFiltrados = presupuestos.filter(presupuesto => presupuesto.cantidadUnidad != null && presupuesto.cantidadUnidad.toString() != '');
    this.loading = true;

    this.presupuestoService.saveAllPresupuestos(presupuestosFiltrados).subscribe({
      next: (data) => {
        this.presupuestos = data;
        this.loading = false;
        this.storageService.eliminar("presuForm")
        this.updateIdsOnForm(data);
        this.notificationService.success("Guardado", "El presupuesto se ha guardado correctamente.", 5000);

      },
      error: (err) => {
        this.loading = false;
        if (err.error && Object.keys(err.error)[0]) {
          this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
        } else {
          this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
        }
      }
    }
    );
  }

  /**
   * Actualiza los IDs en el formulario.
   * 
   * @param presupuestos - Los presupuestos.
   */
  updateIdsOnForm(presupuestos: Presupuesto[]) {
    console.log(presupuestos);
    this.totalPresupuesto = 0
    this.totalEstimado = 0
    this.totalDesviacion = 0
    this.codigos.controls.forEach((control: any) => {
      // console.log("CONTROL",control);
      //  window.location.reload();

      const presupuesto = presupuestos.find(p => p.codigo.id === control.controls.codigo.id);
      //  console.log("Presupuedto update: ", presupuesto);

      if (presupuesto) {
        // this.totalUnidades = this.totalUnidades + presupuestoExistente.totalSemanas;
        // this.totalCantUnit = this.totalCantUnit + presupuestoExistente.cantidadUnidad;
        this.totalPresupuesto = this.totalPresupuesto + presupuesto.totalPresupuesto ?? 0;
        this.totalEstimado = this.totalEstimado + presupuesto.presupuestoEstimado ?? 0;
        this.totalDesviacion = this.totalDesviacion + presupuesto.desviacion ?? 0;

        control.patchValue({ id: presupuesto.id });
      }
    });
   
    this.isFormSaved = true;

    console.log('Form Dirty:', this.form.dirty);  // Debería ser false
    console.log('Form Pristine:', this.form.pristine);  // Debería ser true
  }

  /**
   * Método para agrupar los presus por subcapítulo
   * @param presupuestos 
   * @returns un objeto con los datos agrupados pos subCapitulos
   */
  organizarPresupuestosPorSubcapitulo(presupuestos: Presupuesto[]): { [subcapitulo: string]: Presupuesto[] } {
    this.dataExport = []



    presupuestos.forEach((presupuesto: any) => {
      const nombreCapitulo: any = presupuesto.codigo.capitulo.nombre;
      const nombreSubcapitulo: any = [presupuesto.codigo.subcapitulo?.codigo, presupuesto.codigo.subcapitulo?.nombre];

      // Crear la estructura de objetos anidados si no existe
      if (!this.presupuestosPorCapitulo[nombreCapitulo]) {
        this.presupuestosPorCapitulo[nombreCapitulo] = {};
      }
      if (!this.presupuestosPorCapitulo[nombreCapitulo][nombreSubcapitulo]) {
        this.presupuestosPorCapitulo[nombreCapitulo][nombreSubcapitulo] = [];
      }

      // Agregar el presupuesto al array del subcapítulo
      this.presupuestosPorCapitulo[nombreCapitulo][nombreSubcapitulo].push(presupuesto);
    });

    this.arrayCaps = Object.keys(this.presupuestosPorCapitulo)


    presupuestos.forEach((presupuesto: any) => {
      // if(presupuesto.tipoUnidad) {
      const tipoUnidad = this.tipoUnidades.find(tipo => tipo.id === presupuesto.tipoUnidad);
      presupuesto.tipoUnidad = tipoUnidad;
      // }      

      // console.log("PResupuesto: ", presupuesto);

      this.dataExport.push(new PresupuestoExport(presupuesto))
      const nombreSubcapitulo: any = [presupuesto.codigo.subcapitulo?.codigo, presupuesto.codigo.subcapitulo?.nombre]

      if (!this.presupuestosPorSubcapitulo[nombreSubcapitulo]) {
        this.presupuestosPorSubcapitulo[nombreSubcapitulo] = [];
      }

      presupuesto.index = presupuestos.indexOf(presupuesto)
      this.presupuestosPorSubcapitulo[nombreSubcapitulo].push(presupuesto);

    });







    this.loading = false
    // && this.isControllerOrDirProd
    if (this.isPresupuestoClosed && this.isControllerOrDirProd) {

      this.codigos.controls.forEach(element => {

        element.get('desviacion')?.enable();
        // element.get('id')?.enable();
      });
    }

    this.array = Object.keys(this.presupuestosPorSubcapitulo)
    this.array = this.array.sort();

    return this.presupuestosPorSubcapitulo;
  }



  /**
   * 
   * Cierra el presupuesto.
   */
  closePresupuesto() {
    this.presupuestoService.closePresupuesto(this.proyecto.id.toString()).subscribe({
      next: (data: any) => {
        this.notificationService.success("Cerrado", data, 5000);
        this.isPresupuestoClosed = true;
        this.codigos.controls.forEach(element => {
          // console.log("ELement: ", element);

          element.disable();
          if (this.isControllerOrDirProd && element) {
            element.get('desviacion')?.enable();
          }
        });

        this.isReading = true;
      }, error: (err) => {
        console.error("Error: ", err);
        if (err.error && Object.keys(err.error)[0]) {
          this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
        } else {
          this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
        }
      },
    })
  }

  /**
   * Elimina el presupuesto.
   */
  deletePresupesto() {

    if (this.isReading || this.isPresupuestoClosed) {
      this.notificationService.info("INFO", "No puede eliminar el presupuesto porque se encuentra cerrado.", 5000);
      return;
    }

    this.loading = true;
    this.presupuestoService.deletePresupuestoByProyecto(this.proyecto.id.toString()).subscribe({
      next: (data) => {
        this.loading = false;
        this.notificationService.success("Eliminado", data, 5000);
        this.storageService.eliminar('presuForm')
        location.reload();
      }, error: (err) => {
        this.loading = false;
        console.error("Error: ", err);
        if (err.error && err.error.message)
          this.notificationService.warning("ERROR", err.error.message, 5000);
      },
    })
  }

  /**
   * Establece la acción del boton.
   * 
   * @param event - El evento (ne se utiliza).
   */
  setAction(event: any) {
    console.log(event);

    switch (event) {
      case 'cerrarPresupuesto':
        this.modalPresupuestoClosed = true;
        // this.closePresupuesto();
        break;
      case 'validarCambios':
        // Método para validar cambios
        break;
      case 'eliminarPresupuesto':
        this.modalPresupuestoDelete = true;
        // this.deletePresupesto();
        break;
      case 'pasarACastflow':
        // Acción para pasar a castflow
        break;
      default:
        break;
    }
    this.action = ""

  }

  /**
   * Regresa a la página anterior.
   */
  goBack() {
    this.raiz.goBack()
  }

  /**
   * Exporta los presupuestos.
   */
  export() {
    this.isClearForm = false
    this.exportService.export("Presupuesto", this.dataExport, "Presupuestos", this.interfazInicial.proyaux.titulo)
  }

  /**
   * Al clicar una tecla en los campos de nuemros detecta si es un . y lo cambia a , para los decimales
   * @param event evento de clickar
   * @param control control del form
   */
  changeDec(event: any, control: any): any {
    if (event.key == ".") {
      control.setValue(control?.value + ",")
    }
  }

  /**
   * Obtiene el documento y realiza la importación del presupuesto
   * 
   * @param event 
   */
  onFileChange(event: any) {

    if (event.target.files.length > 0) {
      this.loading = true;
      const file = event.target.files[0];
      this.presupuestoService.importPresupuesto(file, this.proyecto.id.toString()).subscribe({
        next: (data) => {
          this.loading = false;
          this.notificationService.success("HECHO", "Presupuesto importado con éxito.", 5000);
          this.presupuestos = data;
          this.chargePresu();
          this.input.nativeElement.value = "";
          this.isForImport = false;
        }, error: (err) => {
          this.isForImport = false;
          this.loading = false;
          console.log("Error: ", err);
          this.input.nativeElement.value = "";
          this.notificationService.error("ERROR", err.error.message, 5000);
        },
      }
      );
    }
  }

  onFormChange() {
    this.isFormSaved = false
    this.storageService.guardar('presuForm', JSON.stringify(this.form.value));
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }

  mostrarTooltip(element: any): boolean {
    if (this.calculaLongitud(this.getConcepto(element)) >= 67) return true;
    return false;
  }

  calculaLongitud(text: string): number {
    let length = 0;
    for (let char of text) {
      if (char >= 'A' && char <= 'Z') length += 1.5;
      else length += 1;
    }
    return length;
  }

  /**
   * Descarga la plantilla Excel para poder importar el presupuesto
   */
  downloadTemplate() {
    const fileName = 'Plantilla_Presupuesto.xlsx';
    const filePath = './assets/templates/Plantilla_presupuesto.xlsx';

    this.http.get(filePath, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, fileName);
    })
  }

  calcTotalSemanasP(list: any[]) {
    // console.log("Lista: ", list);

    var aux: number = 0
    list.forEach(element => {
      if (element.semanasPreparacion) {
        aux = aux + parseFloat(element.semanasPreparacion)
      }
    })
    return aux;
  }

  calcTotalSemanasR(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.semanasRodaje) {
        aux = aux + parseFloat(element.semanasRodaje)
      }
    })
    return aux;
  }

  calcTotalSemanasC(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.semanasCierre) {
        aux = aux + parseFloat(element.semanasCierre)
      }
    })
    return aux;
  }

  calcTotalSemanasTotales(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.totalSemanas) {
        aux = aux + parseFloat(element.totalSemanas)
      }
    })
    return aux;
  }

  calcCantUnit(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.cantidadUnidad) {
        aux = aux + parseFloat(element.cantidadUnidad)
      }
    })
    return aux;
  }

  calcTotalPresupuesto(list: any[]) {

    var aux: number = 0
    list.forEach(element => {
      if (element.totalPresupuesto) {
        aux = aux + parseFloat(element.totalPresupuesto)
      }
    })
    return aux;
  }

  calcPresEstimado(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.presupuestoEstimado) {
        aux = aux + parseFloat(element.presupuestoEstimado)
      }
    })
    return aux;
  }

  calcTotalDesviacion(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.desviacion) {

        aux = aux + parseFloat(element.desviacion)

      }
    })
    return aux;
  }

  getArrayByCap(element: any) {
    var listAux: any = []
    this.array.forEach((data) => {
      if (element[data]) {
        element[data].forEach((a: any) => {
          listAux.push(a)
        });
      }
    })
    return listAux;
  }



  clearIfZero(aux: any, contr: string): void {
    const control = aux.get(contr);
    if (control?.value === 0) {
      control.setValue(null); // Borra el valor 0
    }
  }

  restoreIfEmpty(aux: any, contr: string): void {
    const control = aux.get(contr)
    console.log(control);

    if (control?.value === null || control?.value === '' || control?.value === undefined || control?.value === 0) {
      control.setValue(0); // Restaura el valor 0 si está vacío
    }
  }
}
