import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FechaService {

  constructor() { }

  /* 
  Parsea la fecha pasada en formato String a tipo Date
  */
  date(date: any) {
    date = date.split("-")

    var year = date[2];
    var month = date[1];
    var day = date[0];
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  /* 
  Parsea la fecha pasada en tipo Date al formato requerido en String
  */
  type(date: any): any {
    console.log("date. ", date);

    if (date == '') {
      return null;
    }

    if (date instanceof Date) {

      const year = date.getFullYear();
      var month = (date.getMonth() + 1) + "";
      if (month.length == 1) {
        month = 0 + month
      }

      var day = date.getDate() + "";
      if (day.length == 1) {
        day = 0 + day
      }

      return day + '-' + month + '-' + year;
    } else {
      return date.format('DD-MM-YYYY');
    }
    return null;
  }

}
