import { GastoExport } from './gasto';
import { Productora } from './productora';
import { Proveedor, ProveedorExportProy } from './proveedor';
import { Proyecto } from './proyecto';
import { Departamento } from './usuario';

//TODO: Hacer todos los objetos de listas ANY
export class OrdenCompra {
  id!: number;
  base!: String;
  conceptoOc!: ConceptosOC[];
  departamento!: Departamento;
  documentos!: any[];
  estado!: NomId;
  fechaAlta!: string;
  gastos!: any[];
  pagoOc!: PagoOC;
  proveedor!: Proveedor;
  proyecto!: Proyecto;
  solicitante!: string;
  totalConceptos!: number;
  totalIrpf!: number;
  totalIva!: number;

  public constructor(data?: any, init?: Partial<OrdenCompra>) {
    if (data) {
      this.id = data.id;
      this.base = data.base;
      this.conceptoOc = data.conceptoOc;
      this.conceptoOc = data.conceptos;
      this.departamento = data.departamento;
      this.documentos = data.documentos;
      this.estado = data.estado;
      this.fechaAlta = data.fechaAlta;
      this.gastos = data.gastos;
      this.pagoOc = data.pagoOc;
      this.proveedor = data.proveedor;
      this.proyecto = data.proyecto;
      this.solicitante = data.solicitante;
      this.totalConceptos = data.totalConceptos;
      this.totalIrpf = data.totalIrpf;
      this.totalIva = data.totalIva;
    } else {
      Object.assign(this, init);
    }
  }
}
export class OrdenCompraListar {
  numOc!: number;
  base!: String;
  conceptos!: any[];
  codigoConcepto!: any;
  descripcionConcepto!: any;
  departamento!: Departamento;
  documentos!: any[];
  estado!: NomId;
  fechaAlta!: string;
  fechaModificacion!: string;
  pendientePago!: number;
  facturado!: number;
  gastos!: any[];
  pagoOc!: PagoOC;
  proveedor!: Proveedor;
  productora!:any;
  proyecto!: Proyecto;
  solicitante!: any;
  totalConceptos!: number;
  totalIrpf!: number;
  totalIva!: number;
  fianza!: number;
  JC!: NomId;
  DP!: NomId;
  JD!: NomId;
  modificado!: String;
  cerrada!: boolean;
  public constructor(data?: any, init?: Partial<OrdenCompraListar>) {
    if (data) {
      this.numOc = data.numOc;
      this.fechaAlta = data.fechaAlta;
      this.solicitante = data.solicitante;
      this.departamento = data.departamento;
      this.conceptos = data.conceptos;
      this.codigoConcepto = data.concepto;
      this.descripcionConcepto = data.conceptos[0].descripcion;
      this.proveedor = data.proveedor;
      this.productora = data.productora;
      this.base = data.base;
      this.totalIrpf = data.totalIrpf;
      this.totalIva = data.totalIva;
      this.totalConceptos = data.totalConceptos;
      this.fianza = data.pagoOc.fianza;
      this.pendientePago = data.total - data.facturado;
      this.facturado = data.facturado;
      this.JC = data.aceptado_jefe_contabilidad;
      this.DP = data.aceptado_director_produccion;
      this.JD = data.aceptado_jefe_departamento;
      this.estado = data.estado;
      this.fechaModificacion = data.fechaModificacion;
      this.modificado = data.modificado;
      this.cerrada = this.estado.id == 3;
      this.proyecto = data.proyecto;
    } else {
      Object.assign(this, init);
    }
  }
}
export class OrdenCompraExport {
  id!: number;
  base!: String;
  conceptos!: ConceptosOExport[];
  departamento!: string;
  //documentos!: any[]
  estado!: NomId;
  fechaAlta!: string;
  gastos!: any[];
  tipoPagoOc!: string;
  periocidadPagoOc!: string;
  numeroPagosOC!: number;
  fechasPagoOC!: string;
  fianzaPagoOC!: string;
  observacionesOC!: string;
  proveedor!: ProveedorExportProy[];
  solicitante!: string;
  totalConceptos!: number;
  totalIrpf!: number;
  totalIva!: number;

  public constructor(data?: any, init?: Partial<OrdenCompraExport>) {
    if (data) {
      this.id = data.id;
      this.base = data.base;
      this.conceptos = [];
      data.conceptos.forEach((element: any) => {
        element.numOc = data.id;
        this.conceptos.push(new ConceptosOExport(element));
      });
      this.departamento = data.departamento.nombre;
      // this.documentos = data.documentos;
      this.estado = data.estado.nombre;
      this.fechaAlta = data.fechaAlta;
      if (data.gastos) {
        this.gastos = [];
        data.gastos.forEach((element: any) => {
          element.numOc = data.id;
          this.gastos.push(new GastoExport(element));
        });
      }
      this.tipoPagoOc = data.pagoOc.tipoPagoOc.nombre;
      this.periocidadPagoOc = data.pagoOc.periodicidadPago
        ? data.pagoOc.periodicidadPago.nombre
        : '';
      this.numeroPagosOC = data.pagoOc.numeroPagos;
      this.fechasPagoOC = '';
      data.pagoOc.fechasPago.forEach((element: any) => {
        this.fechasPagoOC = this.fechasPagoOC + element + ', ';
      });
      this.fianzaPagoOC = data.pagoOc.fianza;
      this.observacionesOC = data.pagoOc.observaciones;
      data.proveedor.numOc = data.id;
      this.proveedor = [new ProveedorExportProy(data.proveedor)];
      this.solicitante = data.solicitante;
      this.totalConceptos = data.totalConceptos;
      this.totalIrpf = data.totalIrpf;
      this.totalIva = data.totalIva;
    } else {
      Object.assign(this, init);
    }
  }
}

export class ConceptosOC {
  tipoConceptoOc!: NomId;
  codigo!: any;
  descripcion!: string;
  fechaInicio!: string;
  fechaFin!: string;
  unidadesOc!: UnidadesOC[];
  precio!: number;
  irpf!: number;
  iva!: number;
  lugar!: string;
  moneda!: string;
  cambio!: number;
  conversion!: number;
  tipoImpuesto!: String;
  suma!: number;
  public constructor(data?: any, init?: Partial<ConceptosOC>) {
    if (data) {
      console.log('data en  ', data);

      this.tipoConceptoOc = data.tipoConceptoOc;
      this.codigo = data.cuentaConcepto ? data.cuentaConcepto : data.codigo;
      this.descripcion = data.descripcion;
      this.fechaInicio = data.fechaInicio;
      this.fechaFin = data.fechaFin;
      this.unidadesOc = data.unidadesOc;
      this.precio = data.precio;
      this.irpf = data.irpf;
      this.iva = data.iva;
      this.lugar = data.lugar;
      this.tipoImpuesto = data.tipoImpuesto;
      this.moneda = data.moneda;
      this.cambio = data.cambio;
      this.conversion = data.conversion;
      this.suma = data.suma;
    } else {
      Object.assign(this, init);
    }
  }
}
export class ConceptosOCListar {
  tipo!: NomId;
  cuentaConcepto!: any;
  descripcion!: string;
  fechaInicio!: string;
  fechaFin!: string;
  tipoCantidad!: string;
  cantidad!: string;
  unidadCantidad!: any;
  unidad!: string;
  precio!: number;
  /* irpf!: number
    iva!: number */
  irpf!: number;
  impuesto!: number;
  lugar!: string;
  tipoImpuesto!: String;
  moneda!: string;
  cambio!: number;
  baseConversion!: number;

  public constructor(data?: any, init?: Partial<ConceptosOCListar>) {
    if (data) {
      console.log('data en ConceptosOCListar ', data);
      this.tipo = data.tipoConceptoOc ? data.tipoConceptoOc : data.tipo;

      this.cuentaConcepto = data.codigo ? data.codigo : data.cuentaConcepto;
      this.descripcion = data.descripcion;
      this.fechaInicio = data.fechaInicio;
      this.fechaFin = data.fechaFin;
      this.tipoCantidad = data.unidadesOc[0].tipoUnidad.nombre;
      this.cantidad = data.unidadesOc[0].unidades;
      this.unidadCantidad = 'Unidades';
      this.unidad = data.unidadesOc[1].unidades;
      this.precio = data.precio;
      this.irpf = data.irpf;
      this.impuesto = data.iva;
      this.tipoImpuesto = data.tipoImpuesto;
      this.moneda = data.moneda;
      this.cambio = data.cambio;
      this.baseConversion = data.conversion;
      this.lugar = data.lugar;
    } else {
      Object.assign(this, init);
    }
  }
}
export class ConceptosOExport {
  numOc!: number;
  tipoConceptoOc!: NomId;
  cuentaConcepto!: any;
  descripcion!: string;
  fechaInicio!: string;
  fechaFin!: string;
  tipoUnidad!: string;
  unidad!: string;
  tipoUnidad2!: any;
  unidad2!: string;
  precio!: number;
  /* irpf!: number
    iva!: number */

  irpf!: number;
  iva!: number;

  public constructor(data?: any, init?: Partial<ConceptosOExport>) {
    if (data) {
      this.numOc = data.numOc;
      this.tipoConceptoOc = data.tipoConceptoOc.nombre;
      this.cuentaConcepto = data.codigo.cuenta;
      this.descripcion = data.descripcion;
      this.fechaInicio = data.fechaInicio;
      this.fechaFin = data.fechaFin;
      this.tipoUnidad = data.unidadesOc[0].tipoUnidad?.nombre ?? '';
      this.unidad = data.unidadesOc[0].unidades;
      this.tipoUnidad2 = 'Unidades';
      this.unidad2 = data.unidadesOc[1].unidades;
      this.precio = data.precio;
      this.irpf = data.irpf;
      this.iva = data.iva;
    } else {
      Object.assign(this, init);
    }
  }
}
export class UnidadesOC {
  tipoUnidad!: NomId;
  unidades!: number;

  public constructor(data?: any, init?: Partial<UnidadesOC>) {
    if (data) {
      this.tipoUnidad = data.tipoUnidad;
      this.unidades = data.unidades;
    } else {
      Object.assign(this, init);
    }
  }
}
export class NomId {
  id?: number;
  nombre!: string;

  public constructor(data?: any, init?: Partial<NomId>) {
    if (data) {
      this.id = data.id;
      this.nombre = data.nombre;
    } else {
      Object.assign(this, init);
    }
  }
}
export class PagoOC {
  id?: number;
  tipoPagoOc?: NomId;
  periodicidadPago?: NomId;
  numeroPagos?: number;
  fechasPago?: string[];
  fianza?: number;
  fechaFianza?: string;
  observaciones?: string;

  public constructor(data?: any, init?: Partial<PagoOC>) {
    if (data) {
      this.id = data.id;
      this.tipoPagoOc = new NomId(data.tipoPagoOc);
      this.periodicidadPago = new NomId(data.periodicidadPago);
      this.numeroPagos = data.numeroPagos;
      this.fechasPago = data.fechasPago;
      this.fianza = data.fianza;
      this.fechaFianza = data.fechaFianza;
      this.observaciones = data.observaciones;
    } else {
      Object.assign(this, init);
    }
  }
}
