import { BancosIban } from "./productora";
import { Proyecto } from "./proyecto";

export class Proveedor {
    id!: number
    cif!: string;
    nombreFiscal!: string;
    nombreComercial!: string;
    direccion!: string;
    codigoPostal!: string;
    ciudad!: string;
    email!: string;
    web!: string;
    personaContacto!: string;
    bancoIban!: BancosIban
    telefonos!: string[];
    proyectos!: Proyecto[]
    nombreBanco!: String
    documentos!: any

    public constructor(data?: any, init?: Partial<Proveedor>) {
        if (data) {
            this.id = data.id;
            this.cif = data.cif;
            this.nombreFiscal = data.nombreFiscal
            this.nombreComercial = data.nombreComercial;
            this.direccion = data.direccion;
            this.codigoPostal = data.codigoPostal;
            this.ciudad = data.ciudad;
            this.email = data.email
            this.web = data.web
            this.personaContacto = data.personaContacto
            this.telefonos = data.telefonos
            this.proyectos = data.proyectos

            this.bancoIban = data.bancoIban
            this.documentos = data.documentos

        } else {
            Object.assign(this, init);
        }


    }
}

export class ProveedorListar {
    id!: number
    cif!: string;
    nombreFiscal!: string;
    nombreComercial!: string;
    direccion!: string;
    codigoPostal!: string;
    ciudad!: string;
    email!: string;
    web!: string;
    personaContacto!: string;
    telefonos!: string[];
    proyectos!: Proyecto[]
    banco!: string
    iban!: string
    titularidad!: boolean
    contratistas!: boolean
   // situacionCensal!: boolean
    situCensal!: boolean
    residencia!: boolean
    documentos!: any

    public constructor(data?: any, init?: Partial<ProveedorListar>) {
        if (data) {
            this.id = data.id;
            this.nombreFiscal = data.nombreFiscal;
            this.nombreComercial = data.nombreComercial;
            this.cif = data.cif;
            this.titularidad = false
            this.contratistas = false
            this.situCensal = false
            this.residencia = false
            this.banco = data.bancoIban.nombreBanco
            this.iban = data.bancoIban.iban
            this.personaContacto = data.personaContacto;
            this.telefonos = data.telefonos
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.email = data.email;
            this.web = data.web;
            this.proyectos = data.proyectos
            this.documentos = data.documentos
            data.documentos.forEach(((doc: any) => {
                if (doc.tipo.id == 1)
                    this.titularidad = true
                if (doc.tipo.id == 2)
                    this.contratistas = true
                if (doc.tipo.id == 3)
                    this.situCensal = true
                if (doc.tipo.id == 4)
                    this.residencia = true
            }))
            //this.documentos = data.documentos
        } else {
            Object.assign(this, init);
        }


    }
}
export class ProveedorExportProy {
    numOc!: number;
    id!: number
    cif!: string;
    nombreFiscal!: string;
    nombreComercial!: string;
    direccion!: string;
    codigoPostal!: string;
    ciudad!: string;
    email!: string;
    web!: string;
    personaContacto!: string;
    telefonos!: string;
    banco!: string
    iban!: string
    titularidad!: string
    contratistas!: string
   // situacionCensal!: string
    situCensal!: string
    residencia!: string

    public constructor(data?: any, init?: Partial<ProveedorExportProy>) {
        if (data) {
            if(data.numOc){
                this.numOc = data.numOc;
            }
            this.id = data.id;
            this.cif = data.cif;
            this.nombreFiscal = data.nombreFiscal;
            this.nombreComercial = data.nombreComercial;
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.email = data.email;
            this.web = data.web;
            this.personaContacto = data.personaContacto;
            this.banco = data.bancoIban?data.bancoIban.nombreBanco:""
            this.iban = data.bancoIban?data.bancoIban.iban:""
            this.telefonos = ""
            this.telefonos = ""
            data.telefonos.forEach((element: any) => {
                this.telefonos = this.telefonos + element + ", "
            });
            this.codigoPostal = data.codigoPostal
            this.titularidad = "NO"
            this.contratistas = "NO"
            this.situCensal = "NO"
            this.residencia = "NO"


            data.documentos.forEach(((doc: any) => {
                if (doc.tipo.id == 1)
                    this.titularidad = "SÍ"
                if (doc.tipo.id == 2)
                    this.contratistas = "SÍ"
                if (doc.tipo.id == 3)
                    this.situCensal = "SÍ"
                if (doc.tipo.id == 4)
                    this.residencia = "SÍ"
            }))
        } else {
            Object.assign(this, init);
        }


    }
}