import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioService } from '../usuario/usuario.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../notification.service';

const TOKEN_KEY = "auth-token";
const USER_KEY = "auth-user";
const USER_ID = "auth_user_id";


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  /* 
  Servicio que se encarga de toda la funcionalidad de manejar 
  el token del usuario que se recibe del back 
  */
  constructor(
    private userService: UsuarioService,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  dataUserForm: Usuario = new Usuario;

  /* 
  Limpia la imformacion que hay en el session
  storage respecto al token y usuario al cerrar sesion
  */
  signOut(): void {
    window.sessionStorage.clear();
  }

  /* 
  Guarda la informacion del token en el session storage
  */
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  /* 
  Getea el token del session storage para sus posibles comporbaciones
  */
  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  /* 
  Getea el id del usuario que esta logueado
  */
  public getId(): string | undefined {
    return window.sessionStorage.getItem(USER_ID)?.replace(/["]/g, "");
  }
  /* 
  Getea toda la informacion guardada en el session storage del usuario
  */
  public getUser(): Usuario {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return new Usuario();
  }
  /* 
  Setea la informacion del usuario en el session storage
  */
  public setUser(user: Usuario): void {
    window.sessionStorage.setItem(USER_ID, user.id + "");
    window.sessionStorage.setItem(
      USER_KEY,
      JSON.stringify(user)
    );
  }
  public saveUser(): void {
    window.sessionStorage.removeItem(USER_KEY);

    firstValueFrom(this.userService.getCurrentUser()).then(
      (dataUser: any) => {
        this.dataUserForm = dataUser.Usuario;
        window.sessionStorage.setItem(USER_ID, this.dataUserForm.id + "");
        window.sessionStorage.setItem(
          USER_KEY,
          JSON.stringify(this.dataUserForm)
        );
      }


    );
  }
  /* 
  Hace les signOut al comprobar si la expiracion del toquen se ha sobrepasado
  */
  signOutExpiration(): void {
    window.sessionStorage.clear();
    this.notificationService.warning(
      "Ha expirado su sesión.",
      "Su sesión ha expirado por inactividad, debe volver a logearse para continuar.",
      5000
    );
    this.router.navigate(["/"], { replaceUrl: true });
  }

  /* 
  Comprueba si el token ha expirado
  */
  isExpired(token: any) {
    if (token == null) {
      this.router.navigate(["/"], { replaceUrl: true });
    }
    return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000

  }
}
