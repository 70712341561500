<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [isViewPage]="isReading"></app-header-listados>
<div>
    <div class="topInfo">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVA'}} PRODUCTORA</label>
    </div>
    <div class="form-surrouded">
        <form class="concepto col-12" [formGroup]="form" (change)="onFormChange()">
            <div class="rowForm">
                <div class="sm-field formMaterial">
                    <label class="input-label formLabel">CIF/NIF<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="input-field formField "
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="cif" (blur)="checkNifCifExists()" placeholder="CIF/NIF...">
                        <mat-error>CIF requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="sm-field d-flex formMaterial">

                    <label class="input-label formLabel">Nombre Productora<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="input-field formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="nombre" placeholder="Nombre Productora...">
                        <mat-error>Nombre requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="bg-field formMaterial">
                    <label class="input-label-bg formLabel">Dirección<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="col-10 formField input-field" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="direccion" placeholder="Dirección...">
                        <mat-error>Dirección requerida</mat-error>
                    </mat-form-field>
                </div>
                    <div class="sm-field formMaterial">
                        <label class="input-label formLabel">Código Postal<span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="input-field formField "
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput type="number" formControlName="codigoPostal" placeholder="Código Postal...">
                            <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['pattern']!= undefined">
                                La longitud del código postal tiene que ser 5</mat-error>
                            <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['required']!= undefined">Código
                                Postal requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="sm-field formMaterial">
                        <label class="input-label formLabel">Ciudad<span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="input-field formField" [ngClass]="isReading?'desactivado':''">
                            <input matInput formControlName="ciudad" placeholder="Ciudad...">
                            <mat-error>Ciudad requerida</mat-error>
                        </mat-form-field>
                    </div>
                

                    <div class="sm-field formMaterial">
                        <label class="input-label formLabel">País<span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="input-field formField " [ngClass]="isReading?'desactivado':''">
                            <input matInput formControlName="pais" placeholder="País...">
                            <mat-error>País requerida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="sm-field formMaterial">
                        <label class="input-label formLabel">Número Seguridad Social</label>
                        <mat-form-field appearance="outline" class="input-field formField" [ngClass]="isReading?'desactivado':''">
                            <input matInput formControlName="seguridadSocial" placeholder="Número Seguridad Social...">
                            <mat-error *ngIf="this.form.controls['seguridadSocial'].errors?.['pattern']!= undefined">
                                La longitud del número seguridad social tiene que ser 15</mat-error>
                            <mat-error *ngIf="this.form.controls['seguridadSocial'].errors?.['required']!= undefined">Número
                                seguridad social requerido</mat-error>
                        </mat-form-field>
                    </div>

                <div class="col-12 formMaterial" *ngIf="isAdmin">
                    <label class="col-2 formLabel">Proyecto</label>
                    <mat-form-field appearance="outline" class="col-10 formField" [ngClass]="isReading?'desactivado':''">
                        <mat-select matInput placeholder="Proyecto..." formControlName="proyectos" multiple
                            [compareWith]="compararObjetos">
                            <mat-option *ngFor="let proyecto of proyectosList" [value]="{id:proyecto.id}">
                                <span class="columnas"> {{proyecto.titulo}}</span></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 rowForm" formArrayName="telefonos">
                <div class="col-12 formMaterial" *ngFor="let tel of telefonos.controls; let i = index">
                    <div class="bg-field">
                        <label class="input-label-bg formLabel ">Teléfono<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="col-10 formField input-field"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput type="text" [formControlName]="i" placeholder="Teléfono...">
                        <mat-icon *ngIf="!isReading && telefonos.length != 1" class="material-icons pointer eye-b"
                            (click)="removeTelefono(i)">
                            highlight_off
                        </mat-icon>
                        <mat-error *ngIf="this.form.controls['telefonos'].value[i]== ''">
                            Teléfono requerido</mat-error>
                        <mat-error
                            *ngIf="this.form.controls['telefonos'].status=='INVALID' && this.form.controls['telefonos'].value[i]!= ''">
                            El teléfono no cumple el formato</mat-error>
                    </mat-form-field>
                    </div>
                    <div class="col-12 ">
                        <div class="col-12 cuadroBotones" *ngIf="i == telefonos.length-1 && !isReading">
                            <button type="button" class="aniadir buttonSecondary" (click)="addTelefono()"><mat-icon
                                    class="material-symbols-outlined">
                                    add
                                </mat-icon>Añadir
                                fila</button>
                        </div>
        
                    </div>
                    
                    
                </div>
            </div>


            <div formArrayName="bancosIban" class="col-12">
                <div *ngFor="let item of bancosIban.controls; let i = index" [formGroupName]="i" class="col-12">
                    <div class="col-12 d-flex">
                        <div class="sm-field formMaterial">
                            <label class="input-label formLabel">Banco<span class="red">*</span></label>
                            <mat-form-field appearance="outline" class="input-field formField "
                                [ngClass]="isReading?'desactivado':''">
                                <input matInput formControlName="nombreBanco" placeholder="Banco...">
                                <mat-error>Nombre de banco requerido</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="sm-field formMaterial">
                            <label class="input-label formLabel">IBAN<span class="red">*</span></label>
                            <mat-form-field appearance="outline" class="input-field formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input matInput formControlName="iban" placeholder="IBAN...">
                                <mat-icon *ngIf="!isReading && bancosIban.length != 1"
                                    class="material-icons pointer eye-b" (click)="removebancosIban(i)">
                                    highlight_off</mat-icon>
                                <mat-error>IBAN requerido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-12">

                        <div class="col-12 cuadroBotones formMaterial" *ngIf="i == bancosIban.length-1 && !isReading">
                            <button type="button" class="aniadir buttonSecondary" (click)="addbancosIban()"><mat-icon
                                    class="material-symbols-outlined">
                                    add</mat-icon>Añadir
                                fila</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12 cuadroBotones formMaterial">
                <!--   <span *ngIf="!isReading" [ngClass]="(!isReading && !isUpdate)?'':'formMaterial'">

                    <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                    </button>
                </span> -->
                <button *ngIf="isClearForm?limparFormulario():''">

                </button>
                <button *ngIf="isSave?onSubmit():''">

                </button>
            </div>

            <div class="dialog-container" *ngIf="modalExistProdu">
                <div class="dialog-content">
                    <div class="contenedortextos">
                        <div class="circle_document">
                            <a class="document-div"></a>
                        </div>
                    </div>
                    <div class="contenedortextos">
                        <h4 class="colorh4">Esta productora ya existe</h4>
                    </div>
                    <div class="contenedortextos2">
                        <p>¿Desea asignarle este proyecto?</p>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button (click)="(modalExistProdu = false)" id="btcancel"
                            class="btn btn-primary mx-1 ">Cancelar</button>
                        <button id="btacept" class="btn btn-primary ml-1">
                            <div></div>
                            <span (click)="asociarProyExit()" class="align-middle"> Confirmar</span>
                        </button>
                    </div>
                </div>
            </div>


        </form>
    </div>

</div>
<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>