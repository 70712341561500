import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  pageSize = "10";
  /* 
  Servicio que se encarga de la preparacion de los filtros para luego enviar en cada componente.ts la peticion del listado paginado
  */
  constructor() { }
  /* 
  Prepara un filtro especifico dependiendo del campo que se le pasa los parametros 
  necesarios para cada campo de las tablas y retorna el listado de filtros
  */
  doFilter(event: any, type: any, operator: any, field_type: any, filters: any[] = [], valuebool: any) {

    let value;
    if (valuebool == null) {
      if (event.target != null) {
        value = event.target.value;
      } else if (event) {
        value = event
      }
    } else {
      value = valuebool
    }
    let isArray = type instanceof Array;
    if (value != '') {
      if (field_type == 'DATE') {
        value = formatDate(value, 'dd-MM-yyyy', 'en-US');
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = '';
        type.forEach((element: any) => {
          composeKey += element;
        });

        index = filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        filters[index] = newFilter;
      } else {
        filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        filters = filters.filter((item) => item.key !== type);
      } else {
        let composeKey = '';
        type.forEach((element: any) => {
          composeKey += element;
        });
        filters = filters.filter((item) => item.key !== composeKey);
      }
    }
    return filters


  }

  /* 
  Genera un filtro generico para ello se le pasan los parametros necesarios y retonta el listado de filtros
  */
  doGeneralFilter(evento: any, filters: any[] = []) {
    let operator = 'ALL_FIELDS_CLASS';
    var index = 0;
    filters.forEach((element: any) => {
      if ((element['operator'] = !undefined)) {
        filters.splice(index, 1);
      }
      index++;
    });

    if (evento != '') {
      filters.push({
        operator: operator,
        value: evento,
      });

    }
    console.log("filters", filters);

    return filters
  }


  /* 
  Se ecarga de generar un filtro de ordenacion de mayor a menor
  */
  ordenar(property: string, sorts: any[], ordenarCampos: any) {
    var orden = ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in ordenarCampos) {
      if (clave == property) {
        ordenarCampos[property] = ordenarCampos[property];
      } else {
        ordenarCampos[clave] = false;
      }
    }
    return sorts = [{ key: property, direction: orden }];
    return [];
  }

  crearCampos(data: any): any {
    let ordenarCampos: { [clave: string]: boolean } = {};


    if (ordenarCampos['id'] == undefined) {
      let nombre: string = '';
      if (data && data['content'].length != 0) {
        var mapa = Object.entries(data['content'][0]);
        mapa.forEach((element) => {
          nombre = element[0];
          ordenarCampos[nombre] = false;
        });
      }
    }
    return ordenarCampos;
  }
}
