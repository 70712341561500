import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

  url: string = environment.API_URL + "/departamento";
  /*
  Servicio que conecta con el back para el listado de departamentos 
   */

  constructor(private http: HttpClient
  ) { }

  getAllDepartamentos(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }
}
