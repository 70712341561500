<!-- <app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [(action)]="action" 
    [canImport]="!isReading&&isControllerOrDirProd" [canCreate]="isControllerOrDirProd"></app-header-listados> -->

    <app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
    [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
    [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento" [(isImport)]="isForImport"
    [canCreate]="tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [canExport]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [dataExport]="dataExport"  [(isPasarACoste)]="isForCoste"
    [canImport]="!tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)&&!interfazInicial.isLicencia"
   ></app-header-listados>
  
  <div>
  
  
    <div class="fondoblanco fondoblanco_tabla">
      <div class="checkBoxLog mb-2">
        <mat-checkbox (change)=" changeCheckedStatus($event, true)"> </mat-checkbox>
        <span class="formLabel">
            Seleccionar todos
        </span>
        <button  [ngClass]="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'd-none':''" class="buttonPrimary" (click)="dialogListVisible = true">
          Borrar
        </button>
    </div>
      <div class="mat-elevation-z8  " class="table_container data_table">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Select" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td class=" " *matCellDef="let element">
              <div class="">
                <mat-checkbox  [ngClass]="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)  || element.tieneCoste? 'd-none':''" (change)="changeCheckedStatus($event, false, element.id)" [checked]="map[element.id]"> </mat-checkbox>
            </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
  
                <input matInput type="text" class="searchinput uppercas" placeholder="ID"
                  (keyup)="doFilter($event, 'numGasto', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('ID', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['numGasto'] ? 'fechaup':'fechadawn'" (click)=" ordenar('numGasto')"></a>
              </div>
            </th>
            <td *matCellDef="let element"> <span class="inline_text">{{element.numGasto ? element.numGasto:element.id }}</span> </td>
          </ng-container>

          <ng-container matColumnDef="Nº">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
  
                <input matInput type="text" class="searchinput uppercas" placeholder="Nº PC"
                  (keyup)="doFilter($event, 'num_pc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº PC', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['num_pc'] ? 'fechaup':'fechadawn'" (click)=" ordenar('num_pc')"></a>
              </div>
            </th>
            <td *matCellDef="let element"> <span class="inline_text">{{element.num_pc}}</span> </td>
          </ng-container>
  
  
  
  
          <ng-container matColumnDef="Fecha Factura">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="FECHA FACTURA"
                  (keyup)="doFilter($event, 'fecha_factura', 'LIKE', 'DATE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FECHA FACTURA', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['fecha_factura'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('fecha_factura')"></a>
              </div>
            </th>
            <td *matCellDef="let element"> <span class="inline_text">{{element.fecha_factura}}</span> </td>
  
          </ng-container>
  
          <ng-container matColumnDef="Factura">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="Nº FACTURA"
                  (keyup)="doFilter($event, 'numeroFactura', 'LIKE', 'LONG')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº FACTURA', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['numeroFactura'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('numeroFactura')"></a>
              </div>
            </th>
            <td *matCellDef="let element"> <span class="inline_text">{{element.numeroFactura}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="CÓDIGO"
                  (keyup)="doFilter($event, 'cuentaPc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('CÓDIGO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['cuentaPc'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('cuentaPc')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.conceptos[0].codigo.cuenta}}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="nif">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="NIF"
                  (keyup)="doFilter($event, 'nifProveedorPc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('NIF', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['nifProveedorPc'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('nifProveedorPc')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.nifProveedorPc}}</span> </td>
          </ng-container>

          <ng-container matColumnDef="codigoPostal">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="C.POSTAL"
                  (keyup)="doFilter($event, 'codigoPostalPc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('C.POSTAL', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['codigoPostalPc'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('codigoPostalPc')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.codigoPostalPc}}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Proveedor">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder=" PROVEEDOR"
                  (keyup)="doFilter($event, 'nombreProveedorPc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('PROVEEDOR', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['nombreProveedorPc'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('nombreProveedorPc')"></a>
              </div>
            </th>
            <td *matCellDef="let element"> <span class="inline_text">{{element.nombreProveedorPc}}</span> </td>
          </ng-container>
  
          <ng-container matColumnDef="Concepto" class="th_large">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="Concepto"
                  (keyup)="doFilter($event, 'conceptos.codigo.concepto', 'LIST_CONTAINS', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Concepto', '600 14px Montserrat, sans-serif')}">
              </div>
            </th>
            <td *matCellDef="let element"><span class="inline_text">{{getConcepto(element.conceptos[0].codigo)}}</span> </td>
  
          </ng-container>
  
          <ng-container matColumnDef="Descripcion" class="th_large">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="Descripción"
                  (keyup)="doFilter($event, 'conceptos.descripcion', 'LIST_CONTAINS', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Descripción', '600 14px Montserrat, sans-serif')}"> 
              </div>
            </th>
            <td *matCellDef="let element"><span class="inline_text">{{element.conceptos[0].descripcion}}</span></td>
          </ng-container>

          <ng-container matColumnDef="Base">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="BASE"
                  (keyup)="doFilter($event, 'base', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('BASE', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['base'] ? 'fechaup':'fechadawn'" (click)=" ordenar('base')"></a>
              </div>
            </th>
            <td *matCellDef="let element" class="numberInput" [ngClass]="element.base<0?'letra-red':''">
              <span class="inline_text">{{element.base|numberEsp}}€</span>
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="Irpf">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="IRPF"
                  (keyup)="doFilter($event, 'irpf', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('IRPF', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['irpf'] ? 'fechaup':'fechadawn'" (click)=" ordenar('irpf')"></a>
              </div>
            </th>
            <td *matCellDef="let element" class="numberInput" [ngClass]="element.irpf<0?'letra-red':''">
              <span class="inline_text">{{element.irpf|numberEsp}}€</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Iva">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="IVA"
                  (keyup)="doFilter($event, 'iva', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('IVA', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['iva'] ? 'fechaup':'fechadawn'" (click)=" ordenar('iva')"></a>
              </div>
            </th>
            <td *matCellDef="let element" class="numberInput" [ngClass]="element.iva<0?'letra-red':''">
              <span class="inline_text">{{element.iva|numberEsp}}€</span>
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="Departamento">
            <th mat-header-cell *matHeaderCellDef class="th_large">
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="DEPARTAMENTO"
                  (keyup)="doFilter($event, 'departamento.nombre', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DEPARTAMENTO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['departamento.nombre'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('departamento.nombre')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.departamento.nombre}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Nombre">
            <th mat-header-cell *matHeaderCellDef class="th_large">
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="NOMBRE"
                  (keyup)="doFilter($event, 'nombre_pc', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('NOMBRE', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['nombre_pc'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('nombre_pc')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.nombre_pc}}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Creado">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="CREADO"
                  (keyup)="doFilter($event, 'createdBy.nombreUsuario', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('CREADO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['createdBy.nombreUsuario'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('createdBy.nombreUsuario')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.createdBy.nombreUsuario}}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Modificado">
            <th mat-header-cell *matHeaderCellDef class="th_large">
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="MODIFICADO"
                  (keyup)="doFilter($event, 'modifyBy.nombreUsuario', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('MODIFICADO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['modifyBy.nombreUsuario'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('modifyBy.nombreUsuario')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.modifyBy== undefined? '':element.modifyBy.nombreUsuario }}</span>
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="Fecha modificación">
            <th mat-header-cell *matHeaderCellDef class="th_large">
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="FECHA MODIFICACIÓN"
                  (keyup)="doFilter($event, 'fechaModificacion', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FECHA MODIFICACIÓN', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['fechaModificacion'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('fechaModificacion')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.fechaModificacion==undefined?'': element.fechaModificacion}}</span>
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="Total">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="TOTAL"
                  (keyup)="doFilter($event, 'total', 'LIKE', 'DOUBLE')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Total', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['total'] ? 'fechaup':'fechadawn'" (click)=" ordenar('total')"></a>
              </div>
            </th>
            <td *matCellDef="let element" class="numberInput" [ngClass]="element.total<0?'letra-red':''">
              <span class="inline_text">{{element.total|numberEsp}}€</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Período de Impuesto">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="PERÍODO IMPUESTO"
                  (keyup)="doFilter($event, 'periodo_iva.nombre', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('PERÍODO IMPUESTO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['periodo_iva.nombre'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('periodo_iva.nombre')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{element.periodo_iva.nombre}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Forma de Pago">
            <th mat-header-cell *matHeaderCellDef class="th_large">
              <div class="thdisp">
                <input matInput type="text" class="searchinput uppercase" placeholder="FORMA DE PAGO"
                  (keyup)="doFilter($event, 'pago.forma_pago.nombre', 'LIKE', 'STRING')"
                  [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FORMA DE PAGO', '600 14px Montserrat, sans-serif')}">
                <a [ngClass]="ordenarCampos['pago.forma_pago.nombre'] ? 'fechaup':'fechadawn'"
                  (click)=" ordenar('pago.forma_pago.nombre')"></a>
              </div>
            </th>
            <td *matCellDef="let element">
              <span class="inline_text">{{ element.pago[0]?.forma_pago?.nombre ?? 'N/A' }}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Acciones" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="accionesth"><span class="correccionmargenAcciones uppercase">
                Acciones
              </span>
            </th>
            <td class=" " *matCellDef="let element" class="accionesth">
              <div class="acciones">
                <a title="Ver" class="display-view-button"></a>
                <a title="Editar" class="display-edit-button"></a>
                <a title="Descargar" class="display-download-button"></a>
                <a title="Borrar"
                  [ngClass]="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)  || element.tieneCoste? 'display-delete-button':'delete-button pointer'"
                  (click)="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos) || element.tieneCoste?'':dialogVisible = true; idEliminar = element"></a>
  
  
  
              </div>
            </td>
          </ng-container>
         <!--  <ng-container matColumnDef="tieneCoste">
            <th mat-header-cell *matHeaderCellDef>
              <div class="thdisp">
                <span class="textwe600">Coste</span>
                <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
              </div>
            </th>
            <td *matCellDef="let element"><span *ngIf="element.tieneCoste">Con coste</span>
              <span *ngIf="!element.tieneCoste">
                <div class="activo pointer" (click)="pasarCoste(element)"> Pasar a coste</div>
              </span>
            </td>
          </ng-container> -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row
            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
            (mouseenter)="tableService.marcarColumna(row,true)" (mouseleave)="tableService.marcarColumna(row,false)"
            (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;"></tr>
  
        </table>
      </div>
  
      <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
        (page)="nextPage($event)">
      </mat-paginator>
  
      <div class="dialog-container" *ngIf="dialogVisible">
        <div class="dialog-content">
          <div class="contenedortextos">
            <div class="circle_document">
              <a class="delete-div"></a>
            </div>
          </div>
          <div class="contenedortextos">
            <h4 class="colorh4">¿Desea eliminar el justificante {{idEliminar.id}}?</h4>
            </div>
            <div class="contenedortextos2">
              <p>Se eliminará de forma permanente la información del justificante.</p>
            </div>
          <div class="dialog_buttons">
            <button (click)="dialogVisible = false" id="btcancel" class="btn btn-primary mx-1 mt-3">Cancelar</button>
            <button id="btacept" class="btn btn-primary mx-1 mt-3">
              <div></div>
              <span (click)="borrarElemento(idEliminar.id)" class="align-middle">Confirmar</span>
            </button>
          </div>
        </div>
      </div>

      <div class="dialog-container" *ngIf="dialogListVisible">
        <div class="dialog-content">
          <div class="contenedortextos">
            <div class="circle_document">
              <a class="delete-div"></a>
            </div>
          </div>
          <div class="contenedortextos">
            <h4 class="colorh4">¿Desea eliminar {{ justificacionesSeleccionadas }} justificante/s seleccionados?</h4>
            </div>
            <div class="contenedortextos2">
              <p>Se eliminará de forma permanente la información de los justificantes y sus pagos asociados.</p>
            </div>
          <div class="dialog_buttons">
            <button (click)="dialogListVisible = false" id="btcancel" class="btn btn-primary mx-1 mt-3">Cancelar</button>
            <button id="btacept" class="btn btn-primary mx-1 mt-3">
              <div></div>
              <span (click)="borrarElementos()" class="align-middle">Confirmar</span>
            </button>
          </div>
        </div>
      </div>

    </div>
    <div class="dialog-container" *ngIf="isForImport">
      <div class="dialog-content" style="width: 500px;">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="document-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
          <h4 class="colorh4">Va a importar justificaciones</h4>
        </div>
        <div class="contenedortextos2">
          <p>Para importar las justificaciones, primero será necesario descargar la plantilla que se
            proporciona. Los datos deben estar todos rellenos, sin errores y sin símbolos, ya que
            sino la
            importación no se realizará con éxito</p>
        </div>
  
        <div class="d-flex justify-content-end">
          <button (click)="(isForImport = false)" id="btcancel" class="btn btn-primary mx-1">Cancelar</button>
          <button class="buttonsAccions botExportar col-4 mx-1" type="button" (click)="downloadTemplate()">Plantilla
          </button>
          <button class="buttonsAccions buttonSquaredWhiteright col-4 mx-1"
            (click)="$event.preventDefault(); fileInput.click()">Importar</button>
          <input #inputFile type="file" #fileInput style="display: none;" (change)="onFileChange($event)">
        </div>
      </div>
    </div>
  
    <div class="dialog-container" *ngIf="isForCoste">
      <div class="dialog-content" style="width: 500px;">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="document-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
          <h4 class="colorh4">Vas a pasar a coste</h4>
        </div>
        <div class="contenedortextos2">
          <p>Vas a pasar a coste todos los registros actuales. Revíselos antes de continuar ya que una vez estén en 
            costes no se podrán eliminar ni modificar.</p>
        </div>

        <div class="d-flex justify-content-end">
          <button (click)="(isForCoste = false)" id="btcancel" class="btn btn-primary mx-1">Cancelar</button>

          <button class="btn btn-primary col-4 mx-1" (click)="pasarCoste()">Continuar</button>
        </div>
      </div>

    <div class="sppiner-container" *ngIf="loading">
      <div class="spinner-border" role="status"></div>