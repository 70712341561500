<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [canSend]="mostrarEnviar && ordenCompraAux.estado.id == 4" (isSendingChange)="enviarNotificacion()" (generarPDF)="generarPDF()" ></app-header-listados>

<div id="correccion">
    <div *ngIf="ordenCompraAux" class="topInfo">
        <label class="titulosPaginas uppercase">SOLICITANTE: {{ordenCompraAux.solicitante.nombreUsuario}}</label>
    </div>
    <div *ngIf="ordenCompraAux" class="topInfo">
        <label *ngIf="ordenCompraAux.gastos[0]" class="titulosPaginas uppercase">PRODUCTORA: {{ordenCompraAux.gastos[0].productora.nombre}}</label>
    </div>
    <div class="topInfo" [ngClass]="{'topInfo2': this.isProveedorExpanded == 'in'}">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVA'}} ORDEN DE COMPRA{{aniadirOCNumber(ordenCompraAux)}}</label>
    </div>

    <div class="showFormText" [ngClass]="{'showFormText2': this.isProveedorExpanded != 'in'}">
        <h6 *ngIf="isProveedorExpanded === 'out'" class="marginAuto titulosPaginas">Datos Proveedor</h6>
        <div class="text-buttons">
            <p (click)="this.isProveedorExpanded = this.isProveedorExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isProveedorExpanded === 'in'}">Mostrar Datos
                Proveedor </p>
            <p (click)="this.isProveedorExpanded = this.isProveedorExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer">
                / </p>
            <p (click)="this.isProveedorExpanded = this.isProveedorExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isProveedorExpanded === 'out'}"> Ocultar</p>
        </div>
    </div>
    <div [@slideInOut]="isProveedorExpanded" class="form-surrouded"
            [ngClass]="{'form-surrouded3': this.isProveedorExpanded != 'in'}">
            <div class="table_container">
                <div class="other">
                    <div>
                        <form [formGroup]="formProvedor" (change)="onFormChange()">
        
                            <div class="col-12">
                                <h6 class="encabezados padded-col">Datos Proveedor</h6>
                                <div class="rowForm d-flex">
                                
                                    <div class="formMaterial autocomplete">
                                        <label class="col-12 formLabel">Nombre Fiscal <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField"
                                            [ngClass]="isReading?'desactivado':''">
                                            <input (keydown)="filterCols($event)" (keyup)="filterCols($event)" type="text" matInput
                                                placeholder="Nombre Fiscal..." formControlName="nombreFiscal" name="autocompletename"
                                                [matAutocomplete]="auto1"
                                                [value]="proveedor.nombreFiscal!=undefined?proveedor.nombreFiscal:''">
                                            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selectProveedorNombre($event)">
                                                <span *ngFor="let proveedoraux of filterProveedor()">
                                                    <mat-option [value]="proveedoraux.nombreFiscal?proveedoraux.nombreFiscal:'' "><span
                                                            class="columnas">
                                                            {{proveedoraux.nombreFiscal}}</span></mat-option>
                                                </span>
                                            </mat-autocomplete>
                                            <mat-error>Proveedor requerido</mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="formMaterial autocomplete">
                                        <label class="col-12 formLabel">NIF <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField auto "
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' : ''">
                                            <input (keydown)="filterCols($event)" (keyup)="filterCols($event)" type="text" matInput
                                                placeholder="NIF..." formControlName="cif" name="autocompletename"
                                                [matAutocomplete]="auto2" [value]="proveedor.cif !=undefined?proveedor.cif:''"
                                                class="auto">
                                            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selectProveedor($event)">
                                                <span *ngFor="let proveedoraux of filterProveedor()">
                                                    <mat-option [value]="proveedoraux.cif?proveedoraux.cif:''"><span class="columnas">
                                                            {{proveedoraux.cif}}</span></mat-option>
                                                </span>
                                            </mat-autocomplete>
                                            <mat-error>Proveedor requerido</mat-error>
                                        </mat-form-field>
                                    </div>
        
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">Nombre Comercial</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]=" isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Nombre Comercial..." formControlName="nombreComercial"
                                                [value]="proveedor.nombreComercial?proveedor.nombreComercial:''">
                                        </mat-form-field>
                                    </div>
        
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">Persona de Contacto</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]=" isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Persona de Contacto..." formControlName="personaContacto"
                                                [value]="proveedor.personaContacto?proveedor.personaContacto:''">
                                        </mat-form-field>
                                    </div>
                                
        
                                
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">Email</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Email..." formControlName="email"
                                                [value]="proveedor.email?proveedor.email:''">
                                            <mat-error>Formato del Email no valido</mat-error>
                                        </mat-form-field>
        
                                    </div>
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">IBAN</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="IBAN..." formControlName="iban"
                                                [value]="proveedor.bancoIban?proveedor.bancoIban.iban:''">
                                        </mat-form-field>
                                    </div>
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">Dirección</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Dirección..." formControlName="direccion"
                                                [value]="proveedor.direccion?proveedor.direccion:''">
                                        </mat-form-field>
                                    </div>
        
                                    <div class="d-flex formMaterial">
                                        <div class="col-6 padded-formField">
                                            <label class="col-12 formLabel">C.P</label>
                                            <mat-form-field appearance="outline" class="col-12 formField "
                                                [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                                <input matInput placeholder="C.P..." formControlName="codigoPostal"
                                                    [value]="proveedor.codigoPostal?proveedor.codigoPostal:''">
                                                <mat-error>Formato del C.P no valido</mat-error>
        
                                            </mat-form-field>
        
                                        </div>
                                        <div class="col-6">
                                            <label class="col-12 formLabel">Ciudad</label>
                                            <mat-form-field appearance="outline" class="col-12 formField "
                                                [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                                <input matInput placeholder="Ciudad..." formControlName="ciudad"
                                                    [value]="proveedor.ciudad?proveedor.ciudad:''">
                                            </mat-form-field>
        
                                        </div>
                                    </div>
                                
                                    <div class=" formMaterial">
                                        <label class="col-12 formLabel">Teléfono</label>
                                        <mat-form-field appearance="outline" class="col-12 formField " 
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Teléfono..." formControlName="telefono"
                                                [value]="proveedor.telefonos?proveedor.telefonos[0]:''">
                                            <mat-error>Formato del Teléfono no valido</mat-error>
        
                                        </mat-form-field>
                                    </div>
                                    <div class="formMaterial">
                                        <label class="col-12 formLabel">Pagina Web</label>
                                        <mat-form-field appearance="outline" class="col-12 formField "
                                            [ngClass]="isReading?'desactivado': !createNewProveedor?'disabled' :'auto'">
                                            <input matInput placeholder="Pagina Web..." formControlName="web"
                                                [value]="proveedor.web?proveedor.web:''">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!--   <div class="col-12 formMaterial">
                                    <button class="buttonPrimary buttonSquared  buttonMargins  " (click)="">
                                    
                                    </button>
                                </div> -->
                                <div class="col-12 cuadroBotones" *ngIf="createNewProveedor">
                                    <button class="buttonSquared buttonPrimary buttonMargins" (click)="saveProveedor()">
                                        {{'Guardar Proveedor'}}
                                    </button>
                                </div>
                            </div>
                            
                        </form>
                        
                    </div>
                        <form [formGroup]="form" (change)="onFormChange()">
        
                            <div>
                                <h6 class="encabezados padded-col">Otros Datos</h6>
                                
                                <div class="rowForm d-flex">
                                    <div class="col-sm-6 col-md-4 col-lg-3 formMaterial">
                                        <label class="col-12 formLabel">Productora <span class="red">*</span> </label>
                                        <mat-form-field appearance="outline" class="col-12 formField"
                                            [ngClass]="isReading?'desactivado':''">
                                            <mat-select matInput formControlName="productora" placeholder="Gasto Productora..."
                                            (selectionChange)="cambiarProductora($event)" [compareWith]="compararObjetos">
                                                <mat-option *ngFor="let tipo of productoras" [value]="tipo">
                                                    {{ tipo.nombre}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>Productora requerida</mat-error>
                                        </mat-form-field>
                                    </div>     
                                </div>
                                
                            </div>
                            
                        </form>
                    </div>
            </div>
        
        
    </div>
    <form [formGroup]="form" (change)="onFormChange()">
        <div class="showFormText" [ngClass]="{'showFormText2': this.isProveedorExpanded=='out'}">
            <h6 *ngIf="isConceptoTotalExpanded === 'out'" class="marginAuto titulosPaginas">Totales conceptos detalle
                gasto</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isConceptoTotalExpanded != 'in'}">
                <p (click)="this.isConceptoTotalExpanded = this.isConceptoTotalExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptoTotalExpanded === 'in'}">Mostrar
                    Totales
                </p>
                <p (click)="this.isConceptoTotalExpanded = this.isConceptoTotalExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isConceptoTotalExpanded = this.isConceptoTotalExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptoTotalExpanded === 'out'}">Ocultar</p>
            </div>
        </div>

        <div [@slideInOut]="isConceptoTotalExpanded" class="priceRow">
            <div class="cardsblue">
                <p class="cardtext">Total Base</p>
                <p class="cardtextbig">{{totalBase|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total Impuesto</p>
                <p class="cardtextbig">{{totalIva|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total IRPF</p>
                <p class="cardtextbig">{{totalIrpf|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total Gastos</p>
                <p class="cardtextbig">{{totalOC|numberEsp}} €</p>
            </div>

            <div class="cardsblue">
                <p class="cardtext">Total Facturado</p>
                <p class="cardtextbig">{{totalPagado|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Saldo Pendiente</p>
                <p class="cardtextbig">{{totalpendientepago|numberEsp}} €</p>
            </div>
        </div>
        <div *ngIf="isConcepto && !isFormaPago && !idFacturasOC" class="showFormText"
            [ngClass]="{'showFormText2': this.isConceptoExpanded != 'in'}">
            <h6 *ngIf="isConceptoExpanded === 'out'" class="marginAuto titulosPaginas">Datos Conceptos</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isConceptoExpanded != 'in'}">
                <p (click)="this.isConceptoExpanded = this.isConceptoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptoExpanded === 'in'}">Mostrar Conceptos
                </p>
                <p (click)="this.isConceptoExpanded = this.isConceptoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isConceptoExpanded = this.isConceptoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptoExpanded === 'out'}">Ocultar</p>
            </div>
        </div>



        <div *ngIf="!isConcepto && isFormaPago && !idFacturasOC" class="showFormText"
            [ngClass]="{'showFormText2': this.isFormaDePagoExpanded != 'in'}">
            <h6 *ngIf="isFormaDePagoExpanded === 'out'" class="marginAuto titulosPaginas">Datos Conceptos</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isFormaDePagoExpanded != 'in'}">
                <p (click)="this.isFormaDePagoExpanded = this.isFormaDePagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFormaDePagoExpanded === 'in'}">Mostrar Forma
                    de pago
                </p>
                <p (click)="this.isFormaDePagoExpanded = this.isFormaDePagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isFormaDePagoExpanded = this.isFormaDePagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFormaDePagoExpanded === 'out'}">Ocultar</p>
            </div>
        </div>


        <div *ngIf="!isConcepto && !isFormaPago && idFacturasOC" class="showFormText"
            [ngClass]="{'showFormText2': this.isFacturaExpanded != 'in'}">
            <h6 *ngIf="isFacturaExpanded === 'out'" class="marginAuto titulosPaginas">Factura OC</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isFacturaExpanded != 'in'}">
                <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFacturaExpanded === 'in'}">Mostrar Factura OC
                </p>
                <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFacturaExpanded === 'out'}">Ocultar</p>
            </div>
        </div>



        <div>
            <div class="col-12  divSelect" *ngIf="!isExporting">
                <div class="col-3 d-flex conc ajust pointer formMaterial"
                    (click)="(isConcepto=true);(isFormaPago=false); (idFacturasOC=false);(isConceptoExpanded='in'); ">
                    <div class="circle iconName rounded-circle pointer" [ngClass]="isConcepto?'selected':''">
                        <label>
                            1.
                        </label>
                    </div>
                    <label class="text_circle">Conceptos</label>
                </div>
                <div class="col-3 d-flex fomPag ajust pointer formMaterial"
                    (click)="(isConcepto=false);(isFormaPago=true) ; (idFacturasOC=false);   (isFormaDePagoExpanded='in') ">
                    <div class="circle iconName rounded-circle pointer" [ngClass]="isFormaPago?'selected':''">
                        <label>
                            2.
                        </label>
                    </div>
                    <label class="text_circle">Formas de pago</label>
                </div>

                <div class="col-3 d-flex fact ajust pointer formMaterial"
                    (click)="(isConcepto=false);(isFormaPago=false) ; (idFacturasOC=true); (isFacturaExpanded='in')">
                    <div class="circle iconName rounded-circle pointer" [ngClass]="idFacturasOC?'selected':''">
                        <label>
                            3.
                        </label>
                    </div>
                    <label class="text_circle">Seguimiento de facturas</label>
                </div>
                <div class="col-3 ajust est">
<!--                     <div *ngIf="mostrarEnviar  && ordenCompraAux.estado.id == 4" class=" col-12 botones_enviar">
                        <button class="buttonsAccions botExportar " type="button" (click)="enviarNotificacion()"
                            title="{{altEnviar}}">Enviar
                        </button>
                    </div> -->
                    <div *ngIf="mostrarActualizarEstado && ordenCompraAux.estado.id == 2" class="col-12 botones_estado">

                        <!-- <div title="Pendiente de aceptación" class="div_circle_warning">
                            <a class="warning-button"></a>
                        </div> -->
                        <div title="Aceptar" class="div_circle-done">
                            <a (click)="actualizarEstado(true)" class="done-button"></a>
                        </div>
                        <div title="Rechazar" class="div_circle_cancel">
                            <a (click)="dialogCancel=true" class="cancel-button"></a>
                        </div>
                    </div>
                    <div *ngIf="mostrarAceptado" class="col-12 estado">
                        <div class="div_circle-aceptada">
                            <span>ACEPTADA</span>
                        </div>
                    </div>
                    <div *ngIf="mostrarRechazado" class="col-12 estado">
                        <div class="div_circle_rechazada">
                            <span>RECHAZADA</span>
                        </div>
                    </div>
                    <div *ngIf="ordenCompraAux && !mostrarRechazado && !mostrarRechazado && !mostrarActualizarEstado && !mostrarEnviar"
                        class="col-12 estado">
                        <div class="div_circle-pendiente">
                            <span *ngIf="ordenCompraAux.estado.id == 4">PENDIENTE ENVÍO</span>
                            <span *ngIf="ordenCompraAux.estado.id == 2">PENDIENTE ACEPTACIÓN</span>
                        </div>
                    </div>
                </div>
            </div>
            <div [@slideInOut]="isFacturaExpanded" [ngClass]="{'form-surrouded2': this.isFacturaExpanded != 'in'}"
                class="col-12 form-surrouded-white" *ngIf="!isConcepto && !isFormaPago && idFacturasOC || isExporting">
                <h6 *ngIf="isExporting" class="encabezados padded-col">Facturas OC</h6>

                <div class="mat-elevation-z8" *ngIf="listaGastosId.length!=0">
                    <div class="formMaterial">
                        <table mat-table [dataSource]="dataSource2">
                            <ng-container matColumnDef="Nº">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">Nº </span>
                                </th>
                                <td *matCellDef="let element"><a
                                        (click)="navegarAEditar(element.id,'ver')">{{element.id}}</a> </td>
                            </ng-container>

                            <ng-container matColumnDef="Fecha Factura">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">FECHA FACTURA
                                    </span>
                                </th>
                                <td *matCellDef="let element"> {{element.fecha_factura}} </td>

                            </ng-container>

                            <ng-container matColumnDef="Factura">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2"> FACTURA</span>
                                </th>
                                <td *matCellDef="let element"> {{element.numeroFactura}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="NIF">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">NIF </span>
                                </th>
                                <td *matCellDef="let element"> {{element.proveedor.cif}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Proveedor">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">PROVEEDOR </span>
                                </th>
                                <td *matCellDef="let element"> {{element.proveedor.nombreFiscal}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Base">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">BASE </span>
                                </th>
                                <td *matCellDef="let element">
                                    {{element.base|numberEsp}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="Irpf">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">IRPF </span>
                                </th>
                                <td *matCellDef="let element">
                                    {{element.irpf|numberEsp}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Iva">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">IVA </span>
                                </th>
                                <td *matCellDef="let element">
                                    {{element.iva|numberEsp}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Pagado">
                                <th mat-header-cell *matHeaderCellDef><span class="correccionmargen2">FACTURADO </span>
                                </th>
                                <td *matCellDef="let element">
                                    {{element.facturado|numberEsp}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Acciones" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                                        class="correccionmargen2"> ACCIONES</span>
                                </th>
                                <td class=" " *matCellDef="let element" class="accionesth">
                                    <div class="acciones">
                                        <a  [ngClass]="tableService.checkPermiso(8,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                                        (click)=" tableService.checkPermiso(8,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegarAEditar(element.id,'ver')"></a>
                                        <a title="Editar"
                                            class="display-edit-button"></a>
                                        <a title="Descargar"
                                             class="display-download-button"></a>
                                        <a title="Borrar"
                                            class="display-delete-button"></a>



                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row
                                [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                                (mouseenter)="tableService.marcarColumna(row,true)"
                                (mouseleave)="tableService.marcarColumna(row,false)"
                                (click)="tableService.marcarColumna(row,null)"
                                *matRowDef="let row; columns: displayedColumns2;"></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 form-surrouded-white" [formGroup]="conceptos" (change)="onFormChange()"
                *ngIf="isConcepto && !isFormaPago && !idFacturasOC  || isExporting" >
                <h6 *ngIf="isExporting" class="encabezados padded-col">Conceptos</h6>

                <div *ngIf="listConceptosListar.length != 0"  class="col-12 d-flex table_container mat-elevation-z8 padd_bot_table ">
                    
                    <table mat-table [dataSource]="dataSource">
                        <!-- numero Column -->
                        <span *ngFor="let column of displayedColumns ">
                            <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                                            el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                                             nombres pero puedes quitar campos que no se quieren listar -->
                            <ng-container *ngIf="column!='acciones'" matColumnDef="{{column}}">
                                <th mat-header-cell *matHeaderCellDef>
                                    <div class="thdisp" *ngIf="column != 'cantidad' && column != 'unidad'&& column != 'precio'&& column != 'baseConversion'&&column != 'irpf' && column != 'impuesto'">
                                        <label
                                            [ngStyle]="{'min-width.px': tableService.calcTextWidth(column, '600 14px Poppins, sans-serif')}">
                                            <span class="uppercase fw">{{primLetMay(column)}}</span></label>
                                    </div>
                                    <div class="thdisp numberInput" *ngIf="column == 'precio'|| column == 'baseConversion'||column == 'irpf' || column == 'impuesto'">
                                        <label
                                            [ngStyle]="{'min-width.px': tableService.calcTextWidth(column, '600 14px Poppins, sans-serif')}">
                                            <span class="uppercase fw">{{primLetMay(column)}}</span></label>
                                    </div>
                                    <div class="thdisp numberInput" *ngIf="column == 'cantidad' || column == 'unidad'">
                                        <label>
                                            <span *ngIf="column == 'cantidad'" class="uppercase fw">Cant</span>
                                            <span *ngIf="column == 'unidad'" class="uppercase fw">Ud</span></label>
                                    </div>
                                </th>
                                <tr>
                                    <span *ngIf="column != 'cantidad' && column != 'unidad'&& column != 'precio'&& column != 'baseConversion'&&column != 'irpf' && column != 'impuesto'">
                                        <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                                    no tienen objetos añadidos seria solo esa linea-->
                                        <td *matCellDef="let element" class="celds">
                                            <span class="inline_text" *ngIf="column != 'tipo' && column != 'tipoUnidad'&& column != 'tipoUnidad2'&& column != 'cuentaConcepto'">
                                                {{element[column]}}
                                            </span>
                                            <span class="inline_text"
                                            *ngIf="column == 'tipo' || column == 'tipoUnidad' || column == 'tipoUnidad2'">
                                            {{element[column].nombre}}
                                        </span>
                                        <span class="inline_text " *ngIf="column == 'cuentaConcepto'">
                                            {{element[column].cuenta +' - '+ getConcepto(element[column])}}
                                        </span>
                                        </td>
                                    </span>

                                    <span *ngIf="column == 'cantidad' || column == 'unidad'|| column == 'precio'|| column == 'baseConversion'||column == 'irpf' || column == 'impuesto'">
                                        <td *matCellDef="let element" class="celds numberInput">
                                        
                                            <span class="inline_text" *ngIf="column != 'precio'&& column != 'baseConversion' && column != 'irpf' && column != 'impuesto' ">
                                                {{element[column]}}
                                            </span>
                                            <span class="inline_text" *ngIf="column == 'precio'|| column == 'baseConversion' ">
                                                {{element[column]|numberEsp}}
                                            </span>
                                            <span class="inline_text" *ngIf="column == 'irpf' || column == 'impuesto' ">
                                                {{element[column]}} %
                                            </span>
                                        </td>
                                    </span >
                                    
                                </tr>
                            </ng-container>
                        </span>

                        <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
                        <ng-container matColumnDef="acciones" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                                    class="correccionmargenAcciones uppercase">
                                    Acciones</span>
                            </th>
                            <td *matCellDef="let element; let i = index;" class="accionesth">
                                <div class="acciones" stickyEnd>
                                    <a title="Ver" [ngClass]="!isReading? 'display-view-button':'view-button '"
                                        (click)="updateConcepto(element,i, true)" (click)=" true?'':''"></a>
                                    <a title="Editar"
                                        [ngClass]="isReading? 'display-edit-button':' edit-button pointer'"
                                        (click)=" isReading?'':updateConcepto(element,i, false)"></a>
                                    <a title="Descargar" [ngClass]="true? 'display-download-button':'download-button '"
                                        (click)=" true?'':''"></a>
                                    <a title="Borrar"
                                        [ngClass]="isReading? 'display-delete-button':' delete-button pointer'"
                                        (click)=" isReading?'':removeAtIndex(i)"></a>
                                </div>
                            </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row
                            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                            (mouseenter)="tableService.marcarColumna(row,true)"
                            (mouseleave)="tableService.marcarColumna(row,false)"
                            (click)="tableService.marcarColumna(row,null)"
                            *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                </div>
                <div [@slideInOut]="isConceptoExpanded"
                    [ngClass]="{'form-surrouded2': this.isConceptoExpanded != 'in'}">
                    <h6 class="encabezados padded-col">Conceptos</h6>
                    <div class="rowForm">
                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Tipo <span class="red">*</span> </label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <mat-select matInput placeholder="Tipo..." formControlName="tipoConceptoOc"
                                    [compareWith]="compararObjetos">
                                    <mat-option *ngFor="let tipoOc of tiposOC" [value]="tipoOc">
                                        <span class="columnas"> {{tipoOc.nombre}}</span></mat-option>
                                </mat-select>
                                <mat-error>Tipo requerido</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3 formMaterial autocomplete">
                            <label class="col-12 formLabel">Código/Concepto <span class="red">*</span></label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input type="text" matInput placeholder="Código/Concepto..."
                                    formControlName="cuentaConcepto" name="autocompletename" [matAutocomplete]="auto"
                                    [value]="(conceptos.controls['cuentaConcepto'].value&&
                                    conceptos.controls['cuentaConcepto'].value.cuenta)?
                                conceptos.controls['cuentaConcepto'].value.cuenta+' - '
                                + getConcepto(conceptos.controls['cuentaConcepto'].value):''">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectCuenta($event)">
                                    <span *ngFor="let codigo of codigosSelects | async; track option">
                                        <mat-option class="matOpt" [value]="codigo.cuenta+ ' - ' + getConcepto(codigo)">
                                            <span class="columnas">
                                                {{ codigo.cuenta }}&nbsp;-&nbsp;{{ getConcepto(codigo)}}
                                            </span>
                                        </mat-option>
                                    </span>
                                </mat-autocomplete>
                                <mat-error>Cuenta requerida</mat-error>
                            </mat-form-field>




                        </div>
                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Descripción</label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input matInput placeholder="Descripción..." formControlName="descripcion">
                                <mat-error>Descripción requerida</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3 formMaterial">
                            <div class="col-12 autocomplete">
                                <!-- <span class="red">*</span> -->
                                <label class="col-12 formLabel">Lugar/Decorado</label>
                                <mat-form-field appearance="outline" class="col-12 formField auto"
                                    [ngClass]="isReading?'desactivado':''">
                                    <input (keydown)="filterColsLugar($event)" (keyup)="filterColsLugar($event)"
                                        type="text" matInput placeholder="Lugar/Decorado..." formControlName="lugar"
                                        name="autocompletename" [matAutocomplete]="auto2" class="auto">
                                    <!-- (optionSelected)="selectProveedor($event)" -->
                                    <mat-autocomplete #auto2="matAutocomplete">
                                        <span *ngFor="let tipo of filterLugar()">
                                            <mat-option [value]="tipo"><span class="columnas">
                                                    {{tipo}}</span></mat-option>
                                        </span>
                                    </mat-autocomplete>
                                    <mat-error>Lugar requerido</mat-error>
                                </mat-form-field>
                            </div>
                        </div>



                        <div class="col-3 formMaterial">

                            <div class="col-12 d-flex">

                                <div class="col-6 padded-formField">
                                    <label class="col-12 formLabel">Fecha Inicio <span class="red">*</span></label>
                                    <mat-form-field appearance="outline" class="col-12 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input matInput [matDatepicker]="picker"
                                            [max]="conceptos.get('fechaFin')?.value" formControlName="fechaInicio"
                                            (click)="picker.open()" placeholder="Fecha inicio...">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"
                                            disabled="{{isReading}}"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="{{isReading}}"></mat-datepicker>
                                        <mat-error>Fecha requerida</mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-6 ">
                                    <label class="col-12 formLabel">Fecha Fin <span class="red">*</span></label>
                                    <mat-form-field appearance="outline" class="col-12 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input matInput [matDatepicker]="picker1"
                                            [min]="conceptos.get('fechaInicio')?.value" formControlName="fechaFin"
                                            (click)="picker1.open()" placeholder="Fecha fin...">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                            disabled="{{isReading}}"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 [startAt]="conceptos.get('fechaInicio')?.value"
                                            disabled="{{isReading}}"></mat-datepicker>
                                        <mat-error>Fecha requerida</mat-error>
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>




                        <div formArrayName="unidadesOc" class="col-3 formMaterial">
                            <div [formGroupName]="0" class="col-12">
                                <div class="col-12 d-flex">
                                    <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">Tipo Cantidad <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField"
                                            [ngClass]="isReading?'desactivado':'desactivado'">
                                            <mat-select matInput placeholder="Tipo Unidad..."
                                                formControlName="tipoUnidad" [compareWith]="compararObjetos"
                                                (selectionChange)="checkIsTa($event,0)">
                                                <mat-option *ngFor="let tipoUnidad of checkOptionUnidades(0)"
                                                    [value]="tipoUnidad">
                                                    <span class="columnas"> {{tipoUnidad.nombre}}</span></mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Cantidad <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField"
                                            [ngClass]="isReading?'desactivado':''">
                                            <input type="text" mask="separator.2" thousandSeparator="."
                                                (keyup)="changeDec($event,unidadesOc.controls[0].get('unidades'));"
                                                decimalMarker="," matInput placeholder="Unidades..."
                                                formControlName="unidades" class="auto">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="col-3 formMaterial">
                            <div class="rowForm">
                            <div class="col-6 padded-formField">
                            <div formArrayName="unidadesOc">
                                <div [formGroupName]="1" class="col-12">

                                    <label class="col-12 formLabel">Unidades <span class="red">*</span></label>
                                    <mat-form-field appearance="outline" class="col-12 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input type="text" mask="separator.2" thousandSeparator="."
                                            (keyup)="changeDec($event,unidadesOc.controls[1].get('unidades'));"
                                            decimalMarker="," matInput placeholder="Unidades..."
                                            formControlName="unidades" class="auto">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 ">
                            <label class="col-12 formLabel">Precio Unitario <span class="red">*</span></label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input type="text" mask="separator.2" thousandSeparator="."
                                    (keyup)="changeDec($event,conceptos.get('precio'));" decimalMarker="," matInput
                                    placeholder="Precio Unitario..." formControlName="precio">
                                <mat-error
                                    *ngIf="this.conceptos.controls['precio'].errors?.['pattern']!= undefined">Introduzca
                                    un número
                                </mat-error>
                                <mat-error
                                    *ngIf="this.conceptos.controls['precio'].errors?.['required']!= undefined">Precio
                                    requerido</mat-error>
                            </mat-form-field>
                        </div>
                            </div>
                    </div>
                    <div class="col-3 formMaterial">
                       
                        <label class="col-12 formLabel">Base</label>
                        <mat-form-field appearance="outline" class="col-12 formField disabled"
                            [ngClass]="isReading?'desactivado':'auto'">
                            <input matInput placeholder="Suma..." formControlName="aux" class="input-right"
                            [value]="getSuma()?(getSuma()|numberEsp):''">
                        </mat-form-field>

                    </div>
                        <div class="col-3 formMaterial multipleField">
                            <div class="rowForm">
                            <div class="col-4 padded-formField">
                                <label class="col-12 formLabel">Moneda</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input type="text" matInput formControlName="moneda"
                                        placeholder="Moneda..."></mat-form-field>
                            </div>
                            <div class="col-4 padded-formField ">
                                <label class="col-12 formLabel">Cambio</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input type="text" mask="separator.2" thousandSeparator="." decimalMarker="," matInput
                                        formControlName="cambio" placeholder="Cambio..."></mat-form-field>
                            </div>

                            <div class="col-4 ">
                                <label class="col-12 formLabel">Base Conversión</label>
                                <mat-form-field appearance="outline" class="col-12 formField"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput placeholder="Conversión..." formControlName="aux"
                                        [value]="getConversion()?(getConversion()|numberEsp):''">
                                </mat-form-field>
    
                            </div>
                        </div>
                        </div>


                        <div class="col-3 formMaterial multipleField">
                            <div class="rowForm">
                                <div class="col-4 padded-formField">
                                    <label class="col-12 formLabel">Impuesto</label>
                                    <mat-form-field appearance="outline" class="col-12 formField">
                                        <mat-select formControlName="tipoImpuesto" matInput fo placeholder="Impuesto..."
                                            [compareWith]="compararObjetos" (selectionChange)="CambiarIva()">
                                            <mat-option *ngFor="let tipo of invSN" [value]="tipo">
                                                {{ tipo}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>Impuesto requerido</mat-error>
                                    </mat-form-field>
                                </div>
                            <div class="col-8 ">
                                
                                    <label class="col-12 formLabel">% {{PlaceHolderIva}} <span
                                            class="red">*</span></label>
                                    <div class="col-12 d-flex">
        
                                        <div class="col-6 padded-formField">
                                            <mat-form-field appearance="outline" class="col-12 formField"
                                                [ngClass]="isReading?'desactivado':''">
                                                <input type="number" matInput placeholder="% {{PlaceHolderIva}}"
                                                    formControlName="iva">
                                                <mat-error
                                                    *ngIf="this.conceptos.controls['iva'].errors?.['pattern']!= undefined">
                                                    El IRPF tiene que estar entre 0 y 100 </mat-error>
                                                <mat-error
                                                    *ngIf="this.conceptos.controls['iva'].errors?.['required']!= undefined">IVA
                                                    requerido</mat-error>
                                            </mat-form-field>
        
                                        </div>
                                        <div class="col-6 ">
                                            <mat-form-field appearance="outline" class="col-12 formField disabled"
                                                [ngClass]="isReading?'desactivado':'auto'">
                                                <input matInput formControlName="aux"
                                                    [value]="get('iva')?(get('iva')|numberEsp):''">
                                            </mat-form-field>
        
                                        </div>
                                    </div>
                                

                            </div>

                   
                        </div>
                        </div>  




                      

                        <div class="col-3 formMaterial" >
                            <label class="col-12 formLabel">% IRPF <span class="red">*</span></label>
                            <div class="col-12 d-flex">

                                <div class="col-6 padded-formField">
                                    <mat-form-field appearance="outline" class="col-12 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input type="number" matInput placeholder="% IRPF..." formControlName="irpf">
                                        <mat-error
                                            *ngIf="this.conceptos.controls['irpf'].errors?.['pattern']!= undefined">
                                            El IRPF tiene que estar entre 0 y 100 </mat-error>
                                        <mat-error
                                            *ngIf="this.conceptos.controls['irpf'].errors?.['required']!= undefined">IRPF
                                            requerido</mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-6"> 
                                    <mat-form-field appearance="outline" class="col-12 formField disabled"
                                        [ngClass]="isReading?'desactivado':'auto'">
                                        <input matInput formControlName="aux"
                                            [value]="get('irpf')?(get('irpf')|numberEsp):''">
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Total</label>
                            <mat-form-field appearance="outline" class="col-12 formField disabled"
                                [ngClass]="isReading?'desactivado':'auto'">
                                <input matInput placeholder="Total..." formControlName="aux" class="input-right"
                                    [value]="getTotal()?(getTotal()|numberEsp):''">
                            </mat-form-field>
                        </div>
                    </div>
                    <span *ngIf="!isReading">
                        <div class="col-12 cuadroBotones">
                            <button class="buttonSquared buttonPrimary buttonMargins" (click)="addConcepto()">
                                {{'Guardar Concepto'}}
                            </button>
                        </div>
                    </span>
                </div>
               

            </div>


            <div class="col-12  form-surrouded-white" [formGroup]="pagoOc" (change)="onFormChange()"
                *ngIf="!isConcepto && isFormaPago && !idFacturasOC  || isExporting" [@slideInOut]="isFormaDePagoExpanded"
                [ngClass]="{'form-surrouded2': this.isFormaDePagoExpanded != 'in'}">
                <h6 class="encabezados padded-col">Formas de pago</h6>
                <div class="col-12 d-flex">
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Tipo de Pago <span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput placeholder="Tipo de Pago..." formControlName="tipoPagoOc"
                                [compareWith]="compararObjetos" (selectionChange)="changeTipoPago()">
                                <mat-option *ngFor="let tipoPagoOc of tiposPagoOC" [value]="tipoPagoOc">
                                    <span class="columnas"> {{tipoPagoOc.nombre}}</span></mat-option>
                            </mat-select>
                            <mat-error>Tipo de pago requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial" *ngIf="pagoOc.controls['tipoPagoOc'].value.id == 1">
                        <label class="col-12 formLabel">Periocidad del Pago</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput placeholder="Periocidad del Pago..." formControlName="periodicidadPago"
                                [compareWith]="compararObjetos" (selectionChange)="getFechasPago()">
                                <mat-option *ngFor="let preioPagoOc of periocidadPagoOC" [value]="preioPagoOc">
                                    <span class="columnas"> {{preioPagoOc.nombre}}</span></mat-option>
                            </mat-select>
                            <mat-error>Periocidad del Pago requerida</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3 formMaterial"
                        *ngIf="pagoOc.controls['tipoPagoOc'].value.id == 1||pagoOc.controls['tipoPagoOc'].value.id == 4">
                        <label class="col-12 formLabel">Número de Pagos</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput placeholder="Número de Pagos..."
                                (keyup)="pagoOc.controls['tipoPagoOc'].value.id == 1?getFechasPago():getPlazos()"
                                (keydown)="pagoOc.controls['tipoPagoOc'].value.id == 1?getFechasPago():getPlazos()"
                                formControlName="numeroPagos">
                            <mat-error>Número de Pagos requerido</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial"
                        *ngIf="pagoOc.controls['tipoPagoOc'].value.id == 1 || pagoOc.controls['tipoPagoOc'].value.id == 3">
                        <label class="col-12 formLabel">{{pagoOc.controls['tipoPagoOc'].value.id == 1?
                            "Fecha Inicio Pagos":"Fecha Pago"}}</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput [matDatepicker]="picker1"
                                (dateChange)="pagoOc.controls['tipoPagoOc'].value.id == 1?getFechasPago():setFechaUnica(fechaService.type(pagoOc.controls['fechaInicio'].value))"
                                formControlName="fechaInicio" (click)="picker1.open()"
                                placeholder="{{pagoOc.controls['tipoPagoOc'].value.id == 1?'Fecha Inicio Pagos...':'Fecha Pago...'}}">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                disabled="{{isReading}}"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="{{isReading}}"></mat-datepicker>
                            <mat-error>Fecha requerida</mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <div class="columnPagos" *ngIf="pagoOc.controls['tipoPagoOc'].value.id == 1">
                    <div formArrayName="fechasPago" class="col-2 formMaterial"
                        *ngFor="let item of fechasPago.value; let i = index">
                        <label class="col-12 formLabel">Pago Nº{{i+1}}</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':'auto'">
                            <input matInput [matDatepicker]="picker1" [value]="fechaService.date(item)"
                                [formControlName]="i" (click)="picker1.open()" placeholder="Pago Nº{{i+1}}...">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                disabled="{{true}}"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="{{true}}"></mat-datepicker>
                            <mat-error>Fecha requerida</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="columnPagos" *ngIf="pagoOc.controls['tipoPagoOc'].value.id == 4">
                    <div class="col-2 formMaterial" formArrayName="fechasPago"
                        *ngFor="let item of fechasPago.controls; let i = index">
                        <label class="col-12 formLabel ">Pago Nº{{i+1}}</label>
                        <mat-form-field appearance="outline" class="col-12 formField">
                            <input matInput [matDatepicker]="picker1" [formControlName]="i"
                                [value]="item.value? fechaService.date(item.value):''" (click)="picker1.open()"
                                placeholder="Pago Nº{{i+1}}..." (dateChange)="setFechPlazos(fechasPago.controls[i],i)">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                disabled="false"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="{{isReading}}"></mat-datepicker>

                            <!-- <mat-icon *ngIf="!isReading && fechasPago.length != 1" class="material-icons pointer eye-b"
                                (click)="removeFechaPago(i)">
                                highlight_off
                            </mat-icon> -->
                        </mat-form-field>

                        <!--     <div class="col-12 ">
                                    <div class="col-12 cuadroBotones" *ngIf="i == fechasPago.length-1 && !isReading">
                                        <button type="button" class="aniadir buttonSecondary" (click)="addFechaPago()"><mat-icon
                                                class="material-symbols-outlined">
                                                add
                                            </mat-icon>Añadir
                                            fila</button>
                                    </div>
            
                                </div> -->
                    </div>

                </div>


                <div class="col-12">

                    <div class="formMaterial col-3">
                        <label class="col-12">Fianza</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input type="text" mask="separator.2" thousandSeparator="."
                                (keyup)="changeDec($event,pagoOc.get('fianza'));" decimalMarker="," matInput
                                placeholder="Fianza..." formControlName="fianza">
                            <mat-error>Fianza requerida</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Fecha Fianza</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput [matDatepicker]="picker" formControlName="fechaFianza"
                                (click)="picker.open()" placeholder="Fecha Fianza...">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"
                                disabled="{{isReading}}"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="{{isReading}}"></mat-datepicker>
                            <mat-error>Fecha requerida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="formMaterial col-12">

                        <label class="col-12">Observaciones</label>
                        <mat-form-field appearance="outline" class="col-12 formTextField"
                            [ngClass]="isReading || isExporting?'desactivado':''">
                            <textarea matInput placeholder="Observaciones..."
                                formControlName="observaciones"></textarea>
                            <mat-error>Observaciones requerido</mat-error>
                        </mat-form-field>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="isExporting">
            <div *ngIf="ordenCompraAux.estado.id == 1 || ordenCompraAux.estado.id == 3" class="estado-export inline_text">
                <div><span>Estado:</span></div>
                <div class="div_circle-aceptada">
                    <span>{{ordenCompraAux.estado.nombre}}</span>
                </div>
            </div>
            <div *ngIf="ordenCompraAux.estado.id == 2 || ordenCompraAux.estado.id == 4"
                class="estado-export inline_text">
                <div><span>Estado:</span></div>
                <div class="div_circle-pendiente">
                    <span>{{ordenCompraAux.estado.nombre}}</span>
                </div>
            </div>
        </div>
        <div class="showFormText" [ngClass]="{'showFormText2': this.isFileExpanded != 'in'}">
            <h6 *ngIf="isFileExpanded === 'out'" class="marginAuto titulosPaginas">Documentación adjunta</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isFileExpanded != 'in'}">
                <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFileExpanded === 'in'}">Mostrar Documentación
                    adjunta
                </p>
                <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFileExpanded === 'out'}">Ocultar</p>
            </div>
        </div>
        <div [@slideInOut]="isFileExpanded" [ngClass]="{'form-surrouded2': this.isFileExpanded != 'in'}">
            <div class="form-surrouded4 mt-3">
                <h6 class="titulosPaginas w-100">Documentación adjunta</h6>
                <div class="displayArchivos " *ngIf="listDocumentos.length != 0">
                    <div class=" col-12 rowForm d-flex" *ngFor="let doc of listDocumentos; let i = index">

                        <div class="padding_tipo col-4 col-xl-2">
                            <label class="col-12 formLabel">Tipo</label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <mat-select matInput placeholder="Tipo..." [compareWith]="compararObjetos"
                                    [disabled]="isReading" [value]="doc.tipo?doc.tipo:''">
                                    <mat-option *ngFor="let tipo of tiposDocumentoOC"
                                        (onSelectionChange)="changeTipoDoc(tipo,i)" [value]="tipo">
                                        <span class="columnas"> {{tipo.nombre}}</span></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-8 col-xl-4 padded-col contenedor_doc">
                            <div *ngIf="!isReading" class="col-5">
                                <button class=" col-12 buttonPrimary buttonDocs"
                                    (click)="$event.preventDefault(); fileInput.click()">Seleccionar archivo</button>
                                <input type="file" #fileInput style="display: none;" (change)="onFileChange($event, i)">
                            </div>
                            <div class="col-7 ">
                                <mat-form-field appearance="outline" class="col-12 formField margin_file_name"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input *ngIf="doc.nombre!= undefined" matInput [disabled]="isReading"
                                        [value]="doc.nombre">
                                    <input *ngIf="doc.nombre== undefined" matInput [disabled]="isReading"
                                        placeholder="Ningún archivo seleccionado">

                                </mat-form-field>
                            </div>
                        </div>

                      
                        <div class="col-10 col-xl-5 formMaterial_des padding_des" >
                            <label class="col-12 formLabel d-flex">Descripción<span
                                    (click)="doc.descripcion= doc.nombre" class="trasladar"> Trasladar el nombre del
                                    archivo</span></label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input matInput placeholder="Descripción..." [disabled]="isReading"
                                    (keyup)="changeDescr($event,i)" [value]="doc.descripcion?doc.descripcion:''">
                            </mat-form-field>
                        </div>

                        <div class="col-2 col-xl-1 acciones_file">
                            <a class="view-button pointer" (click)="viewDoc(doc)"></a>
                            <a class="download-button pointer" (click)="downloadDoc(doc)"></a>
                            <a [ngClass]="isReading? 'display-delete-button':' delete-button pointer'"
                                (click)=" isReading?'':borrarArchivo(doc)"></a>
                        </div>

                        <div *ngIf="doc.gasto_id!=undefined" class="col-3   acciones_button_file ">
                            <p *ngIf="isUpdate" class="ir_gasto" (click)="verGAstoAsociado(doc.gasto_id)">
                                Gasto nº: {{doc.gasto_id}}
                            </p>
                        </div>



                        <div *ngIf="doc.gasto_id!=undefined" class="col-3   acciones_button_file ">
                            <p *ngIf="isUpdate" class="ir_gasto">
                                Gastos: <span *ngFor="let gastoDoc of doc.gasto_id; let i = index"
                                    (click)="verGAstoAsociado(gastoDoc)">
                                    #{{gastoDoc}} <span *ngIf="doc.gasto_id.length-1!= i">, </span> </span>
                            </p>
                        </div>

                    </div>
                    <div *ngIf="!isReading" class="button_add">
                        <button type="button" class="buttonSecondary aniadir"
                            (click)="listDocumentos[listDocumentos.length]={}"><mat-icon
                                class="material-symbols-outlined">
                                add</mat-icon> Añadir fila
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="showFormText" [ngClass]="{'showFormText2': this.isFileProveedortoExpanded != 'in'}">
            <h6 *ngIf="isFileProveedortoExpanded === 'out'" class="marginAuto titulosPaginas">Certificados asociados
                al
                proveedor</h6>


            <div class="text-buttons" [ngClass]="{'text-buttons2': this.isFileProveedortoExpanded != 'in'}">
                <p (click)="this.isFileProveedortoExpanded = this.isFileProveedortoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFileProveedortoExpanded === 'in'}">
                    Mostrar
                    Certificados asociados al proveedor
                </p>
                <p (click)="this.isFileProveedortoExpanded = this.isFileProveedortoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isFileProveedortoExpanded = this.isFileProveedortoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isFileProveedortoExpanded === 'out'}">
                    Ocultar
                </p>
            </div>
        </div>
        <div [@slideInOut]="isFileProveedortoExpanded"
            [ngClass]="{'form-surrouded2': this.isFileProveedortoExpanded != 'in'}">
            <div class="form-surrouded4 mt-3">
                <h6 class="titulosPaginas w-100">Certificados asociados al proveedor</h6>
                <div class="div_certificados">
                    <span class=" d-flex" *ngFor="let tipoDoc of tipoDocumentosProv">
                        <mat-icon *ngIf="checkTipoDoc(tipoDoc)" class="material-symbols-outlined colorBlue">
                            done
                        </mat-icon>
                        <mat-icon *ngIf="!checkTipoDoc(tipoDoc)" class="material-symbols-outlined colorRed">
                            close
                        </mat-icon>
                        <p>{{tipoDoc.nombre}}</p>

                    </span>
                </div>
            </div>

        </div>
        <div class="col-12 cuadroBotones formMaterial mt-3">

            <!--   <span *ngIf="!isReading" [ngClass]="(!isReading && !isUpdate)?'':'formMaterial'" >

                <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                </button>
            </span> -->
            <button *ngIf="isClearForm?limparFormulario():''">
            </button>

            <button *ngIf="isSave?isUpdate?checkEstadoAtu():onSubmit():''">
            </button>
        </div>

    </form>
</div>


<div class="dialog-container" *ngIf="dialogUpdate">
    <div class="dialog-content">
        <div class="contenedortextos">
            <div class="circle_document">
                <a class="warning-button-modal"></a>
            </div>
        </div>
        <div class="contenedortextos">
            <h4 class="color_title_modal"><b>!Alerta! Ya ha sido modificada</b></h4>
        </div>
        <div class="contenedortextos2">
            <p class="text-center">Si actualiza esta orden de compra deberá ser<br>enviada de nuevo para su aprobación
            </p>
            <p class="text-center">¿Desea guardarla y actualizar para su aprobación?</p>
        </div>


        <div class="contenedortextos">
            <!-- <mat-form-field *ngIf="mostrarConceptos.length!=0" appearance="outline" class="col-12 formField">
                <mat-select class="seleccionar_archivo_select" matInput (selectionChange)="cambiarCuenta($event)"
                    placeholder="Seleccione un concepto">
                    <mat-option *ngFor="let item of mostrarConceptos" [value]="item">
                        <span> <b>Cuenta:</b> {{ item.codigo.cuenta }}, <b>Concepto:</b> {{ item.codigo.concepto }},
                            <b> Tipo:</b>{{item.tipoConceptoOc.nombre}}, <b> Importe total: </b>{{item.suma}}
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mx-1 " (click)="onSubmit()">Guardar</button>

            <button (click)="dialogUpdate=false" class="btn btn-primary mx-1 ">Cancelar</button>
        </div>
    </div>
</div>
<div class="dialog-container" *ngIf="modalDataNoComplete">
    <div class="dialog-content">
        <div class="contenedortextos">
            <div class="circle_document">
                <a class="warning-button-modal"></a>
            </div>
        </div>
        <div class="contenedortextos">
            <h4 class="color_title_modal"><b>¡Alerta! Datos incompletos</b></h4>
        </div>
        <div class="contenedortextos2">
            <b class="text-center">¡Alerta! Datos incompletos</b>
            <b  class="text-center" *ngIf="mensajeDocumentosProveedor!= undefined"> {{mensajeDocumentosProveedor}}</b>
            <p class="text-center">¿Desea adjuntarlos?</p>
        </div>


        <div class="contenedortextos">
            <!-- <mat-form-field *ngIf="mostrarConceptos.length!=0" appearance="outline" class="col-12 formField">
                <mat-select class="seleccionar_archivo_select" matInput (selectionChange)="cambiarCuenta($event)"
                    placeholder="Seleccione un concepto">
                    <mat-option *ngFor="let item of mostrarConceptos" [value]="item">
                        <span> <b>Cuenta:</b> {{ item.codigo.cuenta }}, <b>Concepto:</b> {{ item.codigo.concepto }},
                            <b> Tipo:</b>{{item.tipoConceptoOc.nombre}}, <b> Importe total: </b>{{item.suma}}
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mx-1 " (click)="GoToPooveedor()">Aceptar</button>

            <button (click)="modalDataNoComplete=false" class="btn btn-primary mx-1 ">Cancelar</button>
        </div>
    </div>
</div>


<div class="dialog-container" *ngIf="dialogCancel">
    <div class="dialog-content">
        <div class="contenedortextos">
            <div class="circle_document">
                <a class="cancel-button-modal"></a>
            </div>
        </div>
        <div class="contenedortextos">
            <h4 class="color_title_modal"><b>!Alerta¡ Esta rechazando esta orden de compra</b></h4>
        </div>
        <div class="contenedortextos2">
            <p class="text-center">Introduzca un mensaje para informar del motivo</p>
        </div>


        <div>
            <textarea id="miTextarea" placeholder="Introduzca el motivo"></textarea>

        </div>

        <div class="contenedortextos2">
            <p class="text-center">¿Desea guardar y enviar el motivo?</p>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mx-1 " (click)="actualizarEstado(false)">Guardar</button>

            <button (click)="dialogCancel=false" class="btn btn-primary mx-1 ">Cancelar</button>
        </div>

    </div>
</div>


<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>