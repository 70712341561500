import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class PdfExportService {
  constructor() {}
  /* 
  Servicio que se encarga de la generacin de un documento pdf a partir de un componente de angular,
  se le pasa el documento y genera unas imagenes con una altura definida y si la sobrepasa genera una nueva hoja de pdf
  */
  async capturaImagenTabla(
    tableRef: any,
    totalPages: number
  ): Promise<HTMLImageElement[]> {
    return new Promise((resolve, reject) => {
      html2canvas(tableRef)
        .then((canvas) => {
          const images: HTMLImageElement[] = [];
          const totalHeight = canvas.height;
          const fragmentHeight = totalHeight / totalPages;

          // Dividir la imagen en fragmentos
          for (let i = 0; i < totalPages; i++) {
            const startY = i * fragmentHeight;
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = canvas.width;
            tempCanvas.height = fragmentHeight;

            const tempCtx = tempCanvas.getContext('2d');
            tempCtx?.drawImage(
              canvas,
              0,
              startY,
              canvas.width,
              fragmentHeight,
              0,
              0,
              canvas.width,
              fragmentHeight
            );

            const imageData = tempCanvas.toDataURL('image/png');
            const img = new Image();
            img.onload = () => {
              images.push(img);
              if (images.length === totalPages) {
                resolve(images);
              }
            };
            img.src = imageData;
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async exportToPdf(header: HTMLElement, table: HTMLElement, fileName: string) {
    try {
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const padding = 10;
      const imgWidth = pageWidth - 2 * padding;
      let position = padding;

      // Capturar el encabezado
      const headerCanvas = await html2canvas(header, { scale: 2, backgroundColor: '#ffffff' });
      const headerImgData = headerCanvas.toDataURL('image/png');
      const headerImgHeight = (headerCanvas.height * imgWidth) / headerCanvas.width;

      pdf.addImage(headerImgData, 'PNG', padding, position, imgWidth, headerImgHeight);
      position += headerImgHeight + 2.5;

      // Crear un contenedor temporal para los bloques de la tabla
      const tempContainer = document.createElement('div');
      document.body.appendChild(tempContainer);

      // Capturar la tabla en bloques
      const rows = Array.from(table.querySelectorAll('tr'));
      
      // Ajuste dinámico de rowsPerBlock en función del zoom del navegador
      let rowsPerBlock = 54; // Valor inicial
      const zoomFactor = window.innerWidth / window.screen.availWidth;
      if (zoomFactor > 1) {
        rowsPerBlock = Math.floor(rowsPerBlock * zoomFactor);
      }
      
      for (let i = 0; i < rows.length; i += rowsPerBlock) {
        const block = rows.slice(i, i + rowsPerBlock);
        const blockElement = document.createElement('div');
        blockElement.style.left = '0';
        block.forEach(row => blockElement.appendChild(row.cloneNode(true)));
        tempContainer.appendChild(blockElement);

        const blockCanvas = await html2canvas(blockElement, { scale: 2, backgroundColor: '#ffffff' });
        const blockImgData = blockCanvas.toDataURL('image/png');
        const blockImgHeight = (blockCanvas.height * imgWidth) / blockCanvas.width;

        if (position + blockImgHeight > pageHeight - padding) {
          pdf.addPage();
          position = padding;
        }

        pdf.addImage(blockImgData, 'PNG', padding, position, imgWidth, blockImgHeight);
        position += blockImgHeight + padding;
        tempContainer.removeChild(blockElement);
      }

      document.body.removeChild(tempContainer);

      pdf.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  async generatePdf(element: HTMLElement, fileName: string): Promise<void> {
    var padding: number = 10;
    return new Promise<void>((resolve, reject) => {
      html2canvas(element)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 190;
          const pageHeight = 277;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;

          let position = padding;
          pdf.addImage(imgData, 'PNG', padding, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight + padding;
            pdf.addPage();
            pdf.addImage(
              imgData,
              'PNG',
              padding,
              position,
              imgWidth,
              imgHeight
            );
            heightLeft -= pageHeight;
          }

          pdf.save(fileName + '.pdf');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
