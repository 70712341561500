import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermisosProyectoService {

  url: string = environment.API_URL + "/usuario-proyecto-permisos";

  /* 
  Servicio para las peticiones de guardado y getear los permisos de un usuario
  */


  constructor(private http: HttpClient
  ) { }

  savePermisosProyecto(usuario: any, proyecto: any, tipoUsuario: any, permisos: any): Observable<any> {
    console.log(permisos);

    return this.http.post(`${this.url}`, {
      usuario, proyecto, tipoUsuario, permisos
    });
  }
  getPermisosFormPreyectoAndUsu(proyectoId: number, usuarioId: number): Observable<any> {
    let params = new HttpParams().set("proyectoId", proyectoId).set("usuarioId", usuarioId)
    return this.http.get(`${this.url}/obtener-permisos`, { params: params });
  }
}
