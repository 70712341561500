import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { Proveedor } from 'src/app/models/proveedor';
import { Proyecto } from 'src/app/models/proyecto';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';

@Component({
  selector: 'app-formulario-proveeedor',
  templateUrl: './formulario-proveeedor.component.html',
  styleUrls: ['./formulario-proveeedor.component.scss']
})
export class FormularioProveeedorComponent {

  loading: boolean = false;
  id!: number
  proveedorDeOC: boolean = false;

  constructor(
    private proveedorService: ProveedorService,
    private proyectoService: ProyectoService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private nextCloudService: NextcloudServiceService,
    private storageService: StorageServiceService,
    private interfazInicial: InterfazInicialComponent,
    private tableService: TableService
  ) { }

  private formBuilder: FormBuilder = new FormBuilder

  tiposDocumentoProveedor: any

  proyAux: any = []

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Proveedor"
  nombrePagina = "Proveedor"

  form!: FormGroup
  formDocs!: FormGroup
  expandedTelefono = 1

  isAdmin = false
  existProveedor: any
  modalExistProveedor = false

  proyectosList: any = []
  proyectos?: Proyecto[]
  isUpdate: boolean = false
  isReading: boolean = false
  proyectosFinales: any = [];

  proveedor!: Proveedor;

  get telefonos(): FormArray {
    return this.form.get("telefonos") as FormArray;
  }

  get documentos(): FormArray {
    return this.formDocs.get("doc") as FormArray;
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("ModificarProveedor")) {
      this.proveedorDeOC = true;
    }

    if (this.interfazInicial.isSuperAdmin || this.interfazInicial.isLicencia) {
      this.isAdmin = true;
    }

    this.formDocs = new FormGroup({
      doc: new FormArray([])
    })

    this.findAllProyectos()
    this.findAllTiposDoc()

    if (this.tableService.checkAnyAccionByPermiso(19, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    } else {

      this.route.params.subscribe(params => {
        if (params['id']) {
          this.id = params['id'];
          console.log(this.route.url.subscribe(data => {
            if (data[0].path.includes("ver")) {
              this.isReading = true

              if (this.tableService.checkPermiso(19, 1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            } else if (data[0].path.includes("editar")) {
              this.isUpdate = true
              if (this.tableService.checkPermiso(19, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }

            }
          }));
        } else {
          if (this.tableService.checkPermiso(19, 2, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
            this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
            this.router.navigate(["admin-contacine/proyectos"])
          }
        }
      })
    }

    var storedFormData: any = {}
    if (!this.isUpdate && !this.isReading) {
      try {
        storedFormData = JSON.parse(this.storageService.get('proveedorForm') ? this.storageService.get('proveedorForm') : "");
      } catch {

      }
    }

    console.log("storage", storedFormData);

    this.form = new FormGroup({
      cif: new FormControl({ value: storedFormData.cif || "", disabled: this.isReading }, Validators.required,),
      nombreFiscal: new FormControl({ value: storedFormData.nombreFiscal || "", disabled: this.isReading }, Validators.required),
      nombreComercial: new FormControl({ value: storedFormData.nombreComercial || "", disabled: this.isReading }),
      direccion: new FormControl({ value: storedFormData.direccion || "", disabled: this.isReading }),
      codigoPostal: new FormControl({ value: storedFormData.codigoPostal || "", disabled: this.isReading }),
      ciudad: new FormControl({ value: storedFormData.ciudad || "", disabled: this.isReading }),
      personaContacto: new FormControl({ value: storedFormData.personaContacto || "", disabled: this.isReading }),
      proyectos: new FormControl({ value: this.isAdmin ? storedFormData.proyectos || "" : [{ id: this.interfazInicial.proyaux.id }], disabled: this.isReading }, Validators.required),
      bancoIban: new FormGroup({
        iban: new FormControl({ value: storedFormData.bancoIban ? storedFormData.bancoIban.iban : "", disabled: this.isReading }),
        nombreBanco: new FormControl({ value: storedFormData.bancoIban ? storedFormData.bancoIban.nombreBanco : "", disabled: this.isReading }),
      }),
      telefonos: this.camposTelefono(storedFormData),
      email: new FormControl({ value: storedFormData.email || "", disabled: this.isReading }, [Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]),
      web: new FormControl({ value: storedFormData.web || "", disabled: this.isReading }, /*[Validators.required, Validators.pattern(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/)]*/),
    })

    if (this.isUpdate || this.isReading) {

      this.proveedorService.findById(this.id).subscribe({
        next: (value) => {

          console.log("VALUE", value);
          this.getDocumetos(value.documentos)

          this.proveedor = new Proveedor(value)
          this.proveedor.proyectos?.forEach((data) => {
            this.proyAux.push({ id: data.id })
          })

          this.form.controls['telefonos'] = this.camposTelefono(storedFormData)
          this.form.get("cif")?.setValue(this.proveedor.cif)
          this.form.get("ciudad")?.setValue(this.proveedor.ciudad)
          this.form.get("codigoPostal")?.setValue(this.proveedor.codigoPostal)
          this.form.get("direccion")?.setValue(this.proveedor.direccion)
          this.form.get("email")?.setValue(this.proveedor.email)
          this.form.get("nombreComercial")?.setValue(this.proveedor.nombreComercial)
          this.form.get("nombreFiscal")?.setValue(this.proveedor.nombreFiscal)
          this.form.get("personaContacto")?.setValue(this.proveedor.personaContacto)
          this.form.get("proyectos")?.setValue(this.proyAux)
          this.form.get("web")?.setValue(this.proveedor.web)
          this.form.controls['bancoIban'].get("iban")?.setValue(this.proveedor.bancoIban.iban)
          this.form.controls['bancoIban'].get("nombreBanco")?.setValue(this.proveedor.bancoIban.nombreBanco)
        },
      })
    }
  }

  findAllProyectos() {
    this.proyectoService.getAllProyectos().subscribe({
      next: (data) => {
        console.log(data)
        this.proyectosList = data;
      },
      error: (error) => {
        console.log(error)
      },
    });
  }

  findAllTiposDoc() {
    this.proveedorService.getAllTTiposDocs().subscribe({
      next: (data) => {
        console.log(data);
        this.tiposDocumentoProveedor = data
        this.tiposDocumentoProveedor.forEach((tip: any) => {
          this.addDocs(
            "",
            tip, "", "", "", "",
          );
        })
      }
    })
  }

  getDocumetos(documentosUp: any) {
    documentosUp.forEach((doc: any) => {
      this.documentos.controls.forEach((control: any) => {
        if (control.get("tipoDoc").value.nombre == doc.tipo.nombre) {
          this.nextCloudService.getBase64(doc.id_nextcloud).subscribe({
            next: (data) => {
              control.get("nombre").setValue(doc.nombre)
              control.get("base64").setValue(data.b64)
              control.get("descripcion").setValue(doc.descripcion)
              control.get("id_nextcloud").setValue(doc.id_nextcloud)
            }
          })
        }
      })
    });
  }

  camposTelefono(storage: any) {
    var telefonos = this.proveedor?.telefonos;

    if (storage.telefonos) {
      telefonos = storage.telefonos
    }

    var telf
    if (telefonos == null || telefonos.length == 0) {
      telf = new FormArray([new FormControl({ value: "", disabled: this.isReading }, [Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)])])
    }

    else {
      telf = new FormArray([new FormControl({ value: telefonos[0].toString(), disabled: this.isReading })])
      for (let i = 1; i < telefonos.length; i++) {
        telf.push(new FormControl({ value: telefonos[i].toString(), disabled: this.isReading }));
        this.expandedTelefono++;
      }
      return telf
    }
    return telf;
  }

  addTelefono() {
    let lug = this.form.controls['telefonos'] as FormArray;
    lug.push(
      new FormControl('', [Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)]),
    )
    this.expandedTelefono++;
  }

  removeTelefono(i: number) {
    this.telefonos.removeAt(i);
    this.expandedTelefono--;
  }

  saveProveedor() {
    this.isSave = false
    var prov: Proveedor = new Proveedor(this.form.value);

    if (!this.isReading) {
      var documentosEnv: any = []
      this.documentos.controls.forEach((data) => {
        if (data.get("base64")?.value) {
          var aux = {
            nombre: data.get("nombre")?.value,
            tipo: data.get("tipoDoc")?.value,
            descripcion: data.get("descripcion")?.value,
            mime: data.get("mime")?.value,
            base64: data.get("base64")?.value,
            id_nextcloud: data.get("id_nextcloud")?.value ? data.get("id_nextcloud")?.value : undefined,
          }
          documentosEnv.push(aux)
        }
      })

      prov.telefonos = this.telefonos.value;
      prov.bancoIban.iban = this.form.controls['bancoIban'].get("iban")?.value.replaceAll(" ", "");      
      this.form.controls['bancoIban'].get("iban")?.setValue(this.form.controls['bancoIban'].get("iban")?.value.replaceAll(" ", ""))

      if (this.form.valid) {
        if (!this.isUpdate && !this.isReading) {
          this.loading = true;
          this.proveedorService.save({ proveedor: prov, documentos: documentosEnv }).subscribe({
            next: (data) => {
              this.loading = false;
              this.storageService.eliminar("proveedorForm")
              this.notificationService.success("Guardado", "El proveedor se ha guardado correctamente.", 5000);
              this.router.navigate(['admin-contacine/proveedores']);
            },
            error: (err) => {
              this.loading = false;
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }

            }
          })
        } else if (this.isUpdate && !this.isReading) {
          this.loading = true;
          
          this.proveedorService.update(this.id, { proveedor: prov, documentos: documentosEnv }).subscribe({
            next: (data: any) => {
              this.loading = true;
              this.notificationService.success("Guardado", "El proveedor se ha guardado correctamente.", 5000);
              if (this.proveedorDeOC) {
                console.log(data.nombreFiscal)
                sessionStorage.setItem("ModificarProveedor", data.nombreFiscal)
                this.router.navigate(['admin-contacine/nueva-orden-compra']);

              } else {
                this.router.navigate(['admin-contacine/proveedores']);
              }
            },
            error: (err) => {
              this.loading = false;
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })
        }
      } else {
        this.notificationService.warning("ALERTA", "Rellene todos los campos.", 5000)
      }
    } else {
      this.router.navigate(['admin-contacine/proveedores']);
    }
  }

  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  clearDoc(doc: any) {
    doc.get("nombre").setValue("")
    doc.get("mime").setValue("")
    doc.get("base64").setValue("")
    doc.get("id_nextcloud").setValue("")
  }

  getNombreDoc(doc: any): any {
    return doc.nombre
  }

  viewDoc(doc: any) {
    if (doc.get("base64").value) {

      const byteArray = new Uint8Array(atob(doc.get("base64").value).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const opcionesVentana = 'width=800,height=600,scrollbars=yes';

      window.open(url, "_blank", opcionesVentana)

    } else {
      this.notificationService.warning("ALERTA", "No existe el documento.", 5000)
    }
  }

  downloadDoc(doc: any) {
    if (doc.get("base64").value) {
      const linkSource = "data:application/pdf;base64," + doc.get("base64").value;
      const downloadLink = document.createElement("a");
      const fileName = doc.get("nombre").value;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      this.notificationService.warning("ALERTA", "No existe el documento.", 5000)
    }
  }

  onFileChange(event: any, doc: any) {
    console.log(doc);

    if (
      event.target.files[0].type == 'application/pdf' ||
      event.target.files[0].type == 'image/png' ||
      event.target.files[0].type == 'image/jpeg'
    ) {
      const file = event.target.files[0];
      const bits = file.size * 8;
      const bytes = bits / 8;
      const kb = bytes / 1000;
      const mb = kb / 1000;;
      if(mb < 20){
        this.convertFile(file).subscribe((base64) => {
          doc.get("nombre").setValue(event.target.files[0].name)
          doc.get("mime").setValue(event.target.files[0].type)
          doc.get("base64").setValue(base64)
        });
      }
      else{
        this.notificationService.warning(
          'Información',
          'Solo se admite documnetos con tamaño menor a 20 MB.',
          5000
        );
      }
    } else {
      this.notificationService.warning(
        'Información',
        'Solo se admiten archivos del tipo: PDF, PNG y JPEG.',
        5000
      );
    }
  }

  addDocs(nombre: any, tipoDoc: any, descripcion: any, mime: any, base64: any, id_nextcloud: any) {
    console.log(tipoDoc);

    const metadato = this.formBuilder.group({
      nombre: new FormControl({ value: nombre, disabled: this.isReading }),
      tipoDoc: new FormControl({ value: tipoDoc, disabled: true }),
      tipoDocAux: new FormControl({ value: tipoDoc.nombre, disabled: true }),
      descripcion: new FormControl({ value: descripcion, disabled: this.isReading }),
      mime: new FormControl({ value: mime, disabled: this.isReading }),
      base64: new FormControl({ value: base64, disabled: this.isReading }),
      id_nextcloud: new FormControl({ value: base64, disabled: this.isReading }),
    });

    console.log(metadato)
    this.documentos.push(metadato);
  }

  volver() {
    this.router.navigate(['admin-contacine/proveedores'])
  }

  onFormChange() {
    if (!this.isUpdate && !this.isReading) {
      this.storageService.guardar('proveedorForm', JSON.stringify(this.form.value));
    }
  }
  limparFormulario() {
    this.isClearForm = false
    this.storageService.eliminar('proveedorForm');
    this.form.reset()
  }
  checkNifCifExists() {
    if (this.form.get("cif")?.value && !this.isAdmin && !this.isUpdate && !this.isReading) {
      this.proveedorService.findByNifCif(this.form.get("cif")?.value).subscribe({
        next: (data) => {
          this.existProveedor = data
          this.modalExistProveedor = true
        },
        error: (error) => {
          console.log(error);
        }
      })
    }
  }

  asociarProyExit() {
    this.proveedorService.asociarProyUsuExit(this.existProveedor.id, this.interfazInicial.proyaux.id).subscribe({
      next: (data: any) => {

        this.loading = false;
        this.modalExistProveedor = false
        this.router.navigateByUrl("/admin-contacine/editar-proveedor/" + this.existProveedor.id)
        this.notificationService.success('ÉXITO', 'Datos recuperados con éxito.', 5000);
      },
      error: (err) => {
        this.modalExistProveedor = false
        this.notificationService.error('ERROR', 'Proveedor no asociado.', 5000);

      }
    }

    )

  }
}

export class MapAux {
  id!: number;
  public constructor(data?: any, init?: Partial<MapAux>) {
    if (data) {
      this.id = data.id;
    } else {
      Object.assign(this, init);
    }
  }
}