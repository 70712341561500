import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": '*',
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
    "Access-Control-Max-Age": "300"
  })
}
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {



  /* 
  Servicio que realiza todas las peticiones de usuario respecto a su guardado, listado y modigicacion
  */

  url: string = environment.API_URL + "/user";


  constructor(private http: HttpClient
  ) { }

  getCurrentUserRepresented(): Observable<any> {
    return this.http.get(`${this.url}/getRepresented`);
  }
  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.url}/getCurrent`);
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${this.url}/recoverpass`, { email });
  }
  register(nombreUsuario: any, email: any, password: any, departamento: any, proyectos: any, cargo: any,
    direccion: any, codigoPostal: any, ciudad: any, telefono: any, infoProyecto: any, pais: any, dni: any): Observable<any> {
    console.log("infopro", infoProyecto);
    infoProyecto.forEach((data:any)=>{
      data.empresa = data.empresa.nombre
    })
    return this.http.post(`${this.url}/register`, { nombreUsuario, email, password, departamento, proyectos, cargo, direccion, codigoPostal, ciudad, telefono, dni, pais, infoProyecto });
  }
  updateAdmin(id: number, nombreUsuario: any, email: any, departamento: any, proyectos: any, cargo: any,
    direccion: any, codigoPostal: any, ciudad: any, telefono: any, infoProyecto: any, pais: any, dni: any): Observable<any> {
    console.log("infgopro", infoProyecto);
    infoProyecto.forEach((data:any)=>{
      data.empresa = data.empresa.nombre
    })

    return this.http.post(`${this.url}/updateAdmin`, { id, nombreUsuario, email, departamento, proyectos, cargo, direccion, codigoPostal, ciudad, telefono, dni, pais, infoProyecto });
  }
  resetPassword(identificador: string, email: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.url}/restorepass`, { identificador, email, newPassword }, httpOptions);
  }
  checkStateresetPassword(identificador: string): Observable<any> {
    return this.http.post(`${this.url}/checkstaterecover`, { identificador }, httpOptions);
  }
  findById(usuarioId: number): Observable<any> {
    let params = new HttpParams().set("usuarioId", usuarioId)
    return this.http.get(`${this.url}/findById`, { params: params });
  }
  asociarProyUsuExit(usuarioId: number, proyectoId: number) {
    let params = new HttpParams().set("usuarioId", usuarioId).set("proyectoId", proyectoId)
    console.log(params);

    return this.http.get(`${this.url}/asociarExitente`, { params: params });
  }

  findByEmail(value: any) {
    let params = new HttpParams().set("usuarioEmail", value)
    return this.http.get(`${this.url}/findByEmail`, { params: params });
  }

  findAll() {
    return this.http.get(`${this.url}/findAll`);
  }

  filter(filter: any): Observable<any> {
    return this.http.post(`${this.url}/filter`, filter);
  }
  
  update(id: number, direccion: string, codigoPostal: number, ciudad: string, telefono: number, password?: string): Observable<any> {
    return this.http.post(`${this.url}/update`, { id, password, direccion, codigoPostal, ciudad, telefono });
  }
  updateTwofa(id: number, activo: boolean): Observable<any> {
    return this.http.post(`${this.url}/updateTwofa`, { id, activo });
  }
  updateActive(id: number, activo: boolean, permanent: boolean): Observable<any> {
    return this.http.post(`${this.url}/updateActive`, { id, activo, permanent });
  }
  sendNewEmail(id: number, activo: boolean): Observable<any> {
    
    return this.http.post(`${this.url}/sendNewVerificationEmail`, { id,activo });
  }
  updateImgPerfil(id: number, b64: string, mime: string, isDelete: boolean): Observable<any> {
    return this.http.post(`${this.url}/updateImgPerfil`, { id, b64, mime, isDelete });
  }
}
