import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ControlCostesService {
  /* 
  Servicio que se encarga de las peticiones al back para la gestion de control de costes
  */
  API_URL = environment.API_URL + "/control-costes";

  constructor(private http: HttpClient) { }

  pasarGastos(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/pasarGastos/${id}`);
  }
  pasarJustificaciones(id: any): Observable<any> {
    return this.http.get(`${this.API_URL}/pasarJustificaciones/${id}`);
  }

  cerrarCosteSemanal(idProyecto: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cerrarCoste/${idProyecto}`);
  }
}