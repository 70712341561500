<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [isViewPage]="isReading"></app-header-listados>
<div>
    <div class="topInfo">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVO'}} PROVEEDOR</label>
    </div>
    <form [formGroup]="form" (change)="onFormChange()">
        <div class="form-surrouded rowForm">
            <div class="rowForm col-12">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">CIF/NIF<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="cif" placeholder="CIF/NIF..." (blur)="checkNifCifExists()">
                        <mat-error>CIF requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Nombre Fiscal<span class="red">*</span></label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="nombreFiscal" placeholder="Nombre Fiscal...">
                        <mat-error>Nombre Fiscal requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="rowForm col-12">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Nombre Comercial</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="nombreComercial" placeholder="Nombre Comercial...">
                        <mat-error>Nombre Comercial requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Dirección</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="direccion" placeholder="Dirección...">
                        <mat-error>Dirección requerida</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="rowForm col-12">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Código Postal</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input type="number" matInput formControlName="codigoPostal" placeholder="Código Postal...">
                        <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['pattern']!= undefined">
                            La longitud del código postal tiene que ser 5</mat-error>
                        <mat-error *ngIf="this.form.controls['codigoPostal'].errors?.['required']!= undefined">Código
                            Postal requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Ciudad</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="ciudad" placeholder="Ciudad...">
                        <mat-error>Ciudad requerida</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="rowForm col-12">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Email</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="email" placeholder="Email...">
                        <mat-error>Email requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Página Web</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="web" placeholder="Página Web...">
                        <mat-error>Página Web requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="rowForm col-12">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Persona de Contacto</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="personaContacto" placeholder="Persona de Contacto...">
                        <mat-error>Persona de Contacto</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6 formMaterial" formArrayName="telefonos">
                    <div class="col-12" *ngFor="let tel of telefonos.controls; let i = index">
                        <label class="col-4 formLabel ">Teléfonos</label>
                        <mat-form-field appearance="outline" class="col-8 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput type="text" [formControlName]="i" placeholder="Teléfono">
                            <mat-icon *ngIf="!isReading && telefonos.length != 1" class="material-icons pointer eye-b"
                                (click)="removeTelefono(i)">
                                highlight_off
                            </mat-icon>
                            <mat-error *ngIf="this.form.controls['telefonos'].value[i]== ''">
                                El teléfono es requerido</mat-error>
                            <mat-error
                                *ngIf="this.form.controls['telefonos'].status=='INVALID' && this.form.controls['telefonos'].value[i]!= ''">
                                El teléfono no cumple el formato</mat-error>

                        </mat-form-field>

                        <div class="col-12 ">
                            <div class="col-12 cuadroBotones" *ngIf="i == telefonos.length-1 && !isReading">
                                <button type="button" class="aniadir buttonSecondary" (click)="addTelefono()"><mat-icon
                                        class="material-symbols-outlined">
                                        add
                                    </mat-icon>Añadir
                                    fila</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 d-flex rowForm" formGroupName="bancoIban">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Banco</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="nombreBanco" placeholder="Banco...">
                        <mat-error>IBAN requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">IBAN</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput formControlName="iban" placeholder="IBAN...">
                        <mat-error>IBAN requerido</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-12 formMaterial">
                <label *ngIf="isAdmin" class="col-2 formLabel">Proyecto<span class="red">*</span></label>
                <mat-form-field *ngIf="isAdmin" appearance="outline" class="col-10 formField"
                    [ngClass]="isReading?'desactivado':''">
                    <mat-select matInput placeholder="Proyecto..." formControlName="proyectos" multiple
                        [compareWith]="compararObjetos">
                        <mat-option *ngFor="let proyecto of proyectosList" [value]="{id:proyecto.id}">
                            <span class="columnas"> {{proyecto.titulo}}</span></mat-option>
                    </mat-select>
                    <mat-error>Proyecto requerido</mat-error>
                </mat-form-field>
            </div>


            <div class="form-group col-12 cuadroBotones formMaterial" style="display:none;">
                <!--  <span *ngIf="!isReading" [ngClass]="(!isReading && !isUpdate)?'':'formMaterial'">
                    <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                    </button>
                </span> -->

                <button *ngIf="isClearForm?limparFormulario():''">
                </button>

                <button *ngIf="isSave?saveProveedor():''">
                    <span *ngIf="proveedorDeOC">e ir a OC</span>
                </button>
            </div>
        </div>
    </form>

    <div class="col-12 mt-3" *ngIf="tiposDocumentoProveedor && tiposDocumentoProveedor.length != 0">
        <form [formGroup]="formDocs">

            <div class="col-12 form-surrouded rowForm" formArrayName="doc">
                <div class="col-6 formMaterial" *ngFor="let doc of documentos.controls; let i = index">
                    <form [formGroupName]="i" class="col-12 d-flex acciones_button_file">
                        <div class="col-4 ">
                            <label class="col-12 formLabel">{{doc.get("tipoDocAux")?.value}}</label>
                            <!-- <p class="text-docs" *ngIf="doc.get('tipoDoc')?.value.id!=4">(Sólo Autónomos y Persona
                                Física)</p> -->
                            <p class="text-docs" *ngIf="doc.get('tipoDoc')?.value.id==4">(Sólo Extranjeros)</p>
                        </div>
                        <div *ngIf="!isReading" class="col-3 pd-0">
                            <!--  <label class="col-12 formLabel"></label>
                            <button class="buttonPrimary formField">
                                Adjuntar fichero... <input id="file" value="file" name="file" class="pruebaInput"
                                    (change)="onFileChange($event,doc)" onclick="event.target.value=null" type="file" />
                            </button> -->
                            <button class="buttonPrimary buttonDocs"
                                (click)="$event.preventDefault(); fileInput.click()">Seleccionar archivo</button>
                            <input type="file" #fileInput style="display: none;" (change)="onFileChange($event,doc)">
                        </div>
                        <div [ngClass]="isReading?'col-7':'col-4'">
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':'auto'">
                                <input matInput formControlName="nombre" placeholder="Introduzca el nombre (opcional)">
                            </mat-form-field>
                        </div>
                        <!--    <div class="col-4 ">
                            <label class="col-12 formLabel">Descripción</label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <input matInput placeholder="Introduzca la descripcion" formControlName="descripcion">
                            </mat-form-field>
                        </div> -->

                        <div class="acciones_file col-1">
                            <a [ngClass]="!doc.get('base64')?.value? 'display-view-button':' view-button pointer'"
                                (click)=" !doc.get('base64')?.value?'':viewDoc(doc)"></a>
                            <a [ngClass]="!doc.get('base64')?.value? 'display-download-button':' download-button pointer'"
                                (click)=" !doc.get('base64')?.value?'':downloadDoc(doc)"></a>
                            <a [ngClass]="!doc.get('base64')?.value? 'display-delete-button':' delete-button pointer'"
                                (click)=" isReading?'':clearDoc(doc)"></a>
                        </div>
                    </form>
                </div>
                <div class="dialog-container" *ngIf="modalExistProveedor">
                    <div class="dialog-content">
                        <div class="contenedortextos">
                            <div class="circle_document">
                                <a class="document-div"></a>
                            </div>
                        </div>
                        <div class="contenedortextos">
                            <h4 class="colorh4">Este proveedor ya existe</h4>
                        </div>
                        <div class="contenedortextos2">
                            <p>¿Desea asignarle este proyecto?</p>
                        </div>

                        <div class="d-flex justify-content-end">
                            <button (click)="(modalExistProveedor = false)" id="btcancel"
                                class="btn btn-primary mx-1 ">Cancelar</button>
                            <button id="btacept" class="btn btn-primary ml-1">
                                <div></div>
                                <span (click)="asociarProyExit()" class="align-middle"> Confirmar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>