<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [canExport]="!isProductor" [canClose]="!isProductor"></app-header-listados>
<div>

    <div class="contenedor-boton1">
        <h6 class="mih6">Total control de costes </h6>
        <p class="mostrar pointer" (click)="verDetalle()">Mostrar totales Conceptos / </p>
        <p class="ocultar pointer" (click)="verDetalle()">Ocultar</p>
    </div>

    <div class="scrollable-div" [@slideInOut]="mostrarDetalle" [@.disabled]="true">
            <div class="form-surrouded">
                <div class="cardsblueIni ">
                    <p class="cardtext">Presupuesto Aprobado</p>
                    <p class="cardtextbig" [ngClass]="totalPresAprobado<0?'letra-red':''">
                        {{totalPresAprobado?(totalPresAprobado|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue ">
                    <p class="cardtext">Presupuesto Estimado</p>
                    <p class="cardtextbig" [ngClass]="totalPresEstimado<0?'letra-red':''">
                        {{totalPresEstimado?(totalPresEstimado|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue  ">
                    <p class="cardtext">Registro Semanal</p>
                    <p class="cardtextbig" [ngClass]="totalSemAnterior<0?'letra-red':''">
                        {{totalSemAnterior?(totalSemAnterior|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue ">
                    <p class="cardtext">Registro Completo</p>
                    <p class="cardtextbig" [ngClass]="totalCosActual<0?'letra-red':''">
                        {{totalCosActual?(totalCosActual|numberEsp):'0'}} €</p>
                </div>

                <div class="cardsblue ">
                    <p class="cardtext">Saldo Orden Compra</p>
                    <p class="cardtextbig" [ngClass]="totalSaldOC<0?'letra-red':''">
                        {{totalSaldOC?(totalSaldOC|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue">
                    <p class="cardtext">Resto Pendiente</p>
                    <p class="cardtextbig" [ngClass]="totalPendienteGasto<0?'letra-red':''">
                        {{totalPendienteGasto?(totalPendienteGasto|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue">
                    <p class="cardtext">Proyección Cierre</p>
                    <p class="cardtextbig" [ngClass]="totalPoryCierre<0?'letra-red':''">
                        {{totalPoryCierre?(totalPoryCierre|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue">
                    <p class="cardtext">Desviación Actual</p>
                    <p class="cardtextbig" [ngClass]="totalDesvAct<0?'letra-red':''">
                        {{totalDesvAct?(totalDesvAct|numberEsp):'0'}} €</p>
                </div>
        </div>
    </div>
    <div class=" table_container">
        <label class="titulosPaginas"> CONTROL DE COSTES </label>
        <p class="mostrar pointer" (click)="panelsOpen=true">Mostrar conceptos / </p>
        <p class="ocultar pointer" (click)="panelsOpen=false">Ocultar</p>
    </div>

    <div>
        <div class="fondoblanco">
            <button *ngIf="isClearForm?export():''">Exportar
            </button>
            <!-- <div class="contenedor-principal">
                <div class="contenedor-principal-izq">


                    <div appearance="outline" class="contenedor-boton3">
                        <mat-form-field appearance="outline" class="w8 formField">
                            <mat-select placeholder="Mostrar 10" disabled="true">
                                <mat-option value="10">Mostrar 10</mat-option>
                                <mat-option value="25">Mostrar 25</mat-option>
                                <mat-option value="50">Mostrar 50</mat-option>
                                <mat-option value="100">Mostrar 100</mat-option>
                                <mat-option value="150">Mostrar 150</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="buscadorHeader formField">
                            <input disabled="true" matInput placeholder="Filtrar todo:">
                        </mat-form-field>

                    </div>
                    <div class="contenedor-boton2">
                        <button class="buttonsAccions botVolver col-5" type="button" (click)="goBack()">Volver</button>
                        <button *ngIf="isClearForm?export():''">Exportar
                        </button>
                         <button class="buttonsAccions buttonSquaredWhiteright col-4">Importar</button>
                    </div>
                </div>

                <div class="contenedor-boton">
                    <button *ngIf="isSave?cerrarCuenta():''"></button>
                </div>

                <div class="contenedorButtonPres">
                    <button class="buttonPres" (click)="onSubmit()" type="button">Nuevo Presupuesto</button>
                    <mat-form-field class="select-button">
                        <mat-select [(value)]="opcionSeleccionada" (selectionChange)="setAction($event)" hidden>
                            <mat-option value="cerrarPresupuesto">Cerrar presupuesto</mat-option>
                            <mat-option value="validarCambios">Validar cambios</mat-option>
                            <mat-option value="eliminarPresupuesto">Eliminar presupuesto</mat-option>
                            <mat-option value="pasarACastflow">Pasar presupuesto a Castflow</mat-option>
                        </mat-select>
                      
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>

                </div>

            </div> -->



            <div class="form-surrouded-presup">
                <!-- <div> -->

                <div class="rowForm tabla">
                    <form class="col-12" [formGroup]="form" >

                        <div class="col-12 data_table data_table" formArrayName="codigos">

                            <div class="col-12 formMaterial  headerPresuList">
                                <div class=" dflex">
                                    <label class="uppercase col1 descp">Cuenta</label>
                                    <label class="uppercase col2 descp">Descripción</label>
                                    <label class="uppercase centro">Presupuesto Aprobado</label>
                                    <label class="uppercase centro">Presupuesto Estimado</label>
                                    <label class="uppercase centro">Registro Semanal</label>
                                    <label class="uppercase centro">Registro Completo</label>
                                    <label class="uppercase centro">Saldo Orden Compra</label>
                                    <label class="uppercase centro">Resto Pendiente</label>
                                    <label class="uppercase centro ">Proyección Cierre</label>
                                    <label class="uppercase centro ">Desviación Total</label>


                                </div>
                            </div>

                            <div *ngFor="let cap of arrayCaps" class="w-100 pointer presuListContent">
                                <mat-expansion-panel [expanded]="panelsOpen" (opened)="isOpened = true"
                                    (closed)="isOpened = false" [@.disabled]="true">

                                    <mat-expansion-panel-header class="specific-class color-head-cap"
                                        [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
                                        <mat-panel-title class="d-flex justify-content-left col-12">
                                            <label class="fontCapitulos let-head col3">{{cap}}</label>
                                            <label
                                                [ngClass]="calcTotalPresAprobado(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2">{{(calcTotalPresAprobado(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalPresAprobado(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalPresEstimado(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2">{{calcTotalPresEstimado(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalPresEstimado(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalSemAnterior(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2">{{calcTotalSemAnterior(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalSemAnterior(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalCosActual(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2">{{calcTotalCosActual(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalCosActual(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalSaldOC(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2  marg_left">{{calcTotalSaldOC(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalSaldOC(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalPendienteGasto(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2  marg_left">{{calcTotalPendienteGasto(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalPendienteGasto(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalPoryCierre(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2  marg_left2">{{calcTotalPoryCierre(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalPoryCierre(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                            <label
                                                [ngClass]="calcTotalDesvAct(getArrayByCap(this.costesPorCapitulo[cap]))<0?'letra-red':''"
                                                class="fontCapitulos numberInput col1_2  marg_left2">{{calcTotalDesvAct(getArrayByCap(this.costesPorCapitulo[cap]))
                                                ?(calcTotalDesvAct(getArrayByCap(this.costesPorCapitulo[cap]))|numberEsp)+'€':''}}</label>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>


                                    <div class="col-12">
                                        <span *ngFor="let subcap of this.array">

                                            <span
                                                *ngIf="this.costesPorCapitulo[cap] && this.costesPorCapitulo[cap][subcap]">


                                                <mat-expansion-panel [expanded]="panelsOpen" (opened)="isOpened = true"
                                                    (closed)="isOpened = false" [@.disabled]="true">
                                                    <!-- <mat-expansion-panel (opened)="isOpened = true" (closed)="isOpened = false" style="background-color: #c7d5f9;"> -->
                                                    <mat-expansion-panel-header class="specific-class color-head"
                                                        [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
                                                        <mat-panel-title class="d-flex justify-content-left col-12">
                                                            <p class="let-head fontCapitulos col1">{{subcap.split(",")[0]|
                                                                codigosCuentas}}</p>
                                                            <p class="let-head fontCapitulos col-2">{{subcap.split(",")[1]}}</p>

                                                            <label
                                                                [ngClass]="calcTotalPresAprobado(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput">{{(calcTotalPresAprobado(this.costesPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalPresAprobado(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalPresEstimado(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput">{{calcTotalPresEstimado(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalPresEstimado(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalSemAnterior(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput">{{calcTotalSemAnterior(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalSemAnterior(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalCosActual(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput">{{calcTotalCosActual(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalCosActual(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalSaldOC(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput  marg_left">{{calcTotalSaldOC(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalSaldOC(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalPendienteGasto(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput  marg_left">{{calcTotalPendienteGasto(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalPendienteGasto(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalPoryCierre(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput  marg_left2">{{calcTotalPoryCierre(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalPoryCierre(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                            <label
                                                                [ngClass]="calcTotalDesvAct(this.costesPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col1_2 fontCapitulos numberInput  marg_left2">{{calcTotalDesvAct(this.costesPorSubcapitulo[subcap])
                                                                ?(calcTotalDesvAct(this.costesPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>


                                                    <div class="tam col-12"
                                                        *ngFor="let codigo of  this.costesPorCapitulo[cap][subcap]">

                                                        <form [formGroupName]="codigo.index" class="col-12">
                                                            <div class="col-12 formMaterial content-form">
                                                                <div class="col-12">
                                                                    <label class="col1 descp-ab">{{codigo.codigo.cuenta
                                                                        +""+(codigo.codigo.codigoHijo?codigo.codigo.codigoHijo:"")
                                                                        |
                                                                        codigosCuentas}}</label>
                                                                    <label
                                                                        class="col-2 descp-ab two-line-limit"
                                                                        [matTooltip]="mostrarTooltip(codigo.codigo) ? getConcepto(codigo.codigo) : ''" 
                                                                        matTooltipClass="tooltip-class">{{ getConcepto(codigo.codigo) }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.presupuestoAprobado<0?'letra-red':''"
                                                                        class="col1_2 numberInput">{{codigo.presupuestoAprobado?(codigo.presupuestoAprobado|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.presupuestoEstimado<0?'letra-red':''"
                                                                        class="col1_2 numberInput">{{codigo.presupuestoEstimado?(codigo.presupuestoEstimado|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.costeSemanaAnterior<0?'letra-red':''"
                                                                        class="col1_2 numberInput">{{codigo.costeSemanaAnterior?(codigo.costeSemanaAnterior|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label (click)="navegarAGasto(codigo.codigo)"
                                                                        [ngClass]="codigo.costeActual<0?'letra-red':''"
                                                                        class="col1_2 numberInput">{{codigo.costeActual?(codigo.costeActual|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label (click)="navegarAOc(codigo.codigo)"
                                                                        [ngClass]="codigo.saldoOrdenCompra<0?'letra-red':''"
                                                                        class="col1_2 numberInput marg_left">{{codigo.saldoOrdenCompra?(codigo.saldoOrdenCompra|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.restoPendienteGasto<0?'letra-red':''"
                                                                        [ngClass]="codigo.restoPendienteGasto<0?'letra-red':''"
                                                                        class="col1_2 numberInput marg_left">{{codigo.restoPendienteGasto?(codigo.restoPendienteGasto|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.proyeccionCierre<0?'letra-red':''"
                                                                        class="col1_2 numberInput marg_left2">{{codigo.proyeccionCierre?(codigo.proyeccionCierre|numberEsp)+'€':""
                                                                        }}</label>
                                                                    <label
                                                                        [ngClass]="codigo.desviacionActual<0?'letra-red':''"
                                                                        class="col1_2 numberInput marg_left2">{{codigo.desviacionActual?(codigo.desviacionActual|numberEsp)+'€':""
                                                                        }}</label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </mat-expansion-panel>
                                            </span>
                                        </span>

                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                        <div class="col-12 cuadroBotones">
                            <!-- <button class="btn btn-primary" (click)="goBack()">
                                Volver
                            </button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>