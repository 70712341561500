import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CuentaListar } from 'src/app/models/cuenta';
import { CuentaService } from 'src/app/services/cuenta.service';
import { FilterService } from 'src/app/services/filter.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import {
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-listado-cuentas',
  templateUrl: './listado-cuentas.component.html',
  styleUrls: ['./listado-cuentas.component.scss'],
})

//TODO: PAGINATOR
export class ListadoCuentasComponent {
  nombrePagina = 'CUENTAS';
  rutaNuevo = 'admin-contacine/nueva-cuenta';
  nombreBoton = 'Nueva Cuenta';
  evento: any = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  totalElements = 0;
  page = '0';
  filters: any[] = [];
  pageSize = '99999';
  sorts: any[] = [{ key: 'cuenta', direction: 'ASC' }];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };

  isFirst: boolean = true;

  mostrarPopup = 'out';
  mostrarDetalle = 'in';

  listaCuent: any = [];
  dataExport: any;

  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};

  constructor(
    private router: Router,
    private cuentaService: CuentaService,
    public interfazInicial: InterfazInicialComponent,
    private filterService: FilterService,
    public tableService: TableService,
    public notificationService: NotificationService
  ) { }
  ngOnInit(): void {
    if (
      this.tableService.checkAnyAccionByPermiso(
        23,
        this.interfazInicial.isSuperAdmin,
        this.interfazInicial.permisos
      )
    ) {
      this.notificationService.error(
        'ERROR',
        'No tienes permisos suficientes.',
        5000
      );
      this.router.navigate(['admin-contacine/proyectos']);
    }

    this.tableService.subscribeToProyectoChanges(() => {
      this.getFilterProyecto();
      this.getCuentasList();
    });
    this.getFilterProyecto();
    this.getCuentasList();
  }

  getFilterProyecto() {
    if (
      !this.interfazInicial.isSuperAdmin &&
      !this.interfazInicial.isLicencia
    ) {
      if (this.filters[0] != undefined) {
        if (this.filters[0].operator == 'CONTAINS_ID_OR_NULL') {
          this.filters.shift();
        }
      }
      this.filters = [];
      this.filters.unshift({
        field_type: 'STRING',
        key: 'proyectos.id',
        operator: 'CONTAINS_ID_OR_NULL',
        value: this.interfazInicial.proyaux.id,
      },
      {
        field_type: 'INTEGER',
        key: 'version',
        operator: 'LIKE',
        value: this.interfazInicial.proyaux.versionCodigos,
      },
      {
        field_type: 'DATE',
        key: 'fechaBaja',
        operator: 'IS_NULL'
      },
      );
    } else {
      this.filters = [];
    }
    this.filter.filters = this.filters;
  }

  /**
   * Método para iniciar las listas para los campos de mostrar/ocultar
   */
  iniciarListas() {
    this.displayedColumnscompleta = [];
    if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
      var a: any = sessionStorage.getItem(this.nombrePagina)
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {

      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }

    this.actualizarTabla();
  }
  /**
   * Actualiza la tabla según las columnas mostradas
   */
  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }
  /**
   * Método que se encarga de la llamada al filterService y luego lista las cuentas filtradas
   * @param event El evento que activa el filtro
   * @param type El tipo de filtro
   * @param operator El operador de filtro
   * @param field_type El tipo de campo
   */
  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filters,
      null
    );
    this.page = '0';
    this.getCuentasList();
  }
  /**
   * Muestra u oculta un modal
   * @param mostrar El valor que indica si se debe mostrar u ocultar el modal
   */
  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }

  /**
   * Muestra todos los campos en la tabla
   */
  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }

  /**
   * Muestra el detalle
   * @param detalle El campo a mostrar
   */
  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }

  /**
   * Prepara el filtro genérico y luego lista las cuentas filtradas
   */
  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAA");
      
      this.pageSize = this.evento.pageSize;
      this.page = '0';
      this.filter = {
        filters: [],
        page: this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      console.log("mostrar",this.filter);
      
      this.getCuentasList();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filters.push(
          {
            operator: operator,
            value: this.evento,
            values: [
              'Capitulo.nombre',
              'Subcapitulo.nombre',
              'Subcapitulo.codigo',
              'Proyecto.titulo',
            ],
          },
        );
        this.filter = {
          filters: this.filters,
          page: this.page,
          size: this.pageSize,
          sorts: this.sorts,
        };
        this.getCuentasList();
      } else {
        this.filters = [];
        if (!this.interfazInicial.isSuperAdmin) {
          this.filters = [];
          this.filters = [
            {
              field_type: 'STRING',
              key: 'proyectos.id',
              operator: 'CONTAINS_ID_OR_NULL',
              value: this.interfazInicial.proyaux.id,
            },
            {
              field_type: 'INTEGER',
              key: 'version',
              operator: 'LIKE',
              value: this.interfazInicial.proyaux.versionCodigos,
            }
          ];
        }
        this.getCuentasList();
      }
    }
  }

  /**
   * Ordena las cuentas según la propiedad especificada
   * @param property La propiedad por la que ordenar las cuentas
   * @param sorts Las opciones de ordenamiento adicionales
   */
  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == property) {
        this.ordenarCampos[property] = !this.ordenarCampos[property];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.sorts = [{ key: property, direction: orden }];

    this.getCuentasList();
  }

  /**
   * Captura el evento del tamaño de la tabla para actualizar su contenido
   * @param event El evento de cambio de tamaño
   */
  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.page = '0';
    this.filter = { filter: this.filter, page: this.page, size: this.pageSize };
    this.getCuentasList();
  }

  /**
   * Metodo que se encarga de llamar a cuentService para el listado filtrado de cuentas
   */
  getCuentasList() {
    
    this.filter = {
      filters: this.filters,
      page: this.page,
      size: this.pageSize,
      sorts: this.sorts,
    };
    console.log(this.filter);
    

    /* Importante resetear todas las listas pero en teoria se podrian copiar y 
    pegar los metodos cambiando el servicio que compruaba el back y los objetos que construyen las listas y objetos*/
    this.cuentaService.filter(this.filter).subscribe({
      next: (data: any) => {
      
        console.log(data);
        
        if (this.ordenarCampos['id'] == undefined) {
          this.ordenarCampos = this.filterService.crearCampos(data);
        }

        this.dataExport = data.content;

        this.listaCuent = [];
        if (data) {
          /* Este es el objeto de la lista cambiando los campos del 
          contructor de orden se cambia los campos de orden en la tabla */
          var aux = new CuentaListar(data.content[0]);
          if (this.isFirst) {
            this.displayedColumns = Object.keys(aux);
            this.displayedColumns.push('acciones');
            this.isFirst = false;
            this.paginator._intl = this.interfazInicial.paginator;

            this.iniciarListas();
          }
          this.totalElements = data.totalElements;

          this.dataSource = data.content;
          this.paginator.pageIndex = data.pageable.pageNumber
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = new MatTableDataSource();
        }
      },
      error: (error) => { },
    });
  }

  /**
   * Método auxiliar que hace que la primera letra de cada palabra se ponga en mayúscula
   * @param text El texto a transformar
   * @returns El texto transformado
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim();

    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  /**
   * Navega a una página específica
   * @param id El ID del usuario
   * @param accion La pagina a navegar
   */
  navegar(id: any, accion: string) {
    this.router.navigate([`admin-contacine/${accion}-cuenta/${id}`]);
  }

  /**
   * Captura el evento de la siguiente página y llama al método que lista las cuentas filtradas para cambiar de página
   * @param event El evento de cambio de página
   */
  nextPage(event: PageEvent) {
    this.page = event.pageIndex + '';
    this.pageSize = event.pageSize + '';

    this.getCuentasList();
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }
}
