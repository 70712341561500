import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter } from '@angular/material/core';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { NotificationService } from 'src/app/services/notification.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { Observable, ReplaySubject, finalize } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { CuentaService } from 'src/app/services/cuenta.service';
import { ProductoraService } from 'src/app/services/productora.service ';
import { OcGastoService } from 'src/app/services/servicios-funcionalidad/oc-gasto.service';
import { FechaService } from 'src/app/services/servicios-funcionalidad/fecha.service';
import { OrdenCompraService } from 'src/app/services/orden-compra.service';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { Usuario } from 'src/app/models/usuario';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import * as moment from 'moment';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-nuevo-gasto',
  templateUrl: './nuevo-gasto.component.html',
  styleUrls: ['./nuevo-gasto.component.scss'],
  animations: [dropdownAnimation],
})
export class NuevoGastoComponent {
  filters = [
    {
      field_type: 'STRING',
      key: 'proyectos.id',
      operator: 'CONTAINS_ID',
      value: this.interfazInicial.proyaux.id,
    },
  ];

  filter = {
    filters: this.filters,
    page: '0',
    sorts: [],
  };

  isCreatePage = true;
  isSave = false;
  isClearForm = false;
  nombreBoton = 'Guardar Gasto';
  nombrePagina = 'Gasto';

  impuestoTipo = 'IVA';
  tiposDocumentoOC: any = [];
  loading = false;
  isSubmitted = true;
  indexPagoActualizar = 0;
  arrayDocuments: any = [{}];
  gastoProductora: any = ['provisional'];
  tipoPago: any = [];
  tipoGasto: any = [];
  isFacturaExpanded: String = 'in';
  isFileExpanded: String = 'in';
  isConceptosExpanded: String = 'in';
  isPagoExpanded: String = 'in';
  mostrarDetalle: String = 'in';
  conceptosSelected: String = 'in';
  formasDePagoSelected: String = 'out';
  facturasOcSelected: String = 'out';
  displayedColumns: string[] = [];
  displayedColumns2: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  matcher = new MyErrorStateMatcher();
  conceptArray: any[] = [];
  pagoArray: any[] = [];
  pagoArrayEnv: any[] = [];
  totalBase: number = 0;
  totalIva: number = 0;
  totalIrpf: number = 0;
  totalGasto: number = 0;
  totalPagado: number = 0;
  totalpendientepago: number = 0;
  cantidadBaseAux: number = 0;
  cantidadPagoAux: number = 0;
  cantidadIvaAux: number = 0;
  cantidadIrpfAux: number = 0;
  cantidadGastoAux: number = 0;
  pagoObjectResto: any;
  mesesDelAno: any = [];
  proveedores: any = [];
  cuentas: any = [];
  cuentasCompletas: any = [];
  ordenesDeCompra: any = [];
  gastoRecibido: any;
  productoras: any = [];
  invSN = ['SI', 'NO'];
  buscarCuentas: string = '';
  isReading = false;
  isEditado = false;
  id: any = null;

  dialogVisible = false;
  dialogVisible2 = false;
  pago!: FormGroup;
  concepto!: FormGroup;
  gasto!: FormGroup;

  documetnoOC: any;
  gastoOc: any;
  mostrarArchivos: any = [];
  mostrarConceptos: any = [];
  proyecto: any;
  currentUser: Usuario = new Usuario();
  lugares: any;
  codigoAux: any;
  conceptoAux: any;
  loadingArchivo: boolean = true;

  @ViewChild('top')
  topElement!: ElementRef;
  cuentaDestino: any;
  cuentasCargo: any = [];
  deparamento: any;
  numFacturaDuplicado: boolean = false;
  minDate: any = undefined;

  ngAfterViewInit(): void {
    if (this.topElement && this.topElement.nativeElement) {
      this.topElement.nativeElement.scrollIntoView();
    }
  }
  ngOnInit(): void {
    if (this.interfazInicial.proyaux.id != undefined) {
      this.lugares = this.interfazInicial.proyaux.lugarDecorado.map(
        (item: any) => item.lugar + ' - ' + item.decorado,
      );

      this.getAllOrdenDeCompra();
    } else {
      this.notificationService.warning(
        'ALERTA',
        'No dispones de un proyecto seleccionado, no podrás rellenar el proveedor ni la orden de compra.',
        5000,
      );
    }

    if (
      this.tableService.checkAnyAccionByPermiso(
        8,
        this.interfazInicial.isSuperAdmin,
        this.interfazInicial.permisos,
      )
    ) {
      this.notificationService.error(
        'ERROR',
        'No tienes permisos suficientes.',
        5000,
      );
      this.router.navigate(['admin-contacine/proyectos']);
    } else {
      this.route.url.subscribe((data) => {
        if (data[0].path.includes('ver')) {
          this.isReading = true;
          this.route.params.subscribe((params) => {
            this.id = params['id'];
            if (!this.isReading) {
              Object.keys(this.gasto.controls).forEach((field) => {
                this.gasto.get(field)?.disable();
              });
            }
          });
          if (
            this.tableService.checkPermiso(
              8,
              1,
              this.interfazInicial.isSuperAdmin,
              this.interfazInicial.permisos,
            )
          ) {
            this.notificationService.error(
              'ERROR',
              'No tienes permisos suficientes.',
              5000,
            );
            this.router.navigate(['admin-contacine/proyectos']);
          }
        } else if (data[0].path.includes('editar')) {
          this.route.params.subscribe((params) => {
            this.id = params['id'];
            this.isEditado = true;
            if (
              this.tableService.checkPermiso(
                8,
                3,
                this.interfazInicial.isSuperAdmin,
                this.interfazInicial.permisos,
              )
            ) {
              this.notificationService.error(
                'ERROR',
                'No tienes permisos suficientes.',
                5000,
              );
              this.router.navigate(['admin-contacine/proyectos']);
            }
          });
        } else if (data[0].path.includes('nuevo')) {
          this.isReading = false;
          this.isEditado = false;

          if (
            this.tableService.checkPermiso(
              8,
              2,
              this.interfazInicial.isSuperAdmin,
              this.interfazInicial.permisos,
            )
          ) {
            this.notificationService.error(
              'ERROR',
              'No tienes permisos suficientes.',
              5000,
            );
            this.router.navigate(['admin-contacine/proyectos']);
          }
        }
      });
    }

    var storedGastoData: any = {};
    var storedConceptoData: any = {};
    var storedPagoData: any = {};

    if (!this.isEditado && !this.isReading) {
      try {
        storedGastoData = JSON.parse(
          this.storageService.get('gastoForm')
            ? this.storageService.get('gastoForm')
            : '',
        );
        storedConceptoData = JSON.parse(
          this.storageService.get('conceptoGastoForm')
            ? this.storageService.get('conceptoGastoForm')
            : '',
        );
        storedPagoData = JSON.parse(
          this.storageService.get('pagoGastoForm')
            ? this.storageService.get('pagoGastoForm')
            : '',
        );
      } catch {}
    }

    this.concepto = new FormGroup({
      id: new FormControl({ value: '', disabled: this.isReading }),
      codigo: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required,
      ),
      concepto: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required,
      ),
      descripcion: new FormControl({ value: '', disabled: this.isReading }),
      numero_oc: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required,
      ),
      oc_importe_pendiente: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      lugar: new FormControl({ value: undefined, disabled: this.isReading }),
      conceptoOc: new FormControl({
        value: undefined,
        disabled: this.isReading,
      }),
      base: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      ivaPorcentaje: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,2}([,.][0-9]{1,2})?$/),
      ]),
      iva: new FormControl({ value: '', disabled: true }, Validators.required),
      irpfPorcentaje: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,2}([,.][0-9]{1,2})?$/),
      ]),
      irpf: new FormControl({ value: '', disabled: true }, Validators.required),
      descuento: new FormControl({ value: '', disabled: this.isReading }),
      total: new FormControl(
        { value: '', disabled: true },
        Validators.required,
      ),
      buscador: new FormControl({ value: '', disabled: this.isReading }),
      cuenta: new FormControl({ value: '', disabled: this.isReading }),
      moneda: new FormControl({ value: 'Euro', disabled: this.isReading }),
      cambio: new FormControl({ value: '1', disabled: this.isReading }),
      conversion: new FormControl({ value: '', disabled: this.isReading }),
    });
    this.pago = new FormGroup({
      id: new FormControl(''),
      fecha_pago: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required,
      ),
      forma_pago: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required,
      ),
      numero_talon: new FormControl({ value: '', disabled: this.isReading }),
      cuenta_cargo: new FormControl({ value: '', disabled: this.isReading }),
      cuenta_destino: new FormControl({ value: '', disabled: this.isReading }),
      descripcion: new FormControl({ value: '', disabled: this.isReading }),
      cantidad: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      pagado: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      concepto: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
    });

    this.gasto = new FormGroup({
      id: new FormControl(''),
      nif: new FormControl(
        {
          value: storedGastoData.proveedor || '',
        },
        [
          Validators.required,
          //Validators.pattern(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/),
        ],
      ),
      nombre: new FormControl(
        {
          value: storedGastoData.proveedor || '',
        },
        Validators.required,
      ),
      numeroFactura: new FormControl(
        {
          value: storedGastoData.numeroFactura || '',
          disabled: this.isReading,
        },
        Validators.required,
      ),
      fecha_factura: new FormControl(
        {
          value: storedGastoData.fecha_factura
            ? moment(storedGastoData.fecha_factura)
            : '',
          disabled: this.isReading,
        },
        Validators.required,
      ),
      tipo_gasto: new FormControl(
        { value: storedGastoData.tipo_gasto || '', disabled: this.isReading },
        Validators.required,
      ),
      gastoProductora: new FormControl(
        {
          value: storedGastoData.gastoProductora || '',
          disabled: this.isReading,
        },
        Validators.required,
      ),
      num_pc: new FormControl(
        { value: storedGastoData.num_pc || '', disabled: true },
        [Validators.required],
      ),
      nombre_pc: new FormControl(
        { value: storedGastoData.nombre_pc || '', disabled: true },
        [Validators.required],
      ),
      periodo_iva: new FormControl(
        { value: storedGastoData.periodo_iva || '', disabled: this.isReading },
        Validators.required,
      ),
      proveedor: new FormControl({
        value: storedGastoData.proveedor || '',
        disabled: this.isReading,
      }),
      productora: new FormControl({
        value: storedGastoData.productora || '',
        disabled: this.isReading,
      }),
      inv: new FormControl({
        value: storedGastoData.inv || '',
        disabled: this.isReading,
      }),
      carbono: new FormControl({
        value: storedGastoData.carbono || '',
        disabled: this.isReading,
      }),
    });

    if (this.isEditado) {
      this.gasto.controls['nif'].disable();
      this.gasto.controls['nombre'].disable();
    }

    if (storedPagoData['0']) {
      this.pagoArrayEnv = [...storedPagoData];

      this.pagoArray = [...storedPagoData];
      this.pagoArray.forEach((element) => {
        if (element.pagado) {
          this.totalPagado = +element.cantidad;
        }
      });
    }
    if (storedConceptoData['0']) {
      this.conceptArray = storedConceptoData;
      this.conceptArray.forEach((element: any) => {
        this.totalBase = this.totalBase + element.base;
        this.totalIrpf = this.totalIrpf + element.irpf;
        this.totalIva = this.totalIva + element.iva;
        this.totalGasto =
          this.totalGasto + element.conversion + element.iva - element.irpf;
      });
      this.dataSource = new MatTableDataSource(this.conceptArray);
      this.gasto.get('nif')?.disable;
      this.gasto.get('nombre')?.disable;
    }
    this.actualizartotal();

    this.documetnoOC = this.ocGastoService.getDocumento();
    this.gastoOc = this.ocGastoService.getOc();
    if (this.documetnoOC != undefined) {
      //this.comprobarDoc()
    }
    this.dateAdapter.setLocale('es-ES');
    this.IniciarListas();
    this.GetTiposGastos();
    this.getTiposPagos();
    this.getAllPeriodosIva();
    // this.actualizartotal();
    this.getAllProveedores();
    // this.filtrarCuentasProyecto();
    this.getAllProductoras();

    if (this.id != null) {
      this.buscarGasto();
    }

    this.currentUser = this.tokenService.getUser();
    this.buscarTipoDocumentos();
    this.getDepartamento();
  }
  valueLug = '';
  filterLugar() {
    return this.lugares.filter((option: any) =>
      option.toLowerCase().includes(this.valueLug),
    );
  }

  filterCols(event: any) {
    try {
      this.valueLug = event.target.value.toLowerCase();
    } catch {
      this.valueLug = '';
    }
  }
  constructor(
    private notificationService: NotificationService,
    private proveedoresService: ProveedorService,
    private cuentaService: CuentaService,
    private productoraService: ProductoraService,
    private dateAdapter: DateAdapter<any>,
    private registroGastosService: RegistroGastosService,
    private router: Router,
    private ocGastoService: OcGastoService,
    public fechaService: FechaService,
    private route: ActivatedRoute,
    private ordenCompraService: OrdenCompraService,
    private nextCloudService: NextcloudServiceService,
    private tokenService: TokenStorageService,
    public interfazInicial: InterfazInicialComponent,
    public tableService: TableService,
    private oredenCompraService: OrdenCompraService,
    private storageService: StorageServiceService,
    public permisosProyectoService: PermisosProyectoService,
  ) {}

  getDepartamento() {
    this.permisosProyectoService
      .getPermisosFormPreyectoAndUsu(
        this.interfazInicial.proyaux.id,
        this.currentUser.id,
      )
      .subscribe({
        next: (data) => {
          this.deparamento = data.departamento;
        },
      });
  }
  actualizarOrdenDeCompra() {
    if (this.gastoOc != undefined) {
      this.cuentaDestino = this.gastoOc.proveedor.bancoIban.iban;
      this.gasto.controls['proveedor'].setValue(this.gastoOc.proveedor);
      if (this.gastoOc.productora) {
        this.gasto.controls['gastoProductora'].setValue({
          id: this.gastoOc.productora.id,
        });
        this.gasto.controls['gastoProductora'].disable();
      }
      this.gasto.controls['nif'].setValue(
        this.BuscarElementoEnLista(this.proveedores, this.gastoOc.proveedor.id),
      );

      if (
        this.gasto.controls['numeroFactura'].value != undefined &&
        this.gasto.controls['numeroFactura'].value != ''
      ) {
        this.isValidFactura();
      }
      this.gasto.controls['nombre'].setValue(
        this.BuscarElementoEnLista(this.proveedores, this.gastoOc.proveedor.id),
      );
      this.gasto.controls['nif'].disable();
      this.gasto.controls['nombre'].disable();

      /*     this.pago.controls[""].setValue()
          this.pago.controls[""].disable()
        */

      /*this.concepto.controls['numero_oc'].setValue(
        this.BuscarElementoEnLista(this.ordenesDeCompra, this.gastoOc.id)
      );
      this.concepto.controls['oc_importe_pendiente'].setValue(
        this.gastoOc.totalConceptos -
        this.gastoOc.totalIrpf +
        this.gastoOc.totalIva
      );*/
    }
    if (this.codigoAux != undefined) {
      this.concepto.controls['codigo'].setValue(
        this.BuscarElementoEnLista(this.cuentas, this.codigoAux.id),
      );
      this.concepto.controls['concepto'].setValue(this.conceptoAux);
    }
    //this.comprobarDoc()
    this.ActualizarOrdenesDeCompraPorProveedor();
  }

  /*
  Busca un gasto por la id recibida 
*/

  buscarGasto() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.registroGastosService
      .findGastoByid(this.id)
      .pipe(
        finalize(() => {
          this.completarCampos();
        }),
      )
      .subscribe(
        (data: any) => {
          this.gastoOc = data.ordenCompra;
          this.documetnoOC = data.documento;
          this.gastoRecibido = data;
        },
        (error) => {},
      );
  }
  buscarTipoDocumentos() {
    this.oredenCompraService.getAllTiposDocumentoOC().subscribe({
      next: (data) => {
        this.tiposDocumentoOC = data;
      },
      error: (err) => {},
    });
  }

  /*
con el gasto que se recibe se rellena el formulario las tablas y los documentos
*/
  completarCampos() {
    this.gasto.controls['id'].setValue(this.gastoRecibido.id);
    this.gasto.controls['proveedor'].setValue(this.gastoRecibido.proveedor);
    this.gasto
      .get('carbono')
      ?.setValue(
        this.BuscarElementoEnLista(this.invSN, this.gastoRecibido.carbono),
      );
    this.gasto
      .get('inv')
      ?.setValue(
        this.BuscarElementoEnLista(this.invSN, this.gastoRecibido.inv),
      );
    this.cuentaDestino = this.gastoRecibido.proveedor.bancoIban.iban;
    this.gasto.controls['gastoProductora'].setValue(
      this.gastoRecibido.productora,
    );
    this.gasto.controls['nif'].setValue(
      this.BuscarElementoEnLista(
        this.proveedores,
        this.gastoRecibido.proveedor.id,
      ),
    );
    this.gasto.controls['nombre'].setValue(
      this.BuscarElementoEnLista(
        this.proveedores,
        this.gastoRecibido.proveedor.id,
      ),
    );
    this.cuentasCargo = this.gastoRecibido.productora.bancosIban;
    this.gasto.controls['numeroFactura'].setValue(
      this.gastoRecibido.numeroFactura,
    );
    this.gasto.controls['fecha_factura'].setValue(
      this.fechaService.date(this.gastoRecibido.fecha_factura),
    );
    this.gasto.controls['tipo_gasto'].setValue(
      this.BuscarElementoEnLista(
        this.tipoGasto,
        this.gastoRecibido.tipo_gasto.id,
      ),
    );
    this.gasto.controls['gastoProductora'].setValue(
      this.BuscarElementoEnLista(
        this.productoras,
        this.gastoRecibido.productora.id,
      ),
    );
    this.gasto.controls['num_pc'].setValue(this.gastoRecibido.num_pc);
    this.gasto.controls['nombre_pc'].setValue(this.gastoRecibido.nombre_pc);
    this.gasto.controls['periodo_iva'].setValue(
      this.BuscarElementoEnLista(
        this.mesesDelAno,
        this.gastoRecibido.periodo_iva.id,
      ),
    );
    this.pagoArrayEnv = this.gastoRecibido.pago;
    let contadorId = 0;
    this.gastoRecibido.conceptos.forEach((element: any) => {
      element['codigo'] = element.codigo;
      element.id = element.id;
      this.totalBase += element.base;
      this.totalIrpf += element.irpf;
      this.totalIva += element.iva;
      this.totalGasto += element.total;
      this.codigoAux = element.codigo;
      this.conceptoAux = element.codigo.concepto;
      this.conceptArray.push(element);
      contadorId++;
    });
    this.gastoRecibido.pago.forEach((element: any) => {
      element.fecha_pago = element.fecha_pago;
      element.forma_pago = this.BuscarElementoEnLista(
        this.tipoPago,
        element.forma_pago.id,
      );
      if (element.pagado) {
        this.totalPagado += element.cantidad;
      }
      this.pagoArray.unshift(element);
    });
    this.arrayDocuments = this.gastoRecibido.documentos;
    if (this.arrayDocuments.length == 0) {
      this.arrayDocuments.push({});
    }
    this.dataSource = new MatTableDataSource(this.conceptArray);
    this.dataSource2 = new MatTableDataSource(this.pagoArray);

    this.actualizartotal();
    this.seleccionarAsociadasaCuentasOc();
  }

  BuscarElementoEnLista(lista: any[], id: any): any {
    let elementodev: any = null;
    lista.forEach((element: any) => {
      if (element.id == id) {
        elementodev = element;
        return;
      }
    });
    return elementodev;
  }

  /**
   * auxiliar para iniciar las listas asociadas a las tablas
   */
  IniciarListas() {
    this.displayedColumns = [
      'codigo',
      'concepto',
      'descripcion',
      'base',
      'moneda',
      'cambio',
      'conversion',
      'ivaPorcentaje',
      'iva',
      'irpfPorcentaje',
      'irpf',
      'total',
      'numero_oc',
      'acciones',
    ];

    this.displayedColumns2 = [
      'Fecha de Pago',
      'Forma de Pago',
      'Nº Talon/Pagaré',
      'Cuenta Cargo',
      'Cuenta Destino',
      'Descipción',
      'Cantidad',
      'Pagado',
      'Acciones',
    ];
  }

  GetTiposGastos() {
    this.registroGastosService.getAllTiposGasto().subscribe({
      next: (data) => {
        this.tipoGasto = data;
      },
      error: (error) => {},
    });
  }

  getAllPeriodosIva() {
    this.registroGastosService.getAllPeriodosIva().subscribe({
      next: (data) => {
        this.mesesDelAno = data;
      },
      error: (error) => {},
    });
  }

  getAllProveedores() {
    this.proveedoresService
      .filter(this.filter)
      .pipe(
        finalize(() => {
          this.actualizarOrdenDeCompra();
        }),
      )
      .subscribe({
        next: (data: any) => {
          this.proveedores = data.content;
          if (!this.proveedores || this.proveedores.length == 0) {
            this.notificationService.warning(
              'ALERTA',
              'No existen proveedores para este proyecto.',
              3500,
            );
          }
        },
      });
  }

  getAllOrdenDeCompra() {
    let filtersOc = [];
    if (
      this.interfazInicial.tipoUsu.id == 5 &&
      this.interfazInicial.depatamentoUsu
    ) {
      filtersOc.push({
        field_type: 'STRING',
        key: 'departamento.id',
        operator: 'LIKE',
        value: this.interfazInicial.depatamentoUsu.id,
      });
    }
    if (this.interfazInicial.tipoUsu.id == 6) {
      filtersOc.push({
        field_type: 'STRING',
        key: 'solicitante.id',
        operator: 'LIKE',
        value: this.interfazInicial.currentUser.id,
      });
    }

    filtersOc.push({
      field_type: 'STRING',
      key: 'proyecto.id',
      operator: 'LIKE',
      value: this.interfazInicial.proyaux.id,
    });

    filtersOc.push({
      field_type: 'STRING',
      key: 'estado.id',
      operator: 'LIKE',
      value: 1,
    });

    filtersOc.push({
      field_type: 'DOUBLE',
      key: 'estado.id',
      operator: 'NOT_EQUAL',
      value: 3,
    });

    let filter = {
      filters: filtersOc,
    };
    this.ordenCompraService
      .filter(filter)
      .pipe(
        finalize(() => {
          this.actualizarOrdenDeCompra();
        }),
      )
      .subscribe({
        next: (data) => {
          this.ordenesDeCompra = data.content;
          if (this.ordenesDeCompra == null) {
            this.notificationService.warning(
              'ALERTA',
              'No existen órdenes de compra o no estan aprobadas y no podrás crear un gasto.',
              5000,
            );
          }
        },
        error: (error) => {},
      });
  }

  filtrarCuentasProyecto() {
    let filters: any[] = [
      {
        field_type: 'STRING',
        key: 'proyectos.id',
        operator: 'CONTAINS_ID_OR_NULL',
        value: this.interfazInicial.proyaux.id,
      },
    ];
    let pageSize: any = 99999;
    let page: any = 0;
    let sorts: any[] = [];
    let filter2: any = {
      filters: filters,
      page: page,
      size: pageSize,
      sorts: sorts,
    };
    this.cuentaService
      .filter(filter2)
      .pipe(
        finalize(() => {
          this.seleccionarAsociadasaCuentasOc();
        }),
      )
      .subscribe({
        next: (data) => {
          this.cuentas = data.content;
          this.cuentasCompletas = data.content;
        },
        error: (error) => {},
      });
  }

  seleccionarAsociadasaCuentasOc() {
    if (this.gastoOc != undefined) {
      let listaCuentasAux: any = [];
      this.gastoOc.conceptos.forEach((element: any) => {
        if (element.pendiente != 0) {
          listaCuentasAux.push(
            this.BuscarElementoEnLista(this.cuentas, element.codigo.id),
          );
        }
      });
      this.cuentas = listaCuentasAux;
    }
  }

  getAllProductoras() {
    this.productoraService.filter(this.filter).subscribe({
      next: (data) => {
        this.productoras = data.content;

        if (!this.productoras || this.productoras.length == 0) {
          this.notificationService.warning(
            'ALERTA',
            'No existen productoras para este proyecto.',
            3500,
          );
        }
      },
      error: (error) => {},
    });
  }

  getTiposPagos() {
    this.registroGastosService.getAllTiposPagos().subscribe({
      next: (data) => {
        this.tipoPago = data;
      },
      error: (error) => {},
    });
  }

  /*
   *mostrar y ocultar el detalle
   */
  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }

  /*
   *muestra u oculta el elemento que recibe en función de su estado
   */
  changeStep(step: string) {
    if (step === 'concepto') {
      this.conceptosSelected = 'in';
      this.formasDePagoSelected = 'out';
      this.facturasOcSelected = 'out';
      return;
    }
    if (step === 'forma_pago') {
      this.conceptosSelected = 'out';
      this.formasDePagoSelected = 'in';
      this.facturasOcSelected = 'out';
      return;
    }
    if (step === 'facturasOc') {
      this.conceptosSelected = 'out';
      this.formasDePagoSelected = 'out';
      this.facturasOcSelected = 'in';
      return;
    }
  }

  /*
  añade un elemento del formulario de concepto a la tabla
  */
  addToTable() {
    if (
      this.concepto.controls['conceptoOc'].value.pendiente <
      this.concepto.controls['base'].value
    ) {
      this.notificationService.warning(
        'ALERTA',
        'El importe pendiente de este concepto es de ' +
          this.concepto.controls['conceptoOc'].value.pendiente +
          ' y no puede superarse, ni editarse.',
        5000,
      );
    } else {
      if (this.concepto.valid) {
        let ordenSeleccionada: any = this.concepto.controls['numero_oc'].value;
        let conceptObject = {
          id: 0,
          //codigo: this.concepto.controls['codigo'].value.cuenta,
          // concepto: this.concepto.controls['codigo'].value.concepto,
          conceptoOc: this.concepto.controls['conceptoOc'].value,
          descripcion: this.concepto.controls['descripcion'].value,
          ordenCompra: { id: ordenSeleccionada.id },
          lugar: this.concepto.controls['lugar'].value,
          base: this.concepto.controls['base'].value,
          ivaPorcentaje: this.concepto.controls['ivaPorcentaje'].value,
          iva: this.concepto.controls['iva'].value,
          irpfPorcentaje: this.concepto.controls['irpfPorcentaje'].value,
          irpf: this.concepto.controls['irpf'].value,
          descuento:
            this.concepto.controls['descuento'].value === ''
              ? 0
              : this.concepto.controls['descuento'].value,
          total: this.concepto.controls['total'].value,
          codigo: this.concepto.controls['codigo'].value,
          conversion: this.concepto.controls['conversion'].value,
          cambio: this.concepto.controls['cambio'].value,
          moneda: this.concepto.controls['moneda'].value,
        };
        if (
          this.concepto.controls['id'].value === '' ||
          this.concepto.controls['id'].value === null
        ) {
          conceptObject.id = this.conceptArray.length;
          this.conceptArray.push(conceptObject);
        } else {
          conceptObject.id = this.concepto.controls['id'].value;
          let filteredPago = this.pagoArrayEnv.filter(
            (pago: any) =>
              pago.concepto.id === conceptObject.id && pago.pagado == false,
          );
          if (filteredPago.length != 0) {
            let sumPagos = filteredPago.reduce(
              (sum, current) => sum + current.cantidad,
              0,
            );
            let dif = (conceptObject.total - sumPagos) / this.pagoArray.length;
            filteredPago.forEach((element) => {
              this.pagoArrayEnv[
                this.pagoArrayEnv.findIndex(
                  (pagoEnv) => pagoEnv.id === element.id,
                )
              ].cantidad = element.cantidad + dif;
              this.pagoArray[
                this.pagoArray.findIndex((pago) => element == pago)
              ].cantidad = element.cantidad + dif;
            });
          }
          let index = this.conceptArray.findIndex(
            (element) => element.id === conceptObject.id,
          );
          this.conceptArray[index] = conceptObject;
          this.totalBase = this.totalBase - this.cantidadBaseAux;
          this.totalIrpf = this.totalIrpf - this.cantidadIrpfAux;
          this.totalIva = this.totalIva - this.cantidadIvaAux;
          this.totalGasto = this.totalGasto - this.cantidadGastoAux;
        }

        this.totalBase = this.totalBase + parseFloat(conceptObject.base);
        this.totalIva = this.totalIva + parseFloat(conceptObject.iva);
        this.totalIrpf = this.totalIrpf + parseFloat(conceptObject.irpf);
        this.totalGasto = this.totalGasto + parseFloat(conceptObject.total);
        this.dataSource = new MatTableDataSource(this.conceptArray);
        let comprobarAniadido = false;
        this.conceptArray.forEach((element) => {
          this.pagoArrayEnv.forEach((element2) => {
            if (element.id == element2.concepto.id) {
              comprobarAniadido = true;
            }
          });
        });
        if (!comprobarAniadido) {
          this.añadirPagosDeOc(this.conceptArray.indexOf(conceptObject));
        }
        this.clearFormGroup();
        this.concepto.reset();
        this.actualizartotal();
        this.actualizarOrdenDeCompra();
        // this.filtrarCuentasProyecto();
        this.onFormChange();
      } else {
        Object.keys(this.concepto.controls).forEach((field) => {
          const control = this.concepto.get(field);
          control?.markAsTouched({ onlySelf: true });
          control?.markAsDirty({ onlySelf: true });
        });
        this.notificationService.info(
          'No válido',
          'Rellene los campos correctamente antes de añadir el concepto.',
          5000,
        );
      }
    }
  }

  /*
  añade un elemento del formulario de pago a la tabla 
  */
  addToTablepago() {
    if (this.pago.valid) {
      let pagoObject = {
        id: this.indexPagoActualizar,
        fecha_pago: this.fechaService.type(
          this.pago.controls['fecha_pago'].value,
        ),
        forma_pago: this.pago.controls['forma_pago'].value,
        cuenta_cargo: this.pago.controls['cuenta_cargo'].value,
        numero_talon: this.pago.controls['numero_talon'].value,
        cuenta_destino: this.pago.controls['cuenta_destino'].value,
        descripcion: this.pago.controls['descripcion'].value,
        cantidad: this.pago.controls['cantidad'].value,
        pagado: this.pago.controls['pagado'].value == 'SI' ? true : false,
        concepto: this.pago.controls['concepto'].value,
      };
      if (
        this.pago.controls['id'].value === '' ||
        this.pago.controls['id'].value === null
      ) {
        this.pagoArrayEnv.push(pagoObject);
        this.pagoArray.push(pagoObject);
        this.indexPagoActualizar++;
        if (pagoObject.pagado) {
          this.totalPagado = this.totalPagado + parseFloat(pagoObject.cantidad);
        }
      } else {
        //this.pagoArray.unshift(pagoObject);
        this.pagoArrayEnv[this.indexPagoActualizar] = pagoObject;
        this.pagoArray[this.indexPagoActualizar] = pagoObject;
        this.indexPagoActualizar = this.pagoArray.length - 1;
        if (pagoObject.pagado) {
          this.totalPagado =
            this.totalPagado - this.cantidadPagoAux + pagoObject.cantidad;
        } else {
          this.totalPagado = this.totalPagado - this.cantidadPagoAux;
        }
      }
      this.onFormChange();
      this.dataSource2 = new MatTableDataSource(this.pagoArray);
      this.clearFormGroupPago();
      this.pago.reset();
      // this.pago.markAsUntouched
    } else {
      Object.keys(this.pago.controls).forEach((field) => {
        const control = this.pago.get(field);
        control?.markAsTouched({ onlySelf: true });
        control?.markAsDirty({ onlySelf: true });
      });
      this.notificationService.info(
        'No válido',
        'Rellene los campos correctamente antes de añadir el pago.',
        5000,
      );
    }
    this.actualizartotal();
  }

  actualizartotal() {
    this.totalpendientepago = this.totalGasto - this.totalPagado;
    if (this.totalpendientepago < 0) {
      this.notificationService.error(
        'ERROR',
        'Revisa los calculos, el total pendiente de pago no puede ser negativo.',
        5000,
      );
    }
    this.pagoObjectResto = {
      cantidad: this.totalpendientepago,
      descripcion: 'PENDIENTE DE PAGO',
    };
    this.pagoArray.forEach((element) => {
      if (element.descripcion != undefined) {
        if (element.descripcion == 'PENDIENTE DE PAGO') {
          this.pagoArray.splice(this.pagoArray.indexOf(element), 1);
        }
      }
    });
    this.pagoArray.push(this.pagoObjectResto);
    this.dataSource2 = new MatTableDataSource(this.pagoArray);
  }
  /*
  Borra un elemento de la tabla de conceptos
  */
  deleteConcepto(concepto: any) {
    let id = this.conceptArray.indexOf(concepto);
    this.totalBase = this.totalBase - this.conceptArray[id].base;
    this.totalIrpf = this.totalIrpf - this.conceptArray[id].irpf;
    this.totalIva = this.totalIva - this.conceptArray[id].iva;
    this.totalGasto = this.totalGasto - this.conceptArray[id].total;
    this.conceptArray.splice(id, 1);
    this.dataSource = new MatTableDataSource(this.conceptArray);
    this.actualizartotal();
  }

  /*
 Borra un elemento de la tabla de pago
 */
  deletePago(pago: any) {
    let index = this.pagoArray.indexOf(pago);
    if (this.pagoArray[index].pagado) {
      this.totalPagado = this.totalPagado - this.pagoArray[index].cantidad;
    }
    this.pagoArray.splice(index, 1);
    this.pagoArrayEnv.splice(index, 1);
    this.dataSource2 = new MatTableDataSource(this.pagoArray);
    this.actualizartotal();
  }

  /*
  introduce un elemento de la tabla concepto en su formulario
  */
  updateConcepto(id: any) {
    this.isConceptosExpanded = 'in';
    let conceptObject: any;
    if (this.isEditado || this.isReading) {
      this.conceptArray.forEach((element) => {
        if (element.id == id) {
          conceptObject = element;
        }
      });
    } else {
      conceptObject = this.conceptArray.at(id);
    }
    //conceptObject.conceptoOc.pendiente = conceptObject.total + conceptObject.conceptoOc.pendiente
    this.concepto.controls['conceptoOc'].setValue(conceptObject.conceptoOc),
      this.concepto.controls['id'].setValue(conceptObject.id);

    if (
      this.BuscarElementoEnLista(this.cuentas, conceptObject.codigo.id) == null
    ) {
      // this.cuentas.push(
      //   this.BuscarElementoEnLista(
      //     this.cuentasCompletas,
      //     conceptObject.codigo.id
      //   )
      // );
    }

    if (
      this.BuscarElementoEnLista(
        this.ordenesDeCompra,
        conceptObject.ordenCompra.id,
      ) == null
    ) {
      this.oredenCompraService
        .findById(conceptObject.ordenCompra.id)
        .pipe(
          finalize(() => {
            this.concepto.controls['numero_oc'].setValue(
              this.BuscarElementoEnLista(
                this.ordenesDeCompra,
                conceptObject.ordenCompra.id,
              ),
            );
            this.concepto.controls['oc_importe_pendiente'].setValue(
              conceptObject.conceptoOc.pendiente,
            );
            this.cuentas = [];
            this.BuscarElementoEnLista(
              this.ordenesDeCompra,
              conceptObject.ordenCompra.id,
            ).conceptos.forEach((element: any) => {
              this.cuentas.push(element.codigo);
              this.concepto.controls['concepto'].setValue(
                element.codigo.concepto,
              );
              this.concepto.controls['codigo'].setValue(element.codigo);
            });
          }),
        )
        .subscribe({
          next: (data: any) => {
            this.ordenesDeCompra.push(data);
          },
          error: () => {
            this.notificationService.warning(
              'ALERTA',
              'No se ha encontrado esta orden de compra.',
              5000,
            );
          },
        });
    } else {
      this.cuentas = [];
      this.BuscarElementoEnLista(
        this.ordenesDeCompra,
        conceptObject.ordenCompra.id,
      ).conceptos.forEach((element: any) => {
        if (element.pendiente != 0) {
          this.cuentas.push(element.codigo);
          if (conceptObject.codigo.id == undefined) {
            if (element.codigo.id == conceptObject.codigo) {
              this.concepto.controls['concepto'].setValue(
                element.codigo.concepto,
              );
              this.concepto.controls['codigo'].setValue(element.codigo);
            }
          } else {
            if (element.codigo.id == conceptObject.codigo.id) {
              this.concepto.controls['concepto'].setValue(
                element.codigo.concepto,
              );
              this.concepto.controls['codigo'].setValue(element.codigo);
            }
          }
        }
      });

      this.concepto.controls['descripcion'].setValue(conceptObject.descripcion);
      this.concepto.controls['numero_oc'].setValue(
        this.BuscarElementoEnLista(
          this.ordenesDeCompra,
          conceptObject.ordenCompra.id,
        ),
      );
      this.concepto.controls['oc_importe_pendiente'].setValue(
        conceptObject.conceptoOc.pendiente,
      );
    }

    this.concepto.controls['lugar'].setValue(conceptObject.lugar);
    this.concepto.controls['base'].setValue(conceptObject.base);
    this.concepto.controls['ivaPorcentaje'].setValue(
      conceptObject.ivaPorcentaje,
    );
    this.concepto.controls['iva'].setValue(conceptObject.iva);
    this.concepto.controls['irpfPorcentaje'].setValue(
      conceptObject.irpfPorcentaje,
    );
    this.concepto.controls['irpf'].setValue(conceptObject.irpf);
    this.concepto.controls['descuento'].setValue(conceptObject.descuento);
    this.concepto.controls['total'].setValue(conceptObject.total);

    this.cantidadBaseAux = conceptObject.base;
    this.cantidadIvaAux = conceptObject.iva;
    this.cantidadIrpfAux = conceptObject.irpf;
    this.cantidadGastoAux = conceptObject.total;
    this.concepto.controls['moneda'].setValue(conceptObject.moneda);
    this.concepto.controls['cambio'].setValue(conceptObject.cambio);
    this.concepto.controls['conversion'].setValue(conceptObject.conversion);
    this.isConceptosExpanded = 'in';
  }

  /*
  introduce un elemento de la tabla pagos en su formulario
  */
  updatePago(pago: any) {
    this.isPagoExpanded = 'in';
    this.indexPagoActualizar = this.pagoArray.indexOf(pago);
    let pagoObject = this.pagoArray[this.indexPagoActualizar];
    this.pago.controls['id'].setValue(this.indexPagoActualizar);
    this.pago.controls['id'].setValue(this.indexPagoActualizar);
    this.pago.controls['fecha_pago'].setValue(
      this.fechaService.date(pagoObject.fecha_pago),
    );
    this.pago.controls['forma_pago'].setValue(pagoObject.forma_pago);
    this.pago.controls['numero_talon'].setValue(pagoObject.numero_talon);
    this.pago.controls['cuenta_cargo'].setValue(pagoObject.cuenta_cargo);
    this.pago.controls['cuenta_destino'].setValue(pagoObject.cuenta_destino);
    this.pago.controls['descripcion'].setValue(pagoObject.descripcion);
    this.pago.controls['cantidad'].setValue(pagoObject.cantidad);
    this.pago.controls['pagado'].setValue(pagoObject.pagado ? 'SI' : 'NO');
    this.pago.controls['concepto'].setValue(pagoObject.concepto);
    if (pagoObject.pagado) {
      this.cantidadPagoAux = pagoObject.cantidad;
    } else {
      this.cantidadPagoAux = 0;
    }
    this.isPagoExpanded = 'in';
  }

  //TODO : hacer que el formulario vuelva a su estado inicial una vez se añadan los datos a la tabla
  /*
  vacia el formulario de concepto
  */
  clearFormGroup() {
    for (let i = 0; i < Object.keys(this.concepto.controls).length; i++) {
      this.concepto.controls[Object.keys(this.concepto.controls)[i]].setValue(
        '',
      );
      this.concepto.controls[Object.keys(this.concepto.controls)[i]].setErrors(
        null,
      );
    }
  }

  clearFormGroupPago() {
    for (let i = 0; i < Object.keys(this.pago.controls).length; i++) {
      this.pago.controls[Object.keys(this.pago.controls)[i]].setValue('');
      this.pago.controls[Object.keys(this.pago.controls)[i]].setErrors(null);
    }
  }

  /*
  crea el gasto

  */
  crearGasto() {
    this.isSave = false;
    let totalEnPagos = 0;
    this.pagoArrayEnv.forEach((element: any) => {
      totalEnPagos = totalEnPagos + element.cantidad;
    });

    if (this.interfazInicial.proyaux.id == undefined) {
      this.notificationService.warning(
        'ALERTA',
        'No dispones de un proyecto seleccionado, no podrás crear el gasto.',
        5000,
      );
    } else if (this.totalpendientepago < 0) {
      this.notificationService.error(
        'ERROR',
        'No se puede crear un gasto si el pendiente de pago es menor que 0.',
        5000,
      );
    } else if (totalEnPagos != this.totalGasto) {
      this.notificationService.error(
        'ERROR',
        'Importe total de la factura tiene que estar registrado como pagos.',
        5000,
      );
    } else if (this.numFacturaDuplicado && !this.isEditado) {
      this.notificationService.warning(
        'ERROR',
        'Esa factura ya ha sido registrada.',
        5000,
      );
    } else {
      if (!this.isReading && !this.isEditado) {
        if (
          this.gasto.valid &&
          this.conceptArray.length != 0 &&
          this.pagoArrayEnv.length != 0
        ) {
          let gastoObject = {
            id: this.gasto.controls['id'].value,
            numeroFactura: this.gasto.controls['numeroFactura'].value,
            fecha_factura: this.fechaService.type(
              this.gasto.get('fecha_factura')?.value,
            ),
            tipo_gasto: this.gasto.controls['tipo_gasto'].value,
            num_pc: this.gasto.controls['num_pc'].value,
            nombre_pc: this.gasto.controls['nombre_pc'].value,
            periodo_iva: this.gasto.controls['periodo_iva'].value,
            proveedor: { id: this.gasto.controls['proveedor'].value.id },
            productora: { id: this.gasto.controls['gastoProductora'].value.id },
            proyecto: { id: this.interfazInicial.proyaux.id },
            carbono: this.gasto.get('carbono')?.value,
            inv: this.gasto.get('inv')?.value,
          };
          let arrayDocumentsEnv: any = [];
          this.arrayDocuments.forEach((element: any) => {
            if (element.fecha_captura == undefined) {
              arrayDocumentsEnv.push(element);
            } else {
              arrayDocumentsEnv.push({ id: element.id });
            }
          });

          this.loading = true;
          this.registroGastosService
            .save(
              gastoObject,
              this.conceptArray,
              this.pagoArrayEnv,
              arrayDocumentsEnv,
              this.interfazInicial.currentUser.id,
              this.deparamento.id,
            )
            .subscribe({
              next: (data) => {
                this.loading = false;
                this.notificationService.success(
                  'ÉXITO',
                  'Gasto creado correctamente.',
                  5000,
                );
                this.storageService.eliminar('gastoForm');
                this.storageService.eliminar('pagoGastoForm');
                this.storageService.eliminar('conceptoGastoForm');

                this.router.navigate(['admin-contacine/registro/gastos']);
              },
              error: (err) => {
                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning(
                    'ERROR',
                    err.error[Object.keys(err.error)[0]],
                    5000,
                  );
                } else {
                  this.notificationService.warning(
                    'ERROR',
                    'Ha ocurrido un error.',
                    5000,
                  );
                }
              },
            });
        } else {
          if (!this.gasto.valid) {
            this.notificationService.warning(
              'Información',
              'Tienes que completar todos los datos de la factura.',
              5000,
            );
          } else if (this.conceptArray.length == 0) {
            this.notificationService.warning(
              'Información',
              'Introduce al menos un concepto.',
              5000,
            );
          } else if (this.pagoArrayEnv.length == 0) {
            this.notificationService.warning(
              'Información',
              'Introduce al menos un pago.',
              5000,
            );
          }
        }
      } else if (this.isEditado) {
        if (
          this.gasto.valid &&
          this.conceptArray.length != 0 &&
          this.pagoArrayEnv.length != 0
        ) {
          let gastoObject = {
            id: this.gasto.controls['id'].value,
            numeroFactura: this.gasto.controls['numeroFactura'].value,
            fecha_factura: this.fechaService.type(
              this.gasto.get('fecha_factura')?.value,
            ),
            tipo_gasto: this.gasto.controls['tipo_gasto'].value,
            num_pc: this.gasto.controls['num_pc'].value,
            nombre_pc: this.gasto.controls['nombre_pc'].value,
            periodo_iva: this.gasto.controls['periodo_iva'].value,
            proveedor: { id: this.gasto.controls['proveedor'].value.id },
            productora: { id: this.gasto.controls['gastoProductora'].value.id },
            proyecto: { id: this.interfazInicial.proyaux.id },
            carbono: this.gasto.get('carbono')?.value,
            inv: this.gasto.get('inv')?.value,
          };
          this.pagoArrayEnv.forEach((element) => {
            element.id = null;
            if (element.gasto != null) {
              element.gasto = { id: element.gasto.id };
            }
            this.conceptArray.forEach((element) => {
              element.id = null;
              if (element.ordenCompra != undefined) {
                element.ordenCompra = { id: element.ordenCompra.id };
              }
            });
          });

          let arrayDocumentsEnv: any = [];
          this.arrayDocuments.forEach((element: any) => {
            if (element.fecha_captura == undefined) {
              arrayDocumentsEnv.push(element);
            } else {
              arrayDocumentsEnv.push({ id: element.id });
            }
          });

          this.loading = true;
          this.registroGastosService
            .update(
              gastoObject,
              this.conceptArray,
              this.pagoArrayEnv,
              arrayDocumentsEnv,
              this.interfazInicial.currentUser.id,
              this.deparamento.id,
            )
            .subscribe({
              next: (data) => {
                this.loading = false;
                this.notificationService.success(
                  'ÉXITO',
                  'Gasto creado correctamente.',
                  5000,
                );
                this.storageService.eliminar('gastoForm');
                this.storageService.eliminar('pagoGastoForm');
                this.storageService.eliminar('conceptoGastoForm');
                this.router.navigate(['admin-contacine/registro/gastos']);
              },
              error: (err) => {
                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning(
                    'ERROR',
                    err.error[Object.keys(err.error)[0]],
                    5000,
                  );
                } else {
                  this.notificationService.warning(
                    'ERROR',
                    'Ha ocurrido un error.',
                    5000,
                  );
                }
              },
            });
        } else {
          this.notificationService.warning(
            'Información',
            'Tienes que completar todos los datos.',
            5000,
          );
        }
      }
    }
    if (this.isReading) {
      this.volver();
    }
  }

  /*
   evalua el cambio de fichero
  */
  onFileChange(event: any, index: number) {
    if (
      event.target.files[0].type == 'application/pdf' ||
      event.target.files[0].type == 'image/png' ||
      event.target.files[0].type == 'image/jpeg'
    ) {
      const file = event.target.files[0];
      const bits = file.size * 8;
      const bytes = bits / 8;
      const kb = bytes / 1000;
      const mb = kb / 1000;
      if (mb < 20) {
        this.convertFile(file).subscribe((base64) => {
          let document = {
            nombre: event.target.files[0].name,
            mime: event.target.files[0].type,
            base64: base64,
          };
          this.arrayDocuments[index] = document;
        });
      } else {
        this.notificationService.warning(
          'Información',
          'Solo se admite documnetos con tamaño menor a 20 MB.',
          5000,
        );
      }
    } else {
      this.notificationService.warning(
        'Información',
        'Solo se admiten archivos del tipo: PDF.',
        5000,
      );
    }
  }

  downloadDoc(doc: any) {
    const linkSource = 'data:application/pdf;base64,' + doc.b64;
    const downloadLink = document.createElement('a');
    const fileName = doc.nombre;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  viewDoc(doc: any) {
    const byteArray = new Uint8Array(
      atob(doc.base64)
        .split('')
        .map((char) => char.charCodeAt(0)),
    );
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const opcionesVentana = 'width=800,height=600,scrollbars=yes';
    window.open(url, '_blank', opcionesVentana);
  }

  changeDescr(descripcion: any, index: any) {
    this.arrayDocuments[index].descripcion = descripcion.target.value;
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  borrarArchivo(doc: any) {
    this.arrayDocuments.splice(this.arrayDocuments.indexOf(doc));
    if (this.arrayDocuments.length == 0) {
      this.arrayDocuments.push({});
    }
  }

  changeTipoDoc(tipo: any, index: any) {
    this.arrayDocuments[index].tipo = tipo;
  }

  /*
  calcula los totales que se mostrarán 
  */
  sumaTotal() {
    let base: number =
      this.concepto.controls['base'].value === '' ||
      this.concepto.controls['base'].value === null
        ? 0
        : this.getConversion();
    let ivaPorcentaje: number = parseFloat(
      this.concepto.controls['ivaPorcentaje'].value === '' ||
        this.concepto.controls['ivaPorcentaje'].value === null
        ? 0
        : this.concepto.controls['ivaPorcentaje'].value,
    );
    let iva: number = 0; //= parseFloat(this.concepto.controls["iva"].value === "" ? 0 : this.concepto.controls["iva"].value)
    let irpfPorcentaje: number = parseFloat(
      this.concepto.controls['irpfPorcentaje'].value === '' ||
        this.concepto.controls['irpfPorcentaje'].value === null
        ? 0
        : this.concepto.controls['irpfPorcentaje'].value,
    );
    let irpf: number = 0; //= parseFloat(this.concepto.controls["irpf"].value === "" ? 0 : this.concepto.controls["irpf"].value)
    let descuento: number = parseFloat(
      this.concepto.controls['descuento'].value === '' ||
        this.concepto.controls['descuento'].value === null
        ? 0
        : this.concepto.controls['descuento'].value,
    );
    base = base - descuento;
    iva = (ivaPorcentaje / 100) * base;
    this.concepto.controls['iva'].setValue(iva);
    irpf = (irpfPorcentaje / 100) * base;
    this.concepto.controls['irpf'].setValue(irpf);

    let resultado: number = base + iva - irpf;
    resultado = parseFloat(resultado.toFixed(2));
    this.concepto.controls['total'].setValue(resultado);
  }

  /*
   al cambiar un proveedor el control 'proveedor' se cambia, ademas se cambia el nombre al cambiar el nif y viceversa
  */
  CambiarProveedor(tipo: any, isNif: boolean) {
    this.gasto.controls['proveedor'].setValue({ id: tipo.value.id });
    if (isNif) {
      this.gasto.controls['nombre'].setValue(tipo.value);
    } else {
      this.gasto.controls['nif'].setValue(tipo.value);
    }
    this.getAllOrdenDeCompra();
  }

  /*
  al cambiar una productora el control 'gastoProductora' se cambia
  */
  CambiarProductora(tipo: any, isNumProd: boolean) {
    this.cuentasCargo = tipo.value.bancosIban;
    this.gasto.controls['gastoProductora'].setValue({ id: tipo.value.id });
    this.getAllOrdenDeCompra();
  }

  /*
 al cambiar una cuenta el control 'cuenta' se cambia tambien se autocompleta el concepto
 */
  cambiarCuenta(event: any) {
    let cuenta: any = null;
    let id: any;
    if (event.value.codigo != undefined) {
      id = event.value.codigo.id;
    } else {
      id = event.value.id;
    }
    let isRepeated =
      this.cuentas.filter((cuenta: any) => cuenta.id == id).length > 1;
    if (event.value.codigo != undefined && !isRepeated) {
      this.dialogVisible2 = false;
      cuenta = this.BuscarElementoEnLista(this.cuentas, event.value.codigo.id);
    } else if (event.value.codigo != undefined && isRepeated) {
      this.dialogVisible2 = false;
      cuenta = event.value.codigo;
      this.concepto.controls['oc_importe_pendiente'].setValue(
        event.value.pendiente,
      );
      this.concepto.controls['lugar'].setValue(event.value.lugar);
    } else {
      cuenta = event.value;
    }
    if (!isRepeated) {
      this.concepto.controls['numero_oc'].value.conceptos.forEach(
        (element: any) => {
          if (element.codigo.id == cuenta.id) {
            this.concepto.controls['oc_importe_pendiente'].setValue(
              element.pendiente,
            );
            this.concepto.controls['lugar'].setValue(element.lugar);
          }
        },
      );
    }

    this.concepto.controls['codigo'].setValue(cuenta);
    this.concepto.controls['concepto'].setValue(cuenta.concepto);
    this.concepto.controls['descripcion'].setValue(event.value.descripcion);
    if (this.gastoOc != undefined) {
      this.gastoOc.conceptos.forEach((element: any) => {
        if (element.codigo.id == id) {
          if (element.tipoImpuesto != undefined) {
            this.impuestoTipo = element.tipoImpuesto;
          }
          this.concepto.controls['conceptoOc'].setValue(element);
          this.concepto.controls['ivaPorcentaje'].setValue(element.iva);
          this.concepto.controls['iva'].setValue(element.cantidadIva);
          this.concepto.controls['irpfPorcentaje'].setValue(element.irpf);
          this.concepto.controls['irpf'].setValue(element.cantidadIrpf);
          this.concepto.controls['base'].setValue(element.pendiente);
          this.concepto.controls['total'].setValue(
            element.suma - element.cantidadIrpf + element.cantidadIva,
          );
          this.concepto.controls['moneda'].setValue(element.moneda);
          this.concepto.controls['cambio'].setValue(element.cambio);
          if (this.gastoOc.pagoOc.fechasPago.length != 0) {
            if (this.indexPagoActualizar == -1) {
              this.indexPagoActualizar = 0;
            }
          }
          this.sumaTotal();
        }
      });
      this.codigoAux = event.value;
      this.conceptoAux = event.value.concepto;
    }
  }

  añadirPagosDeOc(index: any) {
    if (this.gastoOc != undefined) {
      this.gastoOc.pagoOc.fechasPago.forEach((element: any) => {
        let numPagos = 1;
        if (this.gastoOc.pagoOc.numeroPagos != undefined) {
          numPagos = this.gastoOc.pagoOc.numeroPagos;
        }

        let pagoObject = {
          id: this.indexPagoActualizar,
          fecha_pago: element,
          forma_pago: this.tipoPago[2],
          cuenta_cargo: '',
          numero_talon: '',
          cuenta_destino: '',
          descripcion: '',
          pagado: false,
          cantidad: this.concepto.controls['total'].value / numPagos,
          concepto: this.conceptArray[index],
        };
        this.pagoArrayEnv[this.indexPagoActualizar] = pagoObject;
        this.pagoArray[this.indexPagoActualizar] = pagoObject;
        this.dataSource2 = new MatTableDataSource(this.pagoArray);
        //
        this.indexPagoActualizar++;
      });

      this.totalpendientepago = this.concepto.controls['total'].value;

      this.pagoObjectResto = {
        cantidad: this.concepto.controls['total'].value,
        descripcion: 'PENDIENTE DE PAGO',
      };
      if (this.pagoArray.length != 0) {
        if (
          this.pagoArray[this.pagoArray.length - 1].descripcion !=
          'PENDIENTE DE PAGO'
        ) {
          this.pagoArray.push(this.pagoObjectResto);
        }
      }
    }
  }

  /*
 método auxliar para sacar de la lista un elemento para cabiar el valor del formulario
*/
  BuscarTipoGastoEnLista(nif: any): any {
    let elementodev: any = null;
    this.proveedores.forEach((element: any) => {
      if (element.nif == nif) {
        elementodev = element;
        return;
      }
    });
    return elementodev;
  }

  comprobarDoc(documento: any, accion: string) {
    this.loadingArchivo = false;
    if (documento.base64 == undefined) {
      this.nextCloudService
        .getBase64(documento.id_nextcloud)
        .pipe(
          finalize(() => {
            if (accion == 'ver') {
              this.viewDoc(documento);
            } else if (accion == 'descargar') {
              this.downloadDoc(documento);
            }
          }),
        )
        .subscribe({
          next: (b64) => {
            if (b64 == null) {
              this.loadingArchivo = true;
              this.notificationService.error(
                'ERROR',
                'Error al leer los documentos guardados.',
                5000,
              );
            } else {
              documento.base64 = b64.b64;
            }
          },
          error: (b64) => {
            this.loadingArchivo = true;
            this.notificationService.error(
              'ERROR',
              'Error al leer los documentos guardados.',
              5000,
            );
          },
        });
    } else {
      if (accion == 'ver') {
        this.viewDoc(documento);
      } else if (accion == 'descargar') {
        this.downloadDoc(documento);
      }
    }
  }

  /*
  método que introduce en un div un visor de pdf 
  */
  sacarEmbed(doc: any) {
    var padre: any = document.getElementById('visualizararchivo');
    var child: any = document.getElementById('embed');
    if (child != null) {
      padre.removeChild(child);
    }
    document.getElementById('visualizararchivo')?.removeChild;
    var base64Data = doc.base64;
    var binaryData = atob(base64Data);
    var length = binaryData.length;
    var uint8Array = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    var embed = document.createElement('embed');
    embed.setAttribute('id', 'embed');
    embed.setAttribute('type', doc.mime);
    var blob = new Blob([uint8Array], { type: doc.mime });

    var pdfUrl = URL.createObjectURL(blob);
    embed.setAttribute('src', pdfUrl);
    embed.setAttribute('width', '100%');
    embed.setAttribute('height', '650px');
    padre.appendChild(embed);

    var slidebnav: any = document.getElementById('viewer');

    //slidebnav.setAttribute('--viewer-pdf-sidenav-width', '0px');
  }

  buscarEnCuentas() {
    this.cuentas = this.cuentasCompletas.filter((element: any) => {
      if (element.cuenta != undefined) {
        return element.cuenta.includes(
          this.concepto.controls['buscador'].value,
        );
      }
    });
  }

  cambiarArchivo(archivo: any) {
    if (
      this.arrayDocuments[this.arrayDocuments.length - 1].nombre == undefined
    ) {
      this.arrayDocuments = [];
    }
    this.arrayDocuments.push(archivo.value);
  }

  openDialog(bool: boolean): void {
    if (this.ordenesDeCompra.length != 0) {
      this.mostrarArchivos = [];
      this.conceptArray.forEach((concepto) => {
        this.BuscarElementoEnLista(
          this.ordenesDeCompra,
          concepto.ordenCompra.id,
        ).documentos.forEach((element: any) => {
          this.mostrarArchivos.push(element);
        });
      });

      this.dialogVisible = bool;
    }
  }

  openDialog2(bool: boolean): void {
    this.gastoOc = this.concepto.controls['numero_oc'].value;
    //this.filtrarCuentasProyecto();
    this.ActualizarOC();
    this.mostrarConceptos = [];

    this.concepto.controls['numero_oc'].value.conceptos.forEach(
      (element: any) => {
        if (element.pendiente != 0) {
          this.mostrarConceptos.push(element);
        }
      },
    );
    this.cuentas = [];
    this.mostrarConceptos.forEach((element: any) => {
      this.cuentas.push(element.codigo);
    });
    this.dialogVisible2 = bool == true ? true : false;
  }

  ActualizarOC() {
    this.gastoOc = this.concepto.controls['numero_oc'].value;
    this.actualizarOrdenDeCompra();
    this.dialogVisible = false;
    this.ActualizarOrdenesDeCompraPorProveedor();
  }

  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id;
  }
  changeDec(event: any, control: any): any {
    if (event.key == '.') {
      control.setValue(control?.value + ',');
    }
  }

  ActualizarOrdenesDeCompraPorProveedor() {
    if (this.gasto.controls['proveedor'].value != '') {
      let proveedor = this.gasto.controls['proveedor'].value;
      let listaOrdenesCompraAux: any = [];
      this.ordenesDeCompra.forEach((element: any) => {
        if (element.proveedor.id == proveedor.id) {
          listaOrdenesCompraAux.push(element);
        }
      });
      this.ordenesDeCompra = listaOrdenesCompraAux;
    }
    if (this.gasto.controls['gastoProductora'].value != '') {
      let productora = this.gasto.controls['gastoProductora'].value;
      let listaOrdenesCompraAux: any = [];
      this.ordenesDeCompra.forEach((element: any) => {
        if (element.productora) {
          if (element.productora.id == productora.id) {
            listaOrdenesCompraAux.push(element);
          }
        }
      });
      this.ordenesDeCompra = listaOrdenesCompraAux;
    }
  }

  actualizarCuentaDestino(tipo: any) {
    if (tipo.id == '7') {
      this.pago.controls['cuenta_destino'].setValue(this.cuentaDestino);
    } else {
      this.pago.controls['cuenta_destino'].disable;
    }
  }

  volver() {
    this.router.navigate(['admin-contacine/registro/gastos']);
  }

  onFormChange() {
    if (!this.isEditado && !this.isReading) {
      if (
        this.gasto.get('nif')?.disabled &&
        this.gasto.get('nombre')?.disabled
      ) {
        this.gasto.get('nif')?.enable;
        this.gasto.get('nombre')?.enable;
        this.storageService.guardar(
          'gastoForm',
          JSON.stringify(this.gasto.value),
        );

        this.gasto.get('nif')?.disable;
        this.gasto.get('nombre')?.disable;
      } else {
        this.storageService.guardar(
          'gastoForm',
          JSON.stringify(this.gasto.value),
        );
      }
      this.storageService.guardar(
        'conceptoGastoForm',
        JSON.stringify(this.conceptArray),
      );

      this.storageService.guardar(
        'pagoGastoForm',
        JSON.stringify(this.pagoArrayEnv),
      );
    }
  }
  limparFormulario() {
    Object.keys(this.concepto.controls).forEach((field) => {
      const control = this.concepto.get(field);
      control?.setValue('');
    });
    Object.keys(this.gasto.controls).forEach((field) => {
      const control = this.gasto.get(field);
      control?.setValue('');
    });
    Object.keys(this.pago.controls).forEach((field) => {
      const control = this.pago.get(field);
      control?.setValue('');
    });
    this.isClearForm = false;
    this.storageService.eliminar('gastoForm');
    this.gasto.reset();

    this.storageService.eliminar('conceptoGastoForm');
    this.conceptArray = [];
    this.pagoArray = [];
    this.pagoArrayEnv = [];
    this.dataSource = new MatTableDataSource(this.conceptArray);
    this.dataSource2 = new MatTableDataSource(this.pagoArray);
    this.totalBase = 0;
    this.totalIva = 0;
    this.totalIrpf = 0;
    this.totalGasto = 0;
    this.totalPagado = 0;
    this.totalpendientepago = 0;
    this.indexPagoActualizar = 0;
    this.isPagoExpanded = 'in';
    this.isConceptosExpanded = 'in';
    this.storageService.eliminar('pagoGastoForm');
  }

  isValidFactura() {
    if (
      this.gasto.controls['nif'].value.id != undefined &&
      this.gasto.controls['nif'].value.id != ''
    ) {
      this.registroGastosService
        .isValidFactura(
          this.interfazInicial.proyaux.id,
          this.gasto.controls['nif'].value.id,
          this.gasto.controls['numeroFactura'].value,
        )
        .subscribe({
          next: (data) => {
            this.numFacturaDuplicado = data as boolean;
            if (this.numFacturaDuplicado && !this.isEditado) {
              this.notificationService.warning(
                'ERROR',
                'Esa factura ya ha sido registrada.',
                5000,
              );
            }
          },
          error: (error) => {},
        });
    }
  }

  getConversion(): number {
    this.concepto
      .get('conversion')
      ?.setValue(
        this.concepto.get('base')?.value * this.concepto.get('cambio')?.value,
      );
    return (
      this.concepto.get('base')?.value * this.concepto.get('cambio')?.value
    );
  }

  cambiarPagado(pagado: any) {
    this.comprobarDate(true, pagado);
  }

  comprobarDate(comprobar: boolean, pagado: any) {
    if (pagado == 'SI') {
      let date: Date = this.fechaService.type(
        this.pago.get('fecha_pago')?.value,
      );
      if (date > this.fechaService.type(new Date())) {
        if (comprobar) {
          this.notificationService.warning(
            'ALERTA',
            'Revisa que la fecha para que sea anterior a hoy.',
            5000,
          );
        }
      } else {
        this.minDate = new Date();
      }
      this.minDate = new Date();
    } else {
      this.minDate = undefined;
    }
  }

  actualizarConceptoPago(event: any) {
    this.pago.get('descripcion')?.setValue(event.value.descripcion);
    this.pago.get('cantidad')?.setValue(event.value.total);
  }

  aniadirOCNumber(numero: any): String {
    let num: string = numero + '';
    for (let i = num.length; i < 4; i++) {
      num = 0 + num;
    }
    return 'OC' + num;
  }
}
