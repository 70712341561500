import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent {
  currentEnv: string;
  constructor() {
    this.currentEnv = environment.environment;
  }

  ngOnInit(): void {
  }
}
