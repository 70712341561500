<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm"></app-header-listados>
<div>
    <div #top></div>
    <div [ngClass]="{'topInfo2':isFacturaExpanded == 'in'}" class="topInfo">
        <label class="titulosPaginas"> {{isEditado?'':isReading?'':'NUEVO'}} GASTO</label>
    </div>
    <div class="showFormText" [ngClass]="{'showFormText3': this.isFacturaExpanded != 'in'}">
        <h6 *ngIf="isFacturaExpanded === 'out'" class="marginAuto titulosPaginas">Datos Factura</h6>
        <div class="text-buttons">
            <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isFacturaExpanded === 'in'}">Mostrar Datos Factura
            </p>
            <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer">
                /</p>
            <p (click)="this.isFacturaExpanded = this.isFacturaExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isFacturaExpanded === 'out'}">Ocultar</p>
        </div>
    </div>


    <form class="concepto" [formGroup]="gasto" (ngSubmit)="crearGasto()" (change)="onFormChange()">

        <div [@slideInOut]="isFacturaExpanded" class="form-surrouded"
            [ngClass]="{'form-surrouded2': this.isFacturaExpanded != 'in'}">
            <div class="col-12">
                <h6 class="encabezados padded-col">Datos Factura</h6>
                <div class="rowForm">
                    
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">NIF</label>
                        <mat-form-field appearance="outline" class="col-12 formField"   
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput [compareWith]="compararObjetos" formControlName="nif" placeholder="NIF..."
                                (selectionChange)="CambiarProveedor($event, true)">
                                <mat-option *ngFor="let tipo of proveedores" [value]="tipo">
                                    {{ tipo.cif}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Nombre</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput [compareWith]="compararObjetos" formControlName="nombre"
                                placeholder="Nombre..." (selectionChange)="CambiarProveedor($event, false)">
                                <mat-option *ngFor="let tipo of proveedores" [value]="tipo">
                                    {{ tipo.nombreFiscal}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Nombre requerido</mat-error>
                        </mat-form-field>


                    </div>
                    <div class="col-3 d-flex formMaterial" > 
                    <div class="col-6 padded-formField">
                        <label class="col-12 formLabel">Nº Factura <span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input (keyup)="isValidFactura()" type="text" matInput formControlName="numeroFactura"
                                placeholder="Nº Factura...">
                            <mat-error>Nº Factura requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 ">
                        <label class="col-12 formLabel">Fecha Factura <span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput [matDatepicker]="picker" formControlName="fecha_factura" (click)="picker.open()"
                                placeholder="Fecha Factura..." class="pointer">
                            <mat-datepicker-toggle disabled="{{isReading}}" matIconSuffix
                                [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                            <mat-error>Fecha requerida</mat-error>
                        </mat-form-field>
                    </div>
                    </div>

                    <div class="col-3 d-flex formMaterial">   
                        <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">INV</label>
                                        <mat-form-field appearance="outline" class="col-12 formField" [ngClass]="isReading?'desactivado':''">
                                            <mat-select formControlName="inv" matInput fo placeholder="INV...">
                                                <mat-option *ngFor="let tipo of invSN" [value]="tipo">
                                                    {{ tipo}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                    
                    
                                    <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">Carbono</label>
                                        <mat-form-field appearance="outline" class="col-12 formField" [ngClass]="isReading?'desactivado':''">
                                            <mat-select formControlName="carbono" matInput fo placeholder="Carbono...">
                                                <mat-option *ngFor="let tipo of invSN" [value]="tipo">
                                                    {{ tipo}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                    </div>

                
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Tipo <span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput [compareWith]="compararObjetos" formControlName="tipo_gasto"
                                placeholder="Tipo...">
                                <mat-option *ngFor="let tipo of tipoGasto" [value]="tipo">
                                    {{ tipo.nombre }}
                                </mat-option>
                            </mat-select>
                            <mat-error>Tipo requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Período de IVA <span class="red">*</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select formControlName="periodo_iva" matInput [compareWith]="compararObjetos"
                                placeholder="Período de IVA...">
                                <mat-option *ngFor="let tipo of mesesDelAno" [value]="tipo">
                                    {{ tipo.nombre }}
                                </mat-option>
                            </mat-select>

                            <mat-error>Período de IVA requerida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel">Gasto Productora <span class="red">*</span> </label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput formControlName="gastoProductora" placeholder="Gasto Productora..."
                                (selectionChange)="CambiarProductora($event, true)" [compareWith]="compararObjetos">
                                <mat-option *ngFor="let tipo of productoras" [value]="tipo">
                                    {{ tipo.nombre}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Gasto Productora requerido</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-3 formMaterial">
                        <label class="col-12 formLabel " >Nº PC y Nombre</label>
                        <div class="rowForm">
                            <div class="col-6 padded-formField">
                                <mat-form-field appearance="outline" class="formField col-12 disabled"
                                    [ngClass]="isReading?'desactivado':''">
                                    <input type="number" pattern="[0-9]+([,.][0-9]+)?" formControlName="num_pc" matInput
                                        placeholder="Nº PC...">
                                    <mat-error>Nº PC requerido</mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="formField col-12 disabled"
                                    [ngClass]="isReading?'desactivado':''">
                                    <input matInput formControlName="nombre_pc" placeholder="Nombre...">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="showFormText" [ngClass]="{'showFormText2': this.mostrarDetalle != 'in'}">
            <h6 class="marginAuto titulosPaginas">Totales conceptos detalle gasto</h6>
            <div class="text-buttons">
                <p (click)="this.mostrarDetalle = this.mostrarDetalle === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.mostrarDetalle === 'in'}">Mostrar Detalles</p>
                <p (click)="this.mostrarDetalle = this.mostrarDetalle === 'out' ? 'in' : 'out'"
                    class="text-button pointer">
                    /</p>
                <p (click)="this.mostrarDetalle = this.mostrarDetalle === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.mostrarDetalle === 'out'}">Ocultar</p>
            </div>
        </div>
        <div [@slideInOut]="mostrarDetalle" class="priceRow">
            <div class="cardstart">
                <p class="cardtext">Total Base</p>
                <p class="cardtextbig">{{totalBase|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total IVA</p>
                <p class="cardtextbig">{{totalIva|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total IRPF</p>
                <p class="cardtextbig">{{totalIrpf|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total Gastos</p>
                <p class="cardtextbig">{{totalGasto|numberEsp}} €</p>
            </div>

            <div class="cardsblue">
                <p class="cardtext">Total Pagado</p>
                <p class="cardtextbig">{{totalPagado|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Pendiente de Pago</p>
                <p class="cardtextbig">{{totalpendientepago|numberEsp}} €</p>
            </div>
        </div>
        <div class="showFormText" [ngClass]="{'showFormText2': this.isConceptosExpanded != 'in'}">
            <h6 *ngIf="isConceptosExpanded === 'out'" class="marginAuto titulosPaginas mt-2 m-b1">Conceptos </h6>
            <div class="text-buttons">
                <p (click)="this.isConceptosExpanded = this.isConceptosExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptosExpanded === 'in'}">Mostrar
                    Formulario Conceptos
                </p>
                <p (click)="this.isConceptosExpanded = this.isConceptosExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">/</p>
                <p (click)="this.isConceptosExpanded = this.isConceptosExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isConceptosExpanded === 'out'}">Ocultar</p>
            </div>
        </div>


            <div *ngIf="conceptArray.length != 0"  class="mat-elevation-z8  table_container data_table">
                
                    <table mat-table [dataSource]="dataSource">
    
    
                        <ng-container matColumnDef="descripcion">
                            <th mat-header-cell *matHeaderCellDef> DESCRIPCIÓN </th>
                            <td *matCellDef="let element"> {{element.descripcion}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="codigo">
                            <th mat-header-cell *matHeaderCellDef> CÓDIGO </th>
                            <td *matCellDef="let element"> {{element.codigo.cuenta}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="concepto">
                            <th mat-header-cell *matHeaderCellDef>CONCEPTO GASTO</th>
                            <td *matCellDef="let element"> {{element.codigo.concepto}} </td>
                        </ng-container>
                        <ng-container matColumnDef="numero_oc">
                            <th mat-header-cell *matHeaderCellDef> Nº OC </th>
                            <td *matCellDef="let element"> {{element.ordenCompra.numOc?aniadirOCNumber(element.ordenCompra.numOc):''}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="base">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> BASE </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.base|numberEsp}} </td>
                        </ng-container>
                        <ng-container matColumnDef="moneda">
                            <th mat-header-cell *matHeaderCellDef> MONEDA </th>
                            <td *matCellDef="let element"> {{element.moneda}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="cambio">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> CAMBIO </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.cambio}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="conversion">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> CONVERSIÓN </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.conversion|numberEsp}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="ivaPorcentaje">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> %IMPUESTO </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.ivaPorcentaje}} </td>
                        </ng-container>
                        <ng-container matColumnDef="iva">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> IMPUESTO </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.iva|numberEsp}} </td>
                        </ng-container>
                        <ng-container matColumnDef="irpfPorcentaje">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> %IRPF </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.irpfPorcentaje}} </td>
                        </ng-container>
                        <ng-container matColumnDef="irpf">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> IRPF </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.irpf|numberEsp}} </td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef class="numberInput"> TOTAL </th>
                            <td *matCellDef="let element" class="numberInput"> {{element.total|numberEsp}} </td>
                        </ng-container>
               
    
                        <ng-container matColumnDef="acciones" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef class="accionesth"><span class="correccionmargen2">
                                    ACCIONES</span>
                            </th>
                            <td *matCellDef="let element" class="accionesth">
                                <div class="acciones">
    
                                    <a *ngIf="!isReading" title="Ver" class="display-view-button activated"></a>
                                    <a *ngIf="isReading" class="view-button activated"  (click)="updateConcepto(element.id)"></a>
                                    <a *ngIf="isReading" class="display-edit-button"></a>
                                    <a title="Editar" *ngIf="!isReading" class="edit-button activated"
                                        (click)="updateConcepto(element.id)"></a>
                                    <a title="Descargar" class="display-download-button activated"></a>
                                    <a  title="Borrar" *ngIf="isReading" class="display-delete-button"></a>
                                    <a title="Borrar" *ngIf="!isReading" class="delete-button activated"
                                        (click)="deleteConcepto(element)"></a>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row
                            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                            (mouseenter)="tableService.marcarColumna(row,true)"
                            (mouseleave)="tableService.marcarColumna(row,false)"
                            (click)="tableService.marcarColumna(row,null)"
                            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                            (mouseenter)="tableService.marcarColumna(row,true)"
                            (mouseleave)="tableService.marcarColumna(row,false)"
                            (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                
            </div>
            <div [@slideInOut]="isConceptosExpanded" class="form-surrouded-white">
            <div [@slideInOut]="conceptosSelected">
                <form  class="concepto" [formGroup]="concepto" (change)="onFormChange()">
                    <div class="col-12">
                        <h6 class="encabezados padded-col">Conceptos</h6>
                        <div class="rowForm">
                            <div class="col-3 formMaterial">
                                <div class="col-12 rowForm">
                                    <div class="col-6 padded-formField ">
                                        <label class="col-12 formLabel">Nº OC <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField">
                                            <mat-select formControlName="numero_oc" matInput
                                                (selectionChange)="openDialog2(true)" placeholder="Nº OC..."
                                                [errorStateMatcher]="matcher">
                                                <mat-option *ngFor="let tipo of ordenesDeCompra" [value]="tipo">
                                                    {{ aniadirOCNumber(tipo.numOc) }}
                                                </mat-option>
                                            </mat-select>
    
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Importe Pendiente</label>
                                        <mat-form-field appearance="outline" class="formField col-12 disabled">
                                            <input formControlName="oc_importe_pendiente" matInput
                                                placeholder="Importe pendiente..."
                                                [value]="this.concepto.controls['oc_importe_pendiente'].value|numberEsp">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 formMaterial">
                                <label class="col-12 formLabel">Código/Concepto <span class="red">*</span></label>
                                <div class="rowForm">
                                    <div class="col-6 padded-formField">
                                        <mat-form-field appearance="outline" class="formField col-12">
                                            <!--<a class="searchIcon"></a>-->
                                            <mat-select matInput formControlName="codigo" placeholder="Código..."
                                                (selectionChange)="cambiarCuenta($event)">
                                                <!--   <mat-option>
                                                    <div class="no-seleccionable" (click)="$event.stopPropagation()">
                                                        <input class="buscador" formControlName="buscador"
                                                            placeholder="Buscar..." (input)="buscarEnCuentas()"
                                                            [matMenuTriggerFor]="null" />
                                                    </div>
                                                </mat-option>-->
                                                <mat-option *ngFor="let tipo of cuentas" [value]="tipo">
                                                    {{ tipo.cuenta}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>Código requerido</mat-error>
                                        </mat-form-field>
    
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field appearance="outline" class="formField col-12 disabled">
                                            <input formControlName="concepto" matInput placeholder="Concepto..."
                                                [errorStateMatcher]="matcher" readonly>
    
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 formMaterial">
                                <label class="col-12 formLabel">Descripción</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input formControlName="descripcion" matInput placeholder="Descripción..."
                                        [errorStateMatcher]="matcher">
                                    <mat-error>Descripción requerida</mat-error>
                                </mat-form-field>
                            </div>
    
    
    
    
    
                            <div class="col-3 formMaterial">
                                <div class="rowForm">
                                    <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">Lugar/Decorado</label>
                                        <mat-form-field appearance="outline" class="col-12 formField auto"
                                            [ngClass]="isReading?'desactivado':''">
                                            <input (keydown)="filterCols($event)" (keyup)="filterCols($event)" type="text"
                                                matInput placeholder="Lugar/Decorado..." formControlName="lugar"
                                                name="autocompletename" [matAutocomplete]="auto2" class="auto">
                                            <!-- (optionSelected)="selectProveedor($event)" -->
                                            <mat-autocomplete #auto2="matAutocomplete">
                                                <span *ngFor="let tipo of filterLugar()">
                                                    <mat-option [value]="tipo"><span class="columnas">
                                                            {{tipo}}</span></mat-option>
                                                </span>
                                            </mat-autocomplete>
                                            <mat-error>Lugar requerido</mat-error>
                                        </mat-form-field>
    
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Base <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField">
                                            <input type="text" mask="separator.2" thousandSeparator="." decimalMarker=","
                                                (keyup)="changeDec($event,concepto.get('base'));" formControlName="base" matInput
                                                placeholder="Base..." [errorStateMatcher]="matcher" (input)="sumaTotal()"
                                                value="00.00">
                                            <mat-error>Base requerida</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
    
                    
                            <div class="col-3 formMaterial">
                                <div class="rowForm">
                            <div class="col-4  padded-formField">
                                <label class="col-12 formLabel">Moneda</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input type="text" matInput formControlName="moneda"
                                        placeholder="Moneda..."></mat-form-field>
                            </div>
                            <div class="col-4 padded-formField">
                                <label class="col-12 formLabel">Cambio</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input type="text" mask="separator.2" thousandSeparator="." decimalMarker="," matInput
                                        formControlName="cambio" (input)="sumaTotal()"
                                        placeholder="Cambio..."></mat-form-field>
                            </div>
    
                            <div class="col-4 ">
                                <label class="col-12 formLabel">Conversión</label>
                                <mat-form-field appearance="outline" class="col-12 formField"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput placeholder="conversion..." formControlName="conversion"
                                        [value]="getConversion()?(getConversion()|numberEsp):''">
                                </mat-form-field>
                            </div>
                            </div>
                            </div>
                            <div class="col-3 formMaterial">
                                <div class="rowForm">
                                    <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">%{{impuestoTipo}} <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField">
                                            <input type="number" formControlName="ivaPorcentaje" matInput
                                                placeholder="%{{impuestoTipo}}..." [errorStateMatcher]="matcher"
                                                (input)="sumaTotal()">
                                            <mat-error
                                                *ngIf="this.concepto.controls['ivaPorcentaje'].errors?.['pattern']!= undefined">
                                                El impuesto tiene que estar entre 0 y 100</mat-error>
                                            <mat-error
                                                *ngIf="this.concepto.controls['ivaPorcentaje'].errors?.['required']!= undefined">Impuesto
                                                requerido</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Cantidad</label>
                                        <mat-form-field appearance="outline" class="formField col-12 disabled">
                                            <input formControlName="iva" type="text" mask="separator.2"
                                                thousandSeparator="." decimalMarker="," matInput placeholder="Cantidad..."
                                                (input)="sumaTotal()" readonly>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 formMaterial">
                                <div class="rowForm">
                                    <div class="col-6 padded-formField">
                                        <label class="col-12 formLabel">%IRPF <span class="red">*</span></label>
                                        <mat-form-field appearance="outline" class="col-12 formField">
                                            <input type="number" formControlName="irpfPorcentaje" matInput
                                                placeholder="%IRPF..." [errorStateMatcher]="matcher" (input)="sumaTotal()">
                                            <mat-error
                                                *ngIf="this.concepto.controls['irpfPorcentaje'].errors?.['pattern']!= undefined">El
                                                IRPF tiene que estar entre 0 y 100</mat-error>
                                            <mat-error
                                                *ngIf="this.concepto.controls['irpfPorcentaje'].errors?.['required']!= undefined">IRPF
                                                requerido</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Cantidad</label>
                                        <mat-form-field appearance="outline" class="formField col-12 disabled">
                                            <input formControlName="irpf" type="text" mask="separator.2"
                                                thousandSeparator="." decimalMarker="," matInput placeholder="Cantidad..."
                                                (input)="sumaTotal()" readonly>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 formMaterial">
                                <div class="rowForm">
                                    <div class="col-6 padded-formField">
                                        <label type="text" class="col-12 formLabel">Descuento</label>
                                        <mat-form-field appearance="outline" mask="separator.2" thousandSeparator="."
                                            decimalMarker="," class="formField col-12">
                                            <input mask="separator.2" thousandSeparator="."
                                                (keyup)="changeDec($event,concepto.get('descuento'));" decimalMarker=","
                                                formControlName="descuento" matInput placeholder="Descuento..."
                                                (input)="sumaTotal()">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <label class="col-12 formLabel">Total</label>
                                        <mat-form-field appearance="outline" class="formField col-12 disabled">
                                            <input formControlName="total" type="text" mask="separator.2"
                                                thousandSeparator="." decimalMarker="," matInput placeholder="Total..."
                                                readonly>
    
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="showFormText">
                        <button *ngIf="!isReading" type="button" (click)="addToTable()"
                            class="buttonSquared buttonPrimary buttonMargins">Guardar
                            concepto</button>
                    </div>
                </form>

            </div>


        </div>

        

        <div class="showFormText" [ngClass]="{'showFormText2': this.isPagoExpanded != 'in'}">
            <h6 *ngIf="isPagoExpanded === 'out'" class="marginAuto titulosPaginas mt-2 m-b1">Registro de Pago</h6>
            <div class="text-buttons">
                <p (click)="this.isPagoExpanded = this.isPagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isPagoExpanded === 'in'}">Mostrar Formulario
                    Pagos
                </p>
                <p (click)="this.isPagoExpanded = this.isPagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer">
                    /</p>
                <p (click)="this.isPagoExpanded = this.isPagoExpanded === 'out' ? 'in' : 'out'"
                    class="text-button pointer" [ngClass]="{'close':this.isPagoExpanded === 'out'}">Ocultar</p>
            </div>
        </div>

        
            <div *ngIf="pagoArrayEnv.length != 0" class="mat-elevation-z8  table_container data_table">
               
                    <table mat-table [dataSource]="dataSource2">
                        <ng-container matColumnDef="Fecha de Pago">
                            <th mat-header-cell *matHeaderCellDef>FECHA DE PAGO </th>
                            <td *matCellDef="let element"> <span *ngIf="element.fecha_pago!=undefined">
                                    {{element.fecha_pago}}</span> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="Forma de Pago">
                            <th mat-header-cell *matHeaderCellDef>FORMA DE PAGO</th>
                            <td *matCellDef="let element"> <span
                                    *ngIf="element.forma_pago!=undefined">{{element.forma_pago.nombre}}</span> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="Nº Talon/Pagaré">
                            <th mat-header-cell *matHeaderCellDef> Nº TALÓN/PAGARÉ </th>
                            <td *matCellDef="let element"><span *ngIf="element.numero_talon!=undefined">
                                    {{element.numero_talon}}</span> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="Cuenta Cargo">
                            <th mat-header-cell *matHeaderCellDef> CUENTA CARGO</th>
                            <td *matCellDef="let element"><span *ngIf="element.cuenta_cargo!=undefined">
                                    {{element.cuenta_cargo}}</span> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="Cuenta Destino">
                            <th mat-header-cell *matHeaderCellDef>CUENTA DESTINO</th>
                            <td *matCellDef="let element"> <span
                                    *ngIf="element.cuenta_destino!=undefined">{{element.cuenta_destino}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="Descipción">
                            <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN </th>
                            <td *matCellDef="let element"> <span
                                    *ngIf="element.descripcion!=undefined">{{element.descripcion}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="Cantidad">
                            <th mat-header-cell *matHeaderCellDef> CANTIDAD </th>
                            <td *matCellDef="let element"> {{element.cantidad|numberEsp}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Pagado">
                            <th mat-header-cell *matHeaderCellDef> PAGADO </th>
                            <td *matCellDef="let element"> {{element.pagado==undefined? '': element.pagado? 'SI':  'NO' }}  </td>
                        </ng-container>
                        <ng-container matColumnDef="Acciones" stickyEnd>
                            <th mat-header-cell *matHeaderCellDef class="accionesth"><span class="correccionmargen2">
                                    ACCIONES</span>
                            </th>
                            <td class=" " *matCellDef="let element" class="accionesth">
                                <div *ngIf="element.fecha_pago!=undefined" class="acciones">
                                    <a *ngIf="!isReading" class="display-view-button activated"></a>
                                    <a *ngIf="isReading" class="view-button activated" (click)="updatePago(element)"></a>
                                    <a *ngIf="isReading" class="display-edit-button"></a>
                                    <a *ngIf="!isReading" class="edit-button activated" (click)="updatePago(element)"></a>
                                    <a class="display-download-button activated"></a>
                                    <a *ngIf="isReading" class="display-delete-button"></a>
                                    <a *ngIf="!isReading" class="delete-button activated" (click)="deletePago(element)"></a>
    
    
                                </div>
                            </td>
                        </ng-container>
    
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row
                            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                            (mouseenter)="tableService.marcarColumna(row,true)"
                            (mouseleave)="tableService.marcarColumna(row,false)"
                            (click)="tableService.marcarColumna(row,null)"
                            [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                            (mouseenter)="tableService.marcarColumna(row,true)"
                            (mouseleave)="tableService.marcarColumna(row,false)"
                            (click)="tableService.marcarColumna(row,null)"
                            *matRowDef="let row; columns: displayedColumns2;"></tr>
                    </table>
                
            </div><div [@slideInOut]="isPagoExpanded" class="form-surrouded-white">
            <div [@slideInOut]="conceptosSelected">
                <form  class="concepto" [formGroup]="pago" (change)="onFormChange()">
                    <div class="rowForm">
                        <h6 class="encabezados padded-col">Registro de Pago</h6>
                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Fecha de Pago</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <input matInput [matDatepicker]="picker2" formControlName="fecha_pago"
                                    [max]="this.minDate" (click)="picker2.open()" placeholder="Fecha de Pago..."
                                    class="pointer">
                                <mat-datepicker-toggle disabled="{{isReading}}" matIconSuffix
                                    [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 disabled="{{isReading}}"></mat-datepicker>
                                <mat-error>Fecha requerida</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Forma de Pago</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <mat-select formControlName="forma_pago" matInput placeholder="Forma de Pago..."
                                    [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let tipo of tipoPago" [value]="tipo"
                                        (onSelectionChange)="actualizarCuentaDestino(tipo)">
                                        {{ tipo.nombre }}
                                    </mat-option>
                                </mat-select>

                                <mat-error>Forma de pago requerida</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-3 formMaterial ">
                            <label class="col-12 formLabel">Pagado</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <mat-select formControlName="pagado" matInput fo placeholder="Pagado...">
                                    <mat-option *ngFor="let tipo of invSN" [value]="tipo"
                                        (onSelectionChange)="cambiarPagado(tipo)">
                                        {{ tipo}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Pagado requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3 formMaterial">
                                <label class="col-12 formLabel">Concepto</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <mat-select formControlName="concepto" matInput fo placeholder="Concepto..." [compareWith]="compararObjetos"
                                        (selectionChange)="actualizarConceptoPago($event)">
                                        <mat-option *ngFor="let tipo of conceptArray" [value]="tipo" >
                                            Concepto: {{tipo.codigo.cuenta}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>Concepto requerido</mat-error>
                                </mat-form-field>


                        </div>
                        <div class="col-3 formMaterial" *ngIf="pago.controls['forma_pago'].value?.id=='7'">
                            <label class="col-12 formLabel">Cuenta Cargo</label>
                            <mat-form-field appearance="outline" class="col-12 formField"
                                [ngClass]="isReading?'desactivado':''">
                                <mat-select matInput formControlName="cuenta_cargo" placeholder="Cuenta Cargo...">
                                    <mat-option *ngFor="let cuenta of cuentasCargo" [value]="cuenta.iban">
                                        {{ cuenta.iban}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>Cuenta cargo requerida</mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-3 formMaterial" *ngIf="pago.controls['forma_pago'].value?.id=='7'">
                            <div *ngIf="pago.controls['forma_pago'].value!= undefined"></div>
                            <label class="col-12 formLabel">Cuenta Destino</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <input formControlName="cuenta_destino" matInput placeholder="Cuenta Destino..."
                                    [errorStateMatcher]="matcher"
                                    [value]="cuentaDestino == undefined? '':cuentaDestino ">
                                <mat-error>Cuenta Destino requerida</mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Descripción</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <input formControlName="descripcion" matInput placeholder="Descripción..."
                                    [errorStateMatcher]="matcher">
                                <mat-error>Descripción requerida</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3 formMaterial">
                            <label class="col-12 formLabel">Cantidad</label>
                            <mat-form-field appearance="outline" class="col-12 formField">
                                <input type="text" mask="separator.2" thousandSeparator="."
                                    (keyup)="changeDec($event,pago.get('cantidad'));" decimalMarker=","
                                    formControlName="cantidad" matInput placeholder="Cantidad..."
                                    [errorStateMatcher]="matcher">
                                <mat-error>Cantidad requerida</mat-error>
                            </mat-form-field>
                        </div>



                        <div class="col-3 formMaterial"
                            *ngIf="pago.controls['forma_pago'].value!= undefined  && pago.controls['forma_pago'].value!= ''">
                            <div *ngIf="pago.controls['forma_pago'].value.id=='4' || pago.controls['forma_pago'].value.id=='5'"
                                class="col-12 rowForm">
                                <label class="col-12 formLabel">Nº Talón/Pagaré</label>
                                <mat-form-field appearance="outline" class="col-12 formField">
                                    <input formControlName="numero_talon" matInput placeholder="Nº Talón/Pagaré...">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="showFormText">
                        <button *ngIf="!isReading" type="button" (click)="addToTablepago()"
                            class="buttonSquared buttonPrimary buttonMargins">Guardar
                            pago</button>
                    </div>
                </form>

            </div>


        </div>



        <div class="showFormText formMaterial botones">
            <!-- <span *ngIf="!isReading" [ngClass]="(!isReading && !isEditado)?'':'formMaterial'">

                <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                </button>
            </span> -->
            <button *ngIf="isClearForm?limparFormulario():''"></button>
            <button *ngIf="isSave?crearGasto():''"></button>
           <!--  <button *ngIf="isReading" type="submit" class="buttonSquared buttonPrimary ">Volver</button> -->

        </div>

    </form>

    <div class="showFormText" [ngClass]="{'showFormText3': this.isFileExpanded != 'in'}"
        *ngIf="!isReading || arrayDocuments[0].nombre!= undefined">
        <h6 *ngIf="isFileExpanded === 'out'" class="marginAuto titulosPaginas">Documentación adjunta</h6>
        <div class="text-buttons">
            <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'" class="text-button pointer"
                [ngClass]="{'close':this.isFileExpanded === 'in'}">Mostrar Documentación adjunta
            </p>
            <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'" class="text-button pointer">
                /</p>
            <p (click)="this.isFileExpanded = this.isFileExpanded === 'out' ? 'in' : 'out'" class="text-button pointer"
                [ngClass]="{'close':this.isFileExpanded === 'out'}">Ocultar</p>
        </div>
    </div>
    <div [@slideInOut]="isFileExpanded" [ngClass]="{'form-surrouded2': this.isFileExpanded != 'in'}"
        *ngIf="!isReading || arrayDocuments[0].nombre!= undefined">
        <div class="form-surrouded4 mt-3">
            <h6 class="titulosPaginas w-100">Documentación adjunta</h6>
            <p *ngIf="!isReading" class="text-button pointer close" (click)="openDialog(true)">Añadir documentos de
                orden de compra
            </p>
            <div class="displayArchivos ">
                <div class=" col-12 row d-flex" *ngFor="let doc of arrayDocuments; let i = index">

                    <div class="col-4 col-xl-2 padding_tipo ">
                        <label class="col-12 formLabel">Tipo</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <mat-select matInput placeholder="Tipo..." [compareWith]="compararObjetos"
                                [disabled]="isReading" [value]="doc.tipo?doc.tipo:''">
                                <mat-option *ngFor="let tipo of tiposDocumentoOC"
                                    (onSelectionChange)="changeTipoDoc(tipo,i)" [value]="tipo">
                                    <span class="columnas"> {{tipo.nombre}}</span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> 

                    <div class="col-8 col-xl-4 padded-col contenedor_doc">
                        <div class="col-5" *ngIf="!isReading">
                            <button class=" col-12 buttonPrimary buttonDocs"
                                (click)="$event.preventDefault(); fileInput.click()">Seleccionar archivo</button>
                            <input type="file" #fileInput style="display: none;" (change)="onFileChange($event, i)">
                        </div>
                        <div class="col-7 ">
                            <mat-form-field appearance="outline" class="col-12 formField margin_file_name"
                                [ngClass]="isReading?'desactivado':'auto'">
                                <input *ngIf="doc.nombre!= undefined" matInput [disabled]="isReading"
                                    [value]="doc.nombre">
                                <input *ngIf="doc.nombre== undefined" matInput [disabled]="isReading"
                                    placeholder="Ningún archivo seleccionado">

                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-10 col-xl-5 formMaterial_des padding_des">
                        <label class="col-12 formLabel d-flex">Descripción<span (click)="doc.descripcion= doc.nombre"
                                class="trasladar"> Trasladar el nombre del archivo</span></label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input matInput placeholder="Descripción..." [disabled]="isReading"
                                (keyup)="changeDescr($event,i)" [value]="doc.descripcion?doc.descripcion:''">
                        </mat-form-field>
                    </div>

                    <div class="col-2 col-xl-1 acciones_file">
                        <a class="view-button pointer" (click)="comprobarDoc(doc,'ver')"></a>
                        <a class="download-button pointer" (click)="comprobarDoc(doc,'descargar')"></a>
                        <a [ngClass]="isReading? 'display-delete-button':' delete-button pointer'"
                            (click)=" isReading?'': borrarArchivo(doc);"></a>


                    </div>
                </div>

            </div>
            <div class="button_add" *ngIf="!isReading">
                <button type="button" class="buttonSecondary aniadir"
                    (click)="arrayDocuments[arrayDocuments.length]={}"><mat-icon class="material-symbols-outlined">
                        add</mat-icon> Añadir fila
                </button>
            </div>
        </div>



        <div class="dialog-container" *ngIf="dialogVisible">
            <div class="dialog-content">
                <div class="contenedortextos">
                    <div class="circle_document">
                        <a class="document-div"></a>
                    </div>
                </div>


                <div class="contenedortextos">
                    <h4 class="colorh4">Documentos de la orden de compra</h4>
                </div>
                <div class="contenedortextos2" *ngIf="mostrarArchivos.length==0">
                    <p>Completa un concepto antes de seleccionar el documento</p>
                </div>
                <div *ngIf="mostrarArchivos.length!=0">
                    <div class="contenedortextos2">
                        <p>Seleccione el archivo que quiere ver en el gasto</p>
                    </div>
                    <div class="contenedortextos">
                        <mat-form-field *ngIf="mostrarArchivos.length!=0" appearance="outline" class="col-12 formField">
                            <mat-select class="seleccionar_archivo_select" matInput
                                (selectionChange)="cambiarArchivo($event)" placeholder="Seleccione un documento">
                                <mat-option *ngFor="let item of mostrarArchivos" [value]="item">
                                    <span> nombre: {{ item.nombre }}, descripción: {{ item.descripcion }} </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!--<button class="col-1 color2 botExportar3" ></button>-->

                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button (click)="this.dialogVisible=false" id="btcancel" class="btn btn-primary mx-1 ">Continuar sin
                        archivo</button>
                    <button *ngIf="mostrarArchivos.length!=0" id="btacept" class="btn btn-primary marginl">

                        <span (click)="this.dialogVisible=false" class="align-middle"> Confirmar</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="dialog-container" *ngIf="dialogVisible2">
            <div class="dialog-content">
                <div class="contenedortextos">
                    <div class="circle_document">
                        <a class="monitoring-div"></a>
                    </div>
                </div>
                <div class="contenedortextos">
                    <h4 class="colorh4">Conceptos de la orden de compra</h4>
                </div>
                <div class="contenedortextos2">
                    <p>Seleccione el concepto que quiere ver en el gasto</p>
                </div>


                <div class="contenedortextos">
                    <mat-form-field *ngIf="mostrarConceptos.length!=0" appearance="outline" class="col-12 formField">
                        <mat-select class="seleccionar_archivo_select" matInput
                            (selectionChange)="cambiarCuenta($event)" placeholder="Seleccione un concepto">
                            <mat-option *ngFor="let item of mostrarConceptos" [value]="item">
                                <span>
                                    <p class="margT"> <b>Cuenta:</b> {{ item.codigo.cuenta }}</p>
                                    <p class="margT"><b>Concepto:</b> {{ item.codigo.concepto }}</p>
                                    <p class="margT"> <b> Tipo: </b>{{item.tipoConceptoOc.nombre}}</p>
                                    <p class="margT"> <b> Importe total: </b>{{item.suma}}</p>
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!--<button class="col-1 color2 botExportar3" ></button>-->

                </div>
                <div class="d-flex justify-content-end">
                    <button (click)="dialogVisible2=false" id="btcancel" class="btn btn-primary mx-1 ">Seleccionar
                        cuenta
                        más tarde</button>
                </div>
            </div>
        </div>


    </div>

    <div *ngIf="documetnoOC!=undefined" class="margint">
        <h6 class="encabezados padded-col">Documento asociados</h6>
        <div *ngIf="documetnoOC.base64==undefined && !loadingArchivo " class="sppiner_file">
            <p class="wi_100">Cargando archivo...</p>
            <div class="wi_100">
                <div class=" spinner-border" role="status"></div>
            </div>


        </div>
    </div>
    <div id="visualizararchivo"></div>

    <div class="sppiner-container" *ngIf="loading">
        <div class="spinner-border" role="status"></div>