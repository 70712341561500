<div>
    <app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
        [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
        [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
        [canCreate]="tableService.checkPermiso(23,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
        [canExport]="tableService.checkPermiso(23,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
        [dataExport]="dataExport"></app-header-listados>


    <div class="fondoblanco fondoblanco_tabla">
        <div class="table_container data_table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <!-- numero Column -->
                <span *ngFor="let column of displayedColumns ">
                    <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                            el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                             nombres pero puedes quitar campos que no se quieren listar -->
                    <ng-container *ngIf="column!='acciones'" matColumnDef="{{column}}">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="thdisp ">

                                <input
                                    *ngIf="tableService.checkPro(column,(interfazInicial.isSuperAdmin||interfazInicial.isLicencia))"
                                    matInput type="text" class="searchinput uppercase"
                                    placeholder="{{primLetMay(column)}}" (keyup)="doFilter($event,  column == 'capitulo'? column+'.nombre': column == 'subCapitulo'?'subcapitulo.nombre':
                                 column == 'proyecto'?'proyecto.titulo':column, 'LIKE', 'STRING')"
                                    [ngStyle]="{'min-width.px': tableService.calcTextWidth(column, '600 14px Poppins, sans-serif')}">
                                <a [ngClass]="ordenarCampos[column] ? 'fechaup':'fechadawn'"
                                    (click)="ordenar(column)"></a>
                            </div>
                        </th>
                        <tr>

                            <td *matCellDef="let element" class="celds">
                                <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                    no tienen objetos añadidos seria solo esa linea-->

                                <span class="inline_text"
                                    *ngIf="column != 'capitulo' && column != 'subCapitulo' && column != 'cuenta'&& column != 'proyecto'&&column != 'concepto'">
                                    {{element[column]}}
                                </span>
                                <span class="inline_text" *ngIf="column == 'concepto'">
                                    {{getConcepto(element)}}
                                </span>
                                <span class="inline_text" *ngIf="column == 'capitulo'">
                                    {{element[column].nombre}}
                                </span>
                                <span class="inline_text" *ngIf="column == 'subCapitulo'">
                                    {{element["subcapitulo"].codigo|codigosCuentas}} {{element["subcapitulo"].nombre}}
                                </span>
                                <span class="inline_text" *ngIf="column == 'cuenta'">
                                    {{element[column]|codigosCuentas}}{{element["codigoHijo"]?element["codigoHijo"]!='00'?"."+(element["codigoHijo"]):'':''
                                    }}
                                </span>
                                <span class="inline_text"
                                    *ngIf="column == 'proyecto' && tableService.checkPro(column,(interfazInicial.isSuperAdmin||interfazInicial.isLicencia))">
                                    {{element["proyectos"]?element["proyectos"][0]?element["proyectos"][0].titulo:'':'General'}}
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                </span>

                <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                            class="correccionmargenAcciones uppercase">
                            Acciones</span>
                    </th>
                    <td *matCellDef="let element" class="accionesth">
                        <div class="acciones" stickyEnd>
                            <a [ngClass]="tableService.checkPermiso(23,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'display-view-button '"
                                (click)=" tableService.checkPermiso(23,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':''"></a>
                            <a [ngClass]="tableService.checkPermiso(23,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-edit-button':'edit-button pointer'"
                                (click)=" tableService.checkPermiso(23,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'editar')"></a>
                            <a [ngClass]="tableService.checkPermiso(23,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-download-button':'display-download-button '"
                                (click)=" tableService.checkPermiso(23,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':''"></a>
                            <a [ngClass]="!tableService.checkPermiso(23,3,interfazInicial.isSuperAdmin,interfazInicial.permisos) && !element['codigoHijo']? 'display-delete-button':'delete-button pointer'"
                                (click)=" !tableService.checkPermiso(23,3,interfazInicial.isSuperAdmin,interfazInicial.permisos) && !element['codigoHijo']?'':navegar(element.id,'editar')"></a>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                    (mouseenter)="tableService.marcarColumna(row,true)"
                    (mouseleave)="tableService.marcarColumna(row,false)" (click)="tableService.marcarColumna(row,null)"
                    *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>

        <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
            (page)="nextPage($event)">
        </mat-paginator>
    </div>
</div>