import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {

  constructor() { }

  eliminar(key: string) {
    sessionStorage.removeItem(key)
  }
  guardar(key: string, data: any) {
    sessionStorage.setItem(key, data)
  }
  get(key: string): any {
    return sessionStorage.getItem(key)
  }
  vaciar() {
    sessionStorage.removeItem('ocForm');
    sessionStorage.removeItem('conceptosForm');
    sessionStorage.removeItem('formProvedor');
    sessionStorage.removeItem('presuForm');
    sessionStorage.removeItem('productoraForm');
    sessionStorage.removeItem('proveedorForm');
    sessionStorage.removeItem('proyForm');
    sessionStorage.removeItem('gastoForm');
    sessionStorage.removeItem('conceptoGastoForm');
    sessionStorage.removeItem('pagoGastoForm')
    sessionStorage.removeItem('userForm');

  }

  guardarLocal(key: string, data: any) {
    localStorage.setItem(key, data)
  }
  getLocal(key: string): any {
    return localStorage.getItem(key)
  }
}
