import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
 
 
  API_URL = environment.API_URL;

  constructor(private http: HttpClient) {}

  getNotificaciones(request:any) {
    
    return this.http.post(`${this.API_URL}/notificaciones/filter`, request);
  }

  findByUsuario(usuario: any, proyecto:any) {
    let request={
      idUsuario:usuario,
      idProyecto:proyecto
    }
    return this.http.post(`${this.API_URL}/notificaciones/findByUsuario`, request);
  }

  actualizarEstado( idNotificación:any) {
    let request={
      idNotificación:idNotificación
    }
    return this.http.post(`${this.API_URL}/notificaciones/actualizarEstado`, request);  }


    send(usuario: any, proyecto:any, ordenCompra:any) {
      let request={
        idUsuario:usuario,
        idProyecto:proyecto,
        ordenCompra: ordenCompra
      }
      return this.http.post(`${this.API_URL}/notificaciones/sendNotificaciones`, request);  }
    

}
