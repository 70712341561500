import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codigosCuentas'
})
export class CodigosCuentasPipe implements PipeTransform {
  /* 
  Metodo que se encarga de tranformar los numeros de cuenta del 
  formato del back al formato del front que est 2 num . y asi consecutivamente
  */
  transform(value: string): string | undefined {
    if (!value) return '';
    return value.match(/.{1,2}/g)?.join('.');

  }

}
