<div>

    <app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
        [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
        [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
        [canCreate]="tableService.checkPermiso(19,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
        [canExport]="tableService.checkPermiso(19,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
        [dataExport]="dataExport"></app-header-listados>

    <div class="fondoblanco fondoblanco_tabla">

        <div class="table_container data_table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <!-- numero Column -->
                <span *ngFor="let column of displayedColumns ">
                    <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                                el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                                 nombres pero puedes quitar campos que no se quieren listar -->
                    <ng-container *ngIf="column!='acciones'" matColumnDef="{{column}}">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="thdisp"
                                *ngIf=" column == 'titularidad' || column == 'residencia'  || column == 'contratistas' || column == 'situCensal' ">
                                <span  class="inline_text uppercase textwe600">{{primLetMay(column)}}</span>
                            </div>
                            <div class="thdisp"
                                *ngIf=" column != 'titularidad' && column != 'residencia'  && column != 'contratistas' && column != 'situCensal' ">

                                <input
                                    *ngIf="tableService.checkPro(column,interfazInicial.isSuperAdmin) && column!='documentos'"
                                    matInput type="text" class="searchinput uppercase" placeholder="{{column=='cif'?'CIF/NIF':primLetMay(column)}}"
                                    (keyup)="doFilter($event,  column == 'iban'?'bancosIban.iban':
                                    column == 'banco'?'bancosIban.nombreBanco':column, 
                                    column == 'banco'||column =='telefonos'||column =='iban'?'CONTAINS':'LIKE', 'STRING')"
                                    [ngStyle]="{'min-width.px': tableService.calcTextWidth(column, '600 14px Poppins, sans-serif')}">
                                <a [ngClass]="ordenarCampos[column] ? 'fechaup':'fechadawn'"
                                    (click)="ordenar(column)"></a>
                            </div>
                        </th>
                        <tr>

                            <td *matCellDef="let element" class="celds">
                                <span class="inline_text"
                                    *ngIf="column != 'proyectos'&&column != 'residencia'&&column != 'situCensal'&&column != 'contratistas'&&column != 'titularidad' && column!='documentos'">
                                    {{element[column]}}
                                </span>
                                <span class="inline_text"
                                    *ngIf="column == 'proyectos' &&tableService.checkPro(column,interfazInicial.isSuperAdmin) ">
                                    <label>{{tableService.checkLength(element[column],"titulo")}}</label>
                                </span>
                                <span class="center"
                                    *ngIf="column == 'residencia'||column == 'situCensal'||column == 'contratistas'||column == 'titularidad' ">
                                    <mat-icon *ngIf="element[column]" class="material-symbols-outlined colorBlue">
                                        done
                                    </mat-icon>
                                    <mat-icon *ngIf="!element[column]" class="material-symbols-outlined colorRed">
                                        close
                                    </mat-icon>
                                </span>

                            </td>
                        </tr>
                    </ng-container>
                </span>

                <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class=""><span class="correccionmargenAcciones uppercase">
                            Acciones</span>
                    </th>
                    <td *matCellDef="let element" class="accionesth">
                        <div  class="acciones accionesth" stickyEnd>
                            <a title="Ver" [ngClass]="tableService.checkPermiso(19,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                                (click)=" tableService.checkPermiso(19,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'ver')"></a>
                            <a title="Editar" [ngClass]="tableService.checkPermiso(19,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-edit-button':'edit-button pointer'"
                                (click)=" tableService.checkPermiso(19,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'editar')"></a>
                            <a title="Descargar" [ngClass]="tableService.checkPermiso(19,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-download-button':'download-button pointer'"
                                (click)=" tableService.checkPermiso(19,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':download(element)"></a>
                            <a title="Borrar" [ngClass]="tableService.checkPermiso(19,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-delete-button':'delete-button pointer'"
                                (click)=" tableService.checkPermiso(19,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':dialogVisible = true; idEliminar = element"></a>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}" (mouseenter)="tableService.marcarColumna(row,true)" (mouseleave)="tableService.marcarColumna(row,false)"  (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
        <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
            (page)="nextPage($event)">
        </mat-paginator>
    </div>

    

    <div class="dialog-container" *ngIf="dialogVisible">
        <div class="dialog-content">
          <div class="contenedortextos">
            <div class="circle_document">
                <a class="delete-div"></a>
            </div>
        </div>
        <div class="contenedortextos">
            <h4 class="colorh4">¿Desea eliminar el proveedor {{idEliminar.nombreFiscal}}?</h4>
            </div>
            <div class="contenedortextos2">
              <p>Se dará de baja a el proveedor para que no se vea afectada la información relacionada.</p>
            </div>
            <div class="dialog_buttons">
            <button (click)="dialogVisible = false" id="btcancel" class="btn btn-primary mx-1 mt-3">Cancelar</button>
            <button id="btacept" class="btn btn-primary mx-1 mt-3">
                <div></div>
                <span (click)="delete(idEliminar.id)"  class="align-middle"> Confirmar</span>
            </button>
            </div>
        </div>
    </div>
</div>