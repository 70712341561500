import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NomId } from '../models/orden-compra';

@Injectable({
  providedIn: 'root'
})
export class OrdenCompraService {

  /* 
  Servicio que se encarga de las peticiones de toda las modificaciones, guardado y listados de ordenes de compra
  */


  url: string = environment.API_URL + "/oc";
  urlTiposUnidad: string = environment.API_URL + "/tipoUnidad";
  urlTiposOC: string = environment.API_URL + "/tipoConceptoOc";
  urlPeriocidadPagoOC: string = environment.API_URL + "/periodicidadPago";
  urlTipoPagoOC: string = environment.API_URL + "/tipoPagoOc";
  urlTipoDocOC: string = environment.API_URL + "/tipoDocumentoOc";


  constructor(private http: HttpClient
  ) { }

  list() {
    return this.http.get(`${this.url}/list`);
  }
  filter(filter: any): Observable<any> {
    return this.http.post(`${this.url}/filter`, filter);
  }

  getAllTiposUnidades(): Observable<any> {
    return this.http.get(`${this.urlTiposUnidad}/list`);
  }
  getAllTiposOC(): Observable<any> {
    return this.http.get(`${this.urlTiposOC}/list`);
  }
  getAllTiposDocumentoOC(): Observable<any> {
    return this.http.get(`${this.urlTipoDocOC}/list`);
  }
  getAllTiposPagosOC(): Observable<any> {
    return this.http.get(`${this.urlTipoPagoOC}/list`);
  }
  getAllPeriocidadPagosOC(): Observable<any> {
    return this.http.get(`${this.urlPeriocidadPagoOC}/list`);
  }
  postFechasPagosOC(periodicidadPago: NomId, numeroPagos: number, fechaInicio: string): Observable<any> {
    return this.http.post(`${this.url}/calcularFechasConsecutivas`, { periodicidadPago, numeroPagos, fechaInicio });
  }
  save(ordenCompra: any): Observable<any> {
    console.log(ordenCompra)
    return this.http.post(`${this.url}`, ordenCompra);
  }
  update(id: any, ordenCompra: any): Observable<any> {
    console.log(ordenCompra);

    return this.http.post(`${this.url}/${id}`, ordenCompra);
  }
  findById(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }
  download(idsNext: any) {
    return this.http.post(`${this.url}/download`, idsNext, { responseType: 'arraybuffer' });
  }


  getTotalOrdenDeCompraProyecto(id: any): Observable<any> {

    return this.http.get(`${this.url}/getTotalOrdenDeCompraProyecto/${id}`);
  }


  actualizarEstado(tipoUsuario: any, id: any, actualizar: boolean, mensaje: String, idUsuario:number) {
    let request = {
      tipoUsuario: tipoUsuario,
      idOrdenCompra: id,
      actualizar: actualizar,
      mensaje: mensaje,
      idUsuario: idUsuario
    }
    return this.http.post(`${this.url}/updateEstado`, request);
  }

  deleteById(id: number) {
    return this.http.post(`${this.url}/delete`, { id: id });
  }

  cerrarOc(id: any): Observable<any> {
    return this.http.get(`${this.url}/cerrarOc/${id}`);
  }
}
