<div>
    <div class="topInfo">
        <label class="titulosPaginas">PERFIL USUARIO</label>
    </div>

    <div class="form-surrouded">
        <form class="rowForm col-12" [formGroup]="form">

            <div class="col-3">

                <div class="infoizq">

                    <div class="iconName rounded-circle">
                        
                        <h2 *ngIf="usuario.imgPerfil==undefined || usuario.imgPerfil==null">
                            {{getFirstLetter(usuario.nombreUsuario)}}
                        </h2>
                        <img *ngIf="usuario?.imgPerfil!=undefined" src="data:image;base64,{{img}}" width="100%"
                        height="100%" class="rounded-circle">
                        <div class="editIcono rounded-circle pointer">
                            <img class="white pointer" src="./assets/icons/edit_white.svg"
                                (click)="isChangePhoto = !isChangePhoto">
                            <div *ngIf="isChangePhoto" class="modalChangePhoto">
                                <div class="d-flex justify-content-between">

                                    <div class="subir pointer">
                                        <mat-icon class="material-icons">
                                            publish
                                        </mat-icon>
                                        <label>Subir foto</label>
                                        <input value="file" name="file" class="seleccionarArchivo pointer"
                                            (change)="onFileChange($event)" onclick="event.target.value=null"
                                            type="file" />
                                    </div>

                                    <div class="eliminar pointer" (click)="deleteFoto()">
                                        <mat-icon class="material-icons">
                                            delete
                                        </mat-icon>
                                        <label>Eliminar</label>
                                    </div>
                                </div>
                                <div class="textModal">
                                    <label>El tamaño de la imagen no puede ser superior a 1Mb. Tamaño recomendado 500
                                        ppp x 500 ppp</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="mt-4 mb-0"><b>{{usuario.nombreUsuario}}</b></p>
                    <p class="mb-2">{{usuario.email}}</p>

                    <!--
                    <div class="formMaterial mt-5">
                        <label class="textCenter formLabel">¿Quieres activar el doble factor de autenticación?</label>
                        <div class="contenedor-boton justify-content-center">

                            <button class="buttonPrimary "
                                (click)="updateTwoFa()">{{isTwoFaActivo?"Desactivar":"Activar"}}</button>
                        </div>
                    </div>
                      -->
                </div>

            </div>
            <mat-divider vertical class="divider col-1"></mat-divider>

            <div class="col-8 infoder">

                <label class="col-2 formLabel">Nombre Usuario</label>
                <mat-form-field appearance="outline" class="col-4 formField desactivado">
                    <input formControlName="nombreUsuario" matInput placeholder="Introduzca el nombre de usuario...">
                </mat-form-field>

                <label class="col-2 formLabel pad">Cargo</label>
                <mat-form-field appearance="outline" class="col-4 formField desactivado">
                    <input formControlName="cargo" matInput placeholder="Introduzca el cargo...">
                </mat-form-field>

                <label class="col-2 formLabel">Empresa</label>
                <mat-form-field appearance="outline" class="col-10 formField desactivado">
                    <input formControlName="empresa" matInput placeholder="Introduzca el nombre de la empresa...">
                </mat-form-field>

              <!--   <label class="col-2 formLabel">Dirección</label>
                <mat-form-field appearance="outline" class="col-10 formField">
                    <input formControlName="direccion" matInput placeholder="Introduzca la dirección...">
                </mat-form-field> -->

                <div class="col-12">
             <!--        <label class="col-2 formLabel">Código Postal</label>
                    <mat-form-field appearance="outline" class="col-4 formField">
                        <input type="number" formControlName="codigoPostal" mask="9{5}" matInput
                            placeholder="Introduzca el código postal...">
                            <mat-error
                            *ngIf="this.form.controls['codigoPostal'].errors?.['pattern']!= undefined">
                            La longitud del código postal tiene que ser 5</mat-error>
                        <mat-error
                            *ngIf="this.form.controls['codigoPostal'].errors?.['required']!= undefined">Código Postal requerido</mat-error>
                    
                    </mat-form-field> -->
                    <label class="col-2 formLabel">Teléfono</label>
                    <mat-form-field appearance="outline" class="col-4 formField">
                        <input formControlName="telefono" mask="9{9}" matInput placeholder="Introduzca el teléfono...">
                    </mat-form-field>
                    <label class="col-2 formLabel pad">Ciudad</label>
                    <mat-form-field appearance="outline" class="col-4 formField">
                        <input formControlName="ciudad" matInput placeholder="Introduzca la ciudad...">
                    </mat-form-field>
                </div>

               

                <label class="col-2 formLabel">Departamento</label>
                <mat-form-field appearance="outline" class="col-10 formField desactivado">
                    <mat-select placeholder="Seleccione departamento..." formControlName="departamento"
                        [compareWith]="compararObjetos" [(value)]="usuario.departamento">
                        <mat-option *ngFor="let departamento of departamentos"
                            [value]="departamento">{{departamento.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <p class="col-12 dAcc"><b>Datos Acceso</b></p>
                <div class="col-12 d-flex">
                    <label class="col-2 formLabel">Email</label>
                    <mat-form-field appearance="outline" class="col-6 formField desactivado">
                        <input formControlName="email" matInput placeholder="Introduzca el email...">
                    </mat-form-field>

                    <div class="col-4 textPass">

                        <a class="pointer" (click)="isPassChan=!isPassChan"><img
                                src="./assets/icons/edit_blue.svg"><b>{{!isPassChan? "Cambiar contraseña":
                                "Ocultar cambiar contraseña"}}</b></a>
                    </div>
                </div>
                <div *ngIf="isPassChan" class="col-12">
                    <label class="col-2 formLabel">Nueva Contraseña</label>
                    <mat-form-field appearance="outline" class="col-4 formField">
                        <input formControlName="password" type="{{showPass ? 'text' : 'password' }}" matInput
                            placeholder="Introduzca la contraseña...">
                        <mat-icon class="material-icons eye-b pointer" (click)="showPass = !showPass">
                            visibility
                        </mat-icon>
                    </mat-form-field>

                    <label class="col-2 formLabel pad">Repita contraseña</label>
                    <mat-form-field appearance="outline" class="col-4 formField">
                        <input formControlName="reppassword" type="{{showRepPass ? 'text' : 'password' }}" matInput
                            placeholder="Repita la contraseña...">
                        <mat-icon class="material-icons eye-b pointer" (click)="showRepPass = !showRepPass">
                            visibility
                        </mat-icon>
                    </mat-form-field>
                </div>

                <div class="contenedor-boton justify-content-right">

                    <button class="btn buttonPrimary" (click)="actuUsu()">Guardar</button>
                </div>
            </div>
        </form>

    </div>
</div>